import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListContainerHistoryPage = React.lazy(() => import('./ListContainerHistoryPage'));
const ViewContainerHistoryPage = React.lazy(() => import('./ViewContainerHistoryPage'));

const ContainerHistoryRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListContainerHistoryPage t={t} />} />
      <Route exact path="/:sr_no" element={<ViewContainerHistoryPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ContainerHistoryRoutes.propTypes = {
  t: PropTypes.func
};
export default ContainerHistoryRoutes;
