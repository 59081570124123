import client from '@libs/HttpClient';

class StockServices {
  static updateStock(request) {
    return client.post('stocks/update-stock-data', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static getListStock(request) {
    const warehouse_id = request.queryKey[1];
    const page_no = request.queryKey[2];
    const searchText = request.queryKey[3];
    const filterType = request.queryKey[4];
    const actionBy = request.queryKey[5];
    const fromDate = request.queryKey[6];
    const toDate = request.queryKey[7];
    const sortBy = request.queryKey[8];
    const sortOrder = request.queryKey[9];

    return client.get(
      `/stocks/get-stock-data?page=${page_no}&search_text=${searchText}&warehouse_id=${warehouse_id}&type=${filterType}&action_by=${actionBy}&from_date=${fromDate}&to_date=${toDate}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static getListStockByWarehouse(request) {
    const warehouse_id = request.queryKey[1];
    const fromDate = request.queryKey[2];
    const toDate = request.queryKey[3];

    return client.get(
      `/stocks/get-stock-data?warehouse_id=${warehouse_id}&from_date=${fromDate}&to_date=${toDate}`,
      request
    );
  }

  static ownWarehouseList(request) {
    return client.get('stocks/get-own-warehouse-list', request);
  }

  static containerVariableList(request) {
    return client.get('stocks/get-container-variable', request);
  }

  static generateReport(request) {
    return client.post(`/stocks/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}

export { StockServices };
