import { useMutation, useQuery } from 'react-query';

import { InvoiceService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
//
const useGetInvoiceList = (
  [
    currentPage,
    fromDate,
    toDate,
    source_id,
    payout_status,
    transporter_id,
    factory_id,
    searchTextFilter,
    agentId,
    sortOrder,
    sortBy
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      '/finance/invoice/list',
      currentPage,
      fromDate,
      toDate,
      source_id,
      payout_status,
      transporter_id,
      factory_id,
      searchTextFilter,
      agentId,
      sortOrder,
      sortBy
    ],
    InvoiceService.getInvoiceList,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useGetTransporterInvoiceList = (onSuccess, onError = onDefaultError) => {
  return useQuery('finance/invoice/transporter-list', InvoiceService.transporterList, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};

const useGetInvoiceView = (invoice_master_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'finance/invoice/transporter-view',
    () => InvoiceService.invoiceView(invoice_master_id),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useGetFactoryInvoiceList = (onSuccess, onError = onDefaultError) => {
  return useQuery('finance/invoice/factory-list', InvoiceService.factoryList, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};

const useGetAgentInvoiceList = (onSuccess, onError = onDefaultError) => {
  return useQuery('finance/invoice/agent-list', InvoiceService.agentList, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};

const useCancelInvoice = ([invoice_master_id], onSuccess, onError = onDefaultError) => {
  const sent = invoice_master_id ? true : false;

  return useQuery(
    ['finance/invoice/cancel-invoice', invoice_master_id],
    InvoiceService.cancelInvoice,
    {
      onSuccess,
      onError,
      enabled: sent,
      retry: 0
    }
  );
};

const useAddNotesMarkPaid = (onSuccess, onError = onDefaultError) => {
  return useMutation(InvoiceService.addPaidNote, {
    onSuccess,
    onError
  });
};

export {
  useGetInvoiceList,
  useGetTransporterInvoiceList,
  useGetFactoryInvoiceList,
  useCancelInvoice,
  useGetAgentInvoiceList,
  useAddNotesMarkPaid,
  useGetInvoiceView
};
