import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const UnloadCollectTruck = React.lazy(() => import('./ListUnloadCollectTruckPage'));
const UnloadCollectTruckDetails = React.lazy(() => import('./ViewUnloadCollectTruckDetails'));

const UnloadCollectTruckRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index path="/list" element={<UnloadCollectTruck t={t} />} />
      <Route
        path="/view/:delivery_point_current_session_id"
        element={<UnloadCollectTruckDetails t={t} />}
      />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
UnloadCollectTruckRoutes.propTypes = {
  t: PropTypes.func
};
export default UnloadCollectTruckRoutes;
