import { useQuery, useMutation } from 'react-query';

import { MenuMasterService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
//
const useMenuListGet = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['use-menu-master-get', [params]], () => MenuMasterService.getMenuList(params), {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};

const useGetMasterReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(MenuMasterService.generateReport, {
    onSuccess,
    onError
  });
};

export { useMenuListGet, useGetMasterReport };
