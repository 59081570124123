import { useMutation, useQuery } from 'react-query';

import { CouponService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

/* Coupons */
const useListCoupon = (
  [page_no, searchText, selectedStatus, selectedType, setCouponAgents, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'coupon-list',
      page_no,
      searchText,
      selectedStatus,
      selectedType,
      setCouponAgents,
      sortBy,
      sortOrder
    ],
    CouponService.listCoupon,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useListArchieveCoupon = (
  [page_no, searchText, selectedStatus, selectedType, setCouponAgents, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'coupon-list-archived',
      page_no,
      searchText,
      selectedStatus,
      selectedType,
      setCouponAgents,
      sortBy,
      sortOrder
    ],
    CouponService.listArchieveCoupon,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useAddCoupon = (onSuccess, onError = onDefaultError) => {
  return useMutation(CouponService.addCoupon, {
    onSuccess,
    onError
  });
};
const useCouponArchieve = ([Coupon_id, type], onSuccess, onError = onDefaultError) => {
  const sent = Coupon_id ? true : false;
  return useQuery(['coupon-archieve', Coupon_id, type], CouponService.archieveCoupon, {
    onSuccess,
    onError,
    enabled: sent,
    retry: 0
  });
};
const useViewCoupon = (Coupon_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['coupon-view', Coupon_id], CouponService.viewCoupon, {
    onSuccess,
    onError
  });
};

const useUpdateCoupon = (onSuccess, onError = onDefaultError) => {
  return useMutation(CouponService.updateCoupon, {
    onSuccess,
    onError
  });
};

const useProducerTypeList = (onSuccess, onError = onDefaultError) => {
  return useQuery('producer-type-list', CouponService.getProducerTypeList, {
    onSuccess,
    onError
  });
};

const useSubProducerTypeList = (id, onSuccess, onError = onDefaultError) => {
  return useQuery({
    queryKey: 'sub-producer-type-list',
    queryFn: () => CouponService.getSubProducerTypeList(id),
    onSuccess,
    onError,
    enabled: false
  });
};

const useProducerList = (onSuccess, onError = onDefaultError) => {
  return useQuery('producer-list', CouponService.getProducerList, {
    onSuccess,
    onError
  });
};

const useContainerList = (onSuccess, onError = onDefaultError) => {
  return useQuery('container-list', CouponService.getContainerList, {
    onSuccess,
    onError
  });
};

const useCouponAgentList = (onSuccess, onError = onDefaultError) => {
  return useQuery('container-list', CouponService.getAgentCouponList, {
    onSuccess,
    onError
  });
};

const useGenerateCouponReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(CouponService.generateReport, {
    onSuccess,
    onError
  });
};

export {
  useListCoupon,
  useListArchieveCoupon,
  useAddCoupon,
  useCouponArchieve,
  useViewCoupon,
  useUpdateCoupon,
  useProducerTypeList,
  useSubProducerTypeList,
  useProducerList,
  useContainerList,
  useCouponAgentList,
  useGenerateCouponReport
};
