import { useQuery, useMutation } from 'react-query';

import { AgentPayoutService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
//
const useGetAgentPayout = (
  [currentPage, fromDate, toDate, invoiceStatus, searchTextFilter, referenceId, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'ageny-payout-list',
      currentPage,
      fromDate,
      toDate,
      invoiceStatus,
      searchTextFilter,
      referenceId,
      sortBy,
      sortOrder
    ],
    AgentPayoutService.agentPayoutList,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useGetAgentFilterList = (onSuccess, onError = onDefaultError) => {
  return useQuery('agent-payout-filter-agent', AgentPayoutService.agentListFilter, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};

const useGetAgentViewDetail = (
  date,
  type,
  reference_id,
  per_kg_commision,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    'agent-view-detail-get',
    () => AgentPayoutService.agentPayoutView(date, type, reference_id, per_kg_commision),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useGenerateAgentReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(AgentPayoutService.generateReport, {
    onSuccess,
    onError
  });
};

export { useGetAgentPayout, useGetAgentFilterList, useGetAgentViewDetail, useGenerateAgentReport };
