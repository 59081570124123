import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, NavDropdown, Navbar, Row, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logoutSuccess, loggedUser } from '../../../store/features/authSlice';

import { decrementValue, currentVal } from '../../../store/features/counterMessageSlice';

import { LogoUrlFront } from '../index';
import { useLogout, useLatestNotification, useReadNotification } from '@hooks';

function useOutsideAlerter(ref, setShow) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setShow]);
}

const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEditProfile = () => {
    navigate('/edit-profile');
  };
  const handleLogout = () => {
    doUserLogout();
  };
  const checkLoggenInUser = useSelector(loggedUser);
  const currentValData = useSelector(currentVal);

  // const [isActive, setActive] = useState(false);

  const closesidebar = () => {
    props.toggleClass(false);
  };
  const { t } = props;
  const UserMenu = (
    <svg width="9" height="21" viewBox="0 0 9 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="4.44691" cy="4.49619" rx="1.44691" ry="1.49619" fill="#9592A6" />
      <ellipse cx="4.44691" cy="10.4886" rx="1.44691" ry="1.49619" fill="#9592A6" />
      <ellipse cx="4.44691" cy="16.4809" rx="1.44691" ry="1.49619" fill="#9592A6" />
    </svg>
  );

  const { mutate: doUserLogout } = useLogout(
    (response) => {
      dispatch(logoutSuccess());
      window.addEventListener('storage', (e) => {
        e.isLogout = 1;
      });
      navigate('/login');
      toast.success(response.message);
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );

  /**
   * ! this function is used for mark as read notification
   */
  const { mutate: doReadNotification } = useReadNotification(
    (response) => {
      refetch();
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );

  const [show, setShow] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setShow);

  /**
   * !This Function will call for listing of notifications
   */
  const { data: notification_list, refetch } = useLatestNotification();

  if (!notification_list) {
    return null;
  }

  /**
   * !This Function will call when user clicks on View Button
   */
  const handleViewClick = (tdata) => {
    navigate(`/notification/list`);
  };

  const handleClick = (
    redirect_id,
    redirect_url,
    alliance_type,
    alliance_portal_id,
    status,
    notification_list_id
  ) => {
    if (status === 1) {
      const dataStore = {
        count: notification_list.data.unread
      };
      dispatch(decrementValue(dataStore));
    }

    /**
     * ! when read notification Api call..
     */
    doReadNotification({ notification_list_id });

    /**
     * ! redirection on bases of key...
     */

    if (redirect_url === 'admin-dp-user-complete-unloading-collect-truck') {
      navigate('/tours/unload-collect-truck/view/' + redirect_id);
    }
    if (redirect_url === 'admin-dp-user-start-load-transport-truck') {
      navigate('/tour/load-transport-truck/view/' + redirect_id);
    }

    if (
      redirect_url === 'admin-new-warehouse-register' ||
      redirect_url === 'warehouse-portal-update-contract-status-notification'
    ) {
      navigate('/warehouse/view/' + redirect_id);
    }

    if (
      redirect_url === 'admin-new-factory-register' ||
      redirect_url === 'factory-portal-update-contract-status-notification'
    ) {
      navigate('/factory/view/' + redirect_id);
    }

    if (
      redirect_url === 'admin-new-transporter-register' ||
      redirect_url === 'transporter-portal-update-contract-status-notification'
    ) {
      navigate('/transporter/view/' + redirect_id);
    }

    if (redirect_url === 'admin-new-producer-register') {
      navigate('/producer/view/' + redirect_id);
    }

    if (redirect_url === 'admin-driver-data-update') {
      navigate('/transporter/driver/view/' + alliance_portal_id + '/' + redirect_id);
    }
    if (redirect_url === 'admin-container-start-scanning-update') {
      navigate('/orders/detail/' + redirect_id);
    }
    if (redirect_url === 'admin-delivery-point-data-update') {
      if (alliance_type === 3) {
        navigate('/factory/' + alliance_portal_id + '/delivery/point/user/view/' + redirect_id);
      } else {
        navigate('warehouse/' + alliance_portal_id + '/delivery/point/user/view/' + redirect_id);
      }
    }
    if (redirect_url === 'producer-profile-update') {
      navigate('/users/edit/' + redirect_id);
    }
    if (redirect_url === 'producer-data-update') {
      navigate('/producer/view/' + redirect_id);
    }
    if (redirect_url === 'producer-collection-hold-unhold-update') {
      navigate('/producer/view/' + redirect_id);
    }
    if (redirect_url === 'admin-stock-update') {
      navigate('/stocks-history/list');
    }
    if (redirect_url === 'admin-un-attempt-tour-notification') {
      navigate('/tour/available_collect/list');
    }
    if (redirect_url === 'admin-raised-invoice') {
      navigate('/finance/invoice/view/' + redirect_id);
    }

    if (redirect_url === 'admin-dp-user-completed-incineration-process') {
      navigate('/tour/completed-incineration/details/' + redirect_id);
    }

    if (redirect_url === 'driver-start-tour-admin-notification') {
      navigate('/tours/current/view/' + redirect_id);
    }

    if (redirect_url === 'driver-complete-driver-tour-admin-notification') {
      navigate('/tours/completed/view/' + redirect_id);
    }

    if (redirect_url === 'driver-arrive-destination-admin-notification') {
      navigate('/tours/completed/view/' + redirect_id);
    }

    if (redirect_url === 'admin-dp-user-complte-unloading-transport-truck') {
      navigate('/tour/transport-truck-unload/details/' + redirect_id);
    }

    if (redirect_url === 'admin-dp-user-complete-load-transport-truck') {
      navigate('/tour/load-transport-truck/view/' + redirect_id);
    }

    if (redirect_url.endsWith('/')) {
      navigate(redirect_url + redirect_id);
    }
  };

  return (
    <>
      <Navbar className="sticky-top navbar-section">
        <Container fluid className="container-section">
          <Navbar.Brand className="dashboard-logo">
            {/* <img src={'/admin' + LogoImage} alt="logo" width={'100%'} className="nav-brand" /> */}
            <LogoUrlFront />
          </Navbar.Brand>

          <div className="header-setting-menu">
            {/* notification */}
            <div className="notification-bell" ref={wrapperRef} onClick={() => setShow((s) => !s)}>
              {currentValData.countermessage.count === 0 ? null : (
                <div className="unread-cound">{currentValData.countermessage.count}</div>
              )}
              <FontAwesomeIcon icon={faBell} />
              {show && (
                <div className="notification">
                  <div className="notification-panel">
                    <div className="notification-header">
                      <Row className="align-items-center">
                        <Col>
                          <h3 className="mb-0">{t('page.notification_header_label')}</h3>
                        </Col>
                      </Row>
                    </div>
                    <div className="notification-content">
                      {notification_list.data.notification_list.length === 0 ? (
                        <div className="text-center mt-4 fw-bold">
                          <p>{t('page.no_notification_found_label')}</p>
                        </div>
                      ) : (
                        notification_list.data.notification_list.map((data, index) => {
                          return (
                            <div className="notification-messages" key={index}>
                              <div className="notification-single-message">
                                <Row
                                  className="w-100"
                                  onClick={() =>
                                    handleClick(
                                      data.redirect_id,
                                      data.redirect_url,
                                      data.alliance_type,
                                      data.alliance_portal_id,
                                      data.status,
                                      data.notification_list_id
                                    )
                                  }>
                                  <Col xs={2} sm={1}>
                                    <div className="message-bell">
                                      <FontAwesomeIcon icon={faBell} />
                                    </div>
                                  </Col>
                                  <Col xs={10} sm={11}>
                                    <h4>{data.title}</h4>
                                    <p>{data.message}</p>
                                    <span>{data.created_at}</span>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                    {/* <div className="notification-buttons text-center mt-3"> */}
                    <div
                      className={
                        notification_list.data.notification_list.length === 0
                          ? 'd-none'
                          : 'notification-buttons text-center mt-3'
                      }>
                      <Button
                        variant="primary"
                        className="mx-2 secondary-remove-button"
                        onClick={handleViewClick.bind(this)}>
                        {t('page.notification_view_all_label')}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* end notification */}
            <div className="dashboard-toggle-btn">
              <FontAwesomeIcon
                icon={faBars}
                onClick={() => {
                  props.toggleClass(!props.active);
                }}
              />
            </div>
            <div className="desktop-login-name">
              <span>
                {checkLoggenInUser.user.first_name + ' ' + checkLoggenInUser.user.last_name}
              </span>
            </div>

            <div className="profile-img">
              <img
                src={checkLoggenInUser.user.profile_image}
                width="40px"
                height="40px"
                alt="profile_img"
              />
            </div>
            <NavDropdown title={UserMenu} onClick={closesidebar}>
              <div className="mobile-login-name">
                <span>
                  {checkLoggenInUser.user.first_name + ' ' + checkLoggenInUser.user.last_name}
                </span>
                <NavDropdown.Divider className="separator" />
              </div>
              <NavDropdown.Item className="setting-items" onClick={handleEditProfile}>
                {t('page.header_editprofile')}
              </NavDropdown.Item>
              <NavDropdown.Item className="setting-items" onClick={handleLogout}>
                {t('page.header_logout')}
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

Header.propTypes = {
  toggleClass: PropTypes.any.isRequired,
  t: PropTypes.func,
  refetch: PropTypes.func,
  active: PropTypes.any
};

export { Header };
