import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { imagePreviewFromik } from '@helpers/';

const B3DriverCertificateDetails = (props) => {
  const b3_driver_certificate_attachmentRef = useRef();

  const { t, formik } = props;

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_driver_general_details_b3_driver_certificate_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.b3_driver_certificate && formik.errors.b3_driver_certificate
                    ? 'form-field-error'
                    : formik.touched.b3_driver_certificate && !formik.errors.b3_driver_certificate
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="b3_driver_certificate"
                placeholder={t('page.add_driver_general_details_b3_driver_certificate_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.b3_driver_certificate}
              />
              <div className="form-field-error-text">
                {formik.touched.b3_driver_certificate && formik.errors.b3_driver_certificate ? (
                  <div>{t(formik.errors.b3_driver_certificate)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label">
                {t('page.add_driver_b3_driver_certificate_attachment_label')}
              </Form.Label>
              <Form.Control
                className={'d-none'}
                type="file"
                accept="image/*,.pdf"
                ref={b3_driver_certificate_attachmentRef}
                name="b3_driver_certificate_attachment"
                placeholder={t('page.add_driver_b3_driver_certificate_attachment_placeholder')}
                onChange={(event) => {
                  formik.setFieldValue(
                    'b3_driver_certificate_attachment',
                    event.currentTarget.files[0]
                  );
                }}
                hidden
              />
              <Button
                type="button"
                onClick={() => b3_driver_certificate_attachmentRef.current?.click()}
                className={
                  'upload-button' ||
                  (formik.touched.b3_driver_certificate_attachment &&
                  formik.errors.b3_driver_certificate_attachment
                    ? 'form-field-error'
                    : formik.touched.b3_driver_certificate_attachment &&
                      !formik.b3_driver_certificate_attachment
                    ? 'form-field-success'
                    : '')
                }>
                {t('page.file_upload')}
              </Button>
            </Form.Group>
            <div className="form-field-error-text">
              {formik.touched.b3_driver_certificate_attachment &&
              formik.errors.b3_driver_certificate_attachment ? (
                <div>{t(formik.errors.b3_driver_certificate_attachment)}</div>
              ) : null}
            </div>
          </Col>
          <Col sm={12} md={4}></Col>
        </Row>
        <Row className="mt-3">
          <Col sm={12} md={4}></Col>
          <Col sm={12} md={4}>
            {formik.values.b3_driver_certificate_attachment && (
              <div className="preview-image">
                <img
                  src={imagePreviewFromik(formik.values.b3_driver_certificate_attachment)}
                  alt="profile_img"
                />
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={() => {
                    b3_driver_certificate_attachmentRef.current.value = null;
                    formik.setFieldValue('b3_driver_certificate_attachment', '');
                  }}
                />
              </div>
            )}
          </Col>
          <Col sm={12} md={4}></Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
B3DriverCertificateDetails.propTypes = {
  t: PropTypes.func,
  formik: PropTypes.any
};
export { B3DriverCertificateDetails };
