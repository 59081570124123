import client from '@libs/HttpClient';

class MasterService {
  static addTranslation(request) {
    return client.post('/translations/store', request);
  }
  static updateTranslation(request) {
    return client.post(`/translations/update/${request.translation_id}`, request);
  }
  static listTranslation(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const sortBy = request.queryKey[3];
    const sortOrder = request.queryKey[4];

    return client.get(
      `/translations/index?page=${page_no}&search_text=${searchText}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }
  static viewTranslation(request) {
    const translation_id = request.queryKey[1];
    return client.get(`/translations/view/${translation_id}`, request);
  }
  static syncTranslation(request) {
    return client.get(`/translations/sync-data`, request);
  }

  static exportTranslation(request) {
    return client.post(`/translations/export-data`, request);
  }

  static importTranslation(request) {
    return client.post(`/translations/import-data`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static listCms(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const sortBy = request.queryKey[4];
    const sortOrder = request.queryKey[5];

    return client.get(
      `/cms/index?page=${page_no}&search_text=${searchText}&is_active=${status}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }
  static addCms(request) {
    return client.post('/cms/store', request);
  }
  static updateCms(request) {
    return client.post(`/cms/update/${request.cms_id}`, request);
  }
  static viewCms(request) {
    const translation_id = request.queryKey[1];
    return client.get(`/cms/view/${translation_id}`, request);
  }

  static listEmailTemplate(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const sortBy = request.queryKey[3];
    const sortOrder = request.queryKey[4];

    return client.get(
      `/email-template/index?page=${page_no}&search_text=${searchText}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static viewEmailTemplate(request) {
    const email_template_id = request.queryKey[1];
    return client.get(`/email-template/edit/${email_template_id}`, request);
  }

  static updateEmailTemplate(request) {
    return client.post(`/email-template/update/${request.email_template_id}`, request);
  }

  static listTerritory(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const sortBy = request.queryKey[4];
    const sortOrder = request.queryKey[5];

    return client.get(
      `/territory/index?page=${page_no}&search_text=${searchText}&is_active=${status}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }
  static addTerritory(request) {
    return client.post('/territory/store', request);
  }
  static viewTerritory(request) {
    const territory_id = request.queryKey[1];
    return client.get(`/territory/view/${territory_id}`, request);
  }
  static updateTerritory(request) {
    return client.post(`/territory/update/${request.territory_id}`, request);
  }

  static deleteTerritory(request) {
    const area_id = request.queryKey[1];
    return client.get(`/territory/destroy/${area_id}`, request);
  }
  static generateTerritoryAreasReport(request) {
    return client.post(`/territory/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static listAreas(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const territory_id = request.queryKey[4];
    const sortBy = request.queryKey[5];
    const sortOrder = request.queryKey[6];

    return client.get(
      `/area/index/${territory_id}?page=${page_no}&search_text=${searchText}&is_active=${status}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static deleteArea(request) {
    const area_id = request.queryKey[1];
    return client.get(`/area/destroy/${area_id}`, request);
  }
  static generateAreaReport(request) {
    return client.post(`/area/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  /* producer type */
  static listProducerType(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const license = request.queryKey[4];
    const sortBy = request.queryKey[5];
    const sortOrder = request.queryKey[6];

    return client.get(
      `/producertype/index?page=${page_no}&search_text=${searchText}&by_status=${status}&by_liscence=${license}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static addProducerType(request) {
    return client.post('/producertype/store', request);
  }

  static viewProducerType(request) {
    const producer_type_id = request.queryKey[1];
    return client.get(`/producertype/view/${producer_type_id}`, request);
  }
  static updateProducerType(request) {
    return client.post(`/producertype/update/${request.producer_type_id}`, request);
  }
  static deleteProducerType(request) {
    const producer_type_id = request.queryKey[1];
    return client.get(`/producertype/destory/${producer_type_id}`, request);
  }
  static generateReport(request) {
    return client.post(`/producertype/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static addArea(request) {
    return client.post('/area/store', request);
  }

  static viewArea(request) {
    const area_id = request.queryKey[1];
    return client.get(`/area/view/${area_id}`, request);
  }

  static updateArea(request) {
    return client.post(`/area/update/${request.area_id}`, request);
  }

  static factoryList(request) {
    return client.get(`/area/factory/dropdown-data`, request);
  }

  static warehouseList(request) {
    return client.get(`/warehouse-list`, request);
  }

  // useUpdateTerritory

  static listWasteCode(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const by_waste_type = request.queryKey[4];
    const sortBy = request.queryKey[5];
    const sortOrder = request.queryKey[6];

    return client.get(
      `/wastecode/index?page=${page_no}&search_text=${searchText}&by_status=${status}&by_waste_type=${by_waste_type}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }
  static wasteCodeDelete(request) {
    const wastecode_id = request.queryKey[1];
    return client.get(`/wastecode/destroy/${wastecode_id}`, request);
  }

  static generateWastecodeReport(request) {
    return client.post(`/wastecode/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  /* Users */

  static addUser(request) {
    return client.post('/user/store', request);
  }
  static viewUser(request) {
    const user_id = request.queryKey[1];
    return client.get(`/user/view/${user_id}`, request);
  }
  static updateUser(request) {
    return client.post(`/user/update/${request.user_id}`, request);
  }
  static getTranspoterList(request) {
    return client.get(`/transporter-list`, request);
  }
  static getWarehouseList(request) {
    return client.get(`/warehouse-list`, request);
  }
  static getFactoryList(request) {
    return client.get(`/factory-list`, request);
  }
  static getProducerList(request) {
    return client.get(`/producer-list`, request);
  }

  static addWasteCode(request) {
    return client.post('/wastecode/store', request);
  }

  static updateWasteCode(request) {
    return client.post(`/wastecode/update/${request.waste_code_id}`, request);
  }
  static viewWasteCode(request) {
    const translation_id = request.queryKey[1];
    return client.get(`/wastecode/view/${translation_id}`, request);
  }

  static listUser(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const userStatus = request.queryKey[3];
    const adminStatus = request.queryKey[4];
    const userType = request.queryKey[5];
    const allianceType = request.queryKey[6];
    const fromDate = request.queryKey[7];
    const toDate = request.queryKey[8];
    const sortBy = request.queryKey[9];
    const sortOrder = request.queryKey[10];

    return client.get(
      `/user/index?page=${page_no}&search_text=${searchText}&user_status=${userStatus}&admin_status=${adminStatus}&user_type=${userType}&alliance_type=${allianceType}&from_date=${fromDate}&to_date=${toDate}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static userDelete(request) {
    const wastecode_id = request.queryKey[1];
    return client.get(`/user/destroy/${wastecode_id}`, request);
  }
  static generateUserReport(request) {
    return client.post(`/user/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}

export { MasterService };
