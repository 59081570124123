import { useMutation, useQuery } from 'react-query';
import { ContainerMasterService } from '@api/ContainerMasterService';
import { toast } from 'react-toastify';
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useStoreContainerDetail = (onSuccess, onError = onDefaultError) => {
  return useMutation(ContainerMasterService.addContainerDetail, {
    onSuccess,
    onError
  });
};

const useViewContainerDetail = (onSuccess, onError = onDefaultError) => {
  return useQuery(['container-view'], ContainerMasterService.viewContainer, {
    onSuccess,
    onError
  });
};
const useDeleteContainerAttachment = (
  [attachment_id, type],
  onSuccess,
  onError = onDefaultError
) => {
  const sent = attachment_id ? true : false;
  return useQuery(
    ['container-attachment-delete', attachment_id, type],
    ContainerMasterService.deleteContainerAttachment,
    {
      onSuccess,
      onError,
      enabled: sent,
      retry: 0
    }
  );
};

const useStatusChangeContainerVariable = (
  [container_variable_id, status],
  onSuccess,
  onError = onDefaultError
) => {
  const sent = container_variable_id ? true : false;
  return useQuery(
    ['status-change', container_variable_id, status],
    ContainerMasterService.changeStatus,
    {
      onSuccess,
      onError,
      enabled: sent,
      retry: 0
    }
  );
};

export {
  useStoreContainerDetail,
  useViewContainerDetail,
  useDeleteContainerAttachment,
  useStatusChangeContainerVariable
};
