import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListCommissionPage = React.lazy(() => import('./ListCommissionPage'));
const AddCommissionPage = React.lazy(() => import('./AddCommissionPage'));
const EditCommissionPage = React.lazy(() => import('./EditCommissionPage'));

const CommissionRoute = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/list/:transporter_id" element={<ListCommissionPage t={t} />} />
      <Route exact path="/add/:transporter_id" element={<AddCommissionPage t={t} />} />
      <Route exact path="/edit/:commision_master_id" element={<EditCommissionPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
CommissionRoute.propTypes = {
  t: PropTypes.func
};
export default CommissionRoute;
