import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListCurrentTourPage = React.lazy(() => import('./ListCurrentTourPage'));
const ListCompleteTourPage = React.lazy(() => import('./ListCompleteTourPage'));
const ViewCurrentTourPage = React.lazy(() => import('./ViewCurrentTourPage'));
const ViewCompleteTourPage = React.lazy(() => import('./ViewCompleteTourPage'));
const ListCanceledTourPage = React.lazy(() => import('./ListCanceledTourPage'));
const ViewCanceledTourPage = React.lazy(() => import('./ViewCanceledTourPage'));

const TourMasterRoute = ({ t }) => {
  return (
    <Routes>
      <Route path="/:type/current-list/" element={<ListCurrentTourPage t={t} />} />
      <Route path="/:type/complete-list/" element={<ListCompleteTourPage t={t} />} />
      <Route path="/:type/canceled-list/" element={<ListCanceledTourPage t={t} />} />
      <Route path="/current/view/:tour_id" element={<ViewCurrentTourPage t={t} />} />
      <Route path="/completed/view/:tour_id" element={<ViewCompleteTourPage t={t} />} />
      <Route path="/canceled/view/:tour_id" element={<ViewCanceledTourPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
TourMasterRoute.propTypes = {
  t: PropTypes.func
};
export default TourMasterRoute;
