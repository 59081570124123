import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Col, Form, Modal, Row } from 'react-bootstrap';
import moment from 'moment';

import { useViewTruck } from '@hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { imagePreviewFromik, urlToGetExnsion } from '@helpers/';
import TNBreadCurm from '@common/components/TNBreadCurm';

const ViewTruckPage = ({ t }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalImgUrl, setModalImgUrl] = useState('');

  let { truck_id } = useParams();
  /**
   * !This API will call when page set. When response came we are setting up data into the form
   */
  const { data: truck_data, isLoading: truckLoad } = useViewTruck(truck_id);

  let transporter_id = !truckLoad ? truck_data.data.transporter_id : '';
  if (!truck_data) {
    return null;
  }

  const breadcurmArray = [
    {
      label: t('page.transporter_list_title'),
      link: `/transporter/list`,
      active: ''
    },
    {
      label: t('page.transporter_view_details'),
      link: `/transporter/truck/list/${transporter_id}`,
      active: ''
    },
    {
      label: t('page.view_truck_title'),
      link: `/transporter/truck/list/${transporter_id}`,
      active: 'active'
    }
  ];

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />

      <h1 className="text-center">{t('page.view_truck_title')} </h1>

      <Card>
        <Card.Header>
          <Card.Title>{t('page.add_truck_general_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_trucks_brand_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{truck_data.data.brand_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_truck_model_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{truck_data.data.model_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_trucks_date_put_on_road_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>
                    {truck_data.data.road_date !== ''
                      ? moment(truck_data.data.road_date).format('DD-MM-YYYY')
                      : ''}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_trucks_plate_number_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{truck_data.data.plate_number}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">{t('page.add_truck_weight')}</Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{truck_data.data.weight}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_trucks_max_load_in_kg_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{truck_data.data.max_weight_kg}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_truck_max_load_in_l_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{truck_data.data.max_weight_ltr}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.trucks_is_active_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{truck_data.data.status_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_trucks_truck_type_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{truck_data.data.type_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_trucks_gps_tracker_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{truck_data.data.gps_tracker}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_truck_stnk_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">{t('page.add_trucks_stnk_label')}</Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{truck_data.data.stnk}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_trucks_stnk_expired_date_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{truck_data.data.stnk_expired_date}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={12} xs={12}>
                  <Form.Label className="field-label">
                    {t('page.add_truck_registration_card_label')}
                  </Form.Label>

                  {urlToGetExnsion(truck_data.data.registration_card) === 'pdf' ? (
                    <a target="_blank" href={truck_data.data.registration_card} rel="noreferrer">
                      <span>
                        <div className="preview-image">
                          <img
                            src={imagePreviewFromik(truck_data.data.registration_card)}
                            alt="profile_img"
                          />
                        </div>
                      </span>
                    </a>
                  ) : (
                    <span>
                      <div className="preview-image">
                        <img
                          src={imagePreviewFromik(truck_data.data.registration_card)}
                          alt="profile_img"
                          onClick={() => {
                            setModalImgUrl(truck_data.data.registration_card);
                            setModalShow(true);
                          }}
                        />
                      </div>
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_truck_kir_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">{t('page.add_trucks_kir_label')}</Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{truck_data.data.kir}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_trucks_kir_expired_date_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{truck_data.data.kir_expired_date}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={12} xs={12}>
                  <Form.Label className="field-label">
                    {t('page.add_truck_kirn_card_label')}
                  </Form.Label>

                  {urlToGetExnsion(truck_data.data.kir_card) === 'pdf' ? (
                    <a target="_blank" href={truck_data.data.kir_card} rel="noreferrer">
                      <span>
                        <div className="preview-image">
                          <img
                            src={imagePreviewFromik(truck_data.data.kir_card)}
                            alt="profile_img"
                          />
                        </div>
                      </span>
                    </a>
                  ) : (
                    <span>
                      <div className="preview-image">
                        <img
                          src={imagePreviewFromik(truck_data.data.kir_card)}
                          alt="profile_img"
                          onClick={() => {
                            setModalImgUrl(truck_data.data.kir_card);
                            setModalShow(true);
                          }}
                        />
                      </div>
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_trucks_attachment_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={12} xs={12}>
                  <Form.Label className="field-label">
                    {t('page.add_transporter_conformity_certificate_label')}
                  </Form.Label>
                  {urlToGetExnsion(truck_data.data.conformity_certificate) === 'pdf' ? (
                    <a
                      target="_blank"
                      href={truck_data.data.conformity_certificate}
                      rel="noreferrer">
                      <span>
                        <div className="preview-image">
                          <img
                            src={imagePreviewFromik(truck_data.data.conformity_certificate)}
                            alt="profile_img"
                          />
                        </div>
                      </span>
                    </a>
                  ) : (
                    <span>
                      <div className="preview-image">
                        <img
                          src={imagePreviewFromik(truck_data.data.conformity_certificate)}
                          alt="profile_img"
                          onClick={() => {
                            setModalImgUrl(truck_data.data.conformity_certificate);
                            setModalShow(true);
                          }}
                        />
                      </div>
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={12} xs={12}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_truck_photos_label')}
                  </Form.Label>
                </Col>
                <Col lg={12} xs={12}>
                  <Row>
                    {truck_data.data.truck_photos.length > 0 &&
                      truck_data.data.truck_photos.map((item, i) => {
                        return (
                          <Col key={i} lg={2} xs={6}>
                            <span>
                              <div className="preview-image">
                                <img
                                  src={imagePreviewFromik(item.image)}
                                  alt="profile_img"
                                  onClick={() => {
                                    setModalImgUrl(item.image);
                                    setModalShow(true);
                                  }}
                                />
                              </div>
                            </span>
                          </Col>
                        );
                      })}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <div>
          <div className="primary-button">
            <Link to={`/transporter/truck/list/${transporter_id}`} className="link-center pe-0">
              {t('page.waste_code_details_close_link_text')}
            </Link>
          </div>
          <Modal show={modalShow} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="text-center">
              <div className="close-popup">
                <FontAwesomeIcon icon={faClose} onClick={() => setModalShow(false)} />
              </div>
              <img className="text-center" src={modalImgUrl} alt="profile_img" />
            </Modal.Body>
          </Modal>
        </div>
      </Card>
    </>
  );
};
ViewTruckPage.propTypes = {
  t: PropTypes.func
};
export { ViewTruckPage };
