import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ContainerCourierAssign = React.lazy(() => import('./ContainerCourier'));

const ContainerRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/" element={<ContainerCourierAssign t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ContainerRoutes.propTypes = {
  t: PropTypes.func
};
export default ContainerRoutes;
