import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { AbortService } from '@api/';
const onDefaultError = (error) => {
  toast.error(error.message);
};

/**
 * Abort MAster
 */

const useStoreAbortData = (onSuccess, onError = onDefaultError) => {
  return useMutation(AbortService.storeAbortData, {
    onSuccess,
    onError
  });
};

const useListAbort = (
  [page_no, searchText, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(['abort-list', page_no, searchText, sortBy, sortOrder], AbortService.listAborts, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};

const useViewAbort = (maximum_stop_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['abort-view', maximum_stop_id], AbortService.viewAbort, {
    onSuccess,
    onError
  });
};

const useUpdateAbort = (onSuccess, onError = onDefaultError) => {
  return useMutation(AbortService.updateAbort, {
    onSuccess,
    onError
  });
};

const useDeleteAborts = (onSuccess, onError = onDefaultError) => {
  return useMutation(AbortService.deleteAborts, {
    onSuccess,
    onError
  });
};

const useGenerateAbortMasterReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(AbortService.generateReport, {
    onSuccess,
    onError
  });
};

export {
  useStoreAbortData,
  useListAbort,
  useViewAbort,
  useUpdateAbort,
  useDeleteAborts,
  useGenerateAbortMasterReport
};
