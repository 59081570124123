import { useQuery, useMutation } from 'react-query';

import { FactoryPayoutService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
//
const useGetFactoryPayout = (
  [currentPage, fromDate, toDate, invoiceStatus, searchTextFilter, referenceId, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'factory-payout-list',
      currentPage,
      fromDate,
      toDate,
      invoiceStatus,
      searchTextFilter,
      referenceId,
      sortBy,
      sortOrder
    ],
    FactoryPayoutService.factoryPayoutList,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useGetFactoryFilterList = (onSuccess, onError = onDefaultError) => {
  return useQuery('factory-payout-filter-factory', FactoryPayoutService.factoryListFilter, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};

const useGetFactoryViewDetail = (
  date,
  type,
  reference_id,
  per_kg_commision,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    'factory-view-detail-get',
    () => FactoryPayoutService.factoryPayoutView(date, type, reference_id, per_kg_commision),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
const useGenerateFactoryReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(FactoryPayoutService.generateReport, {
    onSuccess,
    onError
  });
};
export {
  useGetFactoryPayout,
  useGetFactoryFilterList,
  useGetFactoryViewDetail,
  useGenerateFactoryReport
};
