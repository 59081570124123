import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { dateFormatCommon, dateFormatManualAdd, imagePreviewFromik } from '@helpers/commonHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const SurveillanceDetails = (props) => {
  const surveillanceCardAttachmentRef = useRef();

  const { t } = props;
  const formik = useFormikContext();

  const handleSurveillanceExpiryDate = (value) => {
    if (value || value !== '') return new Date(value);
  };
  const handleSurveillanceCardAttachment = () => {
    surveillanceCardAttachmentRef.current?.click();
  };

  return (
    <Card className="bg-white rounded">
      <Card.Body>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_transporter_general_details_surveillance_card_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.surveillance_card && formik.errors.surveillance_card
                    ? 'form-field-error'
                    : formik.touched.surveillance_card && !formik.errors.surveillance_card
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="surveillance_card"
                placeholder={t(
                  'page.add_transporter_general_details_transporter_surveillance_card_placeholder'
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.surveillance_card}
              />
              <div className="form-field-error-text">
                {formik.touched.surveillance_card && formik.errors.surveillance_card ? (
                  <div>{t(formik.errors.surveillance_card)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_transporter_general_details_surveillance_card_expiry_date_label')}
              </Form.Label>
              <DatePicker
                onKeyDown={dateFormatManualAdd}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column"
                placeholderText={dateFormatCommon().toUpperCase()}
                selected={handleSurveillanceExpiryDate(formik.values.surveillance_card_expiry_date)}
                dateFormat={dateFormatCommon()}
                onChange={(date) => {
                  formik.setFieldValue('surveillance_card_expiry_date', date);
                }}
                onBlur={(date) => {
                  var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');

                  if (date.currentTarget.value) {
                    if (!date1.isValid()) {
                      toast.error(t('page.invalid_date_format'));
                      formik.setFieldValue('surveillance_card_expiry_date', '');
                    }
                  } else {
                    formik.setFieldValue('surveillance_card_expiry_date', '');
                  }
                }}
              />
              <div className="form-field-error-text">
                {formik.touched.surveillance_card_expiry_date &&
                formik.errors.surveillance_card_expiry_date ? (
                  <div>{t(formik.errors.surveillance_card_expiry_date)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}></Col>
        </Row>
        <Row className="mt-2">
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label">
                {t('page.add_transporter_surveillance_card_attachment_label')}
              </Form.Label>
              <Form.Control
                type="file"
                hidden
                accept="image/*,.pdf"
                ref={surveillanceCardAttachmentRef}
                name="surveillance_card_attachment"
                placeholder={t('page.add_transporter_surveillance_card_attachment_placeholder')}
                onChange={(event) => {
                  formik.setFieldValue(
                    'surveillance_card_attachment',
                    event.currentTarget.files[0]
                  );
                }}
              />
              <Button
                type="button"
                onClick={handleSurveillanceCardAttachment}
                className={
                  'upload-button' ||
                  (formik.touched.surveillance_card_attachment &&
                  formik.errors.surveillance_card_attachment
                    ? 'form-field-error'
                    : formik.touched.surveillance_attachment &&
                      !formik.errors.surveillance_dcard_attchment
                    ? 'form-field-success'
                    : '')
                }>
                {t('page.file_upload')}
              </Button>
            </Form.Group>
            <div className="form-field-error-text">
              {formik.touched.surveillance_card_attachment &&
              formik.errors.surveillance_card_attachment ? (
                <div>{t(formik.errors.surveillance_card_attachment)}</div>
              ) : null}
            </div>
          </Col>
          <Col sm={12} md={4}></Col>
          <Col sm={12} md={4}></Col>
        </Row>
        <Row className="mt-2">
          <Col sm={12} md={4}>
            {formik.values.surveillance_card_attachment && (
              <div className="preview-image">
                <img
                  src={imagePreviewFromik(formik.values.surveillance_card_attachment)}
                  alt="profile_img"
                />
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={() => {
                    surveillanceCardAttachmentRef.current.value = null;
                    formik.setFieldValue('surveillance_card_attachment', '');
                  }}
                />
              </div>
            )}
          </Col>
          <Col sm={12} md={4}></Col>
          <Col sm={12} md={4}></Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
SurveillanceDetails.propTypes = {
  t: PropTypes.func
};
export { SurveillanceDetails };
