import { useMutation, useQuery } from 'react-query';

import { TransporterPayoutService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
//
const useGetTransporterPayout = (
  [
    currentPage,
    fromDate,
    toDate,
    invoiceMode,
    invoiceStatus,
    searchTextFilter,
    selectedType,
    referenceId,
    sortBy,
    sortOrder
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'transporter-payout-list',
      currentPage,
      fromDate,
      toDate,
      invoiceMode,
      invoiceStatus,
      searchTextFilter,
      selectedType,
      referenceId,
      sortBy,
      sortOrder
    ],
    TransporterPayoutService.transporterPayoutList,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useGetTransporterFilterList = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'transporter-payout-filter-transporter',
    TransporterPayoutService.transporterListFilter,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useGenerateTransporterPayoutReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(TransporterPayoutService.generateReport, {
    onSuccess,
    onError
  });
};

const useRaisedInvoice = (onSuccess, onError = onDefaultError) => {
  return useMutation(TransporterPayoutService.raisedInvoice, {
    onSuccess,
    onError
  });
};

const useGetTransportViewDetail = (
  date,
  type,
  reference_id,
  per_kg_commision,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    'transporter-view-detail-get',
    () =>
      TransporterPayoutService.transporterPayoutView(date, type, reference_id, per_kg_commision),
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

export {
  useGetTransporterPayout,
  useGetTransporterFilterList,
  useRaisedInvoice,
  useGetTransportViewDetail,
  useGenerateTransporterPayoutReport
};
