import client from '@libs/HttpClient';
class PostalCodeService {
  static listPostalCode(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const area_id = request.queryKey[4];
    const sortBy = request.queryKey[5];
    const sortOrder = request.queryKey[6];

    return client.get(
      `postalcode/index/${area_id}?page=${page_no}&search_text=${searchText}&status=${status}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static deletePostalCode(request) {
    return client.put(`/postalcode/delete/${request.postal_code_master_id}`, request);
  }

  static storePostalCode(request) {
    return client.post('/postalcode/store', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static viewPostalCode(request) {
    const postal_code_master_id = request.queryKey[1];
    return client.get(`/postalcode/show/${postal_code_master_id}`, request);
  }

  static updatePostalCode(request) {
    return client.post(`/postalcode/update/${request.postal_code_master_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { PostalCodeService };
