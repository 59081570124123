import client from '@libs/HttpClient';
class ProducerService {
  static storeProducer(request) {
    return client.post('producer/store', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static listProducer(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const is_active = request.queryKey[3];
    const from_date = request.queryKey[4];
    const to_date = request.queryKey[5];
    const producerTypeID = request.queryKey[6];
    const selectedCollection = request.queryKey[7];
    const territryId = request.queryKey[8];
    const areaId = request.queryKey[9];
    const wasteCode = request.queryKey[10];
    const selectedDateType = request.queryKey[11];
    const sortBy = request.queryKey[12];
    const sortOrder = request.queryKey[13];

    return client.get(
      `/producer/index?page=${page_no}&search_text=${searchText}&status=${is_active}&from_date=${from_date}&to_date=${to_date}&producer_type_id=${producerTypeID}&type_collection=${selectedCollection}&territory_id=${territryId}&area_id=${areaId}&waste_code=${wasteCode}&date_type=${selectedDateType}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }
  static viewProducer(request) {
    return client.get(`/producer/view/${request}`);
  }
  static updateProducer(request) {
    const producer_id = request.get('producer_id');
    return client.post(`/producer/update/${producer_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static deleteProducerAttachment(request) {
    const attachment_id = request.queryKey[1];
    const type = request.queryKey[2];
    return client.get(`/producer/delete-producer-attachment/${attachment_id}/${type}`, request);
  }
  static deleteProducerWorking(request) {
    return client.put(`/producer/delete-working-time/${request.working_id}`, request);
  }
  static generateReport(request) {
    return client.post(`/producer/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { ProducerService };
