import client from '@libs/HttpClient';

class RealTimeSettingService {
  /**
   * Real Time Setting
   */
  static getTourRealTimeSettingData() {
    return client.get('/tour/list-real-time-setting');
  }

  static storeTourRealTimeSettingData(request) {
    return client.post('/tour/store-real-time-setting', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}

export { RealTimeSettingService };
