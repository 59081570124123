import PropTypes from 'prop-types';
import { React, useState } from 'react';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { useViewDriver } from '@hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { imagePreviewFromik, urlToGetExnsion } from '@helpers/';
import TNBreadCurm from '@common/components/TNBreadCurm';

const ViewDriverPage = ({ t }) => {
  let { driver_id, transporter_id } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [modalImgUrl, setModalImgUrl] = useState('');

  const { isLoading, data } = useViewDriver(driver_id);
  if (isLoading) {
    return null;
  }

  const breadcurmArray = [
    {
      label: t('page.transporter_list_title'),
      link: '/transporter/list',
      active: ''
    },
    {
      label: t('page.view_transporter_title'),
      link: `/transporter/driver/list/${transporter_id}`,
      active: ''
    },
    {
      label: t('page.view_driver_title'),
      link: `/transporter/driver/list/${transporter_id}`,
      active: 'active'
    }
  ];

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />

      <h1 className="text-center">{t('page.view_driver_title')} </h1>

      <Card>
        <Card.Header>
          <Card.Title>{t('page.add_driver_general_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_general_details_first_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{data.data.first_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_general_details_last_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{data.data.last_name}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_general_details_email_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{data.data.email}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_general_details_phone_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>
                    +{data.data.country_code}&nbsp;
                    {data.data.phone}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_general_details_date_of_birth_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>
                    {data.data.date_of_birth !== ''
                      ? moment(data.data.date_of_birth).format('DD-MM-YYYY')
                      : ''}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_general_details_owner_ktp_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{data.data.owner_ktp}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={3} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_general_details_address_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1} className={'divider'}>
                  :
                </Col>
                <Col lg={8} xs={5}>
                  <span>
                    <p>{data.data.address}</p>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={3} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_general_details_address_notes_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1} className={'divider'}>
                  :
                </Col>
                <Col lg={8} xs={5}>
                  <span>
                    <p>{data.data.address_notes}</p>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_general_details_city_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{data.data.city || '-'}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_general_details_state_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{data.data.state || '-'}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_general_details_postcode_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{data.data.postcode || '-'}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_general_details_country_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{data.data.country || '-'}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.users_user_status_name')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{data.data.user_status_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.users_admin_status_name')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{data.data.admin_status_name}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_general_details_photo_label')}
                  </Form.Label>
                </Col>
                <Col lg={6} xs={6}>
                  {data.data.photo && (
                    <span>
                      <div className="preview-image">
                        <img
                          src={imagePreviewFromik(data.data.photo)}
                          alt="profile_img"
                          onClick={() => {
                            setModalImgUrl(data.data.photo);
                            setModalShow(true);
                          }}
                        />
                      </div>
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <Card.Title>{t('page.add_driver_licence_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_general_details_licence_number_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{data.data.licence_number}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_general_details_licence_expiry_date_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>
                    {data.data.licence_expiry_date !== ''
                      ? moment(data.data.licence_expiry_date).format('DD-MM-YYYY')
                      : ''}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_driver_licence_attchement_label')}
                  </Form.Label>
                </Col>
                <Col lg={6} xs={6}>
                  {data.data.driver_licence &&
                    (urlToGetExnsion(data.data.driver_licence) === 'pdf' ? (
                      <a target="_blank" href={data.data.driver_licence} rel="noreferrer">
                        <span>
                          <div className="preview-image">
                            <img
                              src={imagePreviewFromik(data.data.driver_licence)}
                              alt="profile_img"
                            />
                          </div>
                        </span>
                      </a>
                    ) : (
                      <span>
                        <div className="preview-image">
                          <img
                            src={imagePreviewFromik(data.data.driver_licence)}
                            alt="profile_img"
                            onClick={() => {
                              setModalImgUrl(data.data.driver_licence);
                              setModalShow(true);
                            }}
                          />
                        </div>
                      </span>
                    ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <Card.Title>{t('page.add_driver_b1_type_licence_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_b1_type_licence_number_details_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{data.data.b1_type_licence}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_b1_type_licence_expiry_date_details_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>
                    {data.data.b1_type_licence_expiry_date !== ''
                      ? moment(data.data.b1_type_licence_expiry_date).format('DD-MM-YYYY')
                      : ''}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_b1_type_licence_attchment_details_label')}
                  </Form.Label>
                </Col>
                <Col lg={6} xs={6}>
                  {data.data.b1_type_licence_attachment &&
                    (urlToGetExnsion(data.data.b1_type_licence_attachment) === 'pdf' ? (
                      <a
                        target="_blank"
                        href={data.data.b1_type_licence_attachment}
                        rel="noreferrer">
                        <span>
                          <div className="preview-image">
                            <img
                              src={imagePreviewFromik(data.data.b1_type_licence_attachment)}
                              alt="profile_img"
                            />
                          </div>
                        </span>
                      </a>
                    ) : (
                      <span>
                        <div className="preview-image">
                          <img
                            src={imagePreviewFromik(data.data.b1_type_licence_attachment)}
                            alt="profile_img"
                            onClick={() => {
                              setModalImgUrl(data.data.b1_type_licence_attachment);
                              setModalShow(true);
                            }}
                          />
                        </div>
                      </span>
                    ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <Card.Title>{t('page.add_driver_b3_certificate_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_b3_certificate_number_details_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{data.data.b3_driver_certificate}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_b3_certificate_attchment_details_label')}
                  </Form.Label>
                </Col>
                <Col lg={6} xs={6}>
                  {data.data.b3_driver_certificate_attachment &&
                    (urlToGetExnsion(data.data.b3_driver_certificate_attachment) === 'pdf' ? (
                      <a
                        target="_blank"
                        href={data.data.b3_driver_certificate_attachment}
                        rel="noreferrer">
                        <span>
                          <div className="preview-image">
                            <img
                              src={imagePreviewFromik(data.data.b3_driver_certificate_attachment)}
                              alt="profile_img"
                            />
                          </div>
                        </span>
                      </a>
                    ) : (
                      <span>
                        <div className="preview-image">
                          <img
                            src={imagePreviewFromik(data.data.b3_driver_certificate_attachment)}
                            alt="profile_img"
                            onClick={() => {
                              setModalImgUrl(data.data.b3_driver_certificate_attachment);
                              setModalShow(true);
                            }}
                          />
                        </div>
                      </span>
                    ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_driver_attachment_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_driver_ktp_attchment_label')}
                  </Form.Label>
                </Col>
                <Col lg={6} xs={6}>
                  {data.data.driver_ktp &&
                    (urlToGetExnsion(data.data.driver_ktp) === 'pdf' ? (
                      <a target="_blank" href={data.data.driver_ktp} rel="noreferrer">
                        <span>
                          <div className="preview-image">
                            <img src={imagePreviewFromik(data.data.driver_ktp)} alt="profile_img" />
                          </div>
                        </span>
                      </a>
                    ) : (
                      <span>
                        <div className="preview-image">
                          <img
                            src={imagePreviewFromik(data.data.driver_ktp)}
                            alt="profile_img"
                            onClick={() => {
                              setModalImgUrl(data.data.driver_ktp);
                              setModalShow(true);
                            }}
                          />
                        </div>
                      </span>
                    ))}
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_selfi_with_ktp_attchement_label')}
                  </Form.Label>
                </Col>
                <Col lg={6} xs={6}>
                  {data.data.selfi_with_ktp &&
                    (urlToGetExnsion(data.data.selfi_with_ktp) === 'pdf' ? (
                      <a target="_blank" href={data.data.selfi_with_ktp} rel="noreferrer">
                        <span>
                          <div className="preview-image">
                            <img
                              src={imagePreviewFromik(data.data.selfi_with_ktp)}
                              alt="profile_img"
                            />
                          </div>
                        </span>
                      </a>
                    ) : (
                      <span>
                        <div className="preview-image">
                          <img
                            src={imagePreviewFromik(data.data.selfi_with_ktp)}
                            alt="profile_img"
                            onClick={() => {
                              setModalImgUrl(data.data.selfi_with_ktp);
                              setModalShow(true);
                            }}
                          />
                        </div>
                      </span>
                    ))}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_driver_proof_of_address_attchement_label')}
                  </Form.Label>
                </Col>
                <Col lg={6} xs={6}>
                  {data.data.proof_of_address &&
                    (urlToGetExnsion(data.data.proof_of_address) === 'pdf' ? (
                      <a target="_blank" href={data.data.proof_of_address} rel="noreferrer">
                        <span>
                          <div className="preview-image">
                            <img
                              src={imagePreviewFromik(data.data.proof_of_address)}
                              alt="profile_img"
                            />
                          </div>
                        </span>
                      </a>
                    ) : (
                      <span>
                        <div className="preview-image">
                          <img
                            src={imagePreviewFromik(data.data.proof_of_address)}
                            alt="profile_img"
                            onClick={() => {
                              setModalImgUrl(data.data.proof_of_address);
                              setModalShow(true);
                            }}
                          />
                        </div>
                      </span>
                    ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <div className="primary-button">
        <Link to={`/transporter/driver/list/${transporter_id}`} className="link-center">
          {t('page.driver_details_close_link_text')}
        </Link>
      </div>
      <Modal show={modalShow} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body className="text-center">
          <div className="close-popup">
            <FontAwesomeIcon icon={faClose} onClick={() => setModalShow(false)} />
          </div>
          <img className="text-center" src={modalImgUrl} alt="profile_img" />
        </Modal.Body>
      </Modal>
    </>
  );
};
ViewDriverPage.propTypes = {
  t: PropTypes.func
};
export { ViewDriverPage };
