import client from '@libs/HttpClient';
class CommissionService {
  static listCommission(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const transporter_id = request.queryKey[4];
    const territryId = request.queryKey[5];

    const sortBy = request.queryKey[6];
    const sortOrder = request.queryKey[7];

    return client.get(
      `commission/index/${transporter_id}?page=${page_no}&search_text=${searchText}&status=${status}&transporter_id=${transporter_id}&territory_id=${territryId}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static deleteCommission(request) {
    return client.put(`/commission/delete/${request.commision_master_id}`, request);
  }

  static storeCommission(request) {
    return client.post('/commission/store', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static viewCommission(request) {
    const commision_master_id = request.queryKey[1];
    return client.get(`/commission/show/${commision_master_id}`, request);
  }

  static updateCommission(request) {
    return client.post(`/commission/update/${request.commision_master_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static generateReport(request) {
    return client.post(`/commission/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { CommissionService };
