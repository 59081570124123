import { useMutation, useQuery } from 'react-query';

import { DeliveryPointUserService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error, query) => {
  toast.error(error.message);
};

/* Delivery Point User */
const useListDeliveryPointUser = (
  [
    page_no,
    searchText,
    userStatus,
    adminStatus,
    liscense,
    alliance_portal_id,
    type,
    sortBy,
    sortOrder
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'delivery-point-user-list',
      page_no,
      searchText,
      userStatus,
      adminStatus,
      liscense,
      alliance_portal_id,
      type,
      sortBy,
      sortOrder
    ],
    DeliveryPointUserService.listDeliveryPointUser,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
const useAddDeliveryPointUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(DeliveryPointUserService.addDeliveryPointUser, {
    onSuccess,
    onError
  });
};
const useDeliveryPointUserDelete = (
  [delivery_point_user_id],
  onSuccess,
  onError = onDefaultError
) => {
  const sent = delivery_point_user_id ? true : false;
  return useQuery(
    ['delivery-point-user-delete', delivery_point_user_id],
    DeliveryPointUserService.deleteDeliveryPointUser,
    {
      onSuccess,
      onError,
      enabled: sent,
      retry: 0
    }
  );
};
const useViewDeliveryPointUser = (delivery_point_user_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['delivery-type-view', delivery_point_user_id],
    DeliveryPointUserService.viewDeliveryPointUser,
    {
      onSuccess,
      onError
    }
  );
};

const useUpdateDeliveryPointUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(DeliveryPointUserService.updateDeliveryPointUser, {
    onSuccess,
    onError
  });
};

const useGenerateDeliveryPointReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(DeliveryPointUserService.generateReport, {
    onSuccess,
    onError
  });
};
export {
  useListDeliveryPointUser,
  useAddDeliveryPointUser,
  useDeliveryPointUserDelete,
  useViewDeliveryPointUser,
  useUpdateDeliveryPointUser,
  useGenerateDeliveryPointReport
};
