import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const UpdateStocksPage = React.lazy(() => import('./UpdateStocksPage'));
const ListStocksPage = React.lazy(() => import('./ListStocksPage'));

const StocksRoutes = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/update" element={<UpdateStocksPage t={t} />} />
      <Route exact path="/list" element={<ListStocksPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
StocksRoutes.propTypes = {
  t: PropTypes.func
};
export default StocksRoutes;
