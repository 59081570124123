const reactApi = process.env.MIX_API_URL;
const reactVersion = process.env.MIX_REACT_VERSION;

const reactLocations = [
  {
    name: 'Staging',
    api: 'https://staging.cleanmedic.co.id/api'
  },
  {
    name: 'Development',
    api: 'https://dev.cleanmedic.co.id/api'
  },
  {
    name: 'Production',
    api: 'https://portal.cleanmedic.co.id/api'
  },
  {
    name: 'Testing',
    api: 'https://testing.cleanmedic.co.id/api'
  },
  {
    name: 'Local',
    api: 'http://localhost:3000/api'
  }
];
const reactLocation = reactLocations.map((item) => {
  if (item.api === reactApi) return item.name;
});

export { reactLocation, reactVersion };
