/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
export const DialogLeavingPage = ({
  showDialog,
  setShowDialog,
  cancelNavigation,
  confirmNavigation,
  t
}) => {
  if (showDialog) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
                cancelNavigation();
              }}
            />
            <div className="alert-popup">
              <h2
                dangerouslySetInnerHTML={{
                  __html: t('page.reset_alert_popup_message')
                }}></h2>
              <Button
                className="table-delete-button"
                onClick={() => {
                  onClose();
                  confirmNavigation();
                }}>
                {t('page.alert_popup_yes_button')}
              </Button>
              <Button
                className="table-primary-button"
                onClick={() => {
                  onClose();
                  cancelNavigation();
                }}>
                {t('page.alert_popup_no_button')}
              </Button>
            </div>
          </div>
        );
      }
    });
  }
};
