import client from '@libs/HttpClient';
class SettingService {
  static getSettingData() {
    return client.get('/setting/get');
  }

  static getAdminDashboard(request) {
    const territoryId = request.queryKey[1];
    const areaId = request.queryKey[2];
    return client.get(
      `/admin/dashboard?territory_id=${territoryId}&area_id=${JSON.stringify(areaId)}`,
      request
    );
  }

  static getDownloadLink() {
    return client.get('/setting/get-download-link');
  }

  static storeSettingData(request) {
    return client.post('/setting/store', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static storeDownloadLink(request) {
    return client.post('/setting/store-download-link', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { SettingService };
