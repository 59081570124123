import client from '@libs/HttpClient';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TNTable from '@common/components/TNTable';
import moment from 'moment';
import { Button } from 'react-bootstrap';

const status = {
  1: 'Disiapkan',
  2: 'Sedamg Dikirim',
  3: 'Terkirim',
  4: 'Di Truk Pengumpul',
  5: 'Di Gudang',
  7: 'Di Truk Pengangkut',
  8: 'Di Pabrik',
  9: 'Telah Dibakar'
};

const ProducerContainerHistory = ({ t }) => {
  let { producer_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [producer, setProducer] = useState(null);
  const [containers, setContainers] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showedQRIndex, setShowedQRIndex] = useState(null);
  const [onProducerOnly, setOnProducerOnly] = useState(false);

  const columns = [
    {
      Header: 'QR',
      disableSortBy: true,
      Cell: ({ row }) =>
        row.index === showedQRIndex ? (
          <img
            style={{ height: '140px' }}
            src={`/api/orders/order-prepartion/qr/${row.original.sr_no}`}
            alt="qr"
          />
        ) : (
          <span className="order-prepare-view-link" onClick={() => setShowedQRIndex(row.index)}>
            Lihat QR
          </span>
        )
    },
    {
      Header: 'Serial',
      accessor: 'sr_no',
      disableSortBy: true
    },
    {
      Header: 'Waktu',
      accessor: 'created_at',
      disableSortBy: true,
      Cell: ({ value }) => <span>{moment(value).format('DD-MM-YYYY HH:mm')}</span>
    },
    {
      Header: 'Status Terakhir',
      accessor: 'current_status',
      disableSortBy: true,
      Cell: ({ value }) => status[value]
    },
    {
      Header: 'Keterangan',
      accessor: 'current_location',
      disableSortBy: true
    }
  ];

  const setLatestStatus = (container) => {
    let maxSessionType = 0;
    let locationType = '';
    let locationName = '';
    let truck = '';
    for (const session of container.sessions_containers) {
      if (session.delivery_point_current_session?.session_type > maxSessionType) {
        maxSessionType = session.delivery_point_current_session.session_type;
        locationType = session.delivery_point_current_session.get_delivery_user?.alliance_type;
        if (locationType === 2) {
          locationName =
            session.delivery_point_current_session.get_delivery_user.get_warehouse_detail.name;
        } else if (locationType === 3) {
          locationName =
            session.delivery_point_current_session.get_delivery_user.get_factory_detail.name;
        }
      }

      if (session.delivery_point_current_session?.session_type === 3) {
        const tr = session.delivery_point_current_session.trucks;
        truck = `ID: ${tr.truck_id} - Plat: ${tr.plate_number}`;
      }
    }

    if (maxSessionType > 0) {
      container.current_status = 4 + maxSessionType;
      container.current_location = container.current_status === 7 ? truck : locationName;
      return container;
    }

    if (container.tour_weighted_containers) {
      container.current_status = 4;
      container.current_location = `Tur: ${container.tour_weighted_containers.tours_data.tour_no} - Truk: ${container.tour_weighted_containers.tours_data.trucks.plate_number}`;
      return container;
    }

    container.current_status = container.orders.status - 2;
    return container;
  };

  const getProducerDetail = useCallback(async () => {
    try {
      const { data } = await client.get(`/producer/view/${producer_id}`);

      setProducer(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [producer_id]);

  const getContainerHistory = useCallback(async () => {
    try {
      const { data, pagination } = await client.get(`/producer/containers-history/${producer_id}`, {
        params: {
          page: currentPage,
          'on-producer-only': onProducerOnly ? 1 : 0
        }
      });

      setContainers(data.map((e) => setLatestStatus(e)));
      setPaginationData(pagination);
    } catch (error) {
      console.log(error);
    }
  }, [producer_id, onProducerOnly, currentPage]);

  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
  };

  useEffect(() => {
    Promise.all([getProducerDetail(), getContainerHistory()]).then(() => setLoading(false));
  }, [getProducerDetail, getContainerHistory]);

  useEffect(() => {
    setShowedQRIndex(null);
  }, [currentPage]);

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <h1>Riwayat Kontainer</h1>
      <div className="d-flex justify-content-between mb-2">
        <p>Produser: {producer.name}</p>
        <Button onClick={() => setOnProducerOnly(!onProducerOnly)}>
          {onProducerOnly ? 'Tampilkan Semua' : 'Tampilkan yang berada di produser saja'}
        </Button>
      </div>
      <TNTable
        columns={columns}
        data={containers}
        paginationData={paginationData}
        onSelectPage={pageIndexHandle}
        t={t}
        key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
        pageIndexGet={currentPage - 1}
      />
    </div>
  );
};

export default ProducerContainerHistory;
