import { useQuery, useMutation } from 'react-query';

import { PrintStickerServices } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user Stocks
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

const usePrintRequestList = ([page], onSuccess, onError = onDefaultError) => {
  return useQuery(['print-request-list', page], PrintStickerServices.getList, {
    onSuccess,
    onError
  });
};

const usePrintRequestDetail = (id, onSuccess, onError = onDefaultError) => {
  return useQuery(['print-request-detail', id], PrintStickerServices.getDetail, {
    onSuccess,
    onError
  });
};

const useAddPrintRequest = (onSuccess, onError = onDefaultError) => {
  return useMutation(PrintStickerServices.requestSticker, {
    onSuccess,
    onError
  });
};

const useChangeStatusPrintRequest = (id, onSuccess, onError = onDefaultError) => {
  return useMutation((request) => PrintStickerServices.changeStatus(request, id), {
    onSuccess,
    onError
  });
};

export {
  usePrintRequestList,
  usePrintRequestDetail,
  useChangeStatusPrintRequest,
  useAddPrintRequest
};
