import { useMutation, useQuery } from 'react-query';

import { OwnWarehouseTransporterService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListOwnWarehouseTransporter = (
  [page_no, territoryId, transporterId, warehouseId, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'own-warehouse-transporter-list',
      page_no,
      territoryId,
      transporterId,
      warehouseId,
      sortBy,
      sortOrder
    ],
    OwnWarehouseTransporterService.listOwnWarehouseTransporter,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
const useOwnWarehouseTransporterDelete = (
  [own_warehouse_transport_id],
  onSuccess,
  onError = onDefaultError
) => {
  const sent = own_warehouse_transport_id ? true : false;
  return useQuery(
    ['own-warehouse-transporter-status-delete', own_warehouse_transport_id],
    OwnWarehouseTransporterService.deleteOwnWarehouseTransporter,
    {
      onSuccess,
      onError,
      enabled: sent,
      retry: 0
    }
  );
};

const useOwnWarehouseTransporterStore = (onSuccess, onError = onDefaultError) => {
  return useMutation(OwnWarehouseTransporterService.storeOwnWarehouseTransporter, {
    onSuccess,
    onError
  });
};

const useOwnWarehouseTransporterView = (ownwarehouse_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['own-warehouse-view', ownwarehouse_id],
    OwnWarehouseTransporterService.ownwarehouseView,
    {
      onSuccess,
      onError
    }
  );
};

const useCheckOwnWarehouseOrNot = (warehouse_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['own-warehouse-or-not-check', warehouse_id],
    OwnWarehouseTransporterService.checkOwnWarehouseOrNot,
    {
      onSuccess,
      onError
    }
  );
};

const useOwnWarehouseTransporterUpdate = (onSuccess, onError = onDefaultError) => {
  return useMutation(OwnWarehouseTransporterService.updateOwnWarehouseTransporter, {
    onSuccess,
    onError
  });
};

const useGenerateReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(OwnWarehouseTransporterService.generateReport, {
    onSuccess,
    onError
  });
};
export {
  useListOwnWarehouseTransporter,
  useOwnWarehouseTransporterDelete,
  useOwnWarehouseTransporterStore,
  useOwnWarehouseTransporterView,
  useOwnWarehouseTransporterUpdate,
  useCheckOwnWarehouseOrNot,
  useGenerateReport
};
