import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export function TabNavigation({ t, producer_id }) {
  return (
    <Nav className="tab-navigation">
      <Nav.Item>
        <NavLink to={`/producer/edit/${producer_id}`} end>
          General
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to={`/producer/edit/${producer_id}/addresses`}>Addresses</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to={`/producer/edit-pricing-tier/${producer_id}`}>Postpaid</NavLink>
      </Nav.Item>
    </Nav>
  );
}
