import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PrivateLayout, PublicLayout } from '../common/layouts';
import Loader from '@common/components/Loader';
import { loader } from '../store/features/loaderSlice';
import { loggedUser } from '../store/features/authSlice';

const PrivateRoute = (props) => {
  const checkLoading = useSelector(loader);
  const checkLoggenInUser = useSelector(loggedUser);
  const location = useLocation();
  const navigate = useNavigate();
  const isAdminLoggedIn = checkLoggenInUser.isAdminLoggedIn;

  window.addEventListener('storage', (e) => {
    if (e.isLogout !== undefined && e.isLogout !== '' && e.isLogout === 1) {
      navigate('/login');
    }
  });

  return isAdminLoggedIn ? (
    location.pathname != '/dashboard/multiscreen' ? (
      <PrivateLayout>
        {checkLoading ? <Loader /> : ''}
        <Outlet />
      </PrivateLayout>
    ) : (
      <PublicLayout>
        {checkLoading ? <Loader /> : ''}
        <Outlet />
      </PublicLayout>
    )
  ) : (
    <Navigate
      to={{
        pathname: '/login',
        state: { from: location }
      }}
    />
  );
};

export default PrivateRoute;
