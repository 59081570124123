import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Row, Col, Card, Form, Modal, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { TNButton } from '@common/components/TNButton';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { useFormik } from 'formik';

import { useGetInvoiceView, useCancelInvoice, useAddNotesMarkPaid } from '@hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import TNBreadCurm from '@common/components/TNBreadCurm';

const ViewInvoicePage = ({ t }) => {
  const [modalShowNote, setModalShowNote] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalImgUrl, setModalImgUrl] = useState('');
  let { invoice_master_id } = useParams();

  /**
   * ! this block is use for download QR code
   */

  useCancelInvoice(
    [isCancel],
    (res) => {
      toast.success(res.message);
      setIsCancel(false);
      refetch();
    },
    (err) => {
      toast.error(err.message);
      setIsCancel(false);
    }
  );

  //Add Note for Mark as Paid
  const {
    mutate: doAddNotes
    // error: addTEList,
    // isError: addTError
  } = useAddNotesMarkPaid(
    (response) => {
      toast.success(response.message);
      formik.resetForm();
      setModalShowNote(false);
      refetch();
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );

  const formik = useFormik({
    initialValues: {
      notes: '',
      invoice_master_id: 0
    },
    // validationSchema,
    onSubmit: async (values) => {
      doAddNotes(values);
    }
  });

  const download = (image) => {
    const fileName = image.split('/').pop();
    image = image.replace(window.origin, '');
    var link = document.createElement('a');
    link.href = image;
    link.target = '_blank';
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click'));
  };

  const handleViewClick = (invoice_master_id) => {
    formik.values.invoice_master_id = invoice_master_id;
    setModalShowNote(true);
  };

  const handleCancelButton = (invoice_master_id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
              }}
            />
            <div className="alert-popup">
              <h2>{t('page.admin_invoice_cancel_alert_txt')}</h2>
              <TNButton
                className="table-delete-button"
                onClick={() => {
                  setIsCancel(invoice_master_id);
                  onClose();
                }}>
                {t('page.invoice_alert_popup_yes_button')}
              </TNButton>
              <TNButton className="table-primary-button" onClick={onClose}>
                {t('page.invoice_alert_popup_no_button')}
              </TNButton>
            </div>
          </div>
        );
      }
    });
  };

  const { data: payoutData, refetch } = useGetInvoiceView(invoice_master_id);

  if (!payoutData) {
    return null;
  }

  const breadcurmArray = [
    {
      label: t('page.finance_list_label'),
      link: '/',
      active: ''
    },
    {
      label: t('page.sidebar_finance_invoice'),
      link: '/finance/invoice/list',
      active: ''
    },
    {
      label:
        payoutData.data.commision_type === 3 || payoutData.data.commision_type === 4
          ? t('page.view_transporter_invoice_label')
          : payoutData.data.commision_type === 1
          ? t('page.view_factory_invoice_label')
          : t('page.view_agent_invoice_label'),
      link: '/',
      active: 'active'
    }
  ];

  return (
    <>
      <div key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}>
        <Modal show={modalShow} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body className="text-center">
            <div className="close-popup">
              <FontAwesomeIcon icon={faClose} onClick={() => setModalShow(false)} />
            </div>
            <img className="text-center" src={modalImgUrl} alt="profile_img" />
          </Modal.Body>
        </Modal>
        <TNBreadCurm breadcurmArray={breadcurmArray} />
        <h1>
          {payoutData.data.commision_type === 3 || payoutData.data.commision_type === 4
            ? t('page.view_transporter_invoice_label')
            : payoutData.data.commision_type === 1
            ? t('page.view_factory_invoice_label')
            : t('page.view_agent_invoice_label')}
        </h1>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <Row>
              <Col lg={4} xs={12} className="mt-lg-0 mt-2">
                <span className="fw-bold">
                  {t('page.invoice_date_time_label')}: {payoutData.data.created_at}
                </span>
              </Col>
              <Col lg={4} xs={12} className="mt-lg-0 mt-2">
                <span className="fw-bold">
                  {t('page.invoice_no_label')}: {payoutData.data.invoice_no}
                </span>
              </Col>
              <Col lg={4} xs={12} className="mt-lg-0 mt-2">
                <span className="fw-bold">
                  {t('page.invoice_payout_status_label')} :
                  <span
                    className={
                      'p-2 ms-2 ' +
                      (payoutData.data.payout_status === 1
                        ? ' pending-bg'
                        : payoutData.data.invoice_status === 2
                        ? ' raised-bg'
                        : ' paid-bg')
                    }>
                    {payoutData.data.payment_status_txt}
                  </span>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col lg={12} md={12} sm={12}>
            <Row>
              <Col lg={4} xs={12} className="mt-lg-0 mt-2">
                <span className="collection-tag">
                  <Form.Label className="field-label me-2">
                    {t('page.invoice_total_amount_label')}
                  </Form.Label>
                  {payoutData.data.total_amount}
                </span>
              </Col>
              <Col lg={4} xs={12} className="mt-lg-0 mt-2">
                <span className="collection-tag">
                  <Form.Label className="field-label me-2">
                    {t('page.invoice_vat_label')}
                  </Form.Label>{' '}
                  {payoutData.data.vat}
                </span>
              </Col>
              <Col lg={4} xs={12} className="mt-lg-0 mt-2">
                <span className="collection-tag">
                  <Form.Label className="field-label me-2">
                    {t('page.invoice_vat_with_amount')}
                  </Form.Label>{' '}
                  {payoutData.data.total_amount_with_vat}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>

        <Card className="mt-3">
          <Card.Body>
            <div>
              {payoutData.data.commision_type === 3 || payoutData.data.commision_type === 4
                ? t('page.view_transporter_invoice_detail_label')
                : payoutData.data.commision_type === 1
                ? t('page.view_factory_invoice_detail_label')
                : t('page.view_agent_invoice_detail_label')}
            </div>
            <Row className="order-section align-items-center">
              <Col lg={2}>
                <div className="profile-img mb-3 text-center">
                  <img
                    src={payoutData.data.reference_profile_url}
                    className="rounded-pill"
                    width="100px"
                    height="100px"
                    alt="profile_img"
                    onClick={() => {
                      setModalImgUrl(payoutData.data.reference_profile_url);
                      setModalShow(true);
                    }}
                  />
                </div>
              </Col>
              <Col lg={5}>
                <Row>
                  <Col xs={6}>
                    <Form.Label className="field-label me-2">
                      {payoutData.data.commision_type === 3 || payoutData.data.commision_type === 4
                        ? t('page.view_transporter_name_invoice_detail_label')
                        : payoutData.data.commision_type === 1
                        ? t('page.view_factory_name_invoice_detail_label')
                        : t('page.view_agent_name_invoice_detail_label')}
                    </Form.Label>
                  </Col>
                  <Col xs={6}>
                    <span>{payoutData.data.reference_name}</span>
                  </Col>
                  <Col xs={6}>
                    <Form.Label className="field-label me-2">
                      {payoutData.data.commision_type === 3 || payoutData.data.commision_type === 4
                        ? t('page.view_transporter_contact_no_invoice_detail_label')
                        : payoutData.data.commision_type === 1
                        ? t('page.view_factory_contact_no_invoice_detail_label')
                        : t('page.view_agent_contact_no_invoice_detail_label')}
                    </Form.Label>
                  </Col>
                  <Col xs={6}>{payoutData.data.reference_phone_no}</Col>
                </Row>
              </Col>
              <Col lg={5}>
                <Row>
                  <Col xs={6}>
                    <Form.Label className="field-label me-2">
                      {payoutData.data.commision_type === 3 || payoutData.data.commision_type === 4
                        ? t('page.view_transporter_id_invoice_detail_label')
                        : payoutData.data.commision_type === 1
                        ? t('page.view_factory_id_invoice_detail_label')
                        : t('page.view_agent_id_invoice_detail_label')}
                    </Form.Label>
                  </Col>
                  <Col xs={6}>
                    <span>{payoutData.data.reference_id}</span>
                  </Col>
                  <Col xs={6}>
                    <Form.Label className="field-label me-2"></Form.Label>
                  </Col>
                  <Col xs={6}>
                    <span></span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={12} md={12} sm={12}>
                <h4>{t('page.view_invoice_notes_label')}</h4>
                <h5>{payoutData.data.notes}</h5>
              </Col>
            </Row>
            <Row className={'mt-3' + (payoutData.data.attachment === '' ? ' d-none' : '')}>
              <Col lg={12} md={12} sm={12}>
                <h4>{t('page.view_invoice_attachment_label')}</h4>
                <TNButton onClick={() => download(payoutData.data.attachment)}>
                  {t('page.invoice_view_btn')}
                </TNButton>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Row
          className={'py-3 text-center' + (payoutData.data.commision_type === 1 ? ' ' : ' d-none')}>
          <Col lg={12}>
            <div className="dashboard-table">
              <Table responsive>
                <thead>
                  <tr style={{ verticalAlign: 'middle' }}>
                    <th>{t('page.invoice_factory_list_time_view')}</th>
                    <th>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('page.invoice_factory_sr_no_view')
                        }}></span>
                    </th>
                    <th>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('page.invoice_factory_total_weight_view')
                        }}></span>
                    </th>
                    <th>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('page.invoice_factory_total_commision_view')
                        }}></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {payoutData.data.commision_data.map((key) => {
                    return (
                      <tr
                        key={'f' + key.commision_calculation_id}
                        style={{ verticalAlign: 'middle' }}>
                        <td>
                          {key.date}
                          <br />
                          {key.time}
                        </td>
                        <td>{key.sr_no}</td>
                        <td>{key.calculated_weight_string}</td>
                        <td>{key.total_commision_string}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

        <Row
          className={'py-3 text-center' + (payoutData.data.commision_type === 2 ? ' ' : ' d-none')}>
          <Col lg={12}>
            <div className="dashboard-table">
              <Table responsive>
                <thead>
                  <tr style={{ verticalAlign: 'middle' }}>
                    <th>{t('page.invoice_agent_list_time_view')}</th>
                    <th>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('page.invoice_agent_producer_name_id_view')
                        }}></span>
                    </th>
                    <th>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('page.invoice_agent_sr_no_view')
                        }}></span>
                    </th>
                    <th>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('page.invoice_agent_total_weight_view')
                        }}></span>
                    </th>
                    <th>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('page.invoice_agent_total_commision_view')
                        }}></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {payoutData.data.commision_data.map((key) => {
                    return (
                      <tr
                        key={'a' + key.commision_calculation_id}
                        style={{ verticalAlign: 'middle' }}>
                        <td>
                          {key.date}
                          <br />
                          {key.time}
                        </td>
                        <td>
                          {key.producer_name}
                          <br />
                          {key.producer_id}
                        </td>
                        <td>{key.sr_no}</td>
                        <td>{key.calculated_weight_string}</td>
                        <td>{key.total_commision_string}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

        <Row
          className={
            'py-3 text-center' +
            (payoutData.data.commision_type === 3 || payoutData.data.commision_type === 4
              ? ' '
              : ' d-none')
          }>
          <Col lg={12}>
            <div className="dashboard-table">
              <Table responsive>
                <thead>
                  <tr style={{ verticalAlign: 'middle' }}>
                    <th>{t('page.invoice_trasnporter_list_time_view')}</th>
                    <th>{t('page.invoice_trasnporter_list_commision_type_view')}</th>
                    <th>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('page.invoice_trasnporter_driver_name_truck_number_view')
                        }}></span>
                    </th>
                    <th>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('page.invoice_trasnporter_total_weight_view')
                        }}></span>
                    </th>
                    <th>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('page.invoice_trasnporter_total_commision_view')
                        }}></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {payoutData.data.commision_data.map((key) => {
                    return (
                      <tr
                        key={'t' + key.commision_calculation_id}
                        style={{ verticalAlign: 'middle' }}>
                        <td>
                          {key.date}
                          <br />
                          {key.time}
                        </td>
                        <td>{key.type_name}</td>
                        <td>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: key.driver_name + '<br>' + key.truck_plate_number
                            }}></span>
                        </td>
                        <td>{key.calculated_weight_string}</td>
                        <td>{key.total_commision_string}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

        <Card className={'mt-3' + (payoutData.data.payout_notes === '' ? ' d-none' : '')}>
          <Card.Body>
            <Row className={'mt-3'}>
              <Col lg={12} md={12} sm={12}>
                <h4>{t('page.view_invoice_payout_notes_label')}</h4>
              </Col>
              <Col lg={6} md={6} sm={6}>
                {payoutData.data.payout_notes}
              </Col>
              <Col className="text-end" lg={6} md={6} sm={6}>
                {payoutData.data.updated_at}
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Row className={'text-center ' + (payoutData.data.payout_status === 1 ? ' ' : ' d-none')}>
          <Col>
            <TNButton
              className="inner-action-button"
              invoice_master_id={payoutData.data.invoice_master_id}
              onClick={() => handleViewClick(payoutData.data.invoice_master_id)}>
              {t('page.invoice_mark_paid_txt')}
            </TNButton>
            <TNButton
              className="table-primary-button"
              onClick={() => handleCancelButton(payoutData.data.invoice_master_id)}
              invoice_master_id={payoutData.data.invoice_master_id}>
              {t('page.invoice_cancel_txt')}
            </TNButton>
          </Col>
        </Row>
      </div>
      <Modal show={modalShowNote} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body className="text-center">
          <div className="close-popup">
            <FontAwesomeIcon icon={faClose} onClick={() => setModalShowNote(false)} />
          </div>
          <h1 className="page-heading-center">{t('page.invoice_add_note_title_txt')}</h1>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Label className="field-label field-label-top">
              {t('page.invoice_note_label')}
            </Form.Label>
            <Form.Control
              className={
                ' ' +
                (formik.touched.seo_meta_desc && formik.errors.seo_meta_desc
                  ? 'form-field-error'
                  : formik.touched.seo_meta_desc && !formik.errors.seo_meta_desc
                  ? 'form-field-success'
                  : '')
              }
              as="textarea"
              rows={3}
              name="notes"
              placeholder={t('page.invoice_note_plceholder_txt')}
              onChange={formik.handleChange}
              value={formik.values.notes}
            />
            <div className="form-field-error-text">
              {formik.touched.notes && formik.errors.notes ? (
                <div>{t(formik.errors.notes)}</div>
              ) : null}
            </div>
            <div className="primary-button">
              <span className="link-center" onClick={() => setModalShowNote(false)}>
                {t('page.invoice_cancel_button_text')}
              </span>
              <Button className="table-primary-button mt-2" type="submit">
                {t('page.invoice_note_submit_button')}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
ViewInvoicePage.propTypes = {
  t: PropTypes.func
};
export default ViewInvoicePage;
