import client from '@libs/HttpClient';
class InvoiceService {
  static getInvoiceList(request) {
    const page = request.queryKey[1];
    const from_date = request.queryKey[2];
    const to_date = request.queryKey[3];
    const source_id = request.queryKey[4];
    const payout_status = request.queryKey[5];
    const transporter_id = request.queryKey[6];
    const factory_id = request.queryKey[7];
    const search_text = request.queryKey[8];
    const agent_id = request.queryKey[9];
    const sortOrder = request.queryKey[10];
    const sortBy = request.queryKey[11];

    return client.get(
      `finance/invoice/list?page=${page}&search_text=${search_text}&transporter_id=${transporter_id}&from_date=${from_date}&to_date=${to_date}&source_id=${source_id}&payout_status=${payout_status}&factory_id=${factory_id}&agent_id=${agent_id}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static transporterList(request) {
    return client.get(`finance/invoice/transporter-list`);
  }
  static agentList(request) {
    return client.get(`finance/invoice/agent-list`);
  }
  static factoryList(request) {
    return client.get(`finance/invoice/factory-list`);
  }

  static invoiceView(request) {
    return client.get(`finance/invoice/view/${request}`);
  }

  static cancelInvoice(request) {
    const invoice_master_id = request.queryKey[1];
    return client.get(`/finance/invoice/cancel-invoice/${invoice_master_id}`, request);
  }

  static addPaidNote(request) {
    return client.post('finance/invoice/mark-paid', request);
  }
}
export { InvoiceService };
