import { useCallback } from 'react';
import client from '@libs/HttpClient';
import { useState } from 'react';
import { useEffect } from 'react';
import '@assets/scss/page/_dashboard.scss';
import { Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
// import TourCard from './TourCard';
// import ProducerTypeCard from './ProducerTypeCard';
import ProducerPieCard from './ProducerPieCard';
import moment from 'moment';
moment.locale('id');

const ORDER_STATUS = {
  2: 'Tertunda',
  3: 'Diproses',
  4: 'Dikirim',
  5: 'Selesai',
  6: 'Dibatalkan',
  7: 'Dibatalkan'
};

const TOUR_STATUS = {
  1: 'Tertunda',
  2: 'Diterima',
  3: 'Dibatalkan',
  4: 'Berjalan',
  5: 'Selesai',
  9: 'Dibatalkan'
};

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [tours, setTours] = useState({
    pending: {},
    active: [],
    finished: {}
  });
  const [orders, setOrders] = useState({
    pending: {},
    active: [],
    finished: {}
  });
  const [tourProducer, setTourProducer] = useState({
    collected_weight: 0,
    pending: 0,
    complete: 0
  });
  const [containers, setContainers] = useState([]);
  const [timer, setTimer] = useState(0);
  const [time, setTime] = useState(new Date().getTime());
  const [currentTime, setCurrentTime] = useState(moment());

  const getTours = useCallback(async () => {
    try {
      const { tours, orders, tour_producers, server_time, containers } = await client.get(
        `/admin/dashboard`
      );
      setTours(tours);
      setOrders(orders);
      setTourProducer(tour_producers);
      setContainers(containers);
      setCurrentTime(moment(server_time));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const refresh = () => {
    setTimer(0);
    setTime(new Date().getTime());
  };

  useEffect(() => {
    setInterval(() => {
      setTimer((t) => t - 1);
      setCurrentTime((t) => t.add(1, 'second'));
    }, 1000);
  }, []);

  useEffect(() => {
    if (timer <= 0) {
      getTours();
      setTimer(60);
    }
  }, [getTours, timer]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const numberFormat = Intl.NumberFormat('id-ID', {
    minimumFractionDigits: 0
  });

  return (
    <div>
      <div className="d-flex gap-2 mb-1 justify-content-between align-items-center">
        <h2 className="dashboard-header">
          Dashboard {currentTime.format('DD MMMM YYYY HH:mm:ss')}
        </h2>
        <div className="d-flex gap-2 align-items-center">
          <Button onClick={refresh}>Refresh</Button>
          <div
            className="circle-timer"
            style={{ '--percentage': `${((60 - timer) / 60) * 100}%` }}
          />
        </div>
      </div>
      <div className="d-flex gap-4">
        <div className="d-flex flex-column gap-4">
          <div>
            <h2>Pengiriman</h2>
            <div className="d-flex gap-2">
              <div className="stat-block">
                <p>Pembelian Belum Dikirim</p>
                <p>
                  <b>{orders.pending.total}</b>
                </p>
              </div>
              <div className="d-flex flex-column gap-2">
                <div className="stat-block">
                  <p>Mengirim</p>
                  <p>{orders.active.length}</p>
                </div>
                <div className="stat-block">
                  <p>Total</p>
                  <p>{orders.pending.total + orders.active.length + orders.finished.total}</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h2>Pengumpulan</h2>
            <div className="d-flex gap-2">
              <div className="stat-block">
                <p>Pengumpulan Tertunda</p>
                <p>
                  <b>{tours.pending.total}</b>
                </p>
              </div>
              <div className="d-flex flex-column gap-2">
                <div className="stat-block">
                  <p>Diproses</p>
                  <p>{tours.active.length}</p>
                </div>
                <div className="stat-block">
                  <p>Total Tour</p>
                  <p>{tours.pending.total + tours.active.length + tours.finished.total}</p>
                </div>
              </div>
              <div className="d-flex flex-column gap-2">
                <div className="stat-block">
                  <p>Total Produser</p>
                  <p>
                    {tourProducer.complete}/{tourProducer.pending + tourProducer.complete}
                  </p>
                </div>
                <div className="stat-block">
                  <p>Terkumpul</p>
                  <p>{numberFormat.format(tourProducer.collected_weight)} kg</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-4 flex-grow-1">
          <ProducerPieCard time={time} />
          {/* <ProducerTypeCard time={time} /> */}
        </div>
      </div>

      <div className="dashboard-table mt-4">
        <h3>Pengiriman Berjalan & Tertunda</h3>
        <Table responsive>
          <thead>
            <tr>
              <th>Waktu</th>
              <th>ID</th>
              <th>Wilayah</th>
              <th>Produser</th>
              <th>Kurir</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {[...orders.active, ...orders.pending.data].map((order) => (
              <tr key={order.order_id}>
                <td>{moment(order.created_at).format('DD-MM-YYYY HH:mm:ss')}</td>
                <td>{order.order_number}</td>
                <td>{order.producer_info.get_area_info.name}</td>
                <td>{order.producer_info.name}</td>
                <td>{`${order.delivery_transport} ${order.delivery_transport_type}`}</td>
                <td>{ORDER_STATUS[order.status]}</td>
              </tr>
            ))}
            {orders.active.length === 0 && orders.pending.data.length === 0 && (
              <tr>
                <td colSpan={6}>Semua pengiriman sudah selesai</td>
              </tr>
            )}
            <tr>
              <td colSpan={6}>
                <a href="orders/list">
                  Selengkapnya <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </a>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <div className="dashboard-table mt-2">
        <h3>Pengumpulan Berjalan & Tertunda</h3>
        <Table responsive>
          <thead>
            <tr>
              <th>Waktu</th>
              <th>ID</th>
              <th>Wilayah</th>
              <th>Produser</th>
              <th>Driver</th>
              <th>Truk</th>
              <th>Stop</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {[...tours.active, ...tours.pending.data].map((tour) => (
              <tr key={tour.tour_id}>
                <td>{moment(tour.created_at).format('DD-MM-YYYY HH:mm:ss')}</td>
                <td>{tour.tour_no}</td>
                <td>{tour.area_info.name}</td>
                <td>{tour.tour_producers.map((e) => e.label).join(', ')}</td>
                <td>{`${tour.drivers?.first_name ?? ''} ${tour.drivers?.last_name ?? ''}`}</td>
                <td>{tour.trucks?.plate_number}</td>
                <td>{tour.tour_stops}</td>
                <td>{TOUR_STATUS[tour.status]}</td>
              </tr>
            ))}
            {tours.active.length === 0 && tours.pending.data.length === 0 && (
              <tr>
                <td colSpan={7}>Semua pengumpulan sudah selesai</td>
              </tr>
            )}
            <tr>
              <td colSpan={7}>
                <a href="tour/list">
                  Selengkapnya <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </a>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <div className="dashboard-table mt-2">
        <h3>Kontainer Terkumpul Hari Ini</h3>
        <Table responsive>
          <thead>
            <tr>
              <th>Kontainer</th>
              <th>Ukuran</th>
              <th>Jumlah</th>
              <th>Berat Terkumpul</th>
            </tr>
          </thead>
          <tbody>
            {containers.map((c) => (
              <tr key={c.container_variable_id}>
                <td>{c.label}</td>
                <td>{c.size} L</td>
                <td>{c.qty}</td>
                <td>{numberFormat.format(c.measured_weight)} kg</td>
              </tr>
            ))}
            {containers.length === 0 && (
              <tr>
                <td colSpan={3}>Belum ada kontainer terkumpul hari ini</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Dashboard;
