import { useMutation } from 'react-query';

import { AuthService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useLogin = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.login, {
    onSuccess,
    onError
  });
};
const useForgotPassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.forgotPassword, {
    onSuccess,
    onError
  });
};

const useOTPVerify = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.otpVerify, {
    onSuccess,
    onError
  });
};

const useResetPassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.resetPassword, {
    onSuccess,
    onError
  });
};

const useResendOtp = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.resendOtp, {
    onSuccess,
    onError
  });
};

const useSetAllianceTypePassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.setAllianceTypePassword, {
    onSuccess,
    onError
  });
};

const useLogout = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.logOutUser, {
    onSuccess,
    onError
  });
};
export {
  useLogin,
  useForgotPassword,
  useOTPVerify,
  useResetPassword,
  useResendOtp,
  useSetAllianceTypePassword,
  useLogout
};
