import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListProducerTypePage = React.lazy(() => import('./ListProducerTypePage'));
const AddProducerTypePage = React.lazy(() => import('./AddProducerTypePage'));
const EditProducerTypePage = React.lazy(() => import('./EditProducerTypePage'));

const SubProducerTypeList = React.lazy(() => import('./SubProducerTypeList'));

const ProducerTypeRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListProducerTypePage t={t} />} />
      <Route exact path="/add" element={<AddProducerTypePage t={t} />} />
      <Route exact path="/edit/:producer_type_id" element={<EditProducerTypePage t={t} />} />
      <Route
        exact
        path="/sub-producer-types/:producer_type_id"
        element={<SubProducerTypeList t={t} />}
      />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ProducerTypeRoutes.propTypes = {
  t: PropTypes.func
};
export default ProducerTypeRoutes;
