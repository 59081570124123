import { useMutation, useQuery } from 'react-query';

import { NotificationService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useListNotification = ([page_no], onSuccess, onError = onDefaultError) => {
  return useQuery(['notification-list', page_no], NotificationService.listNotification, {
    onSuccess,
    onError
  });
};

const useReadNotification = (onSuccess, onError = onDefaultError) => {
  return useMutation(NotificationService.readNotification, {
    onSuccess,
    onError
  });
};

const useDeleteNotification = (onSuccess, onError = onDefaultError) => {
  return useMutation(NotificationService.deleteNotification, {
    onSuccess,
    onError
  });
};

const useLatestNotification = (onSuccess, onError = onDefaultError) => {
  return useQuery(['latest-notification'], NotificationService.latestNotification, {
    onSuccess,
    onError
  });
};

export { useListNotification, useReadNotification, useDeleteNotification, useLatestNotification };
