import * as Yup from 'yup';

Yup.addMethod(Yup.mixed, 'file_typepdf', function (errorMessage) {
  return this.test(`test-file_type`, errorMessage, function (value) {
    if (typeof value === 'string') {
      return true;
    }
    const isValid = ['application/pdf'];
    if (value !== undefined && value !== '') {
      return value && isValid.includes(value.type);
    }
    return true;
  });
});

let validationSchema = Yup.object({
  invoice_pdf: Yup.mixed().file_typepdf('page.invoice_pdf_validation_msg')
});

export default validationSchema;
