import { useMutation, useQuery } from 'react-query';

import { WarehouseService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useStoreWarehouse = (onSuccess, onError = onDefaultError) => {
  return useMutation(WarehouseService.storeWarehouse, {
    onSuccess,
    onError
  });
};

const useListWarehouse = (
  [
    page_no,
    searchText,
    selectedStatus,
    fromDate,
    toDate,
    selectedDateType,
    selectedOwnWarehouse,
    selectedFactory,
    sortBy,
    sortOrder
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'warehouse-list',
      page_no,
      searchText,
      selectedStatus,
      fromDate,
      toDate,
      selectedDateType,
      selectedOwnWarehouse,
      selectedFactory,
      sortBy,
      sortOrder
    ],
    WarehouseService.listWarehouse,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useViewWarehouse = (warehouse_id, onSuccess, onError = onDefaultError) => {
  return useQuery('warehouse-view', () => WarehouseService.viewWarehouse(warehouse_id), {
    onSuccess,
    onError
  });
};

const useDeleteWareHouseAttachment = (onSuccess, onError = onDefaultError) => {
  return useMutation(WarehouseService.deleteWarehouseAttachment, {
    onSuccess,
    onError
  });
};
// const useDeleteWareHouseAttachment = (
//   [attachment_id, type],
//   onSuccess,
//   onError = onDefaultError
// ) => {
//   const sent = attachment_id ? true : false;
//   return useQuery(
//     ['warehouse-attachment-delete', attachment_id, type],
//     WarehouseService.deleteWarehouseAttachment,
//     {
//       onSuccess,
//       onError,
//       enabled: sent,
//       retry: 0
//     }
//   );
// };

const useUpdateWarehouse = (onSuccess, onError = onDefaultError) => {
  return useMutation(WarehouseService.updateWarehouse, {
    onSuccess,
    onError
  });
};

const useGenerateWarehouseReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(WarehouseService.generateWarehouseReport, {
    onSuccess,
    onError
  });
};

export {
  useStoreWarehouse,
  useListWarehouse,
  useViewWarehouse,
  useDeleteWareHouseAttachment,
  useUpdateWarehouse,
  useGenerateWarehouseReport
};
