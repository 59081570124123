import React from 'react';
import { useMemo, useState, useEffect } from 'react';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dateFormatCommon, dateFormatManualAdd } from '@helpers/commonHelpers';
import moment from 'moment';
import TNTable from '@common/components/TNTable';
import { TNButton } from '@common/components/TNButton';
import {
  useGetInvoiceList,
  useGetTransporterInvoiceList,
  useGetFactoryInvoiceList,
  useGetAgentInvoiceList,
  useCancelInvoice,
  useAddNotesMarkPaid
} from '@hooks';
import TNBreadCurm from '@common/components/TNBreadCurm';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import Sort from '@common/components/Sorting';

const ListInvoicePage = ({ t }) => {
  const navigate = useNavigate();
  const [modalShowNote, setModalShowNote] = useState(false);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    localStorage.adminFinanceInvoiceTable !== undefined &&
      localStorage.adminFinanceInvoiceTable !== ''
      ? JSON.parse(localStorage.adminFinanceInvoiceTable).currentPage
      : 1
  );
  const [searchText, setSearchText] = useState(
    localStorage.adminFinanceInvoiceTable !== undefined &&
      localStorage.adminFinanceInvoiceTable !== ''
      ? JSON.parse(localStorage.adminFinanceInvoiceTable).searchText
      : ''
  );
  const [searchTextFilter, setSearchTextFilter] = useState(
    localStorage.adminFinanceInvoiceTable !== undefined &&
      localStorage.adminFinanceInvoiceTable !== ''
      ? JSON.parse(localStorage.adminFinanceInvoiceTable).searchTextFilter
      : ''
  );

  const [sourceId, setSourceId] = useState(
    localStorage.adminFinanceInvoiceTable !== undefined &&
      localStorage.adminFinanceInvoiceTable !== ''
      ? JSON.parse(localStorage.adminFinanceInvoiceTable).sourceId
      : ''
  );

  const [transporterId, setTransporterId] = useState(
    localStorage.adminFinanceInvoiceTable !== undefined &&
      localStorage.adminFinanceInvoiceTable !== ''
      ? JSON.parse(localStorage.adminFinanceInvoiceTable).transporterId
      : ''
  );

  const [factoryId, setFactoryId] = useState(
    localStorage.adminFinanceInvoiceTable !== undefined &&
      localStorage.adminFinanceInvoiceTable !== ''
      ? JSON.parse(localStorage.adminFinanceInvoiceTable).factoryId
      : ''
  );

  const [agentId, setAgentId] = useState(
    localStorage.adminFinanceInvoiceTable !== undefined &&
      localStorage.adminFinanceInvoiceTable !== ''
      ? JSON.parse(localStorage.adminFinanceInvoiceTable).agentId
      : ''
  );

  const [payoutStatus, setPayoutStatus] = useState(
    localStorage.adminFinanceInvoiceTable !== undefined &&
      localStorage.adminFinanceInvoiceTable !== ''
      ? JSON.parse(localStorage.adminFinanceInvoiceTable).payoutStatus
      : ''
  );

  const [fromDate, setFromDate] = useState(
    localStorage.adminFinanceInvoiceTable !== undefined &&
      localStorage.adminFinanceInvoiceTable !== '' &&
      JSON.parse(localStorage.adminFinanceInvoiceTable).fromDate !== ''
      ? new Date(JSON.parse(localStorage.adminFinanceInvoiceTable).fromDate)
      : ''
  );
  const [toDate, setToDate] = useState(
    localStorage.adminFinanceInvoiceTable !== undefined &&
      localStorage.adminFinanceInvoiceTable !== '' &&
      JSON.parse(localStorage.adminFinanceInvoiceTable).toDate !== ''
      ? new Date(JSON.parse(localStorage.adminFinanceInvoiceTable).toDate)
      : ''
  );

  const [sortBy, setSortBy] = useState(
    localStorage.adminFinanceInvoiceTable !== undefined &&
      localStorage.adminFinanceInvoiceTable !== ''
      ? JSON.parse(localStorage.adminFinanceInvoiceTable).sortBy
      : 'created_at'
  );

  const [sortOrder, setSortOrder] = useState(
    localStorage.adminFinanceInvoiceTable !== undefined &&
      localStorage.adminFinanceInvoiceTable !== ''
      ? JSON.parse(localStorage.adminFinanceInvoiceTable).sortOrder
      : 'desc'
  );

  const [isCancel, setIsCancel] = useState(false);
  const [paginationData, setPaginationData] = useState(null);

  /**
   * !This block is for set filter value in localstorage
   */
  useEffect(() => {
    let adminFinanceInvoiceTable = {
      searchText: searchText,
      searchTextFilter: searchTextFilter,
      fromDate: fromDate,
      toDate: toDate,
      sourceId: sourceId,
      payoutStatus: payoutStatus,
      transporterId: transporterId,
      factoryId: factoryId,
      agentId: agentId,
      currentPage: currentPage,
      sortBy: sortBy,
      sortOrder: sortOrder
    };
    localStorage.adminFinanceInvoiceTable = JSON.stringify(adminFinanceInvoiceTable);
  }, [
    currentPage,
    fromDate,
    toDate,
    sourceId,
    payoutStatus,
    transporterId,
    factoryId,
    searchText,
    searchTextFilter,
    agentId,
    sortBy,
    sortOrder
  ]);

  const handleClearFilter = () => {
    setSearchText('');
    setSearchTextFilter('');
    setCurrentPage(1);
    setSourceId('');
    setPayoutStatus('');
    setTransporterId('');
    setFactoryId('');
    setAgentId('');
    setSortBy('created_at');
    setSortOrder('desc');
    localStorage.removeItem('adminFinanceInvoiceTable');
  };

  //Cancel Invoice
  useCancelInvoice(
    [isCancel],
    (res) => {
      toast.success(res.message);
      setIsCancel(false);
      refetch();
    },
    (err) => {
      toast.error(err.message);
      setIsCancel(false);
    }
  );

  //Add Note for Mark as Paid
  const {
    mutate: doAddNotes
    // error: addTEList,
    // isError: addTError
  } = useAddNotesMarkPaid(
    (response) => {
      toast.success(response.message);
      formik.resetForm();
      setModalShowNote(false);
      refetch();
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );

  const formik = useFormik({
    initialValues: {
      notes: '',
      invoice_master_id: 0
    },
    // validationSchema,
    onSubmit: async (values) => {
      doAddNotes(values);
    }
  });

  /**
   * !This Api will call on delete Max Stop
   */

  const clearDateFilter = (date) => {
    setCurrentPage(1);
    setFromDate('');
    setToDate('');
  };

  const handleSort = (name) => {
    setSortBy(name);
    sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc');
  };

  const handleViewClick = (invoice_master_id) => {
    formik.values.invoice_master_id = invoice_master_id;
    setModalShowNote(true);
  };
  /**
   * !This block will call on delete button click
   */
  const handleCancelButton = (tdata) => {
    let invoice_master_id = tdata.currentTarget.getAttribute('invoice_master_id');
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
              }}
            />
            <div className="alert-popup">
              <h2>{t('page.admin_invoice_cancel_alert_txt')}</h2>
              <TNButton
                className="table-delete-button"
                onClick={() => {
                  setIsCancel(invoice_master_id);
                  onClose();
                }}>
                {t('page.invoice_alert_popup_yes_button')}
              </TNButton>
              <TNButton className="table-primary-button" onClick={onClose}>
                {t('page.invoice_alert_popup_no_button')}
              </TNButton>
            </div>
          </div>
        );
      }
    });
  };

  /**
   * !This Block is making Headers for the column
   * @param Not Required
   */

  const columnsjson = [
    {
      Header: (
        <div
          onClick={() => {
            handleSort('created_at');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.invoice_created_date_and_time')
            }}></span>
          <Sort isActive={sortBy === 'created_at' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'created_at',
      disableSortBy: true,
      Cell: ({ value: initialValue }) => {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: initialValue
            }}></span>
        );
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('source_name');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.invoice_source_label')
            }}></span>
          <Sort isActive={sortBy === 'source_name' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'source_name',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.source_name} <br />
            {row.original.source_genrated_by_name}
          </div>
        );
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('invoice_no');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.invoice_invoice_id_label')
            }}></span>
          <Sort isActive={sortBy === 'invoice_no' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'invoice_no',
      disableSortBy: true
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('total_amount');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.invoice_total_amount_label')
            }}></span>
          <Sort isActive={sortBy === 'total_amount' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'total_amount',
      disableSortBy: true
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('vat');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.invoice_vat_per_label')
            }}></span>
          <Sort isActive={sortBy === 'vat' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'vat',
      disableSortBy: true
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('total_amount_with_vat');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.invoice_vat_total_amount_txt')
            }}></span>
          <Sort
            isActive={sortBy === 'total_amount_with_vat' ? true : false}
            orderType={sortOrder}
          />
        </div>
      ),
      accessor: 'total_amount_with_vat',
      disableSortBy: true
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('payment_status_txt');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.invoice_payout_status_label')
            }}></span>
          <Sort isActive={sortBy === 'payment_status_txt' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'payment_status_txt',
      disableSortBy: true
    },
    {
      Header: `${t('page.invoice_action_btn')}`,
      accessor: 'invoice_master_id',
      Cell: ({ value: initialValue, row }) => {
        return (
          <div className="action_btn">
            {row.original.payout_status === 1 || row.original.payout_status === '1' ? (
              <>
                <TNButton
                  className="inner-action-button"
                  invoice_master_id={initialValue}
                  onClick={() => handleViewClick(initialValue)}>
                  {t('page.invoice_mark_paid_txt')}
                </TNButton>
                <TNButton
                  className="table-primary-button"
                  invoice_master_id={initialValue}
                  onClick={handleCancelButton.bind(this)}>
                  {t('page.invoice_cancel_txt')}
                </TNButton>
              </>
            ) : (
              ''
            )}
            <TNButton
              className="table-primary-button"
              onClick={() => {
                navigate(`/finance/invoice/view/${initialValue}`);
              }}>
              {t('page.invoice_view_btn')}
            </TNButton>
          </div>
        );
      },
      disableSortBy: true
    }
  ];

  //Get Transporter Data Based on Invoice Master Table
  const { data: transporterData, isLoading } = useGetTransporterInvoiceList();

  //Get Factory Data Based on Invoice Master Table
  const { data: factoryData, isSuccess: isFactorySuccess } = useGetFactoryInvoiceList();

  //Get Factory Data Based on Invoice Master Table
  const { data: agentData, isSuccess: isAgentSuccess } = useGetAgentInvoiceList();

  const { refetch } = useGetInvoiceList(
    [
      currentPage,
      fromDate !== '' ? moment(fromDate).format('YYYY-MM-DD') : '',
      toDate !== '' ? moment(toDate).format('YYYY-MM-DD') : '',
      sourceId,
      payoutStatus,
      transporterId,
      factoryId,
      searchTextFilter,
      agentId,
      sortOrder,
      sortBy
    ],
    (res) => {
      setData(res.data.invoice_data);
      setPaginationData(res.data.pagination);
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => columnsjson, [sortOrder, sortBy]);
  if (paginationData === null) {
    return null;
  }

  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
  };

  const handleSeach = (search_text) => {
    setCurrentPage(1);
    setSearchText(search_text.target.value);
    if (search_text.target.value.length >= 3) {
      setSearchTextFilter(search_text.target.value);
    } else {
      setSearchTextFilter('');
    }
  };

  const breadcurmArray = [
    {
      label: t('page.finance_list_label'),
      link: '/',
      active: ''
    },
    {
      label: t('page.sidebar_finance_invoice'),
      link: '/',
      active: 'active'
    }
  ];

  const sourceOptions = [
    { value: '', label: t('page.invoice_filter_source_option_placeholder') },
    { value: '1', label: t('page.invoice_filter_source_factory_txt') },
    { value: '2', label: t('page.invoice_filter_source_agent_txt') },
    { value: '3', label: t('page.invoice_filter_source_transporter_txt') }
  ];

  const payoutStatusOptions = [
    { value: '', label: t('page.invoice_filter_payout_status_placeholder') },
    { value: '1', label: t('page.invoice_filter_payout_status_pending_txt') },
    { value: '2', label: t('page.invoice_filter_payout_status_cancel_txt') },
    { value: '3', label: t('page.invoice_filter_payout_status_paid_txt') }
  ];

  if (isLoading) {
    return null;
  }

  //Transporter Option
  const transporterOption = [];
  transporterOption.push({
    value: '',
    label: t('page.select_transporter_option_placeholder')
  });
  if (transporterData.data !== undefined) {
    transporterData.data.map((val) => {
      return transporterOption.push({
        value: val.transporter_id,
        label: val.transporter_name
      });
    });
  }

  //Factory Option
  const factoryOption = [];
  factoryOption.push({
    value: '',
    label: t('page.select_factory_option_placeholder')
  });
  if (isFactorySuccess !== false) {
    if (factoryData.data !== undefined) {
      factoryData.data.map((val) => {
        return factoryOption.push({
          value: val.factory_id,
          label: val.factory_name
        });
      });
    }
  }

  //Agent Option
  const agentOption = [];
  agentOption.push({
    value: '',
    label: t('page.select_agent_option_placeholder')
  });
  if (isAgentSuccess !== false) {
    if (agentData.data !== undefined) {
      agentData.data.map((val) => {
        return agentOption.push({
          value: val.agent_id,
          label: val.agent_name
        });
      });
    }
  }

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />

      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group controlId="search" className="filter-field-space">
              <Form.Control
                placeholder={t('page.search_text')}
                className="filter-column form-field"
                onKeyUp={handleSeach}
                onChange={handleSeach}
                value={searchText}
              />
            </Form.Group>
            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={sourceOptions}
                value={sourceOptions.filter((option) => option.value === sourceId)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setSourceId(selectedOption.value);
                }}
              />
            </Form.Group>
            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={payoutStatusOptions}
                value={payoutStatusOptions.filter((option) => option.value === payoutStatus)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setPayoutStatus(selectedOption.value);
                }}
              />
            </Form.Group>
            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={transporterOption}
                value={transporterOption.filter((option) => option.value === transporterId)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setTransporterId(selectedOption.value);
                }}
              />
            </Form.Group>
            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={factoryOption}
                value={factoryOption.filter((option) => option.value === factoryId)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setFactoryId(selectedOption.value);
                }}
              />
            </Form.Group>

            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={agentOption}
                value={agentOption.filter((option) => option.value === agentId)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setAgentId(selectedOption.value);
                }}
              />
            </Form.Group>

            <Button type="button" onClick={handleClearFilter} className="secondary-remove-button">
              {t('page.cancel_search_button')}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Label className="field-label date-picker-label">
              {t('page.users_from_date_label')}
            </Form.Label>
            <Form.Group className="filter-field-space">
              <DatePicker
                onKeyDown={dateFormatManualAdd}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column"
                placeholderText={dateFormatCommon().toUpperCase()}
                selected={fromDate}
                dateFormat={dateFormatCommon()}
                onChange={(date) => {
                  setCurrentPage(1);
                  setFromDate(date);
                }}
                maxDate={toDate !== '' && toDate !== undefined ? toDate : new Date()}
                onBlur={(date) => {
                  var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                  if (!date1.isValid()) {
                    toast.error(t('page.invalid_date_format'));
                    setFromDate('');
                  } else {
                    var maxDate = toDate !== '' && toDate !== undefined ? toDate : new Date();
                    if (date1 > maxDate) {
                      toast.error(t('page.from_date_less_than_to_Date'));
                      setFromDate('');
                    }
                  }
                }}
              />
            </Form.Group>
            <Form.Label className="field-label date-picker-label">
              {t('page.users_to_date_label')}
            </Form.Label>
            <Form.Group className="filter-field-space">
              <DatePicker
                onKeyDown={dateFormatManualAdd}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column"
                placeholderText={dateFormatCommon().toUpperCase()}
                minDate={fromDate}
                selected={toDate}
                dateFormat={dateFormatCommon()}
                onChange={(date) => {
                  setCurrentPage(1);
                  setToDate(date);
                }}
                onBlur={(date) => {
                  var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                  if (!date1.isValid()) {
                    toast.error(t('page.invalid_date_format'));
                    setToDate('');
                  } else {
                    var minDate = fromDate !== '' && fromDate !== undefined ? fromDate : date1;
                    if (date1 < minDate) {
                      toast.error(t('page.to_date_greater_than_from_Date'));
                      setToDate('');
                    }
                  }
                }}
              />
            </Form.Group>
            <TNButton
              className="secondary-add-button"
              loading={false}
              type="button"
              onClick={clearDateFilter}>
              {t('page.user_list_clear_date_filter_button')}
            </TNButton>
          </div>
        </Col>
      </Row>
      <h1>{t('page.sidebar_finance_invoice')}</h1>
      <TNTable
        columns={columns}
        data={data}
        paginationData={paginationData}
        onSelectPage={pageIndexHandle}
        pageIndexGet={currentPage - 1}
        t={t}
        idName="territory_list"
        key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
      />
      <Modal show={modalShowNote} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body className="text-center">
          <div className="close-popup">
            <FontAwesomeIcon icon={faClose} onClick={() => setModalShowNote(false)} />
          </div>
          <h1 className="page-heading-center">{t('page.invoice_add_note_title_txt')}</h1>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Label className="field-label field-label-top">
              {t('page.invoice_note_label')}
            </Form.Label>
            <Form.Control
              className={
                ' ' +
                (formik.touched.seo_meta_desc && formik.errors.seo_meta_desc
                  ? 'form-field-error'
                  : formik.touched.seo_meta_desc && !formik.errors.seo_meta_desc
                  ? 'form-field-success'
                  : '')
              }
              as="textarea"
              rows={3}
              name="notes"
              placeholder={t('page.invoice_note_plceholder_txt')}
              onChange={formik.handleChange}
              value={formik.values.notes}
            />
            <div className="form-field-error-text">
              {formik.touched.notes && formik.errors.notes ? (
                <div>{t(formik.errors.notes)}</div>
              ) : null}
            </div>
            <div className="primary-button">
              <span className="link-center" onClick={() => setModalShowNote(false)}>
                {t('page.invoice_cancel_button_text')}
              </span>
              <Button className="table-primary-button mt-2" type="submit">
                {t('page.invoice_note_submit_button')}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
ListInvoicePage.propTypes = {
  row: PropTypes.any,
  value: PropTypes.any,
  columns: PropTypes.any,
  data: PropTypes.any,
  paginationData: PropTypes.any,
  t: PropTypes.func
};
export default ListInvoicePage;
