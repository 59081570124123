import client from '@libs/HttpClient';

class CommissionListService {
  static listCommissionFee(params) {
    return client.get(`transporter/commision-fee-list`, { params });
  }

  static getTransportTerritoryData(params) {
    return client.get('transporter/commision-fee-transporter', { params });
  }
}

export { CommissionListService };
