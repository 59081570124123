import client from '@libs/HttpClient';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const ProducerContainerStock = ({ t }) => {
  let { producer_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [producer, setProducer] = useState(null);
  const [containers, setContainers] = useState([]);
  const [stocks, setStocks] = useState(null);
  const [showInstantCollect, setShowInstantCollect] = useState(false);
  const [instantCollect, setInstantCollect] = useState({});

  const getProducerDetail = useCallback(async () => {
    try {
      const { data } = await client.get(`/producer/view/${producer_id}`);

      setProducer(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [producer_id]);

  const getContainerList = useCallback(async () => {
    if (stocks === null) return;

    try {
      const { data } = await client.get(`/v2/containers`);
      setContainers(
        data.sort((a, b) => (stocks[b.id]?.quantity ?? 0) - (stocks[a.id]?.quantity ?? 0))
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [stocks]);

  const getContainerStock = useCallback(async () => {
    try {
      const data = await client.get(`/producer/containers-stock/${producer_id}`);
      setStocks(data);
    } catch (error) {
      console.log(error);
    }
  }, [producer_id]);

  const setInstantCollectAmount = (container_id, amount) => {
    setInstantCollect((prev) => ({ ...prev, [container_id]: amount }));
  };

  const requestInstantCollect = async () => {
    try {
      toast.info('Memproses instant collect...');
      await client.post(
        `/producer/manual-instant-collect/${producer_id}`,
        Object.entries(instantCollect)
          .map(([container_variable_id, qty]) => ({
            container_variable_id,
            qty
          }))
          .filter((e) => e.qty > 0)
      );
      toast.success('Instant collect berhasil direquest, silahkan cek di halaman operasional');
    } catch (error) {
      console.log(error);
      toast.error('Error saat merequest instant collect');
    }
  };

  const askConfirmInstantCollect = () => {
    const hasValue = containers.some((e) => instantCollect[e.id] > 0);
    if (!hasValue) {
      toast.error('Harap masukkan jumlah yang ingin di collect!');
      return;
    }

    const hasAdditional = containers.some(
      (e) => Math.max(0, instantCollect[e.id] - (stocks[e.id]?.quantity ?? 0)) > 0
    );

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
              }}
            />
            <div className="alert-popup">
              <h2>Konfirmasi Instant Collect</h2>
              <p>Kontainer yang akan di collect:</p>
              <table className="simple-table">
                <thead>
                  <tr>
                    <td>Kode</td>
                    <td>Label</td>
                    <td>Ukuran</td>
                    <td>Stok</td>
                    <td>Jumlah Collect</td>
                    <td>Tambahan Stok</td>
                  </tr>
                </thead>
                <tbody>
                  {containers
                    .filter((e) => instantCollect[e.id] > 0)
                    .map((container) => (
                      <tr key={container.id}>
                        <td className="monospace">{container.code}</td>
                        <td>{container.label}</td>
                        <td className="monospace" style={{ textAlign: 'right' }}>
                          {container.size} L
                        </td>
                        <td className="monospace" style={{ textAlign: 'right' }}>
                          {stocks[container.id]?.quantity ?? 0}
                        </td>
                        <td className="monospace">{instantCollect[container.id]}</td>
                        <td className="monospace">
                          {Math.max(
                            0,
                            instantCollect[container.id] - (stocks[container.id]?.quantity ?? 0)
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {hasAdditional && (
                <p>
                  Ini akan membuat sebuah pesanan dengan total Rp 0 untuk <i>Tambahan Stok</i>
                  <br />
                  Harap siapkan sticker QR container untuk dibawa oleh driver sesuai jumlah{' '}
                  <i>Tambahan Stok</i>
                </p>
              )}
              <div className="mt-2">
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    requestInstantCollect();
                    onClose();
                  }}>
                  Lanjutkan Instant Collect
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  Batal
                </Button>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  useEffect(() => {
    getProducerDetail();
    getContainerStock();
  }, [getProducerDetail, getContainerStock]);

  useEffect(() => {
    getContainerList();
  }, [getContainerList]);

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <div className="d-flex align-items-center gap-2 mb-2">
        <h1>Stok Kontainer</h1>
        {!showInstantCollect && (
          <Button onClick={() => setShowInstantCollect(true)}>Instant Collect</Button>
        )}
      </div>
      <p>Produser: {producer.name}</p>
      <table className="simple-table">
        <thead>
          <tr>
            <td>Kode</td>
            <td>Label</td>
            <td>Ukuran</td>
            <td>Stok</td>
            {showInstantCollect && <td>Jumlah Collect</td>}
            <td>Terakhir Beli</td>
            <td>Terakhir Collect</td>
          </tr>
        </thead>
        <tbody>
          {containers.map((container) => (
            <tr key={container.container_variable_id}>
              <td className="monospace">{container.code}</td>
              <td>{container.label}</td>
              <td className="monospace" style={{ textAlign: 'right' }}>
                {container.size} L
              </td>
              <td
                className="monospace"
                style={{
                  textAlign: 'right',
                  color: (stocks[container.id]?.quantity ?? 0) === 0 ? '#ccc' : '#000'
                }}>
                {stocks[container.id]?.quantity ?? 0}
              </td>
              {showInstantCollect && (
                <td>
                  <input
                    style={{ width: '100px' }}
                    placeholder="0"
                    type="number"
                    min={0}
                    onInput={(e) => setInstantCollectAmount(container.id, e.target.value)}
                  />
                </td>
              )}
              <td className="monospace">
                {stocks[container.id]?.last_bought
                  ? moment(stocks[container.id]?.last_bought).format('YYYY-MM-DD HH:mm')
                  : '-'}
              </td>
              <td className="monospace">
                {stocks[container.id]?.last_collected
                  ? moment(stocks[container.id]?.last_collected).format('YYYY-MM-DD HH:mm')
                  : '-'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showInstantCollect && (
        <Button className="mt-2" onClick={askConfirmInstantCollect}>
          Lanjutkan Instant Collect
        </Button>
      )}
    </div>
  );
};

export default ProducerContainerStock;
