import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListTransporterPage } from './ListTransporterPage';
import { AddTransporterPage } from './AddTransporterPage';
import { ViewTransporterPage } from './ViewTransporterPage';
import { EditTransporterPage } from './EditTransporterPage';
import DriverRoutes from '../Driver/DriverRoutes';
import TrucksRoutes from '../TransporterPages/Trucks/TrucksRoute';
import CommissionRoute from '../TransporterPages/Commission/CommissionRoute';
const TransporterRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list/" element={<ListTransporterPage t={t} />} />
      <Route path="/add" element={<AddTransporterPage t={t} />} />
      <Route path="/view/:transporter_id" element={<ViewTransporterPage t={t} />} />
      <Route path="/edit/:transporter_id" element={<EditTransporterPage t={t} />} />
      {/* Driver Routes */}
      <Route path="/driver/*" element={<DriverRoutes t={t} />} />

      {/* Trucks Routes */}
      <Route path="/truck/*" element={<TrucksRoutes t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />

      {/* Commission fee Routes */}
      <Route path="/commission/*" element={<CommissionRoute t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
TransporterRoutes.propTypes = {
  t: PropTypes.func
};
export default TransporterRoutes;
