import client from '@libs/HttpClient';

class TourMasterService {
  static listCurrentCollect(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const territryId = request.queryKey[3];
    const areaId = request.queryKey[4];
    const tranporterId = request.queryKey[5];
    const selectedType = request.queryKey[6];
    const type = request.queryKey[7];
    const sortOrder = request.queryKey[8];
    const sortBy = request.queryKey[9];

    return client.get(
      `tour/master/${type}?page=${page_no}&search_text=${searchText}&territory_id=${territryId}&area_id=${areaId}&type=${selectedType}&transporter_id=${tranporterId}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static listAll(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const territryId = request.queryKey[3];
    const areaId = request.queryKey[4];
    const tranporterId = request.queryKey[5];
    const selectedType = request.queryKey[6];
    const status = request.queryKey[7];
    const sortOrder = request.queryKey[8];
    const sortBy = request.queryKey[9];

    return client.get(
      `tour/list?page=${page_no}&status=${status}&search_text=${searchText}&territory_id=${territryId}&area_id=${areaId}&type=${selectedType}&transporter_id=${tranporterId}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static viewTourDetail(request) {
    return client.get(`/tour/master/view/${request}`);
  }

  static viewOrderScanContainer(request) {
    return client.post(
      `/tour/master/view-container/${request.tour_producer_container_id}`,
      request
    );
  }

  static generateReport(request) {
    return client.post(`/tour/current-tour/master/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static generateCompleteTourReport(request) {
    return client.post(`/tour/complete-tour/master/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static generateCanceledTourReport(request) {
    return client.post(`/cancel-tour/master/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}

export { TourMasterService };
