import { WarehouseStepperService } from '@api/';
import { useMutation, useQuery } from 'react-query';
const { toast } = require('react-toastify');

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetGeneralDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery(['view-generaldetail'], () => WarehouseStepperService.getGeneralDetails(), {
    onSuccess,
    onError
  });
};

const useStoreGeneralDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(WarehouseStepperService.storeGeneralDetails, {
    onSuccess,
    onError
  });
};
const useGetOwnerDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery('view-ownerdetail', () => WarehouseStepperService.getOwnerDetails(), {
    onSuccess,
    onError
  });
};

const useStoreOwnerDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(WarehouseStepperService.storeOwnerDetails, {
    onSuccess,
    onError
  });
};

const useGetPersonInChargeDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery('view-pindetail', () => WarehouseStepperService.getPersonInChargeDetails(), {
    onSuccess,
    onError
  });
};

const useStorePersonInChargeDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(WarehouseStepperService.storePersonInchargeDetails, {
    onSuccess,
    onError
  });
};

const useGetAccountingDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery('view-accountdetail', () => WarehouseStepperService.getAccountingDetails(), {
    onSuccess,
    onError
  });
};

const useStoreAccountingDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(WarehouseStepperService.storeAccountingDetails, {
    onSuccess,
    onError
  });
};
const useGetAttchmentsDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery('view-attachment-detail', () => WarehouseStepperService.getAttchmentsDetails(), {
    onSuccess,
    onError
  });
};

const useStoreAttchmentsDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(WarehouseStepperService.storeAttchmentsDetails, {
    onSuccess,
    onError
  });
};
const useDeleteStepperWareHouseAttachment = (onSuccess, onError = onDefaultError) => {
  return useMutation(WarehouseStepperService.deleteAttchmentsDetails, {
    onSuccess,
    onError
  });
};

export {
  useGetGeneralDetails,
  useStoreGeneralDetails,
  useStoreOwnerDetails,
  useGetOwnerDetails,
  useGetPersonInChargeDetails,
  useStorePersonInChargeDetails,
  useGetAccountingDetails,
  useStoreAccountingDetails,
  useGetAttchmentsDetails,
  useStoreAttchmentsDetails,
  useDeleteStepperWareHouseAttachment
};
