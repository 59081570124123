import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import Select from 'react-select';
import { Alert, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useGetAreaData, useGetTerritoryData, useNavigatingAway } from '@hooks';
import { useFormikContext } from 'formik';
import { npwpFormat } from '@helpers/commonHelpers';
import { DialogLeavingPage } from '@common/components/AlertClosePage';
import { TNButton } from '@common/components';
import { toast } from 'react-toastify';

const PlaceAutoComplete = (props) => {
  const { t, formik, setAddress } = props;
  const [firstLoad, setFirstLoad] = useState(true);
  const {
    suggestions: { status, data },
    setValue
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    const results = await getGeocode({ address });
    setAddress(results[0]);
  };

  const getAddressList = async (address) => {
    await setValue(address);
  };

  useEffect(() => {
    setFirstLoad(false);
    if (!firstLoad) {
      if (status == 'OK') {
        handleSelect(data[0].description);
      } else {
        toast.error('No location found');
        formik.setFieldValue('address', '');
      }
    }
  }, [data]);

  return (
    <>
      <Form.Group>
        <Form.Label className="field-label field-label-top">
          {t('page.add_transporter_general_details_address_label')}
        </Form.Label>
        <InputGroup className="inner-group-box">
          <Form.Control
            className={
              formik.touched.address && formik.errors.address
                ? 'form-field-error'
                : formik.touched.address && !formik.errors.address
                ? 'form-field-success'
                : ''
            }
            type="text"
            name="address"
            placeholder={t('page.add_transporter_general_details_address_placeholder')}
            onKeyDown={(e) => {
              if (e.key !== 'Enter') return;
              e.preventDefault();
              formik.setFieldValue('address', 'Searching...');
              formik.setFieldValue('city', '');
              formik.setFieldValue('state', '');
              formik.setFieldValue('postcode', '');
              formik.setFieldValue('country', '');
              formik.setFieldValue('latitude', '');
              formik.setFieldValue('longitude', '');
              getAddressList(formik.values.address);
            }}
            onChange={(event) => {
              if (event.currentTarget.value === '') {
                formik.setFieldValue('address', '');
                formik.setFieldValue('city', '');
                formik.setFieldValue('state', '');
                formik.setFieldValue('postcode', '');
                formik.setFieldValue('country', '');
                formik.setFieldValue('latitude', '');
                formik.setFieldValue('longitude', '');
              } else {
                formik.setFieldValue('address', event.currentTarget.value);
              }
            }}
            value={formik.values.address}
            onBlur={formik.handleBlur}
          />
          <TNButton
            onClick={() => {
              formik.setFieldValue('address', 'Searching...');
              formik.setFieldValue('city', '');
              formik.setFieldValue('state', '');
              formik.setFieldValue('postcode', '');
              formik.setFieldValue('country', '');
              formik.setFieldValue('latitude', '');
              formik.setFieldValue('longitude', '');
              getAddressList(formik.values.address);
            }}
            type="button">
            {t('page.show_address_results')}
          </TNButton>
        </InputGroup>
        <div className="form-field-error-text">
          {formik.touched.address && formik.errors.address ? (
            <div>{t(formik.errors.address)}</div>
          ) : null}
        </div>
      </Form.Group>
      <div className="map-areas">
        <Row>
          <Col lg={4} sm={12} className={formik.values.city ? '' : 'd-none'}>
            {formik.values.city ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                City:{formik.values.city}
              </Alert>
            ) : (
              ''
            )}
          </Col>
          <Col lg={4} sm={12} className={formik.values.state ? '' : 'd-none'}>
            {formik.values.state ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                State:{formik.values.state}
              </Alert>
            ) : (
              ''
            )}
          </Col>
          <Col lg={4} sm={12} className={formik.values.postcode ? '' : 'd-none'}>
            {formik.values.postcode ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                PostCode:{formik.values.postcode}
              </Alert>
            ) : (
              ''
            )}
          </Col>
          <Col lg={4} sm={12} className={formik.values.country ? '' : 'd-none'}>
            {formik.values.country ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                Country:{formik.values.country}
              </Alert>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

const GeneralDetails = (props) => {
  const areaId = useRef();
  const formik = useFormikContext();
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(
    formik.dirty
  );
  const defaultValue = (option, value) => {
    return option ? option.find((option) => option.value === value) : '';
  };
  const { t, countryIsLoad, countryCodeOptions } = props;
  const [TransporterCenter, setTransporterCenter] = useState({
    lat: -8.675835899999997,
    lng: 115.2127549
  });
  const [TransporterZoom, setTransporterZoom] = useState(18);
  const containerStyle = {
    width: '100%',
    height: '300px'
  };

  useEffect(() => {
    setTransporterCenter({
      lat: Number(formik.values.latitude),
      lng: Number(formik.values.longitude)
    });
  }, [formik.values.latitude, formik.values.longitude]);

  const setAddress = (address) => {
    const place = address;
    const { lat, lng } = getLatLng(place);

    let address1 = place.formatted_address;
    let postcode = '';
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        // case 'street_number': {
        //   address1 = `${component.long_name} ${address1}`;
        //   break;
        // }

        // case 'route': {
        //   address1 += component.short_name;
        //   break;
        // }

        case 'postal_code': {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case 'locality':
          formik.setFieldValue('city', component.long_name);
          break;

        case 'administrative_area_level_1': {
          formik.setFieldValue('state', component.long_name);
          break;
        }
        case 'country':
          formik.setFieldValue('country', component.long_name);
          break;

        default:
          break;
      }
    }
    formik.setFieldValue('address', address1);
    formik.setFieldValue('postcode', postcode);
    formik.setFieldValue('latitude', lat);
    formik.setFieldValue('longitude', lng);
    setTransporterCenter({
      lat: lat,
      lng: lng
    });
    setTransporterZoom(18);
  };

  const StatusOption = [
    { value: '1', label: t('page.contract_status_option_pending') },
    { value: '2', label: t('page.contract_status_option_inreview') },
    { value: '3', label: t('page.contract_status_option_active') },
    { value: '4', label: t('page.contract_status_option_hold'), isDisabled: true },
    { value: '5', label: t('page.contract_status_option_suspended') },
    { value: '6', label: t('page.contract_status_option_terminated') }
  ];

  let TerritoryOptions = [];
  const { isSuccess: TerritoryisSuccess, data: TerritoryList } = useGetTerritoryData();
  if (TerritoryisSuccess !== false) {
    TerritoryOptions = TerritoryList.data.map((item, i) => {
      return { value: item.territory_id, label: item.name };
    });
  }
  let AreaOptions = [];
  const { isSuccess: AreaisSuccess, data: AreaList } = useGetAreaData(formik.values?.territory_id);
  if (AreaisSuccess !== false) {
    AreaOptions = AreaList.data.map((item, i) => {
      return { value: item.area_id, label: item.area_name };
    });
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script'
  });
  // const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    // setMap(null);
  }, []);

  const getAddressFromLatLng = async (lat, lng) => {
    try {
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.MIX_GOOGLE_MAPS_API_KEY}`
      );
      const json = await res.json();

      for (const component of json.results[0].address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case 'postal_code': {
            formik.setFieldValue('postcode', component.long_name);
            break;
          }

          case 'administrative_area_level_1': {
            formik.setFieldValue('state', component.long_name);
            break;
          }

          case 'administrative_area_level_2': {
            formik.setFieldValue('city', component.long_name);
            break;
          }

          case 'country':
            formik.setFieldValue('country', component.long_name);
            break;

          default:
            break;
        }
      }

      return json.results[0].formatted_address;
    } catch (err) {
      console.error(err);
    }
  };

  const onLoadmarker = (marker) => {};

  return (
    <>
      <DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={formik.dirty}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
        t={t}
      />
      <Card>
        <Card.Body>
          <Row>
            <Col sm={12} md={4}>
              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.add_transporter_general_details_transporter_name_label')}
                </Form.Label>
                <Form.Control
                  className={
                    'form-field ' +
                    (formik.touched.name && formik.errors.name
                      ? 'form-field-error'
                      : formik.touched.name && !formik.errors.name
                      ? 'form-field-success'
                      : '')
                  }
                  type="text"
                  name="name"
                  placeholder={t(
                    'page.add_transporter_general_details_transporter_name_placeholder'
                  )}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                <div className="form-field-error-text">
                  {formik.touched.name && formik.errors.name ? (
                    <div>{t(formik.errors.name)}</div>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
            <Col sm={12} md={4}>
              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.add_transporter_general_details_npwp_number_label')}
                </Form.Label>
                <Form.Control
                  className={
                    'form-field ' +
                    (formik.touched.transport_npwp_number && formik.errors.transport_npwp_number
                      ? 'form-field-error'
                      : formik.touched.transport_npwp_number && !formik.errors.transport_npwp_number
                      ? 'form-field-success'
                      : '')
                  }
                  type="text"
                  name="transport_npwp_number"
                  placeholder={t('page.add_transporter_general_details_npwp_number_placeholder')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.transport_npwp_number}
                  minLength={20}
                  maxLength={20}
                  onKeyUp={npwpFormat}
                />
                <div className="form-field-error-text">
                  {formik.touched.transport_npwp_number && formik.errors.transport_npwp_number ? (
                    <div>{t(formik.errors.transport_npwp_number)}</div>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
            <Col sm={12} md={4}>
              <Form.Label className="field-label field-label-top">
                {t('page.add_transporter_general_details_general_contact_number_label')}
              </Form.Label>
              <Row>
                <Col lg={6} xs={6}>
                  <Select
                    name="country_id"
                    placeholder={t(
                      'page.add_transporter_general_details_general_country_id_placeholder'
                    )}
                    value={defaultValue(countryCodeOptions, formik.values.country_id)}
                    defaultValue={formik.values.country_id}
                    onChange={(selectedOption) => {
                      if (selectedOption !== null) {
                        formik.setFieldValue('country_id', selectedOption.value);
                      } else {
                        formik.setFieldValue('country_id', '');
                      }
                    }}
                    className={
                      'contry-code ' +
                      (formik.touched.country_id && formik.errors.country_id
                        ? 'form-select-error'
                        : formik.touched.country_id && !formik.errors.country_id
                        ? 'form-select-success'
                        : '')
                    }
                    options={countryIsLoad ? countryCodeOptions : countryCodeOptions}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.country_id && formik.errors.country_id ? (
                      <div>{t(formik.errors.country_id)}</div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} xs={6} className="px-0">
                  <Form.Control
                    className={
                      'contact-number ' +
                      (formik.touched.company_phone && formik.errors.company_phone
                        ? 'form-field-error'
                        : formik.touched.company_phone && !formik.errors.company_phone
                        ? 'form-field-success'
                        : '')
                    }
                    type="text"
                    name="company_phone"
                    placeholder={t(
                      'page.add_transporter_general_details_general_contact_number_placeholder'
                    )}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.company_phone}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.company_phone && formik.errors.company_phone ? (
                      <div>{t(formik.errors.company_phone)}</div>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={4}>
              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.simpel_id')}
                </Form.Label>
                <Form.Control
                  className={
                    'form-field ' +
                    (formik.touched.simpel_id && formik.errors.simpel_id
                      ? 'form-field-error'
                      : formik.touched.simpel_id && !formik.errors.simpel_id
                      ? 'form-field-success'
                      : '')
                  }
                  type="text"
                  name="simpel_id"
                  placeholder={t('page.simpel_id_placeholder')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.simpel_id}
                />
                <div className="form-field-error-text">
                  {formik.touched.simpel_id && formik.errors.simpel_id ? (
                    <div>{t(formik.errors.simpel_id)}</div>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
            <Col sm={12} md={4}>
              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.simpel_registration_number')}
                </Form.Label>
                <Form.Control
                  className={
                    'form-field ' +
                    (formik.touched.simpel_registration_number &&
                    formik.errors.simpel_registration_number
                      ? 'form-field-error'
                      : formik.touched.simpel_registration_number &&
                        !formik.errors.simpel_registration_number
                      ? 'form-field-success'
                      : '')
                  }
                  type="text"
                  name="simpel_registration_number"
                  placeholder={t('page.simpel_registration_number_placeholder')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.simpel_registration_number}
                />
                <div className="form-field-error-text">
                  {formik.touched.simpel_registration_number &&
                  formik.errors.simpel_registration_number ? (
                    <div>{t(formik.errors.simpel_registration_number)}</div>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={4}>
              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.add_transporter_general_details_territory_name_label')}
                </Form.Label>
                <Select
                  isClearable={true}
                  name="territory_id"
                  options={TerritoryisSuccess ? TerritoryOptions : TerritoryOptions}
                  placeholder={t('page.add_transporter_general_details_territory_name_placeholder')}
                  onChange={(selected) => {
                    if (selected !== null) {
                      formik.setFieldValue('territory_id', selected.value);
                    } else {
                      formik.setFieldValue('territory_id', '');
                    }
                    formik.setFieldValue('area_id', '');
                    areaId.current.clearValue();
                  }}
                  defaultValue={formik.values.territory_id}
                  value={defaultValue(TerritoryOptions, formik.values.territory_id)}
                  onBlur={formik.handleBlur}
                  className={
                    'form-field ' +
                    (formik.touched.territory_id && formik.errors.territory_id
                      ? 'form-select-error'
                      : formik.touched.territory_id && !formik.errors.territory_id
                      ? 'form-select-success'
                      : '')
                  }
                />
                <div className="form-field-error-text">
                  {formik.touched.territory_id && formik.errors.territory_id ? (
                    <div>{t(formik.errors.territory_id)}</div>
                  ) : null}
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.add_transporter_general_details_area_name_label')}
                </Form.Label>
                <Select
                  ref={areaId}
                  isClearable={true}
                  name="area_id"
                  options={AreaisSuccess ? AreaOptions : AreaOptions}
                  placeholder={t('page.add_transporter_general_details_area_name_placeholder')}
                  onChange={(selected) => {
                    if (selected !== null) {
                      formik.setFieldValue('area_id', selected.value);
                    } else {
                      formik.setFieldValue('area_id', '');
                    }
                  }}
                  value={defaultValue(AreaOptions, formik.values?.area_id)}
                  defaultValue={formik.values?.area_id}
                  onBlur={formik.handleBlur}
                  className={
                    'form-field ' +
                    (formik.touched.area_id && formik.errors.area_id
                      ? 'form-select-error'
                      : formik.touched.area_id && !formik.errors.area_id
                      ? 'form-select-success'
                      : '')
                  }
                />
                <div className="form-field-error-text">
                  {formik.touched.area_id && formik.errors.area_id ? (
                    <div>{t(formik.errors.area_id)}</div>
                  ) : null}
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.add_transporter_general_details_status_label')}
                </Form.Label>
                <Select
                  isClearable={true}
                  name="contract_status"
                  options={StatusOption}
                  placeholder={t('page.add_transporter_general_details_status_placeholder')}
                  onChange={(selected) => {
                    if (selected !== null) {
                      formik.setFieldValue('contract_status', selected.value);
                    } else {
                      formik.setFieldValue('contract_status', '');
                    }
                  }}
                  defaultValue={formik.values.contract_status}
                  value={defaultValue(StatusOption, formik.values.contract_status)}
                  onBlur={formik.handleBlur}
                  className={
                    'form-field ' +
                    (formik.touched.contract_status && formik.errors.contract_status
                      ? 'form-select-error'
                      : formik.touched.contract_status && !formik.errors.contract_status
                      ? 'form-select-success'
                      : '')
                  }
                />
                <div className="form-field-error-text">
                  {formik.touched.contract_status && formik.errors.contract_status ? (
                    <div>{t(formik.errors.contract_status)}</div>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
            <Col sm={12} md={8}>
              {isLoaded && (
                <>
                  <PlaceAutoComplete t={t} formik={formik} setAddress={setAddress} />
                  <div className="mt-2">
                    {TransporterCenter ? (
                      <>
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={TransporterCenter}
                          zoom={TransporterZoom}
                          onLoad={onLoad}
                          onPlaceSelected={(event) => {}}
                          onUnmount={onUnmount}>
                          <Marker
                            onLoad={onLoadmarker}
                            position={TransporterCenter}
                            draggable={true}
                            onPositionChanged={(e) => {}}
                            onDragEnd={async (e) => {
                              formik.setFieldValue('latitude', e.latLng.lat());
                              formik.setFieldValue('longitude', e.latLng.lng());
                              setTransporterCenter({
                                lat: e.latLng.lat(),
                                lng: e.latLng.lng()
                              });

                              const results = await getAddressFromLatLng(
                                e.latLng.lat(),
                                e.latLng.lng()
                              );
                              formik.setFieldValue('address', results);
                            }}
                          />
                        </GoogleMap>
                        <Form.Group>
                          <Form.Label className="field-label field-label-top">
                            {t('page.notes_label')}
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            type="text"
                            name="address_notes"
                            placeholder={t('page.notes_placeholder')}
                            onChange={formik.handleChange}
                            value={formik.values.address_notes}
                          />
                        </Form.Group>
                      </>
                    ) : null}
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
GeneralDetails.propTypes = {
  t: PropTypes.func,
  countryCodeOptions: PropTypes.any,
  countryIsLoad: PropTypes.bool
};
export { GeneralDetails };
