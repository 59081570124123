import * as Yup from 'yup';
import moment from 'moment';

Yup.addMethod(Yup.string, 'phone', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const phoneRegExp = /^\d{10}?$/;
    if (value && value.length > 0) {
      return phoneRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'ktp_number', function (errorMessage) {
  return this.test(`test-ktp_number`, errorMessage, function (value) {
    const ktp_numberRegExp = /^\d{16}?$/;
    if (value && value.length > 0) {
      return ktp_numberRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'alphaNumeric', function (errorMessage) {
  return this.test(`test-alphaNumeric`, errorMessage, function (value) {
    const alphaNumericRegExp = /^[aA-zZ0-9\s]+$/;
    if (value && value.length > 0) {
      return alphaNumericRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.mixed, 'file_type', function (errorMessage) {
  return this.test(`test-file_type`, errorMessage, function (value) {
    if (typeof value === 'string') {
      return true;
    }
    const isValid = ['image/jpg', 'image/jpeg', 'image/png'];
    if (value !== undefined && value !== '') {
      return value && isValid.includes(value.type);
    }
    return true;
  });
});
Yup.addMethod(Yup.mixed, 'file_typepdf', function (errorMessage) {
  return this.test(`test-file_type`, errorMessage, function (value) {
    if (typeof value === 'string') {
      return true;
    }
    const isValid = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
    if (value !== undefined && value !== '') {
      return value && isValid.includes(value.type);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'NumericCheck', function (errorMessage) {
  return this.test(`test-NumericCheck`, errorMessage, function (value) {
    const NumericRegExp = /^[0-9]+$/;
    if (value && value.length > 0) {
      return NumericRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.date, 'dynamicMaxDate', function (errorMessage) {
  return this.test(`test-dynamicMaxDate`, errorMessage, function (value) {
    return value && moment(value, 'YYYY-MM-DD').isBefore(moment());
  });
});

export const validationSchema = Yup.object({
  // dirver General Detail
  first_name: Yup.string()
    .required('page.add_driver_general_details_first_name_validation_required')
    .alphaNumeric('page.add_driver_general_details_first_name_validation_alphaNumeric')
    .max(50, 'page.add_driver_general_details_first_name_validation_max'),
  last_name: Yup.string()
    .required('page.add_driver_general_details_last_name_validation_required')
    .alphaNumeric('page.add_driver_general_details_last_name_validation_alphaNumeric')
    .max(50, 'page.add_driver_general_details_last_name_validation_max'),
  country_id: Yup.string().required(
    'page.add_driver_general_details_general_country_code_validation_required'
  ),
  phone: Yup.string()
    .required('page.add_driver_general_details_general_phone_validation_required')
    .NumericCheck('page.add_driver_general_details_phone_validation_digit')
    .min(7, 'page.add_driver_general_details_phone_validation_digit_limit')
    .max(15, 'page.add_driver_general_details_phone_validation_digit_limit'),
  email: Yup.string('page.add_driver_general_details_phone_validation_email_required')
    .required('page.add_driver_general_details_phone_validation_email_required')
    .email('page.add_driver_general_details_phone_validation_email_email'),
  address: Yup.string().required('page.add_driver_general_details_address_validation_required'),
  admin_status: Yup.string().required('page.add_driver_general_details_status_validation_required'),
  user_status: Yup.string().required('page.add_driver_general_details_status_validation_required'),
  date_of_birth: Yup.date()
    .required('page.add_driver_general_details_date_of_birth_validation_required')
    .dynamicMaxDate('page.add_driver_general_details_date_of_birth_validation_max'),
  owner_ktp: Yup.string()
    .required('page.add_driver_general_details_ktp_number_validation_required')
    .NumericCheck('page.add_driver_general_details_ktp_number_validation_digit')
    .min(16, 'page.add_driver_general_details_ktp_number_validation_digit_limit')
    .max(16, 'page.add_driver_general_details_ktp_number_validation_digit_limit'),

  // Licence
  licence_number: Yup.string()
    .required('page.add_driver_general_details_licence_number_validation_required')
    .NumericCheck('page.add_driver_general_details_licence_number_validation_digit')
    .min(12, 'page.add_driver_general_details_licence_number_validation_digit_min'),
  licence_expiry_date: Yup.date().required(
    'page.add_driver_general_details_licence_expiry_date_validation_required'
  ),
  driver_licence: Yup.mixed()
    .required('page.add_driver_driver_licence_required')
    .file_typepdf('page.add_driver_driver_licence_file_type'),

  // B1
  b1_type_licence: Yup.string()
    .required('page.add_driver_general_details_b1_type_licence_validation_required')
    .NumericCheck('page.add_driver_general_details_b1_type_licence_validation_digit'),
  b1_type_licence_expiry_date: Yup.date().required(
    'page.add_driver_general_details_b1_type_licence_expiry_date_validation_required'
  ),
  b1_type_licence_attachment: Yup.mixed()
    .required('page.add_driver_b1_type_licence_attachment_required')
    .file_typepdf('page.add_driver_b1_type_licence_attachment_file_type'),

  // B3
  b3_driver_certificate: Yup.string()
    .required('page.add_driver_general_details_b3_driver_certificate_validation_required')
    .NumericCheck('page.add_driver_general_details_b3_driver_certificate_validation_digit'),
  b3_driver_certificate_attachment: Yup.mixed()
    .required('page.add_driver_b3_driver_certificate_attachment_required')
    .file_typepdf('page.add_driver_b3_driver_certificate_attachment_file_type'),

  // dirver Attachment Detail
  photo: Yup.mixed()
    .required('page.add_driver_general_details_photo_required')
    .file_type('page.add_driver_general_details_photo_file_type'),
  driver_ktp: Yup.mixed()
    .required('page.add_driver_attachment_driver_ktp_file_required')
    .file_typepdf('page.add_driver_attachment_driver_ktp_file_file_type'),
  selfi_with_ktp: Yup.mixed()
    .required('page.add_driver_attachment_selfi_with_ktp_file_required')
    .file_type('page.add_driver_attachment_selfi_with_ktp_file_file_type'),
  proof_of_address: Yup.mixed()
    .required('page.add_driver_attachment_proof_of_address_file_required')
    .file_typepdf('page.add_driver_attachment_proof_of_address_file_file_type')
});
