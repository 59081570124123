import { useMutation, useQuery } from 'react-query';
import { MasterService } from '@api/MasterService';
import { toast } from 'react-toastify';
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useExportTranslation = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.exportTranslation, {
    onSuccess,
    onError
  });
};

const useImportTranslation = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.importTranslation, {
    onSuccess,
    onError
  });
};

const useAddTranslation = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.addTranslation, {
    onSuccess,
    onError
  });
};

const useListTranslation = (
  [page_no, searchText, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['translation-list', page_no, searchText, sortBy, sortOrder],
    MasterService.listTranslation,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
const useSyncTranslation = ([isEnabled], onSuccess, onError = onDefaultError) => {
  return useQuery(['translation-sync'], MasterService.syncTranslation, {
    onSuccess,
    onError,
    enabled: isEnabled,
    retry: 0
  });
};
const useViewTranslation = (translation_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['translation-view', translation_id], MasterService.viewTranslation, {
    onSuccess,
    onError
  });
};
const useUpdateTranslation = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.updateTranslation, {
    onSuccess,
    onError
  });
};
const useListCms = (
  [page_no, searchText, status, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['cms-list', page_no, searchText, status, sortBy, sortOrder],
    MasterService.listCms,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
const useAddCms = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.addCms, {
    onSuccess,
    onError
  });
};
const useUpdateCms = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.updateCms, {
    onSuccess,
    onError
  });
};
const useViewCms = (cms_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['cms-view', cms_id], MasterService.viewCms, {
    onSuccess,
    onError
  });
};
const useListEmailTemplate = (
  [page_no, searchText, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['email-template-list', page_no, searchText, sortBy, sortOrder],
    MasterService.listEmailTemplate,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
const useUpdateEmailTemplate = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.updateEmailTemplate, {
    onSuccess,
    onError
  });
};
const useViewEmailTemplate = (email_template_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['email-template-view', email_template_id], MasterService.viewEmailTemplate, {
    onSuccess,
    onError
  });
};
const useListTerritory = (
  [page_no, searchText, status, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['territory-list', page_no, searchText, status, sortBy, sortOrder],
    MasterService.listTerritory,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
const useAddTerritory = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.addTerritory, {
    onSuccess,
    onError
  });
};
const useUpdateTerritory = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.updateTerritory, {
    onSuccess,
    onError
  });
};
const useViewTerritory = (territory_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['territory-view', territory_id], MasterService.viewTerritory, {
    onSuccess,
    onError
  });
};
const useTerritoryDelete = ([territory_id], onSuccess, onError = onDefaultError) => {
  const sent = territory_id ? true : false;
  return useQuery(['territory-delete', territory_id], MasterService.deleteTerritory, {
    onSuccess,
    onError,
    enabled: sent,
    retry: 0
  });
};
const useGenerateTerritoryAreasReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.generateTerritoryAreasReport, {
    onSuccess,
    onError
  });
};
const useListArea = (
  [page_no, searchText, status, territory_id, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['area-list', page_no, searchText, status, territory_id, sortBy, sortOrder],
    MasterService.listAreas,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
const useAreaDeleteChange = ([area_id], onSuccess, onError = onDefaultError) => {
  const sent = area_id ? true : false;
  return useQuery(['area-delete', area_id], MasterService.deleteArea, {
    onSuccess,
    onError,
    enabled: sent,
    retry: 0
  });
};

const useGeneratePostalAndAreaReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.generateAreaReport, {
    onSuccess,
    onError
  });
};
const useAddArea = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.addArea, {
    onSuccess,
    onError
  });
};
const useViewWarehouseList = (onSuccess, onError = onDefaultError) => {
  return useQuery(['area-warehouse-list'], MasterService.warehouseList, {
    onSuccess,
    onError
  });
};
const useViewFactoryList = (onSuccess, onError = onDefaultError) => {
  return useQuery(['area-factory-list'], MasterService.factoryList, {
    onSuccess,
    onError
  });
};
const useViewArea = (area_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['area-view', area_id], MasterService.viewArea, {
    onSuccess,
    onError
  });
};
const useUpdateArea = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.updateArea, {
    onSuccess,
    onError
  });
};
/* Producer Type */
const useListProducerType = (
  [page_no, searchText, status, liscense, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['producer-type-list', page_no, searchText, status, liscense, sortBy, sortOrder],
    MasterService.listProducerType,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
const useAddProducerType = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.addProducerType, {
    onSuccess,
    onError
  });
};
const useUpdateProducerType = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.updateProducerType, {
    onSuccess,
    onError
  });
};
const useViewProducerType = (producer_type_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['producertype-view', producer_type_id], MasterService.viewProducerType, {
    onSuccess,
    onError
  });
};
const useProducerTypeDelete = ([producer_type_id], onSuccess, onError = onDefaultError) => {
  const sent = producer_type_id ? true : false;
  return useQuery(['producertype-delete', producer_type_id], MasterService.deleteProducerType, {
    onSuccess,
    onError,
    enabled: sent,
    retry: 0
  });
};

const useGenerateProducerTypeReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.generateReport, {
    onSuccess,
    onError
  });
};

const useListWasteCode = (
  [page_no, searchText, status, by_waste_type, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['wastecode-list', page_no, searchText, status, by_waste_type, sortBy, sortOrder],
    MasterService.listWasteCode,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
const useWasteCodeDelete = ([waste_code_id], onSuccess, onError = onDefaultError) => {
  const sent = waste_code_id ? true : false;
  return useQuery(['wastecode/destroy', waste_code_id], MasterService.wasteCodeDelete, {
    onSuccess,
    onError,
    enabled: sent,
    retry: 0
  });
};
const useGenerateWastecodeReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.generateWastecodeReport, {
    onSuccess,
    onError
  });
};
const useAddUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.addUser, {
    onSuccess,
    onError
  });
};

const useAddWasteCode = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.addWasteCode, {
    onSuccess,
    onError
  });
};

const useUpdateUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.updateUser, {
    onSuccess,
    onError
  });
};

const useUpdateWasteCode = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.updateWasteCode, {
    onSuccess,
    onError
  });
};

const useViewUser = (user_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['user-view', user_id], MasterService.viewUser, {
    onSuccess,
    onError
  });
};

const useGetTranspoterList = (onSuccess, onError = onDefaultError) => {
  return useQuery('transporter-list', MasterService.getTranspoterList, {
    onSuccess,
    onError
  });
};
const useGetWarehouseList = (onSuccess, onError = onDefaultError) => {
  return useQuery('warehouse-list', MasterService.getWarehouseList, {
    onSuccess,
    onError
  });
};
const useGetFactoryList = (onSuccess, onError = onDefaultError) => {
  return useQuery('factory-list', MasterService.getFactoryList, {
    onSuccess,
    onError
  });
};
const useGetProducerList = (onSuccess, onError = onDefaultError) => {
  return useQuery('producer-list', MasterService.getProducerList, {
    onSuccess,
    onError
  });
};
const useViewWasteCode = (cms_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['wastecoe-view', cms_id], MasterService.viewWasteCode, {
    onSuccess,
    onError
  });
};

const useListUsers = (
  [
    page_no,
    searchText,
    status,
    adminStatus,
    userType,
    allianceType,
    fromDate,
    toDate,
    sortBy,
    sortOrder
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'user-list',
      page_no,
      searchText,
      status,
      adminStatus,
      userType,
      allianceType,
      fromDate,
      toDate,
      sortBy,
      sortOrder
    ],
    MasterService.listUser,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useUserDelete = ([waste_code_id], onSuccess, onError = onDefaultError) => {
  const sent = waste_code_id ? true : false;

  return useQuery(['user/destroy', waste_code_id], MasterService.userDelete, {
    onSuccess,
    onError,
    enabled: sent,
    retry: 0
  });
};

const useGenerateUserReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(MasterService.generateUserReport, {
    onSuccess,
    onError
  });
};

export {
  useAddTranslation,
  useListTranslation,
  useViewTranslation,
  useUpdateTranslation,
  useSyncTranslation,
  useExportTranslation,
  useImportTranslation,
  useListCms,
  useAddCms,
  useUpdateCms,
  useViewCms,
  useListEmailTemplate,
  useUpdateEmailTemplate,
  useViewEmailTemplate,
  useListTerritory,
  useAddTerritory,
  useUpdateTerritory,
  useViewTerritory,
  useTerritoryDelete,
  useGenerateTerritoryAreasReport,
  useListArea,
  useAddArea,
  useViewWarehouseList,
  useViewFactoryList,
  useViewArea,
  useAreaDeleteChange,
  useGeneratePostalAndAreaReport,
  useUpdateArea,
  useListProducerType,
  useAddProducerType,
  useUpdateProducerType,
  useViewProducerType,
  useProducerTypeDelete,
  useGenerateProducerTypeReport,
  useListWasteCode,
  useWasteCodeDelete,
  useGenerateWastecodeReport,
  useAddUser,
  useUpdateUser,
  useViewUser,
  useGetTranspoterList,
  useGetWarehouseList,
  useGetFactoryList,
  useGetProducerList,
  useAddWasteCode,
  useUpdateWasteCode,
  useViewWasteCode,
  useListUsers,
  useUserDelete,
  useGenerateUserReport
};
