import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import { Card, Row, Col, Form, Modal } from 'react-bootstrap';
import moment from 'moment';

import { useViewProducer } from '@hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { imagePreviewFromik, urlToGetExnsion, timeFormatFunc } from '@helpers/';
import TNBreadCurm from '@common/components/TNBreadCurm';

const ViewProducerPage = ({ t }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalImgUrl, setModalImgUrl] = useState('');

  let { producer_id } = useParams();
  /**
   * !This API will call when page set. When response came we are setting up data into the form
   */
  const { data: producer_data } = useViewProducer(producer_id);

  if (!producer_data) {
    return null;
  }

  const breadcurmArray = [
    {
      label: t('page.producer_list_title'),
      link: '/producer/list',
      active: ''
    },
    {
      label: t('page.view_producer_title'),
      link: '/producer/add',
      active: 'active'
    }
  ];

  const IDRConvert = Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0
  });

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />

      <div className="d-flex" style={{ gap: '10px', alignItems: 'center' }}>
        <h1 className="text-center">{t('page.view_producer_title')} </h1>
        <NavLink to={`/producer/view/${producer_id}/containers`} className="btn btn-primary">
          Riwayat Kontainer
        </NavLink>
        <NavLink to={`/producer/view/${producer_id}/stocks`} className="btn btn-primary">
          Lihat Stok
        </NavLink>
        {producer_data.data.payment_type === 'postpaid' && (
          <NavLink to={`/producer/edit-pricing-tier/${producer_id}`} className="btn btn-primary">
            Lihat Tabel Harga Postpaid
          </NavLink>
        )}
        {producer_data.data.survey && (
          <NavLink
            to={`/producer/survey/${producer_data.data.survey.id}`}
            className="btn btn-primary">
            Lihat Survey
          </NavLink>
        )}
      </div>
      <Card>
        <Card.Header>
          <Card.Title>{t('page.add_producer_general_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_general_details_producer_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_general_details_producer_logo_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  {producer_data.data.logo === '' ? null : (
                    <span>
                      <div className="preview-image">
                        <img
                          src={imagePreviewFromik(producer_data.data.logo)}
                          alt="profile_img"
                          onClick={() => {
                            setModalImgUrl(producer_data.data.logo);
                            setModalShow(true);
                          }}
                        />
                      </div>
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">{t('page.simpel_id')}</Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.simpel_id}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.simpel_registration_number')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.simpel_registration_number}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_general_details_npwp_number_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.producer_npwp_number}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_general_details_contact_number_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                {producer_data.data.phone !== '' ? (
                  <Col lg={5} xs={5}>
                    <span>
                      +{producer_data.data.country_code}&nbsp;
                      {producer_data.data.phone}
                    </span>
                  </Col>
                ) : null}
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_general_details_producer_type_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                {producer_data.data.producer_type_name !== '' ? (
                  <Col lg={5} xs={5}>
                    <span>{producer_data.data.producer_type_name}</span>
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_general_details_sub_producer_type_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                {producer_data.data.sub_producer_type_name !== '' ? (
                  <Col lg={5} xs={5}>
                    <span>{producer_data.data.sub_producer_type_name}</span>
                  </Col>
                ) : null}
              </Row>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              className={producer_data.data.licence_number !== '' ? '' : 'd-none'}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_general_details_licence_number_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                {producer_data.data.licence_number !== '' ? (
                  <Col lg={5} xs={5}>
                    <span>{producer_data.data.licence_number}</span>
                  </Col>
                ) : null}
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.view_producer_address_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={12}>
                  <span>{producer_data.data.address}</span>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.view_producer_city_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.city}</span>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.view_producer_state_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.state}</span>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.view_producer_postcode_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.postcode}</span>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.view_producer_country_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.country}</span>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_general_details_status_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.contract_status_name}</span>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_general_details_business_type_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.business_type}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_producer_collect_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_collect_details_type_collection_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.type_collection_name}</span>
                </Col>
              </Row>
            </Col>

            {producer_data.data.collect_frequence_type_name ? (
              <Col lg={6} md={12} sm={12}>
                <Row>
                  <Col lg={6} xs={6}>
                    <Form.Label className="field-label">
                      {t('page.add_producer_collect_details_collect_frequence_type_label')}
                    </Form.Label>
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={5} xs={5}>
                    <span>{producer_data.data.collect_frequence_type_name}</span>
                  </Col>
                </Row>
              </Col>
            ) : null}

            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_collect_details_waste_code_id_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <>
                    {producer_data.data.waste_codes?.map((c) => (
                      <span className="m-1" key={c.value}>
                        {c.label}
                      </span>
                    ))}
                  </>
                </Col>
              </Row>
            </Col>

            <Row
              className={
                producer_data.data.type_collection === '2' ||
                producer_data.data.type_collection === 2
                  ? 'd-none'
                  : ''
              }>
              <Col sm={12} md={12}>
                {t('page.add_producer_collect_details_information')}
              </Col>
              {producer_data.data.programmed_settings.map((value, key) => {
                return (
                  <Col key={key} lg={6}>
                    <Row>
                      <Col lg={6} xs={6}>
                        {value.container_variable.label + '(' + value.container_variable.size + ')'}
                      </Col>
                      <Col lg={1} xs={1}>
                        :
                      </Col>
                      <Col lg={5} xs={5}>
                        {value.quantity}
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>

            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.view_producer_collect_address_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={12}>
                  <span>{producer_data.data.collection_address}</span>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.view_producer_collect_city_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.collection_city}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.view_producer_collect_state_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.collection_state}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.view_producer_collect_postcode_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.collection_postcode}</span>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.view_producer_collect_country_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.collection_country}</span>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_collect_details_territory_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.territory_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_collect_details_area_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.area_name}</span>
                </Col>
              </Row>
            </Col>
            {producer_data.data.producer_factory.length !== 0 && (
              <Col lg={6} md={12} sm={12}>
                <Row>
                  <Col lg={6} xs={6}>
                    <Form.Label className="field-label">
                      {t('page.factory_pricing_label')}
                    </Form.Label>
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={5} xs={5}>
                    <span>
                      {IDRConvert.format(producer_data.data.producer_factory[0]?.price) +
                        ' (' +
                        producer_data.data.producer_factory[0]?.factory.name +
                        ')'}
                    </span>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_producer_working_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={3} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_work_details_label_1')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1} className="divider">
                  :
                </Col>
                <Col lg={8} xs={5}>
                  {producer_data.data.days_slot_1.map((data, index) => {
                    if (data.is_close === true) {
                      return (
                        <>
                          <span key={index}>
                            {producer_data.data.days_slot_1[0] ===
                            producer_data.data.days_slot_1[index] ? (
                              <p>{t('page.close_working_time')}</p>
                            ) : null}{' '}
                          </span>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Row key={index}>
                            <Col lg={6} xs={5}>
                              <Form.Label className="field-label">
                                {t('page.view_producer_work_details_start_time_label')}
                              </Form.Label>
                              : {timeFormatFunc(data.days_start)}{' '}
                            </Col>
                            <Col lg={6} xs={5}>
                              <Form.Label className="field-label">
                                {t('page.view_producer_work_details_end_time_label')}
                              </Form.Label>
                              : {timeFormatFunc(data.days_end)}{' '}
                            </Col>
                          </Row>
                        </>
                      );
                    }
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
        <Card.Body>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={3} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_work_details_label_2')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1} className="divider">
                  :
                </Col>
                <Col lg={8} xs={5}>
                  {producer_data.data.days_slot_2.map((data, index) => {
                    if (data.is_close === true) {
                      return (
                        <>
                          <span key={index}>
                            {producer_data.data.days_slot_2[0] ===
                            producer_data.data.days_slot_2[index] ? (
                              <p>{t('page.close_working_time')}</p>
                            ) : null}{' '}
                          </span>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Row key={index}>
                            <Col lg={6} xs={5}>
                              <Form.Label className="field-label">
                                {t('page.view_producer_work_details_start_time_label')}
                              </Form.Label>
                              : {timeFormatFunc(data.days_start)}{' '}
                            </Col>
                            <Col lg={6} xs={5}>
                              <Form.Label className="field-label">
                                {t('page.view_producer_work_details_end_time_label')}
                              </Form.Label>
                              : {timeFormatFunc(data.days_end)}{' '}
                            </Col>
                          </Row>
                        </>
                      );
                    }
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
        <Card.Body>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={3} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_work_details_label_3')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1} className="divider">
                  :
                </Col>
                <Col lg={8} xs={5}>
                  {producer_data.data.days_slot_3.map((data, index) => {
                    if (data.is_close === true) {
                      return (
                        <>
                          <span key={index}>
                            {producer_data.data.days_slot_3[0] ===
                            producer_data.data.days_slot_3[index] ? (
                              <p>{t('page.close_working_time')}</p>
                            ) : null}{' '}
                          </span>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Row key={index}>
                            <Col lg={6} xs={5}>
                              <Form.Label className="field-label">
                                {t('page.view_producer_work_details_start_time_label')}
                              </Form.Label>
                              : {timeFormatFunc(data.days_start)}{' '}
                            </Col>
                            <Col lg={6} xs={5}>
                              <Form.Label className="field-label">
                                {t('page.view_producer_work_details_end_time_label')}
                              </Form.Label>
                              : {timeFormatFunc(data.days_end)}{' '}
                            </Col>
                          </Row>
                        </>
                      );
                    }
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
        <Card.Body>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={3} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_work_details_label_4')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1} className="divider">
                  :
                </Col>
                <Col lg={8} xs={5}>
                  {producer_data.data.days_slot_4.map((data, index) => {
                    if (data.is_close === true) {
                      return (
                        <>
                          <span key={index}>
                            {producer_data.data.days_slot_4[0] ===
                            producer_data.data.days_slot_4[index] ? (
                              <p>{t('page.close_working_time')}</p>
                            ) : null}{' '}
                          </span>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Row key={index}>
                            <Col lg={6} xs={5}>
                              <Form.Label className="field-label">
                                {t('page.view_producer_work_details_start_time_label')}
                              </Form.Label>
                              : {timeFormatFunc(data.days_start)}{' '}
                            </Col>
                            <Col lg={6} xs={5}>
                              <Form.Label className="field-label">
                                {t('page.view_producer_work_details_end_time_label')}
                              </Form.Label>
                              : {timeFormatFunc(data.days_end)}{' '}
                            </Col>
                          </Row>
                        </>
                      );
                    }
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
        <Card.Body>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={3} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_work_details_label_5')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1} className="divider">
                  :
                </Col>
                <Col lg={8} xs={5}>
                  {producer_data.data.days_slot_5.map((data, index) => {
                    if (data.is_close === true) {
                      return (
                        <>
                          <span key={index}>
                            {producer_data.data.days_slot_5[0] ===
                            producer_data.data.days_slot_5[index] ? (
                              <p>{t('page.close_working_time')}</p>
                            ) : null}{' '}
                          </span>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Row key={index}>
                            <Col lg={6} xs={5}>
                              <Form.Label className="field-label">
                                {t('page.view_producer_work_details_start_time_label')}
                              </Form.Label>
                              : {timeFormatFunc(data.days_start)}{' '}
                            </Col>
                            <Col lg={6} xs={5}>
                              <Form.Label className="field-label">
                                {t('page.view_producer_work_details_end_time_label')}
                              </Form.Label>
                              : {timeFormatFunc(data.days_end)}{' '}
                            </Col>
                          </Row>
                        </>
                      );
                    }
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
        <Card.Body>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={3} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_work_details_label_6')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1} className="divider">
                  :
                </Col>
                <Col lg={8} xs={5}>
                  {producer_data.data.days_slot_6.map((data, index) => {
                    if (data.is_close === true) {
                      return (
                        <>
                          <span key={index}>
                            {producer_data.data.days_slot_6[0] ===
                            producer_data.data.days_slot_6[index] ? (
                              <p>{t('page.close_working_time')}</p>
                            ) : null}{' '}
                          </span>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Row key={index}>
                            <Col lg={6} xs={5}>
                              <Form.Label className="field-label">
                                {t('page.view_producer_work_details_start_time_label')}
                              </Form.Label>
                              : {timeFormatFunc(data.days_start)}{' '}
                            </Col>
                            <Col lg={6} xs={5}>
                              <Form.Label className="field-label">
                                {t('page.view_producer_work_details_end_time_label')}
                              </Form.Label>
                              : {timeFormatFunc(data.days_end)}{' '}
                            </Col>
                          </Row>
                        </>
                      );
                    }
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
        <Card.Body>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={3} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_work_details_label_7')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1} className="divider">
                  :
                </Col>
                <Col lg={8} xs={5}>
                  {producer_data.data.days_slot_7.map((data, index) => {
                    if (data.is_close === true) {
                      return (
                        <>
                          <span key={index}>
                            {producer_data.data.days_slot_7[0] ===
                            producer_data.data.days_slot_7[index] ? (
                              <p>{t('page.close_working_time')}</p>
                            ) : null}{' '}
                          </span>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Row key={index}>
                            <Col lg={6} xs={5}>
                              <Form.Label className="field-label">
                                {t('page.view_producer_work_details_start_time_label')}
                              </Form.Label>
                              : {timeFormatFunc(data.days_start)}{' '}
                            </Col>
                            <Col lg={6} xs={5}>
                              <Form.Label className="field-label">
                                {t('page.view_producer_work_details_end_time_label')}
                              </Form.Label>
                              : {timeFormatFunc(data.days_end)}{' '}
                            </Col>
                          </Row>
                        </>
                      );
                    }
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_producer_contact_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_contact_details_contract_number_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.contract_number}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_contract_details_contract_start_date_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>
                    {producer_data.data.contract_start_date !== ''
                      ? moment(producer_data.data.contract_start_date).format('DD-MM-YYYY')
                      : ''}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_contract_details_contract_end_date_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>
                    {producer_data.data.contract_end_date !== ''
                      ? moment(producer_data.data.contract_end_date).format('DD-MM-YYYY')
                      : ''}
                  </span>
                </Col>
              </Row>
            </Col>
            {producer_data.data.agent_name ? (
              <>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.view_producer_agent_name_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{producer_data.data.agent_name}</span>
                    </Col>
                  </Row>
                </Col>
              </>
            ) : null}
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_producer_owner_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_owner_first_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.owner_first_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_owner_last_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.owner_last_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_owner_email_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.owner_email}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_owner_details_owner_phone_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                {producer_data.data.owner_phone !== '' ? (
                  <Col lg={5} xs={5}>
                    <span>
                      +{producer_data.data.owner_country_code}&nbsp;
                      {producer_data.data.owner_phone}
                    </span>
                  </Col>
                ) : null}
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_owner_details_owner_ktp_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.owner_ktp}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_producer_person_in_charge_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_person_incharge_first_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.person_incharge_first_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_person_incharge_last_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.person_incharge_last_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_person_incharge_email_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.person_incharge_email}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_person_incharge_phone_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                {producer_data.data.person_incharge_phone !== '' ? (
                  <Col lg={5} xs={5}>
                    <span>
                      +{producer_data.data.person_country_code}&nbsp;
                      {producer_data.data.person_incharge_phone}
                    </span>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_producer_accounting_person_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_accounting_first_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.accounting_first_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_accounting_last_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.accounting_last_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_accounting_email_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{producer_data.data.accounting_email}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_producer_accounting_phone_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                {producer_data.data.accounting_phone !== '' ? (
                  <Col lg={5} xs={5}>
                    <span>
                      +{producer_data.data.accounting_country_code}&nbsp;
                      {producer_data.data.accounting_phone}
                    </span>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_producer_attachment_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={12} xs={12}>
                  <Form.Label className="field-label">
                    {t('page.view_produce_ktp_label')}
                  </Form.Label>
                  {producer_data.data.owner_ktp_attchment === '' ? null : urlToGetExnsion(
                      producer_data.data.owner_ktp_attchment
                    ) === 'pdf' ? (
                    <a
                      target="_blank"
                      href={producer_data.data.owner_ktp_attchment}
                      rel="noreferrer">
                      <span>
                        <div className="preview-image">
                          <img
                            src={imagePreviewFromik(producer_data.data.owner_ktp_attchment)}
                            alt="profile_img"
                          />
                        </div>
                      </span>
                    </a>
                  ) : (
                    <span>
                      <div className="preview-image">
                        <img
                          src={imagePreviewFromik(producer_data.data.owner_ktp_attchment)}
                          alt="profile_img"
                          onClick={() => {
                            setModalImgUrl(producer_data.data.owner_ktp_attchment);
                            setModalShow(true);
                          }}
                        />
                      </div>
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={12} xs={12}>
                  <Form.Label className="field-label">
                    {t('page.view_produce_slefie_card_label')}
                  </Form.Label>
                  {producer_data.data.selfi_with_ktp === '' ? null : urlToGetExnsion(
                      producer_data.data.selfi_with_ktp
                    ) === 'pdf' ? (
                    <a target="_blank" href={producer_data.data.selfi_with_ktp} rel="noreferrer">
                      <span>
                        <div className="preview-image">
                          <img
                            src={imagePreviewFromik(producer_data.data.selfi_with_ktp)}
                            alt="profile_img"
                          />
                        </div>
                      </span>
                    </a>
                  ) : (
                    <span>
                      <div className="preview-image">
                        <img
                          src={imagePreviewFromik(producer_data.data.selfi_with_ktp)}
                          alt="profile_img"
                          onClick={() => {
                            setModalImgUrl(producer_data.data.selfi_with_ktp);
                            setModalShow(true);
                          }}
                        />
                      </div>
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            {producer_data.data.producer_licence !== '' ? (
              <>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={12} xs={12}>
                      <Form.Label className="field-label">
                        {t('page.view_producer_license_label')}
                      </Form.Label>
                      {producer_data.data.producer_licence === '' ? null : urlToGetExnsion(
                          producer_data.data.producer_licence
                        ) === 'pdf' ? (
                        <a
                          target="_blank"
                          href={producer_data.data.producer_licence}
                          rel="noreferrer">
                          <span>
                            <div className="preview-image">
                              <img
                                src={imagePreviewFromik(producer_data.data.producer_licence)}
                                alt="profile_img"
                              />
                            </div>
                          </span>
                        </a>
                      ) : (
                        <span>
                          <div className="preview-image">
                            <img
                              src={imagePreviewFromik(producer_data.data.producer_licence)}
                              alt="profile_img"
                              onClick={() => {
                                setModalImgUrl(producer_data.data.producer_licence);
                                setModalShow(true);
                              }}
                            />
                          </div>
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
              </>
            ) : null}
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={12} xs={12}>
                  <Form.Label className="field-label">{t('page.add_npwp_label')}</Form.Label>

                  {producer_data.data.npwp_attchement === '' ? null : urlToGetExnsion(
                      producer_data.data.npwp_attchement
                    ) === 'pdf' ? (
                    <a target="_blank" href={producer_data.data.npwp_attchement} rel="noreferrer">
                      <span>
                        <div className="preview-image">
                          <img
                            src={imagePreviewFromik(producer_data.data.npwp_attchement)}
                            alt="profile_img"
                          />
                        </div>
                      </span>
                    </a>
                  ) : (
                    <span>
                      <div className="preview-image">
                        <img
                          src={imagePreviewFromik(producer_data.data.npwp_attchement)}
                          alt="profile_img"
                          onClick={() => {
                            setModalImgUrl(producer_data.data.npwp_attchement);
                            setModalShow(true);
                          }}
                        />
                      </div>
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={12} xs={12}>
                  <Form.Label className="field-label">
                    {t('page.view_produce_premise_attachment_label')}
                  </Form.Label>
                </Col>
                <Col lg={12} xs={12}>
                  <Row>
                    {producer_data.data.photos_of_premises_attachment.length > 0 &&
                      producer_data.data.photos_of_premises_attachment.map((item, i) => {
                        return (
                          <Col key={i} lg={6} xs={6}>
                            {item.photos_of_premises_attachment === '' ? null : urlToGetExnsion(
                                item.photos_of_premises_attachment
                              ) === 'pdf' ? (
                              <a
                                target="_blank"
                                href={item.photos_of_premises_attachment}
                                rel="noreferrer">
                                <span>
                                  <div className="preview-image">
                                    <img
                                      src={imagePreviewFromik(item.photos_of_premises_attachment)}
                                      alt="profile_img"
                                    />
                                  </div>
                                </span>
                              </a>
                            ) : (
                              <span>
                                <div className="preview-image">
                                  <img
                                    src={imagePreviewFromik(item.photos_of_premises_attachment)}
                                    alt="profile_img"
                                    onClick={() => {
                                      setModalImgUrl(item.photos_of_premises_attachment);
                                      setModalShow(true);
                                    }}
                                  />
                                </div>
                              </span>
                            )}
                          </Col>
                        );
                      })}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={12} xs={12}>
                  <Form.Label className="field-label">
                    {t('page.view_produce_additional_attachment_label')}
                  </Form.Label>
                </Col>
                <Col lg={12} xs={12}>
                  <Row>
                    {producer_data.data.additional_attachment.length > 0 &&
                      producer_data.data.additional_attachment.map((item, i) => {
                        return (
                          <Col key={i} lg={6} xs={6}>
                            {urlToGetExnsion(item.additional_attachement_file) === 'pdf' ? (
                              <a
                                target="_blank"
                                href={item.additional_attachement_file}
                                rel="noreferrer">
                                <span>
                                  <Col lg={5} xs={5}>
                                    <span>{item.additional_attachement_name}</span>
                                  </Col>
                                  <div className="preview-image">
                                    <img
                                      src={imagePreviewFromik(item.additional_attachement_file)}
                                      alt="profile_img"
                                    />
                                  </div>
                                </span>
                              </a>
                            ) : (
                              <span>
                                <Col lg={5} xs={5}>
                                  <span>{item.additional_attachement_name}</span>
                                </Col>
                                <div className="preview-image">
                                  <img
                                    src={imagePreviewFromik(item.additional_attachement_file)}
                                    alt="profile_img"
                                    onClick={() => {
                                      setModalImgUrl(item.additional_attachement_file);
                                      setModalShow(true);
                                    }}
                                  />
                                </div>
                              </span>
                            )}
                          </Col>
                        );
                      })}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <div>
        <div className="primary-button">
          <Link to={`/producer/list`} className="link-center">
            {t('page.add_producer_cancel_button')}
          </Link>
        </div>
        <Modal show={modalShow} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body className="text-center">
            <div className="close-popup">
              <FontAwesomeIcon icon={faClose} onClick={() => setModalShow(false)} />
            </div>
            <img className="text-center" src={modalImgUrl} alt="profile_img" />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
ViewProducerPage.propTypes = {
  t: PropTypes.func
};
export { ViewProducerPage };
