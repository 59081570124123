import * as Yup from 'yup';
Yup.addMethod(Yup.string, 'phonecheck', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const phoneRegExp = /^\d{7,15}?$/;
    if (value && value.length > 0) {
      return phoneRegExp.test(value);
    }
    return true;
  });
});
let validationSchema = Yup.object({
  first_name: Yup.string()
    .required('page.profile_first_name_required')
    .matches(/^[aA-zZ\s]+$/, 'page.add_edit_profile_first_name_alphabates_validation'),
  last_name: Yup.string()
    .required('page.profile_last_name_required')
    .matches(/^[aA-zZ\s]+$/, 'page.add_edit_profile_last_name_alphabates_validation'),
  email: Yup.string('page.profile_email_required')
    .email('page.login_email_validation_email')
    .required('page.profile_email_required'),

  phone: Yup.string()
    .required('page.profile_phone_required')
    .phonecheck('page.profile_mobile_number_match')
  // .matches(
  //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  //   'page.profile_mobile_number_match'
  // )
  // country_id: Yup.string(('page.profile_country_id_required')).required(
  //   ('page.profile_country_id_required')
  // )
});

let emailValidationSchema = Yup.object({
  email: Yup.string('page.profile_email_required')
    .email('page.login_email_validation_email')
    .required('page.profile_email_required')
});
let validationSchemaVerfiy = Yup.object({
  otp: Yup.string()
    .required('page.profile_email_otp_required')
    .matches(/^[0-9\s]+$/, 'page.profile_email_otp_matches')
});
export { validationSchema, emailValidationSchema, validationSchemaVerfiy };
