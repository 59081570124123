import * as Yup from 'yup';
import moment from 'moment';

Yup.addMethod(Yup.string, 'phonecheck', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const phoneRegExp = /^\d{7,15}?$/;
    if (value && value.length > 0) {
      return phoneRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'ktp_number', function (errorMessage) {
  return this.test(`test-ktp_number`, errorMessage, function (value) {
    const ktp_numberRegExp = /^\d{16}?$/;
    if (value && value.length > 0) {
      return ktp_numberRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'alphaNumeric', function (errorMessage) {
  return this.test(`test-alphaNumeric`, errorMessage, function (value) {
    const alphaNumericRegExp = /^[aA-zZ0-9\s]+$/;
    if (value && value.length > 0) {
      return alphaNumericRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'npwp_number', function (errorMessage) {
  return this.test(`test-npwp_number`, errorMessage, function (value) {
    const npwp_numberRegExp = /^\d{2}(.\d{3})(.\d{3})(.\d{1})(-\d{3})(.\d{3})?$/;
    if (value && value.length > 0) {
      return npwp_numberRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.mixed, 'file_type', function (errorMessage) {
  return this.test(`test-file_type`, errorMessage, function (value) {
    if (typeof value === 'string') {
      return true;
    }
    const isValid = ['image/jpg', 'image/jpeg', 'image/png'];
    if (value !== undefined && value !== '') {
      return value && isValid.includes(value.type);
    }
    return true;
  });
});
Yup.addMethod(Yup.mixed, 'file_typepdf', function (errorMessage) {
  return this.test(`test-file_type`, errorMessage, function (value) {
    if (typeof value === 'string') {
      return true;
    }
    const isValid = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
    if (value !== undefined && value !== '') {
      return value && isValid.includes(value.type);
    }
    return true;
  });
});
export const validationSchema = Yup.object({
  // transporter General Detail
  name: Yup.string()
    .required('page.add_transporter_general_details_transporter_name_validation_required')
    .alphaNumeric('page.add_transporter_general_details_transporter_name_validation_alphaNumeric')
    .max(50, 'page.add_transporter_general_details_transporter_name_validation_max'),
  transport_npwp_number: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_general_details_npwp_number_validation_required')
        .required('page.add_transporter_general_details_npwp_number_validation_required')
        .npwp_number('page.add_transporter_general_details_transporter_name_validation_rgex')
        .test(
          'len',
          'page.add_transporter_general_details_transporter_name_validation_rgex',
          (val) => {
            if (val === undefined) {
              return true;
            }
            return val.length === 20;
          }
        );
    } else {
      return Yup.string().notRequired();
    }
  }),

  country_id: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string(
        'page.add_transporter_general_details_general_country_code_validation_required'
      ).required('page.add_transporter_general_details_general_country_code_validation_required');
    } else {
      return Yup.string().notRequired();
    }
  }),

  company_phone: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string(
        'page.add_transporter_general_details_general_contact_number_validation_required'
      )
        .required('page.add_transporter_general_details_general_contact_number_validation_required')
        .phonecheck('page.add_transporter_general_details_general_contact_number_validation_digit');
    } else {
      return Yup.string().notRequired();
    }
  }),

  address: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string(
        'page.add_transporter_general_details_address_validation_required'
      ).required('page.add_transporter_general_details_address_validation_required');
    } else {
      return Yup.string().notRequired();
    }
  }),

  territory_id: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string(
        'page.add_transporter_general_details_territory_name_validation_required'
      ).required('page.add_transporter_general_details_territory_name_validation_required');
    } else {
      return Yup.string().notRequired();
    }
  }),

  area_id: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string(
        'page.add_transporter_general_details_area_name_validation_required'
      ).required('page.add_transporter_general_details_area_name_validation_required');
    } else {
      return Yup.string().notRequired();
    }
  }),

  contract_status: Yup.string().required(
    'page.add_transporter_general_details_status_validation_required'
  ),
  //   transporter Contract Details
  contract_number: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      // return Yup.string('page.add_transporter_contact_details_contract_number_validation_required')
      //   .required('page.add_transporter_contact_details_contract_number_validation_required')
      //   .alphaNumeric(
      //     'page.add_transporter_general_details_transporter_contract_number_validation_alphaNumeric'
      //   );
      // return Yup.string(
      //   'page.add_transporter_contact_details_contract_number_validation_required'
      // ).required('page.add_transporter_contact_details_contract_number_validation_required');
      return Yup.string('page.add_transporter_contact_details_contract_number_validation_required');
    } else {
      return Yup.string().notRequired();
    }
  }),

  contract_start_date: Yup.date().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.date()
        .test(
          'not empty',
          'page.add_transporter_contract_details_contract_start_date_validation_required',
          function (value) {
            return !!value;
          }
        )
        .test(
          'start_date_test',
          'page.add_transporter_contract_details_contract_start_date_validation_max',
          function (value) {
            const { contract_end_date } = this.parent;
            if (contract_end_date === undefined || contract_end_date === '') {
              return true;
            }
            return isSameOrBeforeDate(value, contract_end_date);
          }
        );
    }
  }),
  // contract_end_date: Yup.date().when('contract_status', (val, schema) => {
  //   if (val !== '1') {
  //     return Yup.date()
  //       .test(
  //         'not empty',
  //         'page.add_transporter_contract_details_contract_end_date_validation_required',
  //         function (value) {
  //           return !!value;
  //         }
  //       )
  //       .test(
  //         'end_time_test',
  //         'page.add_transporter_contract_details_contract_end_date_validation_min',
  //         function (value) {
  //           const { contract_start_date } = this.parent;
  //           if (contract_start_date === undefined || contract_start_date === '') {
  //             return true;
  //           }
  //           return isSameOrAfterDate(value, contract_start_date);
  //         }
  //       );
  //   }
  // }),

  invoice_mode: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string(
        'page.add_transporter_contact_details_invoice_mode_validation_required'
      ).required('page.add_transporter_contact_details_invoice_mode_validation_required');
    } else {
      return Yup.string().notRequired();
    }
  }),

  // transporter Owner Details

  owner_first_name: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_owner_details_first_name_validation_required')
        .required('page.add_transporter_owner_details_first_name_validation_required')
        .alphaNumeric('page.add_transporter_owner_details_first_name_validation_alphaNumeric')
        .max(50, 'page.add_transporter_owner_details_first_name_validation_max');
    } else {
      return Yup.string().notRequired();
    }
  }),

  owner_last_name: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_owner_details_last_name_validation_required')
        .required('page.add_transporter_owner_details_last_name_validation_required')
        .alphaNumeric('page.add_transporter_owner_details_last_name_validation_alphaNumeric')
        .max(50, 'page.add_transporter_owner_details_last_name_validation_max');
    } else {
      return Yup.string().notRequired();
    }
  }),

  owner_email: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_owner_details_email_address_validation_required')
        .email('page.add_transporter_owner_details_email_address_validation_email')
        .required('page.add_transporter_owner_details_email_address_validation_required')
        .max(50, 'page.add_transporter_owner_details_email_address_validation_max');
    } else {
      return Yup.string().notRequired();
    }
  }),

  owner_country_id: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string(
        'page.add_transporter_owner_details_country_code_validation_required'
      ).required('page.add_transporter_owner_details_country_code_validation_required');
    } else {
      return Yup.string().notRequired();
    }
  }),

  owner_phone: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_owner_details_contact_number_validation_required')
        .required('page.add_transporter_owner_details_contact_number_validation_required')
        .phonecheck('page.add_transporter_owner_details_contact_number_validation_digit');
    } else {
      return Yup.string().notRequired();
    }
  }),

  owner_ktp: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_owner_details_ktp_number_validation_required')
        .required('page.add_transporter_owner_details_ktp_number_validation_required')
        .ktp_number('page.add_transporter_owner_details_ktp_number_validation_digit');
    } else {
      return Yup.string().notRequired();
    }
  }),

  // transporter Person In Charge

  person_incharge_first_name: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_person_incharge_first_name_validation_required')
        .required('page.add_transporter_person_incharge_first_name_validation_required')
        .alphaNumeric('page.add_transporter_person_incharge_first_name_validation_alphaNumeric')
        .max(50, 'page.add_transporter_person_incharge_first_name_validation_max');
    } else {
      return Yup.string().notRequired();
    }
  }),

  person_incharge_last_name: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_person_incharge_last_name_validation_required')
        .required('page.add_transporter_person_incharge_last_name_validation_required')
        .alphaNumeric('page.add_transporter_person_incharge_last_name_validation_alphaNumeric')
        .max(50, 'page.add_transporter_person_incharge_last_name_validation_max');
    } else {
      return Yup.string().notRequired();
    }
  }),

  person_incharge_email: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_person_incharge_email_validation_required')
        .email('page.add_transporter_person_incharge_email_validation_email')
        .required('page.add_transporter_person_incharge_email_validation_required')
        .max(50, 'page.add_transporter_person_incharge_email_validation_max');
    } else {
      return Yup.string().notRequired();
    }
  }),

  person_incharge_country_id: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string(
        'page.add_transporter_person_incharge_country_id_validation_required'
      ).required('page.add_transporter_person_incharge_country_id_validation_required');
    } else {
      return Yup.string().notRequired();
    }
  }),

  person_incharge_phone: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_person_incharge_phone_validation_required')
        .required('page.add_transporter_person_incharge_phone_validation_required')
        .phonecheck('page.add_transporter_person_incharge_phone_validation_digit');
    } else {
      return Yup.string().notRequired();
    }
  }),

  // transporter Acounting Person Details

  accounting_first_name: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_accounting_first_name_validation_required')
        .required('page.add_transporter_accounting_first_name_validation_required')
        .alphaNumeric('page.add_transporter_accounting_first_name_validation_alphaNumeric')
        .max(50, 'page.add_transporter_accounting_first_name_validation_max');
    } else {
      return Yup.string().notRequired();
    }
  }),

  accounting_last_name: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_accounting_last_name_validation_required')
        .required('page.add_transporter_accounting_last_name_validation_required')
        .alphaNumeric('page.add_transporter_accounting_last_name_validation_alphaNumeric')
        .max(50, 'page.add_transporter_accounting_last_name_validation_max');
    } else {
      return Yup.string().notRequired();
    }
  }),

  accounting_email: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_accounting_email_validation_required')
        .email('page.add_transporter_accounting_email_validation_email')
        .required('page.add_transporter_accounting_email_validation_required')
        .max(50, 'page.add_transporter_accounting_email_validation_max');
    } else {
      return Yup.string().notRequired();
    }
  }),

  accounting_country_id: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_accounting_country_id_validation_required').required(
        'page.add_transporter_accounting_country_id_validation_required'
      );
    } else {
      return Yup.string().notRequired();
    }
  }),

  accounting_phone: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_accounting_phone_validation_required')
        .required('page.add_transporter_accounting_phone_validation_required')
        .phonecheck('page.add_transporter_accounting_phone_validation_digit');
    } else {
      return Yup.string().notRequired();
    }
  }),

  owner_ktp_attchment: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_attachment_additional_attachement_file_required')
        .required('page.add_transporter_attachment_additional_attachement_file_required')
        .file_typepdf('page.add_transporter_attachment_additional_attachement_file_file_type');
    } else {
      return Yup.string().notRequired();
    }
  }),

  selfi_with_ktp_attchement: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_attachment_additional_attachement_file_required')
        .required('page.add_transporter_attachment_additional_attachement_file_required')
        .file_type('page.add_transporter_attachment_additional_attachement_file_file_type');
    } else {
      return Yup.string().notRequired();
    }
  }),

  stamp: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_attachment_additional_attachement_file_required')
        .required('page.add_transporter_attachment_additional_attachement_file_required')
        .file_type('page.add_transporter_attachment_additional_attachement_file_file_type');
    } else {
      return Yup.string().notRequired();
    }
  }),

  additional_attachement: Yup.array().of(
    Yup.object().shape({
      additional_attachement_name: Yup.string()
        .trim()
        .required('page.add_transporter_attachment_additional_attachement_name_required'),
      additional_attachement_file: Yup.mixed()
        .required('page.add_transporter_attachment_additional_attachement_file_required')
        .file_typepdf('page.add_transporter_attachment_additional_attachement_file_file_type')
    })
  ),

  surveillance_card: Yup.string().required(
    'page.add_transporter_general_details_transporter_surveillance_card_validation_required'
  ),

  surveillance_card_expiry_date: Yup.date().test(
    'not empty',
    'page.add_transporter_surveillance_card_expiry_date_validation_required',
    function (value) {
      return !!value;
    }
  ),

  surveillance_card_attachment: Yup.string().when('contract_status', (val, schema) => {
    if (val !== '1') {
      return Yup.string('page.add_transporter_attachment_additional_attachement_file_required')
        .required('page.add_transporter_attachment_additional_attachement_file_required')
        .file_type('page.add_transporter_attachment_additional_attachement_file_file_type');
    } else {
      return Yup.string().notRequired();
    }
  })
});

const isSameOrBeforeDate = (startTime, endTime) => {
  return moment(startTime, 'YYYY-MM-DD').isSameOrBefore(moment(endTime, 'YYYY-MM-DD'));
};
// const isSameOrAfterDate = (startTime, endTime) => {
//   return moment(startTime, 'YYYY-MM-DD').isSameOrAfter(moment(endTime, 'YYYY-MM-DD'));
// };
