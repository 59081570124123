import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListDeliverySessionPage = React.lazy(() => import('./ListDeliverySessionPage'));
const DetailDeliverySessionPage = React.lazy(() => import('./ViewDeliverySessionPage'));

const DeliverySessionRoutes = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/list" element={<ListDeliverySessionPage t={t} />} />
      <Route exact path="/details/:delivery_point_current_session_id" element={<DetailDeliverySessionPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
DeliverySessionRoutes.propTypes = {
  t: PropTypes.func
};
export default DeliverySessionRoutes;
