import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListPostCodePage = React.lazy(() => import('./ListPostCodePage'));
const AddPostCodePage = React.lazy(() => import('./AddPostCodePage'));
const EditPostCodePage = React.lazy(() => import('./EditPostCodePage'));

const PostCodeRoute = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/list/:area_id" element={<ListPostCodePage t={t} />} />
      <Route exact path="/add/:area_id" element={<AddPostCodePage t={t} />} />
      <Route exact path="/edit/:postal_code_master_id" element={<EditPostCodePage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
PostCodeRoute.propTypes = {
  t: PropTypes.func
};
export default PostCodeRoute;
