import { useQuery, useMutation } from 'react-query';

import { TransporterService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useStoreTransporter = (onSuccess, onError = onDefaultError) => {
  return useMutation(TransporterService.storeTransporter, {
    onSuccess,
    onError
  });
};

const useListTransporter = (
  [
    page_no,
    searchText,
    selectedStatus,
    selectedInvoiceMode,
    fromDate,
    toDate,
    territryId,
    areaId,
    selectedDateType,
    sortBy,
    sortOrder
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'transporter-list',
      page_no,
      searchText,
      selectedStatus,
      selectedInvoiceMode,
      fromDate,
      toDate,
      territryId,
      areaId,
      selectedDateType,
      sortBy,
      sortOrder
    ],
    TransporterService.listTransporter,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useViewTransporter = (transporter_id, onSuccess, onError = onDefaultError) => {
  return useQuery('transporter-view', () => TransporterService.viewTransporter(transporter_id), {
    onSuccess,
    onError
  });
};

const useDeleteTransporterAttachment = (onSuccess, onError = onDefaultError) => {
  return useMutation(TransporterService.deleteTransporterAttachment, {
    onSuccess,
    onError
  });
};

const useUpdateTransporter = (onSuccess, onError = onDefaultError) => {
  return useMutation(TransporterService.updateTransporter, {
    onSuccess,
    onError
  });
};
const useGenerateTransporterReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(TransporterService.generateReport, {
    onSuccess,
    onError
  });
};

export {
  useStoreTransporter,
  useListTransporter,
  useViewTransporter,
  useDeleteTransporterAttachment,
  useUpdateTransporter,
  useGenerateTransporterReport
};
