import { useQuery } from 'react-query';

import { CommonService } from '@api/';
import { toast } from 'react-toastify';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useContainerListData = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'producer-container-list-data',
    () => {
      return CommonService.producerContainerData();
    },
    {
      onSuccess,
      onError
    }
  );
};

const useGetTerritoryData = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'get-territory',
    () => {
      return CommonService.getTerritoryData();
    },
    {
      onSuccess,
      onError
    }
  );
};
const useGetAreaData = (territory_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-area', territory_id],
    ({ queryKey }) => {
      return CommonService.getAreaData(queryKey[1]);
    },
    {
      onSuccess,
      onError
    }
  );
};

const useGetAllAreaData = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['get-area'],
    ({ queryKey }) => {
      return CommonService.getAllAreaData();
    },
    {
      onSuccess,
      onError
    }
  );
};
const useGetCollectAreaData = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'get-collect-area',
    () => {
      return CommonService.getCollectAreaData();
    },
    {
      onSuccess,
      onError
    }
  );
};
const useGetTransportreaData = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'get-transport-area',
    () => {
      return CommonService.getTransportAreaData();
    },
    {
      onSuccess,
      onError
    }
  );
};
const useGetProducerTypeData = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'get-producer-type',
    () => {
      return CommonService.getProducerTypeData();
    },
    {
      onSuccess,
      onError
    }
  );
};
const useProducerTypeWithTest = (onSuccess, onError = onDefaultError) =>
  useQuery(
    'producertype/dropdown-data?with_test_type',
    () => CommonService.producerTypeWithTest(),
    { onSuccess, onError }
  );
const useGetWestCodeData = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'get-west-code',
    () => {
      return CommonService.getWestCodeData();
    },
    {
      onSuccess,
      onError
    }
  );
};

const useGetAgentList = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'get-agent',
    () => {
      return CommonService.getAgentList();
    },
    {
      onSuccess,
      onError
    }
  );
};
const useGetTransporterList = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'get-transporter',
    () => {
      return CommonService.getTransporterList();
    },
    {
      onSuccess,
      onError
    }
  );
};

const useGetDriverData = (request, onSuccess, onError = onDefaultError) => {
  return useQuery('driver-list', () => CommonService.getDriverData(request), {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};

const useGetWarehouseDataArea = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'get-warehouse-list-dropdown-area',
    () => {
      return CommonService.getWarehouseListArea();
    },
    {
      onSuccess,
      onError
    }
  );
};

const useGetFactoryDataArea = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'get-factory-list-dropdown-area',
    () => {
      return CommonService.getFactoryListArea();
    },
    {
      onSuccess,
      onError
    }
  );
};

export {
  useGetProducerTypeData,
  useProducerTypeWithTest,
  useGetTerritoryData,
  useGetAreaData,
  useGetCollectAreaData,
  useGetTransportreaData,
  useGetWestCodeData,
  useGetAllAreaData,
  useGetAgentList,
  useGetTransporterList,
  useGetDriverData,
  useContainerListData,
  useGetWarehouseDataArea,
  useGetFactoryDataArea
};
