import { useMutation, useQuery } from 'react-query';

import { PostalCodeService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Postal Code Hooks
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListPostalCode = (
  [page_no, searchText, status, area_id, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['postal-list', page_no, searchText, status, area_id, sortBy, sortOrder],
    PostalCodeService.listPostalCode,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useDeletePostalCode = (onSuccess, onError = onDefaultError) => {
  return useMutation(PostalCodeService.deletePostalCode, {
    onSuccess,
    onError
  });
};

const useStorePostalCode = (onSuccess, onError = onDefaultError) => {
  return useMutation(PostalCodeService.storePostalCode, {
    onSuccess,
    onError
  });
};

const useViewPostalCode = (postal_code_master_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['postal-view', postal_code_master_id], PostalCodeService.viewPostalCode, {
    onSuccess,
    onError
  });
};

const useUpdatePostalCode = (onSuccess, onError = onDefaultError) => {
  return useMutation(PostalCodeService.updatePostalCode, {
    onSuccess,
    onError
  });
};

export {
  useListPostalCode,
  useDeletePostalCode,
  useStorePostalCode,
  useViewPostalCode,
  useUpdatePostalCode
};
