import { useMutation, useQuery } from 'react-query';

import { CommissionService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Commission Hooks
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListCommission = (
  [page_no, searchText, status, transporter_id, territryId, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['commission-list', page_no, searchText, status, transporter_id, territryId, sortBy, sortOrder],
    CommissionService.listCommission,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useDeleteCommission = (onSuccess, onError = onDefaultError) => {
  return useMutation(CommissionService.deleteCommission, {
    onSuccess,
    onError
  });
};

const useStoreCommission = (onSuccess, onError = onDefaultError) => {
  return useMutation(CommissionService.storeCommission, {
    onSuccess,
    onError
  });
};

const useViewCommission = (commision_master_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['commission-view', commision_master_id], CommissionService.viewCommission, {
    onSuccess,
    onError
  });
};

const useUpdateCommission = (onSuccess, onError = onDefaultError) => {
  return useMutation(CommissionService.updateCommission, {
    onSuccess,
    onError
  });
};

const useGenerateCommissionFeeReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(CommissionService.generateReport, {
    onSuccess,
    onError
  });
};

export {
  useListCommission,
  useDeleteCommission,
  useStoreCommission,
  useViewCommission,
  useUpdateCommission,
  useGenerateCommissionFeeReport
};
