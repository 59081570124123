import client from '@libs/HttpClient';
class ContainerHistoryService {
  static getContainerHistory(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const sizeStatus = request.queryKey[3];
    const producerStatus = request.queryKey[4];
    const fromDate = request.queryKey[5];
    const toDate = request.queryKey[6];
    const isIncineration = request.queryKey[7];
    const sortBy = request.queryKey[8];
    const sortOrder = request.queryKey[9];

    return client.get(
      `container-history/index?page=${page_no}&search_text=${searchText}&producer_id=${producerStatus}&size=${sizeStatus}&from_date=${fromDate}&to_date=${toDate}&is_incineration=${isIncineration}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static getContainerHistoryFilter(request) {
    return client.get(`container-history/size-producer-filter`, request);
  }

  static getContainerHistoryDetail(sr_no) {
    return client.get(`container-history/detail/${sr_no}`);
  }
  static generateReport(request) {
    return client.post(`/container-history/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}

export { ContainerHistoryService };
