import { useMutation, useQuery } from 'react-query';

import { ProducerService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useStoreProducer = (onSuccess, onError = onDefaultError) => {
  return useMutation(ProducerService.storeProducer, {
    onSuccess,
    onError
  });
};

const useListProducer = (
  [
    page_no,
    searchText,
    selectedStatus,
    fromDate,
    toDate,
    producerTypeID,
    selectedCollection,
    territryId,
    areaId,
    wasteCode,
    selectedDateType,
    sortBy,
    sortOrder
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'producer-list',
      page_no,
      searchText,
      selectedStatus,
      fromDate,
      toDate,
      producerTypeID,
      selectedCollection,
      territryId,
      areaId,
      wasteCode,
      selectedDateType,
      sortBy,
      sortOrder
    ],
    ProducerService.listProducer,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useViewProducer = (producer_id, onSuccess, onError = onDefaultError) => {
  return useQuery('producer-view', () => ProducerService.viewProducer(producer_id), {
    onSuccess,
    onError
  });
};

const useUpdateProducer = (onSuccess, onError = onDefaultError) => {
  return useMutation(ProducerService.updateProducer, {
    onSuccess,
    onError
  });
};

const useDeleteProducerAttachment = (
  [attachment_id, type],
  onSuccess,
  onError = onDefaultError
) => {
  const sent = attachment_id ? true : false;
  return useQuery(
    ['producer-attachment-delete', attachment_id, type],
    ProducerService.deleteProducerAttachment,
    {
      onSuccess,
      onError,
      enabled: sent,
      retry: 0
    }
  );
};

const useDeleteProducerWorkingTime = (onSuccess, onError = onDefaultError) => {
  return useMutation(ProducerService.deleteProducerWorking, {
    onSuccess,
    onError
  });
};
const useGenerateProducerReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(ProducerService.generateReport, {
    onSuccess,
    onError
  });
};

export {
  useStoreProducer,
  useListProducer,
  useViewProducer,
  useDeleteProducerAttachment,
  useDeleteProducerWorkingTime,
  useUpdateProducer,
  useGenerateProducerReport
};
