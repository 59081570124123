import client from '@libs/HttpClient';
class ContainerMasterService {
  static addContainerDetail(request) {
    return client.post('container-detail/store', request);
  }

  static viewContainer() {
    return client.get('container-detail/view');
  }

  static deleteContainerAttachment(request) {
    const attachment_id = request.queryKey[1];
    return client.get(`container-detail/delete-attachment/${attachment_id}`, request);
  }

  static changeStatus(request) {
    const container_variable_id = request.queryKey[1];
    const status = request.queryKey[2];
    return client.get(`container-detail/change-status/${container_variable_id}/${status}`, request);
  }
}
export { ContainerMasterService };
