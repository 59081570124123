import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { StoreSessionService } from '@api/';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useStoreSession = (
  [currentPage, search_text, from_date, to_date, warehouse_id, factory_id, sortOrder, sortBy],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'tour/store-session/list',
      currentPage,
      search_text,
      from_date,
      to_date,
      warehouse_id,
      factory_id,
      sortOrder,
      sortBy
    ],
    StoreSessionService.viewStoreSession,
    {
      onSuccess,
      onError
    }
  );
};

const useViewStoreSessionDetails = (
  delivery_point_current_session_id,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    'tour/store-session/details',
    () => StoreSessionService.viewStoreSessionDetails(delivery_point_current_session_id),
    {
      onSuccess,
      onError
    }
  );
};

const useGenerateStoreSessionReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(StoreSessionService.generateReport, {
    onSuccess,
    onError
  });
};
export { useStoreSession, useViewStoreSessionDetails, useGenerateStoreSessionReport };
