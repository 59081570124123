import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import { imagePreviewFromik } from '@helpers/';
import { useDeleteTruckAttachment } from '@hooks';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { TNButton } from '@common/components/TNButton';

const EditAttachmentFiles = (props) => {
  const formik = useFormikContext();
  const [deleteAttchmentId, setDeleteAttchmentId] = useState(false);
  let registrationAttachmentRef = useRef();
  const conformitityAttachmentRef = useRef();
  const premisesRef = useRef();

  /**
   * !This block will call when user delete attachment
   */
  const handleDeleteAttachment = (id, type, i = '') => {
    if (id !== '' && id !== null) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2>{t('page.delete_attachment_popup_message')}</h2>
                <TNButton
                  className="table-delete-button"
                  onClick={() => {
                    if (type === 'register') {
                      registrationAttachmentRef.current.value = null;
                      formik.setFieldValue('registration_card', '');
                      setDeleteAttchmentId(id);
                    } else if (type === 'conformity') {
                      conformitityAttachmentRef.current.value = null;
                      formik.setFieldValue('conformity_certificate', '');
                      setDeleteAttchmentId(id);
                    } else if (type === 'truck_photo') {
                      setDeleteAttchmentId(id);
                      formik.values.truck_photos.splice(i, 1);
                      formik.setFieldValue('truck_photos', formik.values.truck_photos);
                    }
                    onClose();
                  }}>
                  {t('page.alert_popup_yes_button')}
                </TNButton>
                <TNButton className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </TNButton>
              </div>
            </div>
          );
        }
      });
    } else {
      if (type === 'register') {
        registrationAttachmentRef.current.value = null;
        formik.setFieldValue('registration_card', '');
      } else if (type === 'conformity') {
        conformitityAttachmentRef.current.value = null;
        formik.setFieldValue('conformity_certificate', '');
      } else if (type === 'truck_photo') {
        formik.values.truck_photos.splice(i, 1);
        formik.setFieldValue('truck_photos', formik.values.truck_photos);
      }
    }
  };

  /**
   * !This API will call when user confirm delete attachment
   */
  useDeleteTruckAttachment(
    [deleteAttchmentId, 'attachment'],
    (res) => {
      toast.success(res.message);
      setDeleteAttchmentId(false);
    },
    (err) => {
      toast.error(err.message);
      setDeleteAttchmentId(false);
    }
  );

  const { t } = props;
  const handleSelfieWithKTP = () => {
    conformitityAttachmentRef.current?.click();
  };
  //   file upload functions
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label className="field-label">
                {t('page.add_transporter_conformity_certificate_label')}
              </Form.Label>
              <Form.Control
                className={'d-none'}
                type="file"
                accept="image/*"
                ref={conformitityAttachmentRef}
                name="conformity_certificate"
                placeholder={t('page.add_transporter_attachments_selfie_with_ktp_placeholder')}
                onChange={(event) => {
                  formik.setFieldValue('conformity_certificate', event.currentTarget.files[0]);
                }}
                hidden
              />
              <Button
                type="button"
                onClick={handleSelfieWithKTP}
                className={
                  'upload-button' ||
                  (formik.touched.conformity_certificate && formik.errors.conformity_certificate
                    ? 'form-field-error'
                    : formik.touched.conformity_certificate && !formik.errors.conformity_certificate
                    ? 'form-field-success'
                    : '')
                }>
                {t('page.file_upload')}
              </Button>
            </Form.Group>
            <div className="form-field-error-text">
              {formik.touched.conformity_certificate && formik.errors.conformity_certificate ? (
                <div>{t(formik.errors.conformity_certificate)}</div>
              ) : null}
            </div>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label className="field-label">
                {t('page.add_producer_truck_photos_label')}
              </Form.Label>
              <Form.Control
                className={'d-none'}
                type="file"
                multiple={true}
                accept="image/*"
                ref={premisesRef}
                name="truck_photos"
                placeholder={t('page.add_producer_attachments_selfie_with_ktp_placeholder')}
                onChange={(event) => {
                  let filelength = formik.values.truck_photos.length;
                  for (var i = 0; i < event.currentTarget.files.length; ++i) {
                    formik.setFieldValue(`truck_photos.${filelength}`, {
                      image: event.currentTarget.files[i],
                      attachment_id: ''
                    });
                    filelength++;
                  }
                }}
                hidden
              />
              <Button
                type="button"
                onClick={() => premisesRef.current.click()}
                className={
                  'upload-button' ||
                  (formik.touched.truck_photos && formik.errors.truck_photos
                    ? 'form-field-error'
                    : formik.touched.truck_photos && !formik.errors.truck_photos
                    ? 'form-field-success'
                    : '')
                }>
                {t('page.file_upload')}
              </Button>
            </Form.Group>
            <div className="form-field-error-text">
              {formik.touched.truck_photos && formik.errors.truck_photos ? (
                <div>{t(formik.errors.truck_photos)}</div>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            {formik.values.conformity_certificate && (
              <div className="preview-image">
                <img
                  src={imagePreviewFromik(formik.values.conformity_certificate)}
                  alt="profile_img"
                />
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={() =>
                    handleDeleteAttachment(formik.values.conformity_certificate_id, 'conformity')
                  }
                />
              </div>
            )}
          </Col>
          <Col sm={12} md={6}>
            <Row>
              {formik.values.truck_photos.length > 0 &&
                formik.values.truck_photos.map((item, i) => {
                  return (
                    <Col key={i} sm={6} lg={2} className="pt-2">
                      <div className="preview-image">
                        <img src={imagePreviewFromik(item.image)} alt="profile_img" />
                        <FontAwesomeIcon
                          icon={faClose}
                          onClick={() => {
                            handleDeleteAttachment(item.attachment_id, 'truck_photo', i);
                          }}
                        />
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
EditAttachmentFiles.propTypes = {
  t: PropTypes.func
};
export { EditAttachmentFiles };
