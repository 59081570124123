import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { UnloadMasterService } from '@api/';
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useWarehouseTourListAll = (
  [
    currentPage,
    search_text,
    from_date,
    to_date,
    area_id,
    tour_type,
    transporter_id,
    warehouse_id,
    factory_id,
    sortOrder,
    sortBy,
    sessionType
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'tour/unload-collect-truck/list',
      currentPage,
      search_text,
      from_date,
      to_date,
      area_id,
      tour_type,
      transporter_id,
      warehouse_id,
      factory_id,
      sortOrder,
      sortBy,
      sessionType
    ],
    UnloadMasterService.listAll,
    {
      onSuccess,
      onError
    }
  );
};

const useUnloadCollectTruck = (
  [
    currentPage,
    search_text,
    from_date,
    to_date,
    area_id,
    tour_type,
    transporter_id,
    warehouse_id,
    factory_id,
    sortOrder,
    sortBy
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'tour/unload-collect-truck/index',
      currentPage,
      search_text,
      from_date,
      to_date,
      area_id,
      tour_type,
      transporter_id,
      warehouse_id,
      factory_id,
      sortOrder,
      sortBy
    ],
    UnloadMasterService.viewUnloadCollectTruck,
    {
      onSuccess,
      onError
    }
  );
};

const useViewUnloadTruckDetails = (
  delivery_point_current_session_id,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    'tour/unload-collect-truck/details',
    () => UnloadMasterService.viewUnloadCollectTruckDetails(delivery_point_current_session_id),
    {
      onSuccess,
      onError
    }
  );
};

const useAddNotes = (onSuccess, onError = onDefaultError) => {
  return useMutation(UnloadMasterService.addNotes, {
    onSuccess,
    onError
  });
};
const useGenerateUnloadTruckReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(UnloadMasterService.generateReport, {
    onSuccess,
    onError
  });
};

export {
  useUnloadCollectTruck,
  useViewUnloadTruckDetails,
  useAddNotes,
  useGenerateUnloadTruckReport,
  useWarehouseTourListAll
};
