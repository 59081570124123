import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import { dateFormatCommon, dateFormatManualAdd } from '@helpers/commonHelpers';

const ContactrDetails = (props) => {
  const formik = useFormikContext();
  const { t } = props;

  const handleContractNumberIsReadonly = () => {
    return true;
  };

  const generateNumber = () => {
    var number =
      'CMI/W/' +
      formik.values.warehouse_id +
      '/' +
      parseInt(moment().month() + 1) +
      '/' +
      moment().year();

    formik.values.contract_number = number;

    return number;
  };

  const handleContractNumber = (value) => {
    if (value || value !== '') {
      if (value.indexOf('CMI/W') === -1 || value.indexOf('CMI/W/undefined') !== -1) {
        return generateNumber();
      } else {
        return value;
      }
    }

    if (formik.values.contract_status === '1' && 'warehouse_id' in formik.values === true) {
      return generateNumber();
    }
  };

  const handleContractStartDate = (value) => {
    if (value || value !== '') return value;

    if (formik.values.contract_status === '1') {
      let today = new Date();

      const aYearFromNow = new Date(today);
      formik.setFieldValue(
        'contract_end_date',
        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
      );
      formik.setFieldValue('contract_start_date', today);

      return today;
    }
  };

  return (
    <Card className="bg-white rounded">
      <Card.Body>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_warehouse_contact_details_contract_number_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.contract_number && formik.errors.contract_number
                    ? 'form-field-error'
                    : formik.touched.contract_number && !formik.errors.contract_number
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="contract_number"
                placeholder={t('page.add_warehouse_contact_details_contract_number_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={handleContractNumber(formik.values.contract_number)}
                disabled={handleContractNumberIsReadonly()}
              />
              <div className="form-field-error-text">
                {formik.touched.contract_number && formik.errors.contract_number ? (
                  <div>{t(formik.errors.contract_number)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_warehouse_contract_details_contract_start_date_label')}
              </Form.Label>
              <DatePicker
                onKeyDown={dateFormatManualAdd}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column"
                placeholderText={dateFormatCommon().toUpperCase()}
                maxDate={
                  formik.values.contract_end_date !== '' &&
                  formik.values.contract_end_date !== undefined
                    ? formik.values.contract_end_date
                    : new Date()
                }
                selected={handleContractStartDate(formik.values.contract_start_date)}
                dateFormat={dateFormatCommon()}
                onChange={(date) => {
                  formik.setFieldValue('contract_start_date', date);
                }}
                onSelect={(date) => {
                  const aYearFromNow = new Date(date);
                  formik.setFieldValue(
                    'contract_end_date',
                    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
                  );
                }}
                onBlur={(date) => {
                  var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');

                  if (date.currentTarget.value) {
                    if (!date1.isValid()) {
                      toast.error(t('page.invalid_date_format'));
                      formik.setFieldValue('contract_start_date', '');
                    } else {
                      const aYearFromNow = new Date(date1);
                      formik.setFieldValue(
                        'contract_end_date',
                        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
                      );
                    }
                  } else {
                    formik.setFieldValue('contract_start_date', '');
                  }
                }}
              />
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.contract_start_date && formik.errors.contract_start_date
                    ? 'form-field-error'
                    : formik.touched.contract_start_date && !formik.errors.contract_start_date
                    ? 'form-field-success'
                    : '')
                }
                type="hidden"
                min={moment().format('YYYY-MM-DD')}
                name="contract_start_date"
                placeholder={t(
                  'page.add_warehouse_contract_details_contract_start_date_placeholder'
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contract_start_date}
              />
              <div className="form-field-error-text">
                {formik.touched.contract_start_date && formik.errors.contract_start_date ? (
                  <div>{t(formik.errors.contract_start_date)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_warehouse_contract_details_contract_end_date_label')}
                <span className="preview-image">
                  <FontAwesomeIcon
                    icon={faClose}
                    onClick={() => {
                      formik.setFieldValue('contract_end_date', '');
                    }}
                  />
                </span>
              </Form.Label>
              <DatePicker
                onKeyDown={dateFormatManualAdd}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column"
                placeholderText={dateFormatCommon().toUpperCase()}
                selected={formik.values.contract_end_date}
                dateFormat={dateFormatCommon()}
                minDate={formik.values.contract_start_date}
                onChange={(date) => {
                  formik.setFieldValue('contract_end_date', date);
                }}
                onBlur={(date) => {
                  var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                  if (date.currentTarget.value) {
                    if (!date1.isValid()) {
                      toast.error(t('page.invalid_date_format'));
                      formik.setFieldValue('contract_end_date', '');
                    } else {
                      var minDate =
                        formik.values.contract_start_date !== '' &&
                        formik.values.contract_start_date !== undefined
                          ? formik.values.contract_start_date
                          : new Date();
                      if (date1 < minDate) {
                        toast.error(t('page.to_date_greater_than_from_Date'));
                        formik.setFieldValue('contract_end_date', '');
                      }
                    }
                  }
                }}
              />
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.contract_end_date && formik.errors.contract_end_date
                    ? 'form-field-error'
                    : formik.touched.contract_end_date && !formik.errors.contract_end_date
                    ? 'form-field-success'
                    : '')
                }
                type="hidden"
                name="contract_end_date"
                min={
                  formik.values.contract_start_date === ''
                    ? moment().format('YYYY-MM-DD')
                    : moment(formik.values.contract_start_date).format('YYYY-MM-DD')
                }
                placeholder={t('page.add_warehouse_contract_details_contract_end_date_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contract_end_date}
              />
              <div className="form-field-error-text">
                {formik.touched.contract_end_date && formik.errors.contract_end_date ? (
                  <div>{t(formik.errors.contract_end_date)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
ContactrDetails.propTypes = {
  t: PropTypes.func
};
export { ContactrDetails };
