import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'phone', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const phoneRegExp = /^\d{10}?$/;
    if (value && value.length > 0) {
      return phoneRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'ktp_number', function (errorMessage) {
  return this.test(`test-ktp_number`, errorMessage, function (value) {
    const ktp_numberRegExp = /^\d{16}?$/;
    if (value && value.length > 0) {
      return ktp_numberRegExp.test(value);
    }
    return true;
  });
});

// Yup.addMethod(Yup.string, 'plate_number', function (errorMessage) {
//   return this.test(`test-plate_number`, errorMessage, function (value) {
//     const plate_numberRegExp = /^[A-Z]{2}-\d{5}-[A-Z]{3}?$/gi;
//     if (value && value.length > 0) {
//       return plate_numberRegExp.test(value);
//     }
//     return true;
//   });
// });

Yup.addMethod(Yup.string, 'max_weight_thousand', function (errorMessage) {
  return this.test(`test-max_weight_thousand`, errorMessage, function (value) {
    if (value !== '' && value !== undefined) {
      value = parseInt(value.replace(',', ''));
      if (value > 1000) {
        return false;
      }
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'alphaNumeric', function (errorMessage) {
  return this.test(`test-alphaNumeric`, errorMessage, function (value) {
    const alphaNumericRegExp = /^[aA-zZ0-9\s]+$/;
    if (value && value.length > 0) {
      return alphaNumericRegExp.test(value);
    }
    return true;
  });
});

Yup.addMethod(Yup.mixed, 'file_type', function (errorMessage) {
  return this.test(`test-file_type`, errorMessage, function (value) {
    if (typeof value === 'string') {
      return true;
    }
    const isValid = ['image/jpg', 'image/jpeg', 'image/png'];
    if (value !== undefined && value !== '') {
      return value && isValid.includes(value.type);
    }
    return true;
  });
});
Yup.addMethod(Yup.mixed, 'file_typepdf', function (errorMessage) {
  return this.test(`test-file_type`, errorMessage, function (value) {
    if (typeof value === 'string') {
      return true;
    }
    const isValid = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
    if (value !== undefined && value !== '') {
      return value && isValid.includes(value.type);
    }
    return true;
  });
});

Yup.addMethod(Yup.mixed, 'multiplefilecheck', function (errorMessage) {
  return this.test(`test-multiplefilecheck`, errorMessage, function (value) {
    const isValid = ['image/jpg', 'image/jpeg', 'image/png'];
    const checkarr = [];
    for (let key in value) {
      checkarr.push(value && isValid.includes(value[key].type));
    }
    if (checkarr.filter((item, i, ar) => ar.indexOf(item) === i).length > 1) {
      return false;
    }
    return true;
  });
});
export const validationSchema = Yup.object({
  // trucks General Detail
  brand_name: Yup.string()
    // .required('page.add_trucks_brand_name_validation_required')
    // .alphaNumeric('page.add_trucks_general_details_brand_name_validation_alphaNumeric')
    .max(50, 'page.add_transporter_general_details_transporter_name_validation_max'),

  model_name: Yup.string()
    // .required('page.add_trucks_model_name_validation_required')
    .max(50, 'page.add_trucks_model_name_validation_max'),
  weight: Yup.string(),
  //.matches(/^\d{0,6}(\.\d{0,2})?$/i, 'page.add_truck_valid_weight')
  // .required('page.add_trucks_weight_validation_required'),
  max_weight_kg: Yup.string().max_weight_thousand(
    'page.add_trucks_max_load_kg_validation_grater_than_thousand'
  ),
  // .required('page.add_trucks_max_load_kg_validation_required'),
  max_weight_ltr: Yup.string().matches(
    /^\d{0,6}(\.\d{0,2})?$/i,
    'page.add_truck_valid_weight_ltr_weight'
  ),
  // .required('page.add_trucks_max_load_l_validation_required'),

  // road_date: Yup.string().required('page.add_trucks_date_put_on_road_required'),
  // plate_number: Yup.string()
  //   // .required('page.add_trucks_plate_number_validation_required')
  //   .max(50, 'page.add_trucks_plate_number_validation_max')
  //   .plate_number('page.add_trucks_plate_number_format'),
  is_active: Yup.string(),
  // .required('page.add_trucks_is_active_validation_required'),
  type: Yup.string('page.add_trucks_truck_type_validation_radio_required'),
  // .required('page.add_trucks_truck_type_validation_radio_required'),
  conformity_certificate: Yup.mixed().when('is_active', (val, schema) => {
    if (val === '1') {
      return (
        Yup.mixed()
          // .required('page.add_trucks_conformity_attachment_file_required')
          .file_type('page.add_trucks_conformity_attachment_file_file_type')
      );
    }

    return Yup.string().notRequired();
  }),
  truck_photos: Yup.array()
    // .required('page.add_trucks_photo_of_truck_attachment_file_required')
    // .min(1, 'page.add_trucks_photo_of_truck_attachment_file_file_type')
    .multiplefilecheck('page.add_trucks_photo_of_truck_attachment_file_file_type'),
  // truck_photos: Yup.mixed()
  //   .required( ('page.add_trucks_photo_of_truck_attachment_file_required'))
  //   .file_type( ('page.add_trucks_photo_of_truck_attachment_file_file_type'))

  stnk: Yup.string(),
  // .required('page.add_trucks_stnk_required'),
  stnk_expired_date: Yup.string(),
  // .required('page.add_trucks_stnk_expired_date_required'),
  registration_card: Yup.mixed()
    // .required('page.add_trucks_registration_card_attachement_file_required')
    .file_typepdf('page.add_trucks_registration_card_attachment_file_file_type'),
  kir: Yup.string(),
  // .required('page.add_trucks_kir_required'),
  kir_expired_date: Yup.string(),
  // .required('page.add_trucks_kir_expired_date_required'),
  kir_card: Yup.mixed()
    // .required('page.add_trucks_kir_card_attachement_file_required')
    .file_typepdf('page.add_trucks_kir_card_attachment_file_file_type'),

  gps_tracker: Yup.string()
  // .required('page.add_trucks_gps_tracker_validation_required')
});
