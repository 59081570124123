import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useFormikContext } from 'formik';

const PersonInCharge = (props) => {
  const formik = useFormikContext();
  const defaultValue = (option, value) => {
    return option ? option.find((option) => option.value === value) : '';
  };
  const {
    t,
    countryIsLoad,
    countryCodeOptions,
    copyData,
    setCopyData,
    sectionCopy,
    setSectionCopy
  } = props;
  const [isCopy, setIsCopy] = useState(false);
  const [duplicateCopy, setDuplicateCopy] = useState(false);

  const positionOptions = [
    {
      value: 'Rektor',
      label: 'Rektor'
    },
    {
      value: 'Direktur',
      label: 'Direktur'
    },
    {
      value: 'Manajer',
      label: 'Manajer'
    },
    {
      value: 'Manajer Operasional',
      label: 'Manajer Operasional'
    }
  ];

  const doCopyData = () => {
    let _copyData = {
      ...copyData,
      first_name: formik.values.person_incharge_first_name,
      last_name: formik.values.person_incharge_last_name,
      email: formik.values.person_incharge_email,
      phone: formik.values.person_incharge_phone,
      country_id: formik.values.person_incharge_country_id
    };

    setCopyData(_copyData);
  };

  useEffect(() => {
    if (isCopy) {
      doCopyData();
    }
  }, [isCopy]);

  useEffect(() => {
    if (duplicateCopy) {
      formik.setFieldValue('person_incharge_first_name', copyData.first_name);
      formik.setFieldValue('person_incharge_last_name', copyData.last_name);
      formik.setFieldValue('person_incharge_email', copyData.email);
      formik.setFieldValue('person_incharge_phone', copyData.phone);
      formik.setFieldValue('person_incharge_country_id', copyData.country_id);
    }
  }, [duplicateCopy]);

  return (
    <Card>
      <Card.Body>
        {(sectionCopy == 'personInCharge' || !sectionCopy) && (
          <Form.Check
            style={{ marginRight: '10px' }}
            label={t('page.section_copy_data')}
            checked={sectionCopy == 'personInCharge' ? true : false}
            onChange={() => {
              setIsCopy(!isCopy);
              setSectionCopy(sectionCopy == 'personInCharge' ? null : 'personInCharge');
            }}
          />
        )}
        {sectionCopy && sectionCopy != 'personInCharge' ? (
          <Form.Check
            label={t('page.section_duplicate_data')}
            checked={duplicateCopy}
            onChange={() => {
              setDuplicateCopy(!duplicateCopy);
            }}
          />
        ) : null}
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_person_incharge_first_name_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.person_incharge_first_name &&
                  formik.errors.person_incharge_first_name
                    ? 'form-field-error'
                    : formik.touched.person_incharge_first_name &&
                      !formik.errors.person_incharge_first_name
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="person_incharge_first_name"
                placeholder={t('page.add_producer_person_incharge_first_name_placeholder')}
                onChange={(event) => {
                  formik.handleChange(event);
                  setIsCopy(false);
                  sectionCopy == 'personInCharge' && setSectionCopy(null);
                  setDuplicateCopy(false);
                }}
                onBlur={formik.handleBlur}
                value={
                  duplicateCopy ? copyData.first_name : formik.values.person_incharge_first_name
                }
                disabled={isCopy}
              />
              <div className="form-field-error-text">
                {formik.touched.person_incharge_first_name &&
                formik.errors.person_incharge_first_name ? (
                  <div>{t(formik.errors.person_incharge_first_name)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_person_incharge_last_name_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.person_incharge_last_name &&
                  formik.errors.person_incharge_last_name
                    ? 'form-field-error'
                    : formik.touched.person_incharge_last_name &&
                      !formik.errors.person_incharge_last_name
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="person_incharge_last_name"
                placeholder={t('page.add_producer_person_incharge_last_name_placeholder')}
                onChange={(event) => {
                  formik.handleChange(event);
                  setIsCopy(false);
                  sectionCopy == 'personInCharge' && setSectionCopy(null);
                  setDuplicateCopy(false);
                }}
                onBlur={formik.handleBlur}
                value={duplicateCopy ? copyData.last_name : formik.values.person_incharge_last_name}
                disabled={isCopy}
              />
              <div className="form-field-error-text">
                {formik.touched.person_incharge_last_name &&
                formik.errors.person_incharge_last_name ? (
                  <div>{t(formik.errors.person_incharge_last_name)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_person_incharge_email_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.person_incharge_email && formik.errors.person_incharge_email
                    ? 'form-field-error'
                    : formik.touched.person_incharge_email && !formik.errors.person_incharge_email
                    ? 'form-field-success'
                    : '')
                }
                type="email"
                name="person_incharge_email"
                placeholder={t('page.add_producer_person_incharge_email_placeholder')}
                onChange={(event) => {
                  formik.handleChange(event);
                  setIsCopy(false);
                  sectionCopy == 'personInCharge' && setSectionCopy(null);
                  setDuplicateCopy(false);
                }}
                onBlur={formik.handleBlur}
                value={duplicateCopy ? copyData.email : formik.values.person_incharge_email}
                disabled={isCopy}
              />
              <div className="form-field-error-text">
                {formik.touched.person_incharge_email && formik.errors.person_incharge_email ? (
                  <div>{t(formik.errors.person_incharge_email)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Label className="field-label field-label-top">
              {t('page.add_producer_person_incharge_phone_label')}
            </Form.Label>
            <Row>
              <Col lg={6} xs={6}>
                <Select
                  name="person_incharge_country_id"
                  placeholder={t(
                    'page.add_producer_person_incharge_person_incharge_country_id_placeholder'
                  )}
                  value={defaultValue(countryCodeOptions, formik.values.person_incharge_country_id)}
                  defaultValue={formik.values.person_incharge_country_id}
                  onChange={(selectedOption) => {
                    if (selectedOption !== null) {
                      formik.setFieldValue('person_incharge_country_id', selectedOption.value);
                    } else {
                      formik.setFieldValue('person_incharge_country_id', '');
                    }
                  }}
                  className={
                    'contry-code ' +
                    (formik.touched.person_incharge_country_id &&
                    formik.errors.person_incharge_country_id
                      ? 'form-select-error'
                      : formik.touched.person_incharge_country_id &&
                        !formik.errors.person_incharge_country_id
                      ? 'form-select-success'
                      : '')
                  }
                  options={countryIsLoad ? countryCodeOptions : countryCodeOptions}
                  isDisabled={isCopy}
                />
                <div className="form-field-error-text">
                  {formik.touched.person_incharge_country_id &&
                  formik.errors.person_incharge_country_id ? (
                    <div>{t(formik.errors.person_incharge_country_id)}</div>
                  ) : null}
                </div>
              </Col>
              <Col lg={6} xs={6}>
                <Form.Control
                  className={
                    'contact-number ' +
                    (formik.touched.person_incharge_phone && formik.errors.person_incharge_phone
                      ? 'form-field-error'
                      : formik.touched.person_incharge_phone && !formik.errors.person_incharge_phone
                      ? 'form-field-success'
                      : '')
                  }
                  type="text"
                  name="person_incharge_phone"
                  placeholder={t('page.add_producer_person_incharge_phone_placeholder')}
                  onChange={(event) => {
                    formik.handleChange(event);
                    setIsCopy(false);
                    sectionCopy == 'personInCharge' && setSectionCopy(null);
                    setDuplicateCopy(false);
                  }}
                  onBlur={formik.handleBlur}
                  value={duplicateCopy ? copyData.phone : formik.values.person_incharge_phone}
                  disabled={isCopy}
                />
                <div className="form-field-error-text">
                  {formik.touched.person_incharge_phone && formik.errors.person_incharge_phone ? (
                    <div>{t(formik.errors.person_incharge_phone)}</div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">Position</Form.Label>
              <Select
                name="person_incharge_position"
                placeholder="Posisi (Kosongkan jika penanggung jawab)"
                value={defaultValue(positionOptions, formik.values.person_incharge_position)}
                defaultValue={formik.values.person_incharge_position}
                onChange={(selectedOption) => {
                  if (selectedOption !== null) {
                    formik.setFieldValue('person_incharge_position', selectedOption.value);
                  } else {
                    formik.setFieldValue('person_incharge_position', '');
                  }
                }}
                className={
                  ' ' +
                  (formik.touched.person_incharge_position && formik.errors.person_incharge_position
                    ? 'form-select-error'
                    : formik.touched.person_incharge_position &&
                      !formik.errors.person_incharge_position
                    ? 'form-select-success'
                    : '')
                }
                options={positionOptions}
              />
              <div className="form-field-error-text">
                {formik.touched.person_incharge_position &&
                formik.errors.person_incharge_position ? (
                  <div>{t(formik.errors.person_incharge_position)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
PersonInCharge.propTypes = {
  t: PropTypes.func,
  countryIsLoad: PropTypes.bool,
  countryCodeOptions: PropTypes.any
};
export { PersonInCharge };
