import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useFormikContext, FieldArray, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { imagePreviewImageOnly, imagePreviewFromik } from '@helpers/commonHelpers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useDeleteFactoryAttachment } from '@hooks';
import { toast } from 'react-toastify';

const EditAttachmentFiles = (props) => {
  const formik = useFormikContext();

  //const [DeleteAttchmentId, setDeleteAttchmentId] = useState(false);

  let ownerKTPRef = useRef();
  const selfieWithKTPRef = useRef();
  const stampRef = useRef();
  const attachmentNameRef = useRef([]);

  const t = props.t;
  const refetch = props.refetch;

  // target input
  const handleOwnerKTP = () => {
    ownerKTPRef.current?.click();
  };

  const handleSelfieWithKTP = () => {
    selfieWithKTPRef.current?.click();
  };
  const handleStampRef = () => {
    stampRef.current?.click();
  };
  const handleAttachmentName = (index) => {
    attachmentNameRef.current[index]?.click();
  };

  const { mutate: doDeleteAttachment } = useDeleteFactoryAttachment(
    (response) => {
      toast.success(response.message);
      refetch();
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );

  /**
   * !This Block is for Delete Attachment
   */
  const handleDeleteClick = (tdata) => {
    let attchament_id = tdata.currentTarget.getAttribute('attchament_id');
    let field_name = tdata.currentTarget.getAttribute('field_name');
    if (attchament_id !== '') {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2>{t('page.delete_attachment_popup_message')}</h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    formik.setFieldValue(field_name, '');
                    formik.setFieldValue(`${field_name}_id`, '');
                    doDeleteAttachment({
                      attachment_id: attchament_id,
                      type: 'attachment'
                    });
                    // setDeleteAttchmentId(attchament_id);

                    onClose();
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      formik.setFieldValue(field_name, '');
      formik.setFieldValue(`${field_name}_id`, '');
    }
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label">
                {t('page.add_transporter_owner_ktp_attchment_label')}
              </Form.Label>
              <Form.Control
                type="file"
                hidden
                accept="image/*,.pdf"
                ref={ownerKTPRef}
                name="owner_ktp_attchment"
                placeholder={t('page.add_transporter_owner_ktp_attchment_placeholder')}
                onChange={(event) => {
                  formik.setFieldValue('owner_ktp_attchment', event.currentTarget.files[0]);
                }}
              />
              <Button
                type="button"
                onClick={handleOwnerKTP}
                className={
                  'upload-button' ||
                  (formik.touched.owner_ktp_attchment && formik.errors.owner_ktp_attchment
                    ? 'form-field-error'
                    : formik.touched.owner_ktp_attchment && !formik.errors.owner_ktp_attchment
                    ? 'form-field-success'
                    : '')
                }>
                {t('page.file_upload')}
              </Button>
            </Form.Group>
            <div className="form-field-error-text">
              {formik.touched.owner_ktp_attchment && formik.errors.owner_ktp_attchment ? (
                <div>{t(formik.errors.owner_ktp_attchment)}</div>
              ) : null}
            </div>
            {formik.values.owner_ktp_attchment && (
              <div className="preview-image">
                <img
                  src={imagePreviewFromik(formik.values.owner_ktp_attchment)}
                  alt="profile_img"
                />
                <FontAwesomeIcon
                  icon={faClose}
                  attchament_id={formik.values.owner_ktp_attchment_id}
                  field_name={'additional_attachment'}
                  onClick={(event) => {
                    handleDeleteClick(event);
                    ownerKTPRef.current.value = null;
                  }}
                />
              </div>
            )}
          </Col>
          <Col sm={12} md={4} className="mt-2">
            <Form.Group>
              <Form.Label className="field-label">
                {t('page.add_transporter_selfi_with_ktp_attchement_label')}
              </Form.Label>
              <Form.Control
                className={'d-none'}
                type="file"
                accept="image/*"
                ref={selfieWithKTPRef}
                name="selfi_with_ktp_attchement"
                placeholder={t('page.add_transporter_attachments_selfie_with_ktp_placeholder')}
                onChange={(event) => {
                  formik.setFieldValue('selfi_with_ktp_attchement', event.currentTarget.files[0]);
                }}
                hidden
              />
              <Button
                type="button"
                onClick={handleSelfieWithKTP}
                className={
                  'upload-button' ||
                  (formik.touched.selfi_with_ktp_attchement &&
                  formik.errors.selfi_with_ktp_attchement
                    ? 'form-field-error'
                    : formik.touched.selfi_with_ktp_attchement &&
                      !formik.errors.selfi_with_ktp_attchement
                    ? 'form-field-success'
                    : '')
                }>
                {t('page.file_upload')}
              </Button>
            </Form.Group>
            <div className="form-field-error-text">
              {formik.touched.selfi_with_ktp_attchement &&
              formik.errors.selfi_with_ktp_attchement ? (
                <div>{t(formik.errors.selfi_with_ktp_attchement)}</div>
              ) : null}
            </div>
            {formik.values.selfi_with_ktp_attchement && (
              <div className="preview-image">
                <img
                  src={imagePreviewImageOnly(formik.values.selfi_with_ktp_attchement)}
                  alt="profile_img"
                />
                <FontAwesomeIcon
                  icon={faClose}
                  attchament_id={formik.values.selfi_with_ktp_attchement_id}
                  field_name={'additional_attachment'}
                  onClick={(event) => {
                    handleDeleteClick(event);
                    selfieWithKTPRef.current.value = null;
                  }}
                />
              </div>
            )}
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label">
                {t('page.add_transporter_stamp_label')}
              </Form.Label>
              <Form.Control
                className={'d-none'}
                type="file"
                accept="image/*"
                ref={stampRef}
                name="stamp"
                placeholder={t('page.add_transporter_stamp_placeholder')}
                onChange={(event) => {
                  formik.setFieldValue('stamp', event.currentTarget.files[0]);
                }}
                hidden
              />
              <Button
                type="button"
                onClick={handleStampRef}
                className={
                  'upload-button' ||
                  (formik.touched.stamp && formik.errors.stamp
                    ? 'form-field-error'
                    : formik.touched.stamp && !formik.errors.stamp
                    ? 'form-field-success'
                    : '')
                }>
                {t('page.file_upload')}
              </Button>
            </Form.Group>
            <div className="form-field-error-text">
              {formik.touched.stamp && formik.errors.stamp ? (
                <div>{t(formik.errors.stamp)}</div>
              ) : null}
            </div>
            {formik.values.stamp && (
              <div className="preview-image">
                <img src={imagePreviewImageOnly(formik.values.stamp)} alt="profile_img" />
                <FontAwesomeIcon
                  icon={faClose}
                  attchament_id={formik.values.stamp_id}
                  field_name={'additional_attachment'}
                  onClick={(event) => {
                    handleDeleteClick(event);
                    stampRef.current.value = null;
                  }}
                />
              </div>
            )}
          </Col>
        </Row>
        <FieldArray
          name="additional_attachement"
          render={(arrayHelpers) => (
            <div>
              {formik.values.additional_attachement &&
              formik.values.additional_attachement.length > 0
                ? formik.values.additional_attachement.map(
                    (additional_attachement_single, index) => {
                      const additional_attachement_nameErrors =
                        (formik.errors.additional_attachement?.length &&
                          formik.errors.additional_attachement[index]
                            ?.additional_attachement_name) ||
                        '';
                      const additional_attachement_nameTouched =
                        (formik.touched.additional_attachement?.length &&
                          formik.touched.additional_attachement[index]
                            ?.additional_attachement_name) ||
                        '';
                      const additional_attachement_fileErrors =
                        (formik.errors.additional_attachement?.length &&
                          formik.errors.additional_attachement[index]
                            ?.additional_attachement_file) ||
                        '';
                      const additional_attachement_fileTouched =
                        (formik.touched.additional_attachement?.length &&
                          formik.touched.additional_attachement[index]
                            ?.additional_attachement_file) ||
                        '';
                      return (
                        <div key={'additional-' + index}>
                          <Row>
                            <Col lg={12}>
                              <Form.Group className="row-top">
                                <Form.Label className="field-label">
                                  {t('page.add_transporter_attachments_attachment_name_label')}
                                </Form.Label>
                                <div>
                                  <Row>
                                    <Col lg={4} xs={12}>
                                      <Field
                                        type="hidden"
                                        name={`additional_attachement.${index}.additional_attachement_id`}
                                        value={
                                          additional_attachement_single.additional_attachment_id
                                        }
                                      />

                                      <Field
                                        type="text"
                                        name={`additional_attachement.${index}.additional_attachement_name`}
                                        placeholder={t(
                                          'page.add_transporter_attachments_attachment_name_placeholder'
                                        )}
                                        onBlur={(event) => {
                                          formik.setFieldValue(
                                            `additional_attachement.${index}.additional_attachement_name`,
                                            event.currentTarget.value
                                          );
                                        }}
                                        className={
                                          'form-control form-field' ||
                                          (additional_attachement_nameTouched &&
                                          additional_attachement_nameErrors
                                            ? 'form-field-error'
                                            : additional_attachement_nameTouched &&
                                              !additional_attachement_nameErrors
                                            ? 'form-field-success'
                                            : '')
                                        }
                                      />
                                      <div className="form-field-error-text">
                                        {additional_attachement_nameTouched &&
                                        additional_attachement_nameErrors ? (
                                          <div>{t(additional_attachement_nameErrors)}</div>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col lg={8} xs={12}>
                                      <Button
                                        type="button"
                                        onClick={() => handleAttachmentName(index)}
                                        className={
                                          (additional_attachement_single.additional_attachment_id !==
                                          ''
                                            ? ' d-none'
                                            : 'secondary-add-button') ||
                                          (additional_attachement_fileTouched &&
                                          additional_attachement_fileErrors
                                            ? 'form-field-error'
                                            : additional_attachement_fileTouched &&
                                              !additional_attachement_fileErrors
                                            ? 'form-field-success'
                                            : '')
                                        }>
                                        {t('page.file_upload')}
                                      </Button>
                                      <Button
                                        type="button"
                                        className="secondary-remove-button"
                                        attchament_id={
                                          additional_attachement_single.additional_attachment_id
                                        }
                                        field_name={'additional_attachment'}
                                        onClick={(event) => {
                                          handleDeleteClick(event);
                                          arrayHelpers.remove(index);
                                        }}>
                                        {t('page.additional_attachment_remove_button_text')}
                                      </Button>
                                      <div className="form-field-error-text">
                                        {additional_attachement_fileTouched &&
                                        additional_attachement_fileErrors ? (
                                          <div>{t(additional_attachement_fileErrors)}</div>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col lg={12}>
                              <Form.Group className="row-top">
                                <Form.Control
                                  className="field-label field-label-top"
                                  type="file"
                                  // ref={attachmentNameRef[index]}
                                  ref={(el) => (attachmentNameRef.current[index] = el)}
                                  name={`additional_attachement.${index}.additional_attachement_file`}
                                  // id={`additional_attachement.${index}.additional_attachement_file`}
                                  placeholder={t(
                                    'page.add_transporter_attachments_attachment_name_placeholder'
                                  )}
                                  hidden
                                  accept="image/*,.pdf"
                                  onChange={(event) => {
                                    formik.setFieldValue(
                                      event.currentTarget.name,
                                      event.currentTarget.files[0]
                                    );
                                  }}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="preview-image">
                                {formik.values.additional_attachement[index]
                                  .additional_attachement_file && (
                                  <div>
                                    <img
                                      src={imagePreviewFromik(
                                        formik.values.additional_attachement[index]
                                          .additional_attachement_file
                                      )}
                                      alt="profile_img"
                                    />
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  )
                : ''}
              <Row>
                <Col sm={12} md={6}>
                  <Form.Group className="row-top">
                    <Form.Label className="field-label">
                      {t('page.add_transporter_attachments_additional_attachment_label')}
                    </Form.Label>
                    <Button
                      onClick={() => {
                        arrayHelpers.push({
                          additional_attachement_file: '',
                          additional_attachement_name: '',
                          additional_attachment_id: ''
                        });
                        attachmentNameRef.current.push(formik.values.additional_attachement.length);
                      }}
                      className="secondary-add-button">
                      {t('page.additional_attachment_add_button_text')}
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          )}
        />
      </Card.Body>
    </Card>
  );
};
EditAttachmentFiles.propTypes = {
  t: PropTypes.func,
  refetch: PropTypes.func
};
export { EditAttachmentFiles };
