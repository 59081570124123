import { FactoryStepperService } from '@api/';
import { useMutation, useQuery } from 'react-query';
const { toast } = require('react-toastify');

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useFactoryGetGeneralDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'view-generaldetail-factory-stepper',
    () => FactoryStepperService.getGeneralDetails(),
    {
      onSuccess,
      onError
    }
  );
};

const useFactoryStoreGeneralDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(FactoryStepperService.storeGeneralDetails, {
    onSuccess,
    onError
  });
};

const useFactoryGetOwnerDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'view-ownerdetail-factory-stepper',
    () => FactoryStepperService.getOwnerDetails(),
    {
      onSuccess,
      onError
    }
  );
};

const useFactoryStoreOwnerDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(FactoryStepperService.storeOwnerDetails, {
    onSuccess,
    onError
  });
};
const useGetFactoryPersonInChargeDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'view-person-incharge-factory-stepper',
    () => FactoryStepperService.getPersonInChargeDetails(),
    {
      onSuccess,
      onError
    }
  );
};

const useStoreFactoryPersonInChargeDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(FactoryStepperService.storePersonInchargeDetails, {
    onSuccess,
    onError
  });
};

const useGetFactoryAccountingDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'view-accounting-detail-factory-stepper',
    () => FactoryStepperService.getAccountingDetails(),
    {
      onSuccess,
      onError
    }
  );
};

const useStoreFactoryAccountingDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(FactoryStepperService.storeAccountingDetails, {
    onSuccess,
    onError
  });
};
const useGetFactoryAttchmentsDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'view-attachment-factory-stepper',
    () => FactoryStepperService.getAttchmentsDetails(),
    {
      onSuccess,
      onError
    }
  );
};

const useStoreFactoryAttchmentsDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(FactoryStepperService.storeAttchmentsDetails, {
    onSuccess,
    onError
  });
};

const useSignupDeleteFactoryAttachment = (onSuccess, onError = onDefaultError) => {
  return useMutation(FactoryStepperService.deleteAttchmentsDetails, {
    onSuccess,
    onError
  });
};

export {
  useFactoryGetGeneralDetails,
  useFactoryStoreGeneralDetails,
  useFactoryGetOwnerDetails,
  useFactoryStoreOwnerDetails,
  useGetFactoryPersonInChargeDetails,
  useStoreFactoryPersonInChargeDetails,
  useGetFactoryAccountingDetails,
  useStoreFactoryAccountingDetails,
  useGetFactoryAttchmentsDetails,
  useStoreFactoryAttchmentsDetails,
  useSignupDeleteFactoryAttachment
};
