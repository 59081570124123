import client from '@libs/HttpClient';

class LoadTransportTruckService {
  static viewLoadTransportTruck(params) {
    return client.get(`tour/transport-truck-loading/list`, { params });
  }

  static viewLoadTransportTruckDetails(delivery_point_current_session_id) {
    return client.get(`tour/transport-truck-loading/details/${delivery_point_current_session_id}`);
  }

  static async viewLoadTransportTruckDownloadManifest(delivery_point_current_session_id) {
    return client.get(
      `tour/transport-truck-loading/download-manifest/${delivery_point_current_session_id}`,
      {
        responseType: 'blob'
      }
    );
  }

  static useTransportAddNotes(request) {
    return client.post('tour/transport-truck-loading/add-notes', request);
  }
  static generateReport(request) {
    return client.post(`/tour/transport-truck-loading/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}

export { LoadTransportTruckService };
