import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { TNButton } from '@common/components';
import { toast } from 'react-toastify';
// import { usePlacesWidget } from 'react-google-autocomplete';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Form, Card, Row, Col, Alert, InputGroup } from 'react-bootstrap';
import { useGetWestCodeData } from '@hooks';
import { useFormikContext, Field } from 'formik';

const PlaceAutoComplete = (props) => {
  const { t, formik, setAddress } = props;
  const [firstLoad, setFirstLoad] = useState(true);
  const {
    suggestions: { status, data },
    setValue
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    const results = await getGeocode({ address });
    setAddress(results[0]);
  };

  const getAddressList = async (address) => {
    await setValue(address);
  };

  useEffect(() => {
    setFirstLoad(false);
    if (!firstLoad) {
      if (status == 'OK') {
        handleSelect(data[0].description);
      } else {
        toast.error('No location found');
        formik.setFieldValue('address', '');
      }
    }
  }, [data]);

  return (
    <>
      <Form.Group>
        <Form.Label className="field-label field-label-top">
          {t('page.add_producer_collect_details_collection_address_label')}
        </Form.Label>
        <InputGroup className="inner-group-box">
          <Form.Control
            className={
              formik.touched.collection_address && formik.errors.collection_address
                ? 'form-field-error'
                : formik.touched.collection_address && !formik.errors.collection_address
                ? 'form-field-success'
                : ''
            }
            type="text"
            name="address"
            placeholder={t('page.add_producer_collect_details_collection_address_placeholder')}
            onKeyDown={(e) => {
              if (e.key !== 'Enter') return;
              e.preventDefault();
              formik.setFieldValue('address', 'Searching...');
              formik.setFieldValue('city', '');
              formik.setFieldValue('state', '');
              formik.setFieldValue('postcode', '');
              formik.setFieldValue('country', '');
              formik.setFieldValue('latitude', '');
              formik.setFieldValue('longitude', '');
              getAddressList(formik.values.collection_address);
            }}
            onChange={(event) => {
              if (event.currentTarget.value === '') {
                formik.setFieldValue('collection_address', '');
                formik.setFieldValue('collection_city', '');
                formik.setFieldValue('collection_state', '');
                formik.setFieldValue('collection_postcode', '');
                formik.setFieldValue('collection_country', '');
                formik.setFieldValue('collection_latitude', '');
                formik.setFieldValue('collection_longitude', '');
              } else {
                formik.setFieldValue('collection_address', event.currentTarget.value);
              }
            }}
            value={formik.values.collection_address}
            onBlur={formik.handleBlur}
          />
          <TNButton
            onClick={() => {
              formik.setFieldValue('collection_address', 'Searching...');
              formik.setFieldValue('collection_city', '');
              formik.setFieldValue('collection_state', '');
              formik.setFieldValue('collection_postcode', '');
              formik.setFieldValue('collection_country', '');
              formik.setFieldValue('collection_latitude', '');
              formik.setFieldValue('collection_longitude', '');

              getAddressList(formik.values.collection_address);
            }}
            type="button">
            {t('page.show_address_results')}
          </TNButton>
        </InputGroup>
        <div className="form-field-error-text">
          {formik.touched.collection_address && formik.errors.collection_address ? (
            <div>{t(formik.errors.collection_address)}</div>
          ) : null}
        </div>
      </Form.Group>
      <div className="map-areas">
        <Row>
          <Col lg={4} sm={12} className={formik.values.city ? '' : 'd-none'}>
            {formik.values.city ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                City:{formik.values.city}
              </Alert>
            ) : (
              ''
            )}
          </Col>
          <Col lg={4} sm={12} className={formik.values.state ? '' : 'd-none'}>
            {formik.values.state ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                State:{formik.values.state}
              </Alert>
            ) : (
              ''
            )}
          </Col>
          <Col lg={4} sm={12} className={formik.values.postcode ? '' : 'd-none'}>
            {formik.values.postcode ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                PostCode:{formik.values.postcode}
              </Alert>
            ) : (
              ''
            )}
          </Col>
          <Col lg={4} sm={12} className={formik.values.country ? '' : 'd-none'}>
            {formik.values.country ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                Country:{formik.values.country}
              </Alert>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

const CollectDetails = (props) => {
  const formik = useFormikContext();
  const defaultValue = (option, value) => {
    return option ? option.find((option) => option.value === value) : '';
  };
  const { t, stocks } = props;
  const [ProducerCenter, setProducerCenter] = useState({
    lat: Number(formik.values.collection_latitude) ?? -8.675835899999997,
    lng: Number(formik.values.collection_longitude) ?? 115.2127549
  });

  const [collectType, setCollectType] = useState(parseInt(formik.values.type_collection));

  const [ProducerZoom, setProducerZoom] = useState(18);
  const containerStyle = {
    width: '100%',
    height: '300px'
  };

  useEffect(() => {
    setProducerCenter({
      lat: Number(formik.values.collection_latitude),
      lng: Number(formik.values.collection_longitude)
    });
  }, [formik.values.collection_latitude, formik.values.collection_longitude]);

  /**
   * !This block is for google address
   */
  const setAddress = (address) => {
    const place = address;
    const { lat, lng } = getLatLng(place);

    let address1 = place.formatted_address;
    let postcode = '';
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        // case 'street_number': {
        //   address1 = `${component.long_name} ${address1}`;
        //   break;
        // }

        // case 'route': {
        //   address1 += component.short_name;
        //   break;
        // }

        case 'postal_code': {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case 'locality':
          formik.setFieldValue('collection_city', component.long_name);
          break;

        case 'administrative_area_level_1': {
          formik.setFieldValue('collection_state', component.long_name);
          break;
        }

        case 'administrative_area_level_2': {
          formik.setFieldValue('city', component.long_name);
          break;
        }

        case 'country':
          formik.setFieldValue('collection_country', component.long_name);
          break;

        default:
          break;
      }
    }
    formik.setFieldValue('collection_address', address1);
    formik.setFieldValue('collection_postcode', postcode);
    formik.setFieldValue('collection_latitude', lat);
    formik.setFieldValue('collection_longitude', lng);
    setProducerCenter({
      lat: lat,
      lng: lng
    });
    setProducerZoom(18);
  };
  /**
   * !This block is for collection drop down
   */
  const TypeCollectionOption = [
    { value: '', label: t('page.add_producer_collect_details_type_collection_option_blank') },
    { value: '1', label: t('page.add_producer_collect_details_type_collection_option_1') },
    { value: '2', label: t('page.add_producer_collect_details_type_collection_option_2') }
  ];
  /**
   * !This block is for frequency type drop down
   */
  const CollectFrequenceTypeOption = [
    {
      value: '',
      label: t('page.add_producer_collect_details_collect_frequence_type_option_blank')
    },
    {
      value: 'Day-1',
      label: t('page.add_producer_collect_details_collect_frequence_type_option_1')
    },
    {
      value: 'Day-2',
      label: t('page.add_producer_collect_details_collect_frequence_type_option_2')
    },
    {
      value: 'Week-1',
      label: t('page.add_producer_collect_details_collect_frequence_type_option_3')
    },
    {
      value: 'Week-2',
      label: t('page.add_producer_collect_details_collect_frequence_type_option_4')
    },
    {
      value: 'Month',
      label: t('page.add_producer_collect_details_collect_frequence_type_option_5')
    }
  ];

  /**
   * !This Api is for wastecode drop down
   */
  let WestCodeOptions = [];
  const { isSuccess: WestCodeisSuccess, data: WestCodeList } = useGetWestCodeData();
  if (WestCodeisSuccess !== false) {
    WestCodeOptions = WestCodeList.data.map((item, i) => {
      return { value: item.waste_code_id, label: `${item.number} - ${item.name}` };
    });
  }
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script'
  });
  // const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    // setMap(null);
  }, []);
  const onLoadmarker = (marker) => {};

  const getAddressFromLatLng = async (lat, lng) => {
    try {
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.MIX_GOOGLE_MAPS_API_KEY}`
      );
      const json = await res.json();

      for (const component of json.results[0].address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case 'postal_code': {
            formik.setFieldValue('postcode', component.long_name);
            break;
          }

          case 'administrative_area_level_1': {
            formik.setFieldValue('state', component.long_name);
            break;
          }

          case 'administrative_area_level_2': {
            formik.setFieldValue('city', component.long_name);
            break;
          }

          case 'country':
            formik.setFieldValue('country', component.long_name);
            break;

          default:
            break;
        }
      }

      return json.results[0].formatted_address;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_collect_details_type_collection_label')}
              </Form.Label>
              <Select
                isClearable={true}
                name="type_collection"
                options={TypeCollectionOption}
                placeholder={t('page.add_producer_collect_details_type_collection_placeholder')}
                onChange={(selected) => {
                  if (selected !== null) {
                    formik.setFieldValue('type_collection', selected.value);
                    setCollectType(parseInt(selected.value));
                  } else {
                    formik.setFieldValue('type_collection', '');
                    setCollectType(2);
                  }
                }}
                defaultValue={formik.values.type_collection}
                value={defaultValue(TypeCollectionOption, formik.values.type_collection)}
                onBlur={formik.handleBlur}
                className={
                  'form-field ' +
                  (formik.touched.type_collection && formik.errors.type_collection
                    ? 'form-select-error'
                    : formik.touched.type_collection && !formik.errors.type_collection
                    ? 'form-select-success'
                    : '')
                }
              />
              <div className="form-field-error-text">
                {formik.touched.type_collection && formik.errors.type_collection ? (
                  <div>{t(formik.errors.type_collection)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4} className={collectType === 2 ? 'd-none' : ''}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_collect_details_collect_frequence_type_label')}
              </Form.Label>
              <Select
                isClearable={true}
                name="collect_frequence_type"
                options={CollectFrequenceTypeOption}
                placeholder={t(
                  'page.add_producer_collect_details_collect_frequence_type_placeholder'
                )}
                onChange={(selected) => {
                  if (selected !== null) {
                    formik.setFieldValue('collect_frequence_type', selected.value);
                  } else {
                    formik.setFieldValue('collect_frequence_type', '');
                  }
                }}
                defaultValue={formik.values.collect_frequence_type}
                value={defaultValue(
                  CollectFrequenceTypeOption,
                  formik.values.collect_frequence_type
                )}
                onBlur={formik.handleBlur}
                className={
                  'form-field ' +
                  (formik.touched.collect_frequence_type && formik.errors.collect_frequence_type
                    ? 'form-select-error'
                    : formik.touched.collect_frequence_type && !formik.errors.collect_frequence_type
                    ? 'form-select-success'
                    : '')
                }
              />
              <div className="form-field-error-text">
                {formik.touched.collect_frequence_type && formik.errors.collect_frequence_type ? (
                  <div>{t(formik.errors.collect_frequence_type)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={8}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_collect_details_waste_code_id_label')}
              </Form.Label>
              <Field
                isMulti
                component={Select}
                isClearable={true}
                name="waste_codes"
                options={WestCodeOptions}
                placeholder={t('page.add_producer_collect_details_waste_code_id_placeholder')}
                onChange={(selected) => {
                  if (selected !== null) {
                    formik.setFieldValue('waste_codes', selected);
                  } else {
                    formik.setFieldValue('waste_codes', []);
                  }
                }}
                value={formik?.values?.waste_codes}
                onBlur={formik.handleBlur}
                className={
                  'form-field ' +
                  (formik.touched.waste_codes && formik.errors.waste_codes
                    ? 'form-select-error'
                    : formik.touched.waste_codes && !formik.errors.waste_codes
                    ? 'form-select-success'
                    : '')
                }
              />
              <div className="form-field-error-text">
                {formik.touched.waste_codes && formik.errors.waste_codes ? (
                  <div>{t(formik.errors.waste_codes)}</div>
                ) : null}
              </div>
              <div className="form-field">
                {formik.values.waste_codes?.map((c, i) => (
                  <Link
                    key={i}
                    target="_blank"
                    className="float-end me-3"
                    to={`/wastecode/view/${c.value}`}>
                    info {c?.label?.split(' - ')?.[0]}
                  </Link>
                ))}
              </div>
            </Form.Group>
          </Col>
        </Row>
        {/* <FieldArray
          name="container_data"
          render={(arrayHelpers) => (
            <div className={collectType === 1 ? '' : 'd-none'}>
              <Row>
                <Col sm={12} md={8}>
                  {t('page.producer_select_container_label')}
                </Col>
                <Col sm={12} md={4}>
                  {t('page.producer_select_quantity_label')}
                </Col>
              </Row>
              {formik.values.container_data && formik.values.container_data.length > 0
                ? formik.values.container_data.map((single, index) => {
                    const container_quantityErrors =
                      (formik.errors.container_data?.length &&
                        formik.errors.container_data[index]?.container_quantity) ||
                      '';
                    const container_quantityTouched =
                      (formik.touched.container_data?.length &&
                        formik.touched.container_data[index]?.container_quantity) ||
                      '';

                    return (
                      <Row key={index} className={'mt-2'}>
                        <Col sm={12} md={8}>
                          <Form.Check
                            inline
                            type={'checkbox'}
                            label={single.label + ' (' + single.size + ')'}
                            name={`container_data.${index}.container_quantity_checkbox`}
                            id={`container_data.${index}.container_quantity_checkbox`}
                            value={single.container_variable_id}
                            checked={
                              formik.values.container_data[index] &&
                              formik.values.container_data[index].container_quantity_checkbox
                            }
                            onChange={(event) => {
                              formik.setFieldValue(
                                `container_data.${index}.container_quantity_checkbox`,
                                event.currentTarget.checked
                              );

                              if (!event.currentTarget.checked) {
                                formik.setFieldValue(
                                  `container_data.${index}.container_quantity`,
                                  ''
                                );
                              }
                            }}
                          />
                        </Col>
                        <Col sm={12} md={4}>
                          <Form.Control
                            className={
                              'form-field ' +
                              (container_quantityTouched && container_quantityErrors
                                ? 'form-field-error'
                                : container_quantityTouched && !container_quantityErrors
                                ? 'form-field-success'
                                : '')
                            }
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            disabled={
                              !formik.values.container_data[index].container_quantity_checkbox
                            }
                            type="text"
                            name={`container_data.${index}.container_quantity`}
                            placeholder={t(
                              'page.add_producer_contact_details_container_quantity_placeholder'
                            )}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.container_data[index].container_quantity}
                          />
                          <div className="form-field-error-text">
                            {container_quantityTouched && container_quantityErrors ? (
                              <div>{t(container_quantityErrors)}</div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    );
                  })
                : ''}
            </div>
          )}
        /> */}
        <Row>
          <Col>
            <table>
              <tbody>
                <tr>
                  <th style={{ padding: '2px 5px', border: '1px solid #ccc' }}></th>
                  <th style={{ padding: '2px 5px', border: '1px solid #ccc' }}>
                    {t('page.dashboard_th_container_name_size')}
                  </th>
                  <th style={{ padding: '2px 5px', border: '1px solid #ccc' }}>
                    {t('page.order_container_qty_label')}
                  </th>
                  <th style={{ padding: '2px 5px', border: '1px solid #ccc' }}>
                    {t('page.sidebar_stocks_label')}
                  </th>
                </tr>
                {formik.values.container_data.map((single, index) => (
                  <tr key={index}>
                    <td style={{ padding: '2px 5px', border: '1px solid #ccc' }}>
                      <Form.Check
                        inline
                        type="checkbox"
                        disabled
                        checked={
                          formik.values.container_data[index] &&
                          formik.values.container_data[index].container_quantity_checkbox
                        }
                      />
                    </td>
                    <td style={{ padding: '2px 5px', border: '1px solid #ccc' }}>{single.label}</td>
                    <td
                      style={{ padding: '2px 5px', border: '1px solid #ccc', textAlign: 'center' }}>
                      {formik.values.container_data[index].container_quantity}
                    </td>
                    <td
                      style={{ padding: '2px 5px', border: '1px solid #ccc', textAlign: 'center' }}>
                      {stocks[single.container_variable_id]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            {isLoaded && (
              <>
                <PlaceAutoComplete t={t} formik={formik} setAddress={setAddress} />
                <div className="mt-2">
                  {ProducerCenter ? (
                    <>
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={ProducerCenter}
                        zoom={ProducerZoom}
                        onLoad={onLoad}
                        onPlaceSelected={(event) => {}}
                        onUnmount={onUnmount}>
                        <Marker
                          onLoad={onLoadmarker}
                          position={ProducerCenter}
                          draggable={true}
                          onPositionChanged={(e) => {}}
                          onDragEnd={async (e) => {
                            formik.setFieldValue('collection_latitude', e.latLng.lat());
                            formik.setFieldValue('collection_longitude', e.latLng.lng());
                            setProducerCenter({
                              lat: e.latLng.lat(),
                              lng: e.latLng.lng()
                            });

                            const results = await getAddressFromLatLng(
                              e.latLng.lat(),
                              e.latLng.lng()
                            );
                            formik.setFieldValue('collection_address', results);
                          }}
                        />
                      </GoogleMap>
                    </>
                  ) : null}
                </div>
              </>
            )}
          </Col>
        </Row>
        <Form.Group>
          <Form.Label className="field-label field-label-top">{t('page.notes_label')}</Form.Label>
          <Form.Control
            className={
              'address_notes ' +
              (formik.touched.collection_address_notes && formik.errors.collection_address_notes
                ? 'form-field-error'
                : formik.touched.collection_address_notes && !formik.errors.collection_address_notes
                ? 'form-field-success'
                : '')
            }
            as="textarea"
            rows={4}
            type="text"
            name="collection_address_notes"
            placeholder={t('page.notes_placeholder')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.collection_address_notes}
          />
          <div className="form-field-error-text">
            {formik.touched.collection_address_notes && formik.errors.collection_address_notes ? (
              <div>{t(formik.errors.collection_address_notes)}</div>
            ) : null}
          </div>
        </Form.Group>
        <Row>
          <Col sm={12} md={4}></Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
CollectDetails.propTypes = {
  t: PropTypes.func
};
export { CollectDetails };
