import { useMutation, useQuery } from 'react-query';

import { toast } from 'react-toastify';
import { UnloadTransportTruckService } from '@api/';
import { DeliverySessionService } from '../api/DeliverySessionService';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useDeliverySessionList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['delivery-session/list', [params]],
    () => DeliverySessionService.viewDeliverySessionList(params),
    {
      onSuccess,
      onError
    }
  );
};
const useIncinerationList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['delivery-session/incineration/list', [params]],
    () => DeliverySessionService.viewIncinerationList(params),
    {
      onSuccess,
      onError
    }
  );
};

const useUnloadTransportAddNotes = (onSuccess, onError = onDefaultError) => {
  return useMutation(UnloadTransportTruckService.useUnloadTransportAddNotes, {
    onSuccess,
    onError
  });
};

const useGenerateUnloadTransportTruckReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(UnloadTransportTruckService.generateReport, {
    onSuccess,
    onError
  });
};
// export { useLoadTransportTruck, useViewLoadTransportTruckDetails, useTransportAddNotes };
export {
  useDeliverySessionList,
  useIncinerationList,
  useUnloadTransportAddNotes,
  useGenerateUnloadTransportTruckReport
};
