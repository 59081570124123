import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Accordion, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  useGetCountryCodeList,
  useNavigatingAway,
  useUpdateTransporter,
  useViewTransporter
} from '@hooks';
import { Form, Formik } from 'formik';
import AlertCommon from '@common/components/AlertCommon';
import { validationSchema } from './AddTransporterValidation';
import {
  AccountingPersonDetails,
  ContactrDetails,
  EditAttachmentFiles,
  GeneralDetails,
  OwnerDetails,
  PersonInCharge
} from './components';
import { TNButton } from '@common/components/';
import TNBreadCurm from '@common/components/TNBreadCurm';
import moment from 'moment';
import { DialogLeavingPage } from '@common/components/AlertClosePage';
import { SurveillanceDetails } from './components/SurveillanceDetails';

const EditTransporterPage = ({ t }) => {
  const navigate = useNavigate();
  let { transporter_id } = useParams();
  const [formState, setFormState] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(formState);
  const [accrdKey, setAccrdKey] = useState(0);
  const [copyData, setCopyData] = useState({
    country_id: '',
    phone: '',
    email: '',
    ktp: '',
    first_name: '',
    last_name: ''
  });
  const [sectionCopy, setSectionCopy] = useState(null);
  // From this api we are getting country code list.

  const { isLoading: countryIsLoad, data: countryCodeList } = useGetCountryCodeList();
  let countryCodeOptions = [];
  if (countryCodeList !== undefined) {
    countryCodeOptions = countryCodeList.data.map((item, i) => {
      return { value: item.country_id, label: item.phone_code };
    });
  }

  /**
   * This function is use when click on cancel button
   */

  const handleCancel = (dirty) => {
    navigate(`/transporter/list/`);
  };

  /**
   * !This API will call when page set. When response came we are setting up data into the form
   */

  const { data: transporter_data, refetch } = useViewTransporter(transporter_id);

  /**
   * !This Api will call on click submit button
   */
  const {
    mutate: doStoreTransporter,
    isLoading,
    error: addTRList,
    isError: addTRError
  } = useUpdateTransporter(
    (res) => {
      toast.success(res.message);
      navigate('/transporter/list/');
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );

  if (!transporter_data) {
    return null;
  }
  /**
   * !This block is for form initial values
   */
  const initialValues = {
    transporter_id: transporter_data.data.transporter_id,
    name: transporter_data.data.name,
    simpel_id: transporter_data.data.simpel_id,
    simpel_registration_number: transporter_data.data.simpel_registration_number,
    transport_npwp_number: transporter_data.data.transport_npwp_number,
    country_id:
      transporter_data.data.country_id !== '' && transporter_data.data.country_id !== null
        ? transporter_data.data.country_id
        : parseInt(process.env.MIX_DEFAULT_PHONE_CODE),

    company_phone: transporter_data.data.company_phone,
    territory_id: transporter_data.data.territory_id,
    area_id: transporter_data.data.area_id,
    address: transporter_data.data.address,
    address_notes: transporter_data.data.address_notes,
    city: transporter_data.data.city,
    state: transporter_data.data.state,
    postcode: transporter_data.data.postcode,
    country: transporter_data.data.country,
    latitude: transporter_data.data.latitude,
    longitude: transporter_data.data.longitude,
    contract_number: transporter_data.data.contract_number,
    contract_start_date: transporter_data.data.contract_start_date,
    contract_end_date: transporter_data.data.contract_end_date,
    owner_ktp: transporter_data.data.owner_ktp,
    owner_first_name:
      sectionCopy == 'ownerDetails' ? copyData.first_name : transporter_data.data.owner_first_name,
    owner_last_name:
      sectionCopy == 'ownerDetails' ? copyData.last_name : transporter_data.data.owner_last_name,
    owner_email: sectionCopy == 'ownerDetails' ? copyData.email : transporter_data.data.owner_email,
    owner_phone: sectionCopy == 'ownerDetails' ? copyData.phone : transporter_data.data.owner_phone,
    person_incharge_first_name:
      sectionCopy == 'personInCharge'
        ? copyData.first_name
        : transporter_data.data.person_incharge_first_name,
    person_incharge_last_name:
      sectionCopy == 'personInCharge'
        ? copyData.last_name
        : transporter_data.data.person_incharge_last_name,
    person_incharge_email:
      sectionCopy == 'personInCharge'
        ? copyData.email
        : transporter_data.data.person_incharge_email,
    person_incharge_phone:
      sectionCopy == 'personInCharge'
        ? copyData.phone
        : transporter_data.data.person_incharge_phone,
    accounting_first_name:
      sectionCopy == 'accountingDetails'
        ? copyData.first_name
        : transporter_data.data.accounting_first_name,
    accounting_last_name:
      sectionCopy == 'accountingDetails'
        ? copyData.last_name
        : transporter_data.data.accounting_last_name,
    accounting_email:
      sectionCopy == 'accountingDetails' ? copyData.email : transporter_data.data.accounting_email,
    accounting_phone:
      sectionCopy == 'accountingDetails' ? copyData.phone : transporter_data.data.accounting_phone,
    owner_country_id:
      transporter_data.data.owner_country_id !== '' &&
      transporter_data.data.owner_country_id !== null
        ? transporter_data.data.owner_country_id
        : parseInt(process.env.MIX_DEFAULT_PHONE_CODE),

    person_incharge_country_id:
      transporter_data.data.person_incharge_country_id !== '' &&
      transporter_data.data.person_incharge_country_id !== null
        ? transporter_data.data.person_incharge_country_id
        : parseInt(process.env.MIX_DEFAULT_PHONE_CODE),

    accounting_country_id:
      transporter_data.data.accounting_country_id !== '' &&
      transporter_data.data.accounting_country_id !== null
        ? transporter_data.data.accounting_country_id
        : parseInt(process.env.MIX_DEFAULT_PHONE_CODE),

    contract_status: transporter_data.data.contract_status,
    invoice_mode: transporter_data.data.invoice_mode,

    owner_ktp_attchment: transporter_data.data.owner_ktp_attchment,
    owner_ktp_attchment_id: transporter_data.data.transporter_ktp_id,

    selfi_with_ktp_attchement: transporter_data.data.selfi_with_ktp,
    selfi_with_ktp_attchement_id: transporter_data.data.selfi_with_ktp_id,

    stamp: transporter_data.data.stamp,
    stamp_id: transporter_data.data.stamp_id,

    additional_attachement: transporter_data.data.additional_attachment,

    surveillance_card: transporter_data.data.surveillance_card,
    surveillance_card_expiry_date: transporter_data.data.surveillance_card_expiry_date,
    surveillance_card_attachment: transporter_data.data.surveillance_card_attachment,
    surveillance_card_attachment_id: transporter_data.data.surveillance_card_attachment_id
  };

  const breadcurmArray = [
    {
      label: t('page.transporter_list_label'),
      link: '/transporter/list',
      active: ''
    },
    {
      label: t('page.edit_transporter_title'),
      link: '/transporter/add',
      active: 'active'
    }
  ];

  return (
    <>
      <DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={formState}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
        t={t}
      />
      <TNBreadCurm breadcurmArray={breadcurmArray} />
      <AlertCommon key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)} is_error={addTRError}>
        {addTRList}
      </AlertCommon>
      <h1>{t('page.edit_transporter_title')} </h1>
      <Card>
        <Card.Body className="p-0">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const formData = new FormData();
              for (let key in values) {
                let value = values[key];
                if (key === 'additional_attachement') {
                  for (let keyatt in value) {
                    let valueatt = value[keyatt];
                    formData.append(
                      `additional_attachement[${keyatt}][additional_attachement_name]`,
                      valueatt.additional_attachement_name
                    );
                    formData.append(
                      `additional_attachement[${keyatt}][additional_attachement_file]`,
                      valueatt.additional_attachement_file
                    );
                    formData.append(
                      `additional_attachement[${keyatt}][additional_attachment_id]`,
                      valueatt.additional_attachment_id
                    );
                  }
                } else {
                  formData.append(key, value);
                }
              }

              if (values.surveillance_card_expiry_date !== '') {
                formData.append(
                  'surveillance_card_expiry_date',
                  moment(values.surveillance_card_expiry_date).format('YYYY-MM-DD')
                );
              }

              if (values.contract_start_date !== '') {
                formData.append(
                  'contract_start_date',
                  moment(values.contract_start_date).format('YYYY-MM-DD')
                );
              }

              if (values.contract_end_date !== '') {
                formData.append(
                  'contract_end_date',
                  moment(values.contract_end_date).format('YYYY-MM-DD')
                );
              }
              setFormState(false);
              doStoreTransporter(formData);
            }}>
            {({ values, dirty }) => {
              values.contract_start_date =
                values.contract_start_date !== '' ? new Date(values.contract_start_date) : '';
              values.contract_end_date =
                values.contract_end_date !== '' ? new Date(values.contract_end_date) : '';
              return (
                <Form>
                  <Accordion
                    key={accrdKey}
                    alwaysOpen
                    defaultActiveKey={accrdKey && accrdKey !== 0 ? accrdKey : 1}>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 1}
                      className="my-1">
                      <Accordion.Header className="text-info fw-bold shadow-nond">
                        {t('page.add_transporter_general_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <GeneralDetails
                          t={t}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}></GeneralDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 2}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_transporter_contact_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <ContactrDetails t={t}></ContactrDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 3}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_transporter_owner_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <OwnerDetails
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          sectionCopy={sectionCopy}
                          setSectionCopy={setSectionCopy}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}></OwnerDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 4}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_transporter_person_in_charge_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <PersonInCharge
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          sectionCopy={sectionCopy}
                          setSectionCopy={setSectionCopy}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}></PersonInCharge>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 5}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_transporter_accounting_person_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <AccountingPersonDetails
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          sectionCopy={sectionCopy}
                          setSectionCopy={setSectionCopy}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}></AccountingPersonDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 6}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_transporter_surveillance_card_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <SurveillanceDetails t={t}></SurveillanceDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 7}
                      className="border-bottom my-1">
                      <Accordion.Header>
                        {t('page.add_transporter_attachment_title')}{' '}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <EditAttachmentFiles t={t} refetch={refetch}></EditAttachmentFiles>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="primary-button">
                    <span className="link-center" onClick={() => handleCancel(dirty)}>
                      {t('page.add_transporter_cancel_button')}
                    </span>
                    <TNButton
                      loading={isLoading}
                      onClick={() => {
                        setAccrdKey(1);
                      }}
                      isdirtyform={dirty && dirty !== undefined ? 1 : 0}
                      type="submit">
                      {t('page.add_transporter_submit_button')}
                    </TNButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
};
EditTransporterPage.propTypes = {
  t: PropTypes.func
};
export { EditTransporterPage };
