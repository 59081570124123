import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import TNTable from '@common/components/TNTable';
import { TNButton } from '@common/components/TNButton';
import TNBreadCurm from '@common/components/TNBreadCurm';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { dateFormatCommon, dateFormatManualAdd } from '@helpers/commonHelpers';
import {
  useGenerateTransporterReport,
  useGetAreaData,
  useGetTerritoryData,
  useListTransporter
} from '@hooks';
import moment from 'moment';
import Sort from '@common/components/Sorting';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from '../../common/components/Checkbox';
import { convertToPlainText } from '../../../helpers/commonHelpers';

const ListTransporterPage = ({ t }) => {
  const navigate = useNavigate();
  const [modalShowDownload, setModalShowDownload] = useState(false);
  /**
   * !This Block is for status drop down
   */
  const options = [
    { value: '', label: `${t('page.select_status')}` },
    { value: '1', label: t('page.contract_status_option_pending') },
    { value: '2', label: t('page.contract_status_option_inreview') },
    { value: '3', label: t('page.contract_status_option_active') },
    { value: '4', label: t('page.contract_status_option_hold') },
    { value: '5', label: t('page.contract_status_option_suspended') },
    { value: '6', label: t('page.contract_status_option_terminated') }
  ];

  /**
   * !This Block is for Date Type drop down
   */
  const dateTypes = [
    { value: '', label: `${t('page.select_date_type')}` },
    { value: 'created_at', label: t('page.created_at_date_type') },
    { value: 'contract_start_date', label: t('page.contract_start_date_type') },
    { value: 'contract_end_date', label: t('page.contract_end_date_type') }
  ];

  /**
   * !This Block is for Invoice Mode drop down
   */
  const invoiceMode = [
    { value: '', label: t('page.add_transporter_contact_details_invoice_mode_option_blank') },
    { value: '0', label: t('page.add_transporter_contact_details_invoice_mode_option_1') },
    { value: '1', label: t('page.add_transporter_contact_details_invoice_mode_option_2') }
  ];

  const sortByExport = [
    { value: 'created_at', label: `${t('page.transporter_created_at_transporter_id_label')}` },
    { value: 'name', label: `${t('page.transporter_name_id_label')}` },
    { value: 'contract_start_date', label: `${t('page.transporter_contract_detail_label')}` },
    { value: 'territory_name', label: `${t('page.transporter_territory_area_label')}` },
    {
      value: 'invoice_mode',
      label: `${t('page.transporter_invoice_mode_label')}`
    },
    { value: 'contract_status_name', label: `${t('page.transporter_status_label')}` }
  ];

  const sortOrderExport = [
    { value: 'asc', label: `${t('page.asccending')}` },
    { value: 'desc', label: `${t('page.desccending')}` }
  ];

  const groupByExport = [
    { value: 'status', label: `${t('page.select_status')}` },
    {
      value: 'invoice_mode',
      label: `${t('page.add_transporter_contact_details_invoice_mode_option_blank')}`
    },
    { value: 'territory', label: `${t('page.select_territory')}` },
    { value: 'area', label: `${t('page.select_area')}` }
  ];

  /**
   * setting states for preserve search
   */
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    localStorage.transporterTable !== undefined && localStorage.transporterTable !== ''
      ? JSON.parse(localStorage.transporterTable).currentPage
      : 1
  );
  const [searchText, setSearchText] = useState(
    localStorage.transporterTable !== undefined && localStorage.transporterTable !== ''
      ? JSON.parse(localStorage.transporterTable).searchText
      : ''
  );

  const [searchTextFilter, setSearchTextFilter] = useState(
    localStorage.transporterTable !== undefined && localStorage.transporterTable !== ''
      ? JSON.parse(localStorage.transporterTable).searchTextFilter
      : ''
  );

  const [paginationData, setPaginationData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(
    localStorage.transporterTable !== undefined && localStorage.transporterTable !== ''
      ? JSON.parse(localStorage.transporterTable).selectedStatus
      : ''
  );
  const [selectedInvoiceMode, setSelectedInvoiceMode] = useState(
    localStorage.transporterTable !== undefined && localStorage.transporterTable !== ''
      ? JSON.parse(localStorage.transporterTable).selectedInvoiceMode
      : ''
  );
  const [fromDate, setFromDate] = useState(
    localStorage.transporterTable !== undefined &&
      localStorage.transporterTable !== '' &&
      JSON.parse(localStorage.transporterTable).fromDate !== ''
      ? new Date(JSON.parse(localStorage.transporterTable).fromDate)
      : ''
  );
  const [toDate, setToDate] = useState(
    localStorage.transporterTable !== undefined &&
      localStorage.transporterTable !== '' &&
      JSON.parse(localStorage.transporterTable).toDate !== ''
      ? new Date(JSON.parse(localStorage.transporterTable).toDate)
      : ''
  );
  const [territryId, setTerritryId] = useState(
    localStorage.transporterTable !== undefined && localStorage.transporterTable !== ''
      ? JSON.parse(localStorage.transporterTable).territryId
      : ''
  );
  const [areaId, setAreaId] = useState(
    localStorage.transporterTable !== undefined && localStorage.transporterTable !== ''
      ? JSON.parse(localStorage.transporterTable).areaId
      : ''
  );
  const [selectedDateType, setSelectedDateType] = useState(
    localStorage.transporterTable !== undefined && localStorage.transporterTable !== ''
      ? JSON.parse(localStorage.transporterTable).selectedDateType
      : ''
  );

  const [sortBy, setSortBy] = useState(
    localStorage.transporterTable !== undefined && localStorage.transporterTable !== ''
      ? JSON.parse(localStorage.transporterTable).sortBy
      : 'created_at'
  );

  const [sortOrder, setSortOrder] = useState(
    localStorage.transporterTable !== undefined && localStorage.transporterTable !== ''
      ? JSON.parse(localStorage.transporterTable).sortOrder
      : 'desc'
  );

  const [groupBy, setGroupBy] = useState(
    localStorage.transporterTable !== undefined && localStorage.adminUsersTable !== ''
      ? JSON.parse(localStorage.transporterTable).groupBy
      : ''
  );

  /**
   * !This Function will call when user clicks on Edit Button
   * @param {*} tdata which is current element of button
   */
  const handleEditClick = (tdata) => {
    let transporter_id = tdata.currentTarget.getAttribute('transporter_id');
    navigate(`/transporter/edit/${transporter_id}`);
  };

  /**
   * !This Function will call when user clicks on View Button
   * @param {*} tdata which is current element of button
   */
  const handleViewClick = (tdata) => {
    let transporter_id = tdata.currentTarget.getAttribute('transporter_id');
    navigate(`/transporter/view/${transporter_id}`);
  };

  /**
   * !This block will call on click clear date
   */
  const clearDateFilter = (date) => {
    setFromDate('');
    setToDate('');
    setSelectedDateType('');
  };

  useEffect(() => {
    let transporterTable = {
      searchText: searchText,
      searchTextFilter: searchTextFilter,
      currentPage: currentPage,
      selectedStatus: selectedStatus,
      selectedInvoiceMode: selectedInvoiceMode,
      fromDate: fromDate,
      toDate: toDate,
      territryId: territryId,
      areaId: areaId,
      selectedDateType: selectedDateType,
      sortBy: sortBy,
      sortOrder: sortOrder
    };
    localStorage.transporterTable = JSON.stringify(transporterTable);
  }, [
    currentPage,
    searchText,
    selectedStatus,
    selectedInvoiceMode,
    fromDate,
    toDate,
    territryId,
    areaId,
    selectedDateType,
    searchTextFilter,
    sortBy,
    sortOrder
  ]);

  const handleSort = (name) => {
    setSortBy(name);
    sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc');
  };

  /**
   * !This Api is for Terriotory drop down
   */
  const { isLoading: territoryIsLoad, data: TerritoryList } = useGetTerritoryData();
  const TerritoryOptions = [];

  TerritoryOptions.push({
    value: '',
    label: t('page.select_territory')
  });

  if (TerritoryList !== undefined) {
    TerritoryList.data.map((val) => {
      return TerritoryOptions.push({
        value: val.territory_id,
        label: val.name
      });
    });
  }

  const { mutate: doGenerateReport, isLoading: reportGenerateLoading } =
    useGenerateTransporterReport(
      (response) => {
        toast.success(response.message);
        download(response.data.report_url);
        setModalShowDownload(false);
      },
      (error) => {
        window.scrollTo(0, 0);
      }
    );

  const download = (image) => {
    const fileName = image.split('/').pop();
    image = image.replace(window.origin, '');
    var link = document.createElement('a');
    link.href = image;
    link.target = '_blank';
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click'));
  };

  const downloadApiCall = (type) => {
    const values = {};
    values.report_type = type;
    values.search_text = searchText;
    values.select_status = selectedStatus;
    values.invoice_mode = selectedInvoiceMode;
    values.from_date = fromDate !== '' ? moment(fromDate).format('YYYY-MM-DD') : '';
    values.to_date = toDate !== '' ? moment(toDate).format('YYYY-MM-DD') : '';
    values.territory_id = territryId;
    values.area_id = areaId;
    values.date_type = selectedDateType;

    values.column_export = JSON.stringify(isCheck);

    doGenerateReport(values);
  };

  /**
   * !This Api is for Area drop down
   */
  const { isLoading: areaIsLoad, data: AreaList } = useGetAreaData(territryId);
  const AreaOptions = [];

  AreaOptions.push({
    value: '',
    label: t('page.select_area')
  });

  if (AreaList !== undefined) {
    AreaList.data.map((val) => {
      return AreaOptions.push({
        value: val.area_id,
        label: val.area_name
      });
    });
  }

  const columnsjson = [
    {
      Header: (
        <div
          onClick={() => {
            handleSort('created_at');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.transporter_created_at_transporter_id_label')
            }}></span>
          <Sort isActive={sortBy === 'created_at' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'created_at',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return <div>{row.original.created_at}</div>;
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('name');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.transporter_name_id_label')
            }}></span>
          <Sort isActive={sortBy === 'name' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'name',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.transporter_id} <br />
            {row.original.name}
          </div>
        );
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('contract_start_date');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.transporter_contract_detail_label')
            }}></span>
          <Sort isActive={sortBy === 'contract_start_date' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'contract_start_date',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.contract_start_date}
            <br />
            {row.original.contract_end_date}
          </div>
        );
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('territory_name');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.transporter_territory_area_label')
            }}></span>
          <Sort isActive={sortBy === 'territory_name' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'territory_name',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.territory_name}
            <br />
            {row.original.area_name}
          </div>
        );
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('invoice_mode');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.transporter_invoice_mode_label')
            }}></span>
          <Sort isActive={sortBy === 'invoice_mode' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'invoice_mode_name',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return <div>{row.original.invoice_mode_name}</div>;
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('contract_status_name');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.transporter_status_label')
            }}></span>
          <Sort isActive={sortBy === 'contract_status_name' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'contract_status_name',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            <span
              className={row.original.contract_status === 3 ? 'status-active' : 'status-inactive'}>
              {t(initialValue)}
            </span>
          </div>
        );
      }
    },
    {
      Header: `${t('page.action_column')}`,
      accessor: 'transporter_id',
      Cell: ({ value: initialValue }) => {
        return (
          <div className="action_btn">
            <TNButton
              className="table-primary-button"
              transporter_id={initialValue}
              onClick={handleViewClick.bind(this)}>
              {t('page.action_button_text_view')}
            </TNButton>
            <TNButton
              className="table-primary-button"
              transporter_id={initialValue}
              onClick={handleEditClick.bind(this)}>
              {t('page.action_button_text_edit')}
            </TNButton>
          </div>
        );
      },
      disableSortBy: true
    }
  ];

  const [exportLabels, setExportLabels] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  /**
   * !This API will call while Page Load and set data. Once data load we are updating State.
   */
  useListTransporter(
    [
      currentPage,
      searchTextFilter,
      selectedStatus,
      selectedInvoiceMode,
      fromDate !== '' ? moment(fromDate).format('YYYY-MM-DD') : '',
      toDate !== '' ? moment(toDate).format('YYYY-MM-DD') : '',
      territryId,
      areaId,
      selectedDateType,
      sortBy,
      sortOrder
    ],
    (res) => {
      setExportLabels([
        'admin.transporter_details_created_at',
        'page.transporter_name',
        'admin.transporter_npwp_number_csv',
        'admin.transporter_contact_number_csv',
        'admin.transporter_details_address_csv',
        'admin.transporter_details_territory_name_csv',
        'admin.transporter_details_area_name_csv',
        'admin.transporter_details_contarct_number_csv',
        'admin.transporter_details_contarct_start_date_csv',
        'admin.transporter_details_contarct_end_date_csv',
        'admin.transporter_details_contarct_end_date_csv',
        'admin.transporter_details_invoice_mode_csv',
        'admin.transporter_details_owner_details_csv',
        'admin.transporter_details_owner_ktp_no_csv',
        'admin.transporter_details_pic_details_csv',
        'admin.transporter_details_accounting_details_csv'
      ]);

      setExportColumns([
        'transporter_created_at',
        'transporter_transporter_name',
        'transporter_transport_npwp_number',
        'transporter_contact_number',
        'transporter_address',
        'transporter_territory_name',
        'transporter_area_name',
        'transporter_contract_number',
        'transporter_contract_start_date',
        'transporter_contract_end_date',
        'transporter_contract_status_name',
        'transporter_invoice_mode',
        'transporter_owner_details',
        'transporter_owner_ktp',
        'transporter_pic_details',
        'transporter_accounting_details'
      ]);

      setIsCheck(exportColumns);

      setData(res.data.transporter_listing);
      setPaginationData(res.data.pagination);
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => columnsjson, [sortBy, sortOrder]);
  if (paginationData == null) {
    return null;
  }

  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
  };

  const handleSeach = (search_text) => {
    setCurrentPage(1);
    setSearchText(search_text.target.value);
    if (search_text.target.value.length >= 3) {
      setSearchTextFilter(search_text.target.value);
    } else {
      setSearchTextFilter('');
    }
  };

  const handleClearFilter = () => {
    setSearchText('');
    setSearchTextFilter('');
    setCurrentPage(1);
    setSelectedStatus('');
    setSelectedInvoiceMode('');
    setTerritryId('');
    setAreaId('');
    setSortBy('created_at');
    setSortOrder('desc');
    localStorage.removeItem('transporterTable');
  };

  const addAgentHandle = () => {
    navigate(`/transporter/add`);
  };

  const breadcurmArray = [
    {
      label: t('page.transporter_list_title'),
      link: '/agents/list',
      active: 'active'
    }
  ];

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(exportColumns.map((li) => li));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleExportColumnChecked = (e) => {
    const { id, checked } = e.target;

    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />

      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group controlId="search" className="filter-field-space">
              <Form.Control
                placeholder={t('page.search_text')}
                className="filter-column form-field"
                onKeyUp={handleSeach}
                onChange={handleSeach}
                value={searchText}
              />
            </Form.Group>

            <Button type="button" onClick={handleClearFilter} className="secondary-remove-button">
              {t('page.cancel_search_button')}
            </Button>
            <TNButton
              className="table-add-button"
              loading={false}
              type="button"
              onClick={addAgentHandle}>
              {t('page.add_transporter_title')}
            </TNButton>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={options}
                value={options.filter((option) => option.value === selectedStatus)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setSelectedStatus(selectedOption.value);
                }}
              />
            </Form.Group>

            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={invoiceMode}
                value={invoiceMode.filter((option) => option.value === selectedInvoiceMode)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setSelectedInvoiceMode(selectedOption.value);
                }}
              />
            </Form.Group>

            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                placeholder={t('page.terrritory_select_placeholder')}
                options={territoryIsLoad ? TerritoryOptions : TerritoryOptions}
                value={TerritoryOptions.filter((option) => option.value === territryId)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setTerritryId(selectedOption.value);
                }}
              />
            </Form.Group>

            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={areaIsLoad ? AreaOptions : AreaOptions}
                value={AreaOptions.filter((option) => option.value === areaId)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setAreaId(selectedOption.value);
                }}
              />
            </Form.Group>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={dateTypes}
                value={dateTypes.filter((option) => option.value === selectedDateType)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setSelectedDateType(selectedOption.value);
                }}
              />
            </Form.Group>

            <div
              className={selectedDateType !== '' ? '' : 'd-none'}
              style={{ display: 'contents' }}>
              <Form.Label className="field-label date-picker-label">
                {t('page.users_from_date_label')}
              </Form.Label>
              <Form.Group className="filter-field-space">
                <DatePicker
                  onKeyDown={dateFormatManualAdd}
                  dropdownMode="select"
                  showMonthDropdown
                  showYearDropdown
                  adjustDateOnChange
                  className="form-control filter-column"
                  placeholderText={dateFormatCommon().toUpperCase()}
                  selected={fromDate}
                  dateFormat={dateFormatCommon()}
                  onChange={(date) => setFromDate(date)}
                  maxDate={toDate !== '' && toDate !== undefined ? toDate : new Date()}
                  onBlur={(date) => {
                    var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');

                    if (date.currentTarget.value) {
                      if (!date1.isValid()) {
                        toast.error(t('page.invalid_date_format'));
                        setFromDate('');
                      } else {
                        var maxDate = toDate !== '' && toDate !== undefined ? toDate : new Date();
                        if (date1 > maxDate) {
                          toast.error(t('page.from_date_less_than_to_Date'));
                          setFromDate('');
                        }
                      }
                    }
                  }}
                />
              </Form.Group>
              <Form.Label className="field-label date-picker-label">
                {t('page.users_to_date_label')}
              </Form.Label>
              <Form.Group className="filter-field-space">
                <DatePicker
                  onKeyDown={dateFormatManualAdd}
                  dropdownMode="select"
                  showMonthDropdown
                  showYearDropdown
                  adjustDateOnChange
                  className="form-control filter-column"
                  placeholderText={dateFormatCommon().toUpperCase()}
                  minDate={fromDate}
                  selected={toDate}
                  dateFormat={dateFormatCommon()}
                  onChange={(date) => setToDate(date)}
                  onBlur={(date) => {
                    var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                    if (date.currentTarget.value) {
                      if (!date1.isValid()) {
                        toast.error(t('page.invalid_date_format'));
                        setToDate('');
                      } else {
                        var minDate = fromDate !== '' && fromDate !== undefined ? fromDate : date1;
                        if (date1 < minDate) {
                          toast.error(t('page.to_date_greater_than_from_Date'));
                          setToDate('');
                        }
                      }
                    }
                  }}
                />
              </Form.Group>
            </div>
            <TNButton
              className="secondary-add-button"
              loading={false}
              type="button"
              onClick={clearDateFilter}>
              {t('page.user_list_clear_date_filter_button')}
            </TNButton>
            <TNButton
              className="table-add-button ml-0"
              loading={false}
              type="button"
              onClick={() => {
                setModalShowDownload(true);
              }}>
              {t('page.payout_transporter_download_button')}
            </TNButton>
          </div>
        </Col>
      </Row>
      <h1>{t('page.transporter_list_title')}</h1>
      <TNTable
        columns={columns}
        data={data}
        paginationData={paginationData}
        onSelectPage={pageIndexHandle}
        pageIndexGet={currentPage - 1}
        t={t}
        idName="producer_list"
        key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
      />
      <Modal
        show={modalShowDownload}
        size={'xl'}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header className="text-center">
          <h1>{t('page.transporter_select_download_button')}</h1>
          <div className="close-popup">
            <FontAwesomeIcon
              icon={faClose}
              onClick={() => {
                setModalShowDownload(false);
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-5">
              <div className="select-all-bar">
                <Checkbox
                  type="checkbox"
                  id="selectAll"
                  name="SelectAll"
                  label={t('page.select_all')}
                  handleClick={handleSelectAll}
                  isChecked={isCheckAll}
                  style={{ textAlign: 'left' }}
                />
              </div>
              <div
                style={{ height: '200px', overflowY: 'scroll', border: '1px solid #ced4da' }}
                className="mb-5">
                {exportColumns.map((exportColumn, index) => {
                  return (
                    <Checkbox
                      key={index}
                      type="checkbox"
                      name={exportColumn}
                      label={t(exportLabels[index])}
                      id={exportColumn}
                      handleClick={handleExportColumnChecked}
                      isChecked={isCheck.includes(exportColumn)}
                      style={{ textAlign: 'left', paddingLeft: '5px' }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="col-4">
              <Form.Label className="field-label date-picker-label">
                {t('page.short_by_label')}
              </Form.Label>
              <div className="row">
                <div className="col-9">
                  <Form.Group>
                    <Select
                      className="filter-column"
                      options={sortByExport.map((option) => {
                        option.label = convertToPlainText(option.label);

                        return option;
                      })}
                      value={sortByExport.filter((option) => option.value === sortBy)}
                      onChange={(selectedOption) => {
                        setSortBy(selectedOption.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-3">
                  <Form.Group>
                    <Select
                      className="filter-column"
                      options={sortOrderExport.map((option) => {
                        option.label = convertToPlainText(option.label);

                        return option;
                      })}
                      value={sortOrderExport.filter((option) => option.value === sortOrder)}
                      onChange={(selectedOption) => {
                        setSortOrder(selectedOption.value);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
              <Form.Label className="field-label date-picker-label mt-3">
                {t('page.group_by_label')}
              </Form.Label>
              <Form.Group>
                <Select
                  className="filter-column mb-3"
                  options={groupByExport}
                  value={groupByExport.filter((option) => option.value === groupBy)}
                  onChange={(selectedOption) => {
                    setGroupBy(selectedOption.value);
                  }}
                />
              </Form.Group>
              <div className={groupBy === 'status' ? '' : 'd-none'} style={{ display: 'contents' }}>
                <Form.Group controlId="search">
                  <Select
                    className="filter-column"
                    options={options}
                    value={options.filter((option) => option.value === selectedStatus)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setSelectedStatus(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
              <div
                className={groupBy === 'invoice_mode' ? '' : 'd-none'}
                style={{ display: 'contents' }}>
                <Form.Group controlId="search">
                  <Select
                    className="filter-column"
                    options={invoiceMode}
                    value={invoiceMode.filter((option) => option.value === selectedInvoiceMode)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setSelectedInvoiceMode(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
              <div
                className={groupBy === 'territory' ? '' : 'd-none'}
                style={{ display: 'contents' }}>
                <Form.Group controlId="search">
                  <Select
                    className="filter-column"
                    placeholder={t('page.territory_select_placeholder')}
                    options={territoryIsLoad ? TerritoryOptions : TerritoryOptions}
                    value={TerritoryOptions.filter((option) => option.value === territryId)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setTerritryId(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
              <div className={groupBy === 'area' ? '' : 'd-none'} style={{ display: 'contents' }}>
                <Form.Group controlId="search">
                  <Select
                    className="filter-column"
                    options={areaIsLoad ? AreaOptions : AreaOptions}
                    value={AreaOptions.filter((option) => option.value === areaId)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setAreaId(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="col-3">
              <Form.Label className="field-label date-picker-label">
                {t('page.users_date_type_label')}
              </Form.Label>
              <Form.Group controlId="search" className="mb-3">
                <Select
                  className="filter-column"
                  options={dateTypes}
                  value={dateTypes.filter((option) => option.value === selectedDateType)}
                  onChange={(selectedOption) => {
                    setCurrentPage(1);
                    setSelectedDateType(selectedOption.value);
                    setFromDate('');
                    setToDate('');
                  }}
                />
              </Form.Group>
              <div
                className={selectedDateType !== '' ? '' : 'd-none'}
                style={{ display: 'contents' }}>
                <Form.Label className="field-label date-picker-label">
                  {t('page.users_from_date_label')}
                </Form.Label>
                <Form.Group>
                  <DatePicker
                    onKeyDown={dateFormatManualAdd}
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    adjustDateOnChange
                    className="form-control filter-column"
                    placeholderText={dateFormatCommon().toUpperCase()}
                    selected={fromDate}
                    dateFormat={dateFormatCommon()}
                    onChange={(date) => setFromDate(date)}
                    maxDate={toDate !== '' && toDate !== undefined ? toDate : new Date()}
                    onBlur={(date) => {
                      var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                      if (date.currentTarget.value) {
                        if (!date1.isValid()) {
                          toast.error(t('page.invalid_date_format'));
                          setFromDate('');
                        } else {
                          var maxDate = toDate !== '' && toDate !== undefined ? toDate : new Date();
                          if (date1 > maxDate) {
                            toast.error(t('page.from_date_less_than_to_Date'));
                            setFromDate('');
                          }
                        }
                      }
                    }}
                  />
                </Form.Group>
                <Form.Label className="field-label date-picker-label mt-3">
                  {t('page.users_to_date_label')}
                </Form.Label>
                <Form.Group>
                  <DatePicker
                    onKeyDown={dateFormatManualAdd}
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    adjustDateOnChange
                    className="form-control filter-column"
                    placeholderText={dateFormatCommon().toUpperCase()}
                    minDate={fromDate}
                    selected={toDate}
                    dateFormat={dateFormatCommon()}
                    onChange={(date) => setToDate(date)}
                    onBlur={(date) => {
                      var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                      if (date.currentTarget.value) {
                        if (!date1.isValid()) {
                          toast.error(t('page.invalid_date_format'));
                          setToDate('');
                        } else {
                          var minDate =
                            fromDate !== '' && fromDate !== undefined ? fromDate : date1;
                          if (date1 < minDate) {
                            toast.error(t('page.to_date_greater_than_from_Date'));
                            setToDate('');
                          }
                        }
                      }
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="primary-button">
            <TNButton
              type="button"
              loading={reportGenerateLoading}
              onClick={() => {
                downloadApiCall(1);
              }}>
              {t('page.excel_download_button_text')}
            </TNButton>
            <TNButton
              type="button"
              loading={reportGenerateLoading}
              onClick={() => {
                downloadApiCall(3);
              }}
              className="ms-2">
              {t('page.csv_download_button_text')}
            </TNButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
ListTransporterPage.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  paginationData: PropTypes.any,
  value: PropTypes.any,
  row: PropTypes.any,
  t: PropTypes.func
};
export { ListTransporterPage };
