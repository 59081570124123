import { Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useAdminDashboardData,
  useGetTerritoryData,
  useGetAreaData,
  useOwnWarehouseList,
  useListStockByWarehouse,
  useListOrderByWarehouseAndDeliveryType,
  useListStocksHistory
} from '@hooks';
import Select from 'react-select';
import Slider from 'react-slick';
import '@assets/scss/page/_slider.scss';
import { defaultValue } from '@helpers/commonHelpers';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import TNTable from '@common/components/TNTable';
import { NavLink } from 'react-router-dom';

const MultiScreenPage = ({ t }) => {
  const defaultMultiValue = (option, value) => {
    const selected = [];
    if (Array.isArray(value)) {
      value.map((key) => {
        let select = option ? option.find((option) => option.value === key) : '';
        return selected.push(select);
      });
    }
    return selected;
  };

  const [modalShowContainerTypeCollected, setModalShowContainerTypeCollected] = useState(false);
  const [modalShowProducerCollected, setModalShowProducerCollected] = useState(false);
  const [modalShowContainerTypeCollectPending, setModalShowContainerTypeCollectPending] =
    useState(false);
  const [modalShowProducerCollectPending, setModalShowProducerCollectPending] = useState(false);

  const [modalShowStock, setModalShowStock] = useState(false);
  const [modalShowOrder, setModalShowOrder] = useState(false);

  const [territryId, setTerritryId] = useState(
    localStorage.getItem('adminDashboardFilter.territryId') !== undefined &&
      localStorage.getItem('adminDashboardFilter.territryId') !== '' &&
      localStorage.getItem('adminDashboardFilter.territryId') !== null
      ? parseInt(localStorage.getItem('adminDashboardFilter.territryId'))
      : 0
  );

  const [areaId, setAreaId] = useState(
    localStorage.getItem('adminDashboardFilter.areaId') !== undefined &&
      localStorage.getItem('adminDashboardFilter.areaId') !== '' &&
      localStorage.getItem('adminDashboardFilter.areaId') !== null
      ? JSON.parse(localStorage.getItem('adminDashboardFilter.areaId'))
      : []
  );

  const [warehouseId, setWarehouseId] = useState(
    localStorage.adminStockAndOrder !== undefined &&
      localStorage.adminStockAndOrder !== '' &&
      JSON.parse(localStorage.adminStockAndOrder).warehouseId !== ''
      ? JSON.parse(localStorage.adminStockAndOrder).warehouseId
      : ''
  );

  const [dataAllStock, setDataAllStock] = useState([]);
  const [paginationDataStock, setPaginationDataStock] = useState(null);
  const [currentPageStock, setCurrentPageStock] = useState(
    localStorage.adminStockAndOrder !== undefined && localStorage.adminStockAndOrder !== ''
      ? JSON.parse(localStorage.adminStockAndOrder).currentPageStock
      : 1
  );

  const [dataAllOrderBySameDay, setDataAllOrderBySameDay] = useState([]);
  const [paginationDataOrderBySameDay, setPaginationDataOrderBySameDay] = useState(null);
  const [currentPageOrderBySameDay, setCurrentPageOrderBySameDay] = useState(
    localStorage.adminStockAndOrder !== undefined && localStorage.adminStockAndOrder !== ''
      ? JSON.parse(localStorage.adminStockAndOrder).currentPageOrderBySameDay
      : 1
  );

  const [dataAllOrderByNextDay, setDataAllOrderByNextDay] = useState([]);
  const [paginationDataOrderByNextDay, setPaginationDataOrderByNextDay] = useState(null);
  const [currentPageOrderByNextDay, setCurrentPageOrderByNextDay] = useState(
    localStorage.adminStockAndOrder !== undefined && localStorage.adminStockAndOrder !== ''
      ? JSON.parse(localStorage.adminStockAndOrder).currentPageOrderByNextDay
      : 1
  );

  const [dataAllOrder, setDataAllOrder] = useState([]);
  const [paginationDataOrder, setPaginationDataOrder] = useState(null);
  const [currentPageOrder, setCurrentPageOrder] = useState(
    localStorage.adminStockAndOrder !== undefined && localStorage.adminStockAndOrder !== ''
      ? JSON.parse(localStorage.adminStockAndOrder).currentPageOrder
      : 1
  );

  //Addon by satrya
  const [filterType, setFilterType] = useState(
    localStorage.adminStockAndOrder !== undefined && localStorage.adminStockAndOrder !== ''
      ? JSON.parse(localStorage.adminStockAndOrder).filterType
      : ''
  );

  const [actionBy, setActionBy] = useState(
    localStorage.adminStockAndOrder !== undefined && localStorage.adminStockAndOrder !== ''
      ? JSON.parse(localStorage.adminStockAndOrder).actionBy
      : ''
  );

  const [sortBy, setSortBy] = useState(
    localStorage.adminStockAndOrder !== undefined && localStorage.adminStockAndOrder !== ''
      ? JSON.parse(localStorage.adminStockAndOrder).sortBy
      : 'created_at'
  );

  const [sortOrder, setSortOrder] = useState(
    localStorage.adminStockAndOrder !== undefined && localStorage.adminStockAndOrder !== ''
      ? JSON.parse(localStorage.adminStockAndOrder).sortOrder
      : 'desc'
  );

  const [searchTextFilter, setSearchTextFilter] = useState(
    localStorage.adminStockAndOrder !== undefined && localStorage.adminStockAndOrder !== ''
      ? JSON.parse(localStorage.adminStockAndOrder).searchTextFilter
      : ''
  );

  const [dataAllInOutStock, setDataAllInOutStock] = useState([]);
  const [paginationDataInOutStock, setPaginationDataInOutStock] = useState(null);
  const [currentPageInOutStock, setCurrentPageInOutStock] = useState(
    localStorage.adminStockAndOrder !== undefined && localStorage.adminStockAndOrder !== ''
      ? JSON.parse(localStorage.adminStockAndOrder).currentPageStock
      : 1
  );
  //end addon by satrya

  useEffect(() => {
    localStorage.setItem('adminDashboardFilter.territryId', territryId);
    localStorage.setItem('adminDashboardFilter.areaId', JSON.stringify(areaId));

    let adminStockAndOrder = {
      warehouseId: warehouseId,
      currentPageStock: currentPageStock,
      currentPageOrderBySameDay: currentPageOrderBySameDay,
      currentPageOrderByNextDay: currentPageOrderByNextDay,
      currentPageOrder: currentPageOrder
    };

    localStorage.adminStockAndOrder = JSON.stringify(adminStockAndOrder);
  }, [
    territryId,
    areaId,
    warehouseId,
    currentPageStock,
    currentPageOrderBySameDay,
    currentPageOrderByNextDay,
    currentPageOrder
  ]);

  const { isLoading: territoryIsLoad, data: territoryList } = useGetTerritoryData();
  const territoryOptions = [];
  territoryOptions.push({
    value: '',
    label: t('page.select_territory')
  });
  if (territoryList !== undefined) {
    territoryList.data.map((val) => {
      return territoryOptions.push({
        value: val.territory_id,
        label: val.name
      });
    });
  }

  const { isLoading: areaIsLoad, data: areaList } = useGetAreaData(territryId);
  var areaOptions = [];
  areaOptions.push({
    value: '',
    label: t('page.select_area')
  });
  if (areaList !== undefined) {
    if (areaList.data.length > 0) {
      areaOptions = [];
    }

    areaList.data.map((val) => {
      return areaOptions.push({
        value: val.area_id,
        label: val.area_name
      });
    });
  }

  const { data: ownWarehouses } = useOwnWarehouseList();

  const { data: dashboardData } = useAdminDashboardData([territryId, areaId]);

  const { data: stockByWarehouseData } = useListStockByWarehouse(
    [warehouseId, moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
    (res) => {
      setDataAllStock(res.data.stocks_list);
      setPaginationDataStock(res.data.pagination);
    }
  );

  const { data: stockInOutByWarehouseData } = useListStocksHistory(
    [
      warehouseId,
      currentPageInOutStock,
      searchTextFilter,
      filterType,
      actionBy,
      moment(new Date()).format('YYYY-MM-DD'),
      moment(new Date()).format('YYYY-MM-DD'),
      sortBy,
      sortOrder
    ],
    (res) => {
      setDataAllInOutStock(res.data.stocks_list);
      setPaginationDataInOutStock(res.data.pagination);
    }
  );

  const { data: orderBySameDayData } = useListOrderByWarehouseAndDeliveryType(
    [
      warehouseId,
      moment(new Date()).format('YYYY-MM-DD'),
      moment(new Date()).format('YYYY-MM-DD'),
      1
    ],
    (res) => {
      setDataAllOrderBySameDay(res.data.order_list);
      setPaginationDataOrderBySameDay(res.data.pagination);
    }
  );

  const { data: orderByNextDayData } = useListOrderByWarehouseAndDeliveryType(
    [
      warehouseId,
      moment(new Date()).format('YYYY-MM-DD'),
      moment(new Date()).format('YYYY-MM-DD'),
      2
    ],
    (res) => {
      setDataAllOrderByNextDay(res.data.order_list);
      setPaginationDataOrderByNextDay(res.data.pagination);
    }
  );

  const { data: orderByAllData } = useListOrderByWarehouseAndDeliveryType(
    [
      warehouseId,
      moment(new Date()).format('YYYY-MM-DD'),
      moment(new Date()).format('YYYY-MM-DD'),
      ''
    ],
    (res) => {
      setDataAllOrder(res.data.order_list);
      setPaginationDataOrder(res.data.pagination);
    }
  );

  const stockcolumnsjson = [
    {
      Header: `${t('page.stock_history_created_at')}`,
      accessor: 'created_at',
      disableSortBy: true
    },
    {
      Header: `${t('page.stock_history_label_size')}`,
      accessor: 'label_size',
      disableSortBy: true
    },
    {
      Header: `${t('page.stock_history_type')}`,
      accessor: 'type_name',
      disableSortBy: true
    },
    {
      Header: `${t('page.stock_history_quantity')}`,
      accessor: 'quantity',
      disableSortBy: true
    },
    {
      Header: `${t('page.stock_history_done_by')}`,
      accessor: 'action_by_name',
      disableSortBy: true
    },
    {
      Header: `${t('page.stock_history_warehouse_name')}`,
      accessor: 'warehouse_name',
      disableSortBy: true
    }
  ];
  const ordercolumnsjson = [
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.order_order_no_and_date_label')
          }}></span>
      ),
      accessor: 'created_at',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.order_number}
            <br />
            {row.original.created_at}
          </div>
        );
      }
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.order_territory_and_area_label')
          }}></span>
      ),
      accessor: 'territory_name',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.territory_name}
            <br />
            {row.original.area_name}
          </div>
        );
      }
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.order_own_warehouse_label')
          }}></span>
      ),
      accessor: 'warehouse_id',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return <div>{row.original.warehouse_name}</div>;
      }
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.order_producer_id_name_label')
          }}></span>
      ),
      accessor: 'producer_id',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.producer_id}
            <br />
            <NavLink to={`/producer/view/${row.original.producer_id}`}>
              {row.original.producer_name}
            </NavLink>
          </div>
        );
      }
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.order_delivery_type_label')
          }}></span>
      ),
      accessor: 'delivery_type',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return <div>{row.original.delivery_type}</div>;
      }
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.order_status_label')
          }}></span>
      ),
      accessor: 'status_name',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            <span
              className={
                row.original.status === 6 || row.original.status === 7
                  ? 'status-inactive'
                  : 'status-active'
              }>
              {t(initialValue)}
            </span>
            <br />
            {row.original.status_update_date}
          </div>
        );
      }
    }
  ];

  const stockcolumns = useMemo(() => stockcolumnsjson, []);
  const ordercolumns = useMemo(() => ordercolumnsjson, []);

  if (ownWarehouses === undefined || stockByWarehouseData === undefined) {
    return null;
  }

  if (ownWarehouses === undefined || stockInOutByWarehouseData === undefined) {
    return null;
  }

  if (ownWarehouses === undefined || orderBySameDayData === undefined) {
    return null;
  }

  if (ownWarehouses === undefined || orderByNextDayData === undefined) {
    return null;
  }

  if (ownWarehouses === undefined || orderByAllData === undefined) {
    return null;
  }

  if (paginationDataStock == null) {
    return null;
  }

  if (paginationDataInOutStock == null) {
    return null;
  }

  if (paginationDataOrderBySameDay == null) {
    return null;
  }

  if (paginationDataOrderByNextDay == null) {
    return null;
  }

  if (paginationDataOrder == null) {
    return null;
  }

  let slideVal =
    stockByWarehouseData.data.current_stocks.length > 3
      ? 4
      : stockByWarehouseData.data.current_stocks.length;
  const settings = {
    infinite: false,
    slidesToShow: slideVal,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const inOutSettings = {
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const sumStock = stockByWarehouseData.data.current_stocks.reduce(function (prev, current) {
    return prev + +current.current_quantity;
  }, 0);

  const inStock = stockInOutByWarehouseData.data.stocks_list.filter((item) => item.type == 1);
  const outStock = stockInOutByWarehouseData.data.stocks_list.filter((item) => item.type == 2);

  const warehouseOptions = [{ value: '', label: `${t('page.select_warehouse_label')}` }];
  if (ownWarehouses !== undefined) {
    ownWarehouses.data.map((val) => {
      return warehouseOptions.push({
        value: val.warehouse_id,
        label: val.name
      });
    });
  }

  const handleViewContainerTypeCollectedClick = () => {
    setModalShowContainerTypeCollected(true);
  };

  const handleViewProducerCollectedClick = () => {
    setModalShowProducerCollected(true);
  };

  const handleViewContainerTypeCollectPendingClick = () => {
    setModalShowContainerTypeCollectPending(true);
  };

  const handleViewProducerCollectPendingClick = () => {
    setModalShowProducerCollectPending(true);
  };

  const handleViewStockClick = () => {
    setModalShowStock(true);
  };

  const handleViewOrderClick = () => {
    setModalShowOrder(true);
  };

  const pageIndexHandleStock = (pageIndex) => {
    setCurrentPageStock(pageIndex + 1);
  };

  const pageIndexHandleOrderBySameDay = (pageIndex) => {
    setCurrentPageOrderBySameDay(pageIndex + 1);
  };

  const pageIndexHandleOrderByNextDay = (pageIndex) => {
    setCurrentPageOrderByNextDay(pageIndex + 1);
  };

  const pageIndexHandleOrder = (pageIndex) => {
    setCurrentPageOrder(pageIndex + 1);
  };

  return (
    <>
      <div className="dashboard-page p-3" key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}>
        <div className="today-data-section dashboard-card">
          <Row className="p-3 align-items-center">
            <Col lg={6} className="mt-3 mt-lg-0">
              <span className="dashboard-label">{t('page.dashboard_today_text')}</span>
            </Col>
            <Col lg={3} className="mt-3 mt-lg-0">
              <Select
                className="filter-column"
                options={territoryIsLoad ? territoryOptions : territoryOptions}
                value={defaultValue(territoryOptions, territryId)}
                onChange={(selectedOption) => {
                  setTerritryId(selectedOption.value);
                  setAreaId([]);
                }}
              />
            </Col>
            <Col lg={3} className="mt-3 mt-lg-0">
              <Select
                className="filter-column"
                isMulti={true}
                options={areaIsLoad ? areaOptions : areaOptions}
                value={defaultMultiValue(areaOptions, areaId)}
                onChange={(selectedOption) => {
                  setAreaId(
                    selectedOption.map((item) => {
                      return item.value;
                    })
                  );
                }}
              />
            </Col>
          </Row>
        </div>
        <div className="items-collected">
          <Row className="py-3">
            <Col lg={6}>
              <div className="dashboard-card collected-card">
                <div className="p-3">
                  <span className="dashboard-label">
                    {t('page.collected_header_containers_type_collected')}
                  </span>
                  <Button
                    className="bg-transparent border-0"
                    style={{ float: 'right' }}
                    onClick={() => handleViewContainerTypeCollectedClick()}>
                    <svg
                      fill="#000000"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 52 52"
                      xmlSpace="preserve">
                      <g>
                        <path
                          d="M48.7,2H29.6C28.8,2,28,2.5,28,3.3v3C28,7.1,28.7,8,29.6,8h7.9c0.9,0,1.4,1,0.7,1.6l-17,17
		c-0.6,0.6-0.6,1.5,0,2.1l2.1,2.1c0.6,0.6,1.5,0.6,2.1,0l17-17c0.6-0.6,1.6-0.2,1.6,0.7v7.9c0,0.8,0.8,1.7,1.6,1.7h2.9
		c0.8,0,1.5-0.9,1.5-1.7v-19C50,2.5,49.5,2,48.7,2z"
                        />
                        <path
                          d="M36.3,25.5L32.9,29c-0.6,0.6-0.9,1.3-0.9,2.1v11.4c0,0.8-0.7,1.5-1.5,1.5h-21C8.7,44,8,43.3,8,42.5v-21
		C8,20.7,8.7,20,9.5,20H21c0.8,0,1.6-0.3,2.1-0.9l3.4-3.4c0.6-0.6,0.2-1.7-0.7-1.7H6c-2.2,0-4,1.8-4,4v28c0,2.2,1.8,4,4,4h28
		c2.2,0,4-1.8,4-4V26.2C38,25.3,36.9,24.9,36.3,25.5z"
                        />
                      </g>
                    </svg>
                  </Button>
                </div>
                <div className="dashboard-table px-3">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>{t('page.collected_th_container_name_size')}</th>
                        <th>{t('page.collected_th_container_quantity')}</th>
                        <th>{t('page.collected_th_weight_colleacted')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.data.collected_containers.length > 0 ? (
                        dashboardData?.data.collected_containers.map((key) => {
                          return (
                            <tr key={key.weight}>
                              <td>{key.label_size}</td>
                              <td>{key.count} </td>
                              <td>{key.weight}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={'3'}>{t('page.no_data_found_table')}</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="dashboard-card mt-4 mt-lg-0">
                <div className="p-3">
                  <span className="dashboard-label">
                    {t('page.collected_th_producers_collected')}
                  </span>
                  <Button
                    className="bg-transparent border-0"
                    style={{ float: 'right' }}
                    onClick={() => handleViewProducerCollectedClick()}>
                    <svg
                      fill="#000000"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 52 52"
                      xmlSpace="preserve">
                      <g>
                        <path
                          d="M48.7,2H29.6C28.8,2,28,2.5,28,3.3v3C28,7.1,28.7,8,29.6,8h7.9c0.9,0,1.4,1,0.7,1.6l-17,17
		c-0.6,0.6-0.6,1.5,0,2.1l2.1,2.1c0.6,0.6,1.5,0.6,2.1,0l17-17c0.6-0.6,1.6-0.2,1.6,0.7v7.9c0,0.8,0.8,1.7,1.6,1.7h2.9
		c0.8,0,1.5-0.9,1.5-1.7v-19C50,2.5,49.5,2,48.7,2z"
                        />
                        <path
                          d="M36.3,25.5L32.9,29c-0.6,0.6-0.9,1.3-0.9,2.1v11.4c0,0.8-0.7,1.5-1.5,1.5h-21C8.7,44,8,43.3,8,42.5v-21
		C8,20.7,8.7,20,9.5,20H21c0.8,0,1.6-0.3,2.1-0.9l3.4-3.4c0.6-0.6,0.2-1.7-0.7-1.7H6c-2.2,0-4,1.8-4,4v28c0,2.2,1.8,4,4,4h28
		c2.2,0,4-1.8,4-4V26.2C38,25.3,36.9,24.9,36.3,25.5z"
                        />
                      </g>
                    </svg>
                  </Button>
                </div>
                <div className="px-3 pb-3">
                  <Row>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_total_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_collected_total}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_programmed_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_collected_programmed}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_real_time_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_collected_real_time}</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="px-3 pb-3">
                  <span className="dashboard-label"> {t('page.collected_total_kg_collected')}</span>
                </div>
                <div className="px-3 pb-3">
                  <Row>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_total_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_collected_weight_total}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_programmed_text')}</span>
                        <div className="collect-count">
                          <span>
                            {dashboardData?.data.tour_producer_collected_weight_programmed}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_real_time_text')}</span>
                        <div className="collect-count">
                          <span>
                            {dashboardData?.data.tour_producer_collected_weight_real_time}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="items-collected">
          <Row>
            <Col lg={6}>
              <div className="dashboard-card collected-card">
                <div className="p-3">
                  <span className="dashboard-label">
                    {t('page.dashboard_containers_by_type_collect_pending')}
                  </span>
                  <Button
                    className="bg-transparent border-0"
                    style={{ float: 'right' }}
                    onClick={() => handleViewContainerTypeCollectPendingClick()}>
                    <svg
                      fill="#000000"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 52 52"
                      xmlSpace="preserve">
                      <g>
                        <path
                          d="M48.7,2H29.6C28.8,2,28,2.5,28,3.3v3C28,7.1,28.7,8,29.6,8h7.9c0.9,0,1.4,1,0.7,1.6l-17,17
		c-0.6,0.6-0.6,1.5,0,2.1l2.1,2.1c0.6,0.6,1.5,0.6,2.1,0l17-17c0.6-0.6,1.6-0.2,1.6,0.7v7.9c0,0.8,0.8,1.7,1.6,1.7h2.9
		c0.8,0,1.5-0.9,1.5-1.7v-19C50,2.5,49.5,2,48.7,2z"
                        />
                        <path
                          d="M36.3,25.5L32.9,29c-0.6,0.6-0.9,1.3-0.9,2.1v11.4c0,0.8-0.7,1.5-1.5,1.5h-21C8.7,44,8,43.3,8,42.5v-21
		C8,20.7,8.7,20,9.5,20H21c0.8,0,1.6-0.3,2.1-0.9l3.4-3.4c0.6-0.6,0.2-1.7-0.7-1.7H6c-2.2,0-4,1.8-4,4v28c0,2.2,1.8,4,4,4h28
		c2.2,0,4-1.8,4-4V26.2C38,25.3,36.9,24.9,36.3,25.5z"
                        />
                      </g>
                    </svg>
                  </Button>
                </div>
                <div className="dashboard-table px-3">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>{t('page.dashboard_th_container_name_size')}</th>
                        <th>{t('page.dashboard_th_quantity')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.data.pending_containers.length > 0 ? (
                        dashboardData?.data.pending_containers.map((key) => {
                          return (
                            <tr key={key.label_size}>
                              <td>{key.label_size}</td>
                              <td>{key.count} </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={'2'}>{t('page.no_data_found_table')}</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="dashboard-card mt-4 mt-lg-0">
                <div className="p-3">
                  <span className="dashboard-label">
                    {t('page.dashboard_total_producers_collect_pending')}
                  </span>
                  <Button
                    className="bg-transparent border-0"
                    style={{ float: 'right' }}
                    onClick={() => handleViewProducerCollectPendingClick()}>
                    <svg
                      fill="#000000"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 52 52"
                      xmlSpace="preserve">
                      <g>
                        <path
                          d="M48.7,2H29.6C28.8,2,28,2.5,28,3.3v3C28,7.1,28.7,8,29.6,8h7.9c0.9,0,1.4,1,0.7,1.6l-17,17
		c-0.6,0.6-0.6,1.5,0,2.1l2.1,2.1c0.6,0.6,1.5,0.6,2.1,0l17-17c0.6-0.6,1.6-0.2,1.6,0.7v7.9c0,0.8,0.8,1.7,1.6,1.7h2.9
		c0.8,0,1.5-0.9,1.5-1.7v-19C50,2.5,49.5,2,48.7,2z"
                        />
                        <path
                          d="M36.3,25.5L32.9,29c-0.6,0.6-0.9,1.3-0.9,2.1v11.4c0,0.8-0.7,1.5-1.5,1.5h-21C8.7,44,8,43.3,8,42.5v-21
		C8,20.7,8.7,20,9.5,20H21c0.8,0,1.6-0.3,2.1-0.9l3.4-3.4c0.6-0.6,0.2-1.7-0.7-1.7H6c-2.2,0-4,1.8-4,4v28c0,2.2,1.8,4,4,4h28
		c2.2,0,4-1.8,4-4V26.2C38,25.3,36.9,24.9,36.3,25.5z"
                        />
                      </g>
                    </svg>
                  </Button>
                </div>
                <div className="px-3 pb-3">
                  <Row>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_total_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_pending_total}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_programmed_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_pending_programmed}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_real_time_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_pending_real_time}</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="px-3 pb-3">
                  <span className="dashboard-label">
                    {t('page.dashboard_total_kg_collected_pending')}
                  </span>
                </div>
                <div className="px-3 pb-3">
                  <Row>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_total_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_pending_weight_total}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_programmed_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_pending_weight_programmed}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_real_time_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_pending_weight_real_time}</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="items-collected mb-lg-5">
          <Row className="py-3">
            <Col lg={6}>
              <div className="dashboard-card  mt-4 mt-lg-0">
                <div className="p-3">
                  <span className="dashboard-label">{t('page.stock_by_warehouse')}</span>
                  <Button
                    className="bg-transparent border-0"
                    style={{ float: 'right' }}
                    onClick={() => handleViewStockClick()}>
                    <svg
                      fill="#000000"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 52 52"
                      xmlSpace="preserve">
                      <g>
                        <path
                          d="M48.7,2H29.6C28.8,2,28,2.5,28,3.3v3C28,7.1,28.7,8,29.6,8h7.9c0.9,0,1.4,1,0.7,1.6l-17,17
		c-0.6,0.6-0.6,1.5,0,2.1l2.1,2.1c0.6,0.6,1.5,0.6,2.1,0l17-17c0.6-0.6,1.6-0.2,1.6,0.7v7.9c0,0.8,0.8,1.7,1.6,1.7h2.9
		c0.8,0,1.5-0.9,1.5-1.7v-19C50,2.5,49.5,2,48.7,2z"
                        />
                        <path
                          d="M36.3,25.5L32.9,29c-0.6,0.6-0.9,1.3-0.9,2.1v11.4c0,0.8-0.7,1.5-1.5,1.5h-21C8.7,44,8,43.3,8,42.5v-21
		C8,20.7,8.7,20,9.5,20H21c0.8,0,1.6-0.3,2.1-0.9l3.4-3.4c0.6-0.6,0.2-1.7-0.7-1.7H6c-2.2,0-4,1.8-4,4v28c0,2.2,1.8,4,4,4h28
		c2.2,0,4-1.8,4-4V26.2C38,25.3,36.9,24.9,36.3,25.5z"
                        />
                      </g>
                    </svg>
                  </Button>
                </div>
                <div className="px-3 pb-3">
                  <Row>
                    <Col lg={6}>
                      <Form.Group>
                        <Select
                          className="filter-column"
                          options={warehouseOptions}
                          value={defaultValue(warehouseOptions, warehouseId)}
                          onChange={(selectedOption) => {
                            setWarehouseId(selectedOption.value);

                            setCurrentPageStock(1);
                            setCurrentPageOrderBySameDay(1);
                            setCurrentPageOrderByNextDay(1);
                            setCurrentPageOrder(1);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className="current-stock-section mt-3">
                        <Container fluid>
                          <Slider {...settings}>
                            {stockByWarehouseData.data.current_stocks.map(function (item, i) {
                              return (
                                <div key={i} className="slider-section">
                                  <div className="slider-data">
                                    <p className="container-name m-0">{item.container_name}</p>
                                    <p className="container-size">
                                      {item.label_name + ' '}
                                      <span>{item.size_name}</span>
                                    </p>
                                    <p className="container-quantity">{item.current_quantity}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </Slider>
                        </Container>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="dashboard-card mt-4 mt-lg-0" style={{ height: '100%' }}>
                <div className="p-3">
                  <span className="dashboard-label">
                    {t('page.order_by_warehouse_and_delivery_type')}
                  </span>
                  <Button
                    className="bg-transparent border-0"
                    style={{ float: 'right' }}
                    onClick={() => handleViewOrderClick()}>
                    <svg
                      fill="#000000"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 52 52"
                      xmlSpace="preserve">
                      <g>
                        <path
                          d="M48.7,2H29.6C28.8,2,28,2.5,28,3.3v3C28,7.1,28.7,8,29.6,8h7.9c0.9,0,1.4,1,0.7,1.6l-17,17
		c-0.6,0.6-0.6,1.5,0,2.1l2.1,2.1c0.6,0.6,1.5,0.6,2.1,0l17-17c0.6-0.6,1.6-0.2,1.6,0.7v7.9c0,0.8,0.8,1.7,1.6,1.7h2.9
		c0.8,0,1.5-0.9,1.5-1.7v-19C50,2.5,49.5,2,48.7,2z"
                        />
                        <path
                          d="M36.3,25.5L32.9,29c-0.6,0.6-0.9,1.3-0.9,2.1v11.4c0,0.8-0.7,1.5-1.5,1.5h-21C8.7,44,8,43.3,8,42.5v-21
		C8,20.7,8.7,20,9.5,20H21c0.8,0,1.6-0.3,2.1-0.9l3.4-3.4c0.6-0.6,0.2-1.7-0.7-1.7H6c-2.2,0-4,1.8-4,4v28c0,2.2,1.8,4,4,4h28
		c2.2,0,4-1.8,4-4V26.2C38,25.3,36.9,24.9,36.3,25.5z"
                        />
                      </g>
                    </svg>
                  </Button>
                </div>
                <div className="px-3 pb-3">
                  <Row>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.order_same_day')}</span>
                        <div className="collect-count">
                          <span>{orderBySameDayData?.data.order_list.length}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.order_next_day')}</span>
                        <div className="collect-count">
                          <span>{orderByNextDayData?.data.order_list.length}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.order_all')}</span>
                        <div className="collect-count">
                          <span>{orderByAllData?.data.order_list.length}</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={modalShowContainerTypeCollected}
        fullscreen={true}
        onHide={() => setModalShowContainerTypeCollected(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('page.collected_header_containers_type_collected')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="items-collected">
          <div className="dashboard-page p-3" key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}>
            <div className="dashboard-card collected-card">
              <div className="dashboard-table px-3">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>{t('page.collected_th_container_name_size')}</th>
                      <th>{t('page.collected_th_container_quantity')}</th>
                      <th>{t('page.collected_th_weight_colleacted')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData?.data.collected_containers.length > 0 ? (
                      dashboardData?.data.collected_containers.map((key) => {
                        return (
                          <tr key={key.weight}>
                            <td>{key.label_size}</td>
                            <td>{key.count} </td>
                            <td>{key.weight}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={'3'}>{t('page.no_data_found_table')}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalShowProducerCollected}
        fullscreen={true}
        onHide={() => setModalShowProducerCollected(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('page.collected_th_producers_collected')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="dashboard-page p-3" key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}>
            <div className="items-collected">
              <div className="dashboard-card mt-4 mt-lg-0">
                <div className="px-3 pb-3">
                  <Row>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_total_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_collected_total}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_programmed_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_collected_programmed}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_real_time_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_collected_real_time}</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="px-3 pb-3">
                  <span className="dashboard-label"> {t('page.collected_total_kg_collected')}</span>
                </div>
                <div className="px-3 pb-3">
                  <Row>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_total_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_collected_weight_total}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_programmed_text')}</span>
                        <div className="collect-count">
                          <span>
                            {dashboardData?.data.tour_producer_collected_weight_programmed}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_real_time_text')}</span>
                        <div className="collect-count">
                          <span>
                            {dashboardData?.data.tour_producer_collected_weight_real_time}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalShowContainerTypeCollectPending}
        fullscreen={true}
        onHide={() => setModalShowContainerTypeCollectPending(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('page.dashboard_containers_by_type_collect_pending')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="items-collected">
          <div className="dashboard-page p-3" key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}>
            <div className="dashboard-card collected-card">
              <div className="dashboard-table px-3">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>{t('page.dashboard_th_container_name_size')}</th>
                      <th>{t('page.dashboard_th_quantity')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData?.data.pending_containers.length > 0 ? (
                      dashboardData?.data.pending_containers.map((key) => {
                        return (
                          <tr key={key.label_size}>
                            <td>{key.label_size}</td>
                            <td>{key.count} </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={'2'}>{t('page.no_data_found_table')}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalShowProducerCollectPending}
        fullscreen={true}
        onHide={() => setModalShowProducerCollectPending(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('page.dashboard_total_producers_collect_pending')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="dashboard-page p-3" key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}>
            <div className="items-collected">
              <div className="dashboard-card mt-4 mt-lg-0">
                <div className="px-3 pb-3">
                  <Row>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_total_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_pending_total}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_programmed_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_pending_programmed}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_real_time_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_pending_real_time}</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="px-3 pb-3">
                  <span className="dashboard-label">
                    {t('page.dashboard_total_kg_collected_pending')}
                  </span>
                </div>
                <div className="px-3 pb-3">
                  <Row>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_total_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_pending_weight_total}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_programmed_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_pending_weight_programmed}</span>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="items-collected-counts">
                        <span className="collect-label">{t('page.main_head_real_time_text')}</span>
                        <div className="collect-count">
                          <span>{dashboardData?.data.tour_producer_pending_weight_real_time}</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={modalShowStock} fullscreen={true} onHide={() => setModalShowStock(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('page.stock_by_warehouse')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="dashboard-card  mt-4 mt-lg-0">
            <div className="px-3 pb-3">
              <Row>
                <Col lg={3}>
                  <Form.Group>
                    <Select
                      className="filter-column"
                      options={warehouseOptions}
                      value={defaultValue(warehouseOptions, warehouseId)}
                      onChange={(selectedOption) => {
                        setWarehouseId(selectedOption.value);

                        setCurrentPageStock(1);
                        setCurrentPageOrderBySameDay(1);
                        setCurrentPageOrderByNextDay(1);
                        setCurrentPageOrder(1);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="current-stock-section mt-3">
                    <Container>
                      <Slider>
                        <div className="slider-section">
                          <div className="slider-data">
                            <p className="container-name m-0">{t('page.stock_total_label')}</p>
                            <p className="container-quantity">{sumStock}</p>
                          </div>
                        </div>
                      </Slider>
                    </Container>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="current-stock-section mt-3">
                    <Container fluid>
                      <Slider {...settings}>
                        {stockByWarehouseData.data.current_stocks.map(function (item, i) {
                          return (
                            <div key={i} className="slider-section">
                              <div className="slider-data">
                                <p className="container-name m-0">{item.container_name}</p>
                                <p className="container-size">
                                  {item.label_name + ' '}
                                  <span>{item.size_name}</span>
                                </p>
                                <p className="container-quantity">{item.current_quantity}</p>
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    </Container>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="current-stock-section mt-3">
                    <Container>
                      <Slider {...inOutSettings}>
                        <div className="slider-section">
                          <div className="slider-data">
                            <p className="container-name m-0">{t('page.stock_in_today')}</p>
                            <p className="container-quantity">{inStock.length}</p>
                          </div>
                        </div>
                        <div className="slider-section">
                          <div className="slider-data">
                            <p className="container-name m-0">{t('page.stock_out_today')}</p>
                            <p className="container-quantity">{outStock.length}</p>
                          </div>
                        </div>
                      </Slider>
                    </Container>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={modalShowOrder} fullscreen={true} onHide={() => setModalShowOrder(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('page.order_by_warehouse_and_delivery_type')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>{t('page.order_same_day')}</h2>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_order_no_and_date_label')
                    }}></span>
                </th>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_territory_and_area_label')
                    }}></span>
                </th>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_own_warehouse_label')
                    }}></span>
                </th>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_producer_id_name_label')
                    }}></span>
                </th>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_delivery_type_label')
                    }}></span>
                </th>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_status_label')
                    }}></span>
                </th>
              </tr>
            </thead>
            <tbody>
              {orderBySameDayData?.data.order_list.length > 0 ? (
                orderBySameDayData?.data.order_list.map((key) => {
                  return (
                    <tr key={key.order_id}>
                      <td>
                        {key.order_number}
                        <br />
                        {key.created_at}
                      </td>
                      <td>
                        {key.territory_name}
                        <br />
                        {key.area_name}
                      </td>
                      <td>{key.warehouse_name}</td>
                      <td>
                        {key.producer_id}
                        <br />
                        {key.producer_name}
                      </td>
                      <td>{key.delivery_type}</td>
                      <td>{key.status_update_date}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={'6'}>{t('page.no_data_found_table')}</td>
                </tr>
              )}
            </tbody>
          </Table>
          {/*<TNTable*/}
          {/*  columns={ordercolumns}*/}
          {/*  data={dataAllOrderBySameDay}*/}
          {/*  paginationData={paginationDataOrderBySameDay}*/}
          {/*  onSelectPage={pageIndexHandleOrderBySameDay()}*/}
          {/*  pageIndexGet={currentPageOrderBySameDay - 1}*/}
          {/*  t={t}*/}
          {/*  idName="order_list_same_day"*/}
          {/*  key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}*/}
          {/*/>*/}

          <h2>{t('page.order_next_day')}</h2>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_order_no_and_date_label')
                    }}></span>
                </th>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_territory_and_area_label')
                    }}></span>
                </th>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_own_warehouse_label')
                    }}></span>
                </th>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_producer_id_name_label')
                    }}></span>
                </th>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_delivery_type_label')
                    }}></span>
                </th>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_status_label')
                    }}></span>
                </th>
              </tr>
            </thead>
            <tbody>
              {orderByNextDayData?.data.order_list.length > 0 ? (
                orderByNextDayData?.data.order_list.map((key) => {
                  return (
                    <tr key={key.order_id}>
                      <td>
                        {key.order_number}
                        <br />
                        {key.created_at}
                      </td>
                      <td>
                        {key.territory_name}
                        <br />
                        {key.area_name}
                      </td>
                      <td>{key.warehouse_name}</td>
                      <td>
                        {key.producer_id}
                        <br />
                        {key.producer_name}
                      </td>
                      <td>{key.delivery_type}</td>
                      <td>{key.status_update_date}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={'6'}>{t('page.no_data_found_table')}</td>
                </tr>
              )}
            </tbody>
          </Table>
          {/*<TNTable*/}
          {/*  columns={ordercolumns}*/}
          {/*  data={dataAllOrderByNextDay}*/}
          {/*  paginationData={paginationDataOrderByNextDay}*/}
          {/*  onSelectPage={pageIndexHandleOrderByNextDay()}*/}
          {/*  pageIndexGet={currentPageOrderByNextDay - 1}*/}
          {/*  t={t}*/}
          {/*  idName="order_list_next_day"*/}
          {/*  key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}*/}
          {/*/>*/}

          <h2>{t('page.order_all')}</h2>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_order_no_and_date_label')
                    }}></span>
                </th>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_territory_and_area_label')
                    }}></span>
                </th>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_own_warehouse_label')
                    }}></span>
                </th>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_producer_id_name_label')
                    }}></span>
                </th>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_delivery_type_label')
                    }}></span>
                </th>
                <th>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('page.order_status_label')
                    }}></span>
                </th>
              </tr>
            </thead>
            <tbody>
              {orderByAllData?.data.order_list.length > 0 ? (
                orderByAllData?.data.order_list.map((key) => {
                  return (
                    <tr key={key.order_id}>
                      <td>
                        {key.order_number}
                        <br />
                        {key.created_at}
                      </td>
                      <td>
                        {key.territory_name}
                        <br />
                        {key.area_name}
                      </td>
                      <td>{key.warehouse_name}</td>
                      <td>
                        {key.producer_id}
                        <br />
                        {key.producer_name}
                      </td>
                      <td>{key.delivery_type}</td>
                      <td>{key.status_update_date}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={'6'}>{t('page.no_data_found_table')}</td>
                </tr>
              )}
            </tbody>
          </Table>
          {/*<TNTable*/}
          {/*  columns={ordercolumns}*/}
          {/*  data={dataAllOrde}*/}
          {/*  paginationData={paginationDataOrder}*/}
          {/*  onSelectPage={pageIndexHandleOrder()}*/}
          {/*  pageIndexGet={currentPageOrder - 1}*/}
          {/*  t={t}*/}
          {/*  idName="order_list_all"*/}
          {/*  key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}*/}
          {/*/>*/}
        </Modal.Body>
      </Modal>
    </>
  );
};

MultiScreenPage.propTypes = {
  t: PropTypes.func
};

export default MultiScreenPage;
