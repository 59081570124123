import Sort from '@common/components/Sorting';
import { TNButton } from '@common/components/TNButton';
import TNTable from '@common/components/TNTable';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertToPlainText, dateFormatCommon, dateFormatManualAdd } from '@helpers/commonHelpers';
import {
  useGenerateProducerReport,
  useGetAreaData,
  useGetTerritoryData,
  useGetWestCodeData,
  useListProducer,
  useProducerTypeList
} from '@hooks';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import Checkbox from '../../common/components/Checkbox';
import TabProducerNavigation from './components/TabProducerNavigation';

const ListProducerPage = ({ t }) => {
  const navigate = useNavigate();
  const [modalShowDownload, setModalShowDownload] = useState(false);

  /**
   * !This Block is for status drop down
   */
  const options = [
    { value: '', label: `${t('page.select_status')}` },
    { value: '1', label: t('page.contract_status_option_pending') },
    { value: '2', label: t('page.contract_status_option_inreview') },
    { value: '3', label: t('page.contract_status_option_active') },
    { value: '4', label: t('page.contract_status_option_hold') },
    { value: '5', label: t('page.contract_status_option_suspended') },
    { value: '6', label: t('page.contract_status_option_terminated') }
  ];

  /**
   * !This Block is for Collection drop down
   */
  const collectionTypes = [
    { value: '', label: `${t('page.select_collection_type')}` },
    { value: '1', label: t('page.add_producer_collect_details_type_collection_option_1') },
    { value: '2', label: t('page.add_producer_collect_details_type_collection_option_2') }
  ];

  /**
   * !This Block is for Date Type drop down
   */
  const dateTypes = [
    { value: '', label: `${t('page.select_date_type')}` },
    { value: 'created_at', label: t('page.created_at_date_type') },
    { value: 'contract_start_date', label: t('page.contract_start_date_type') },
    { value: 'contract_end_date', label: t('page.contract_end_date_type') }
  ];

  const sortByExport = [
    {
      value: 'created_at',
      label: `${t('page.producer_created_at_producer_id_label')}`
    },
    { value: 'name', label: `${t('page.producer_name')}` },
    {
      value: 'contract_start_date',
      label: `${t('page.producer_contract_detail_label')}`
    },
    {
      value: 'territory_name',
      label: `${t('page.producer_territory_area_label')}`
    },
    {
      value: 'waste_code',
      label: `${t('page.producer_waste_code_label')}`
    },
    {
      value: 'producer_type_name',
      label: `${t('page.producer_producer_type_label')}`
    },
    {
      value: 'type_collection_name',
      label: `${t('page.producer_collection_type_label')}`
    },
    {
      value: 'contract_status_name',
      label: `${t('page.producer_status_label')}`
    }
  ];

  const sortOrderExport = [
    { value: 'asc', label: `${t('page.asccending')}` },
    { value: 'desc', label: `${t('page.desccending')}` }
  ];

  const groupByExport = [
    { value: 'producer_type', label: `${t('page.type')}` },
    { value: 'status', label: `${t('page.status')}` },
    { value: 'collection_type', label: `${t('page.collection_type')}` },
    { value: 'territory', label: `${t('page.territory')}` },
    { value: 'area', label: `${t('page.area')}` },
    { value: 'waste_code', label: `${t('page.waste_code')}` }
  ];

  /**
   * setting states for preserve search
   */
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    localStorage.producerTable !== undefined && localStorage.producerTable !== ''
      ? JSON.parse(localStorage.producerTable).currentPage
      : 1
  );
  const [searchText, setSearchText] = useState(
    localStorage.producerTable !== undefined && localStorage.producerTable !== ''
      ? JSON.parse(localStorage.producerTable).searchText
      : ''
  );

  const [searchTextFilter, setSearchTextFilter] = useState(
    localStorage.producerTable !== undefined && localStorage.producerTable !== ''
      ? JSON.parse(localStorage.producerTable).searchTextFilter
      : ''
  );

  const [paginationData, setPaginationData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(
    localStorage.producerTable !== undefined && localStorage.producerTable !== ''
      ? JSON.parse(localStorage.producerTable).selectedStatus
      : ''
  );

  const [fromDate, setFromDate] = useState(
    localStorage.producerTable !== undefined &&
      localStorage.producerTable !== '' &&
      JSON.parse(localStorage.producerTable).fromDate !== ''
      ? new Date(JSON.parse(localStorage.producerTable).fromDate)
      : ''
  );
  const [toDate, setToDate] = useState(
    localStorage.producerTable !== undefined &&
      localStorage.producerTable !== '' &&
      JSON.parse(localStorage.producerTable).toDate !== ''
      ? new Date(JSON.parse(localStorage.producerTable).toDate)
      : ''
  );
  const [producerTypeID, setProducerTypeID] = useState(
    localStorage.producerTable !== undefined && localStorage.producerTable !== ''
      ? JSON.parse(localStorage.producerTable).producerTypeID
      : ''
  );
  const [selectedCollection, setSelectedCollection] = useState(
    localStorage.producerTable !== undefined && localStorage.producerTable !== ''
      ? JSON.parse(localStorage.producerTable).selectedCollection
      : ''
  );
  const [territryId, setTerritryId] = useState(
    localStorage.producerTable !== undefined && localStorage.producerTable !== ''
      ? JSON.parse(localStorage.producerTable).territryId
      : ''
  );
  const [areaId, setAreaId] = useState(
    localStorage.producerTable !== undefined && localStorage.producerTable !== ''
      ? JSON.parse(localStorage.producerTable).areaId
      : ''
  );
  const [wasteCode, setWasteCode] = useState(
    localStorage.producerTable !== undefined && localStorage.producerTable !== ''
      ? JSON.parse(localStorage.producerTable).wasteCode
      : ''
  );
  const [selectedDateType, setSelectedDateType] = useState(
    localStorage.producerTable !== undefined && localStorage.producerTable !== ''
      ? JSON.parse(localStorage.producerTable).selectedDateType
      : ''
  );

  const [sortBy, setSortBy] = useState(
    localStorage.producerTable !== undefined && localStorage.producerTable !== ''
      ? JSON.parse(localStorage.producerTable).sortBy
      : 'created_at'
  );

  const [sortOrder, setSortOrder] = useState(
    localStorage.producerTable !== undefined && localStorage.producerTable !== ''
      ? JSON.parse(localStorage.producerTable).sortOrder
      : 'desc'
  );

  const [groupBy, setGroupBy] = useState(
    localStorage.producerTable !== undefined && localStorage.producerTable !== ''
      ? JSON.parse(localStorage.producerTable).sortOrder
      : ''
  );

  /**
   * !This Function will call when user clicks on Edit Button
   * @param {*} tdata which is current element of button
   */
  const handleEditClick = (tdata) => {
    let producerUser = tdata.currentTarget.getAttribute('producer_user');
    let producer_id = tdata.currentTarget.getAttribute('producer_id');

    if (producerUser === null) {
      toast.error('User Belum diset, mohon tambahkan user terlebih dahulu');
      return;
    }
    navigate(`/producer/edit/${producer_id}`);
  };

  /**
   * !This Function will call when user clicks on View Button
   * @param {*} tdata which is current element of button
   */
  const handleViewClick = (tdata) => {
    let producerUser = tdata.currentTarget.getAttribute('producer_user');
    let producer_id = tdata.currentTarget.getAttribute('producer_id');

    if (producerUser === null) {
      toast.error('User Belum diset, mohon tambahkan user terlebih dahulu');
      return;
    }
    navigate(`/producer/view/${producer_id}`);
  };

  /**
   * !This block will call on click clear date
   */
  const clearDateFilter = (date) => {
    setFromDate('');
    setToDate('');
    setSelectedDateType('');
  };

  useEffect(() => {
    let producerTable = {
      searchText: searchText,
      searchTextFilter: searchTextFilter,
      currentPage: currentPage,
      selectedStatus: selectedStatus,
      fromDate: fromDate,
      toDate: toDate,
      producerTypeID: producerTypeID,
      selectedCollection: selectedCollection,
      territryId: territryId,
      areaId: areaId,
      wasteCode: wasteCode,
      selectedDateType: selectedDateType,
      sortBy: sortBy,
      sortOrder: sortOrder
    };
    localStorage.producerTable = JSON.stringify(producerTable);
  }, [
    currentPage,
    searchText,
    selectedStatus,
    fromDate,
    toDate,
    producerTypeID,
    selectedCollection,
    territryId,
    areaId,
    wasteCode,
    selectedDateType,
    searchTextFilter,
    sortBy,
    sortOrder
  ]);

  const handleSort = (name) => {
    setSortBy(name);
    sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc');
  };

  // !From this api we are getting Producer list.
  const { isLoading: producerIsLoad, data: producerList } = useProducerTypeList();
  const { mutate: doGenerateReport, isLoading: reportGenerateLoading } = useGenerateProducerReport(
    (response) => {
      toast.success(response.message);
      download(response.data.report_url);
      setModalShowDownload(false);
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );

  const download = (image) => {
    const fileName = image.split('/').pop();
    image = image.replace(window.origin, '');
    var link = document.createElement('a');
    link.href = image;
    link.target = '_blank';
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click'));
  };

  const downloadApiCall = (type) => {
    const values = {};
    values.report_type = type;
    values.search_text = searchText;
    values.by_status = selectedStatus;
    values.from_date = fromDate !== '' ? moment(fromDate).format('YYYY-MM-DD') : '';
    values.to_date = toDate !== '' ? moment(toDate).format('YYYY-MM-DD') : '';
    values.producer_id = producerTypeID;
    values.selected_collection = selectedCollection;
    values.territory_id = territryId;
    values.area_id = areaId;
    values.waste_code = wasteCode;
    values.date_type = selectedDateType;
    // values.timezone_id = currentTimeZoneId.timezone_id;
    values.sort_by = sortBy;
    values.sort_order = sortOrder;
    values.group_by = groupBy;
    values.column_export = JSON.stringify(isCheck);

    doGenerateReport(values);
  };
  /**
   * !This Api is for producer drop down
   */
  const producer_options = [];
  producer_options.push({
    value: '',
    label: t('page.select_producer_type')
  });
  if (producerList !== undefined) {
    producerList.data.map((val) => {
      return producer_options.push({
        value: val.producer_type_id,
        label: val.name
      });
    });
  }

  /**
   * !This Api is for Terriotory drop down
   */
  const { isLoading: territoryIsLoad, data: TerritoryList } = useGetTerritoryData();
  const TerritoryOptions = [];
  TerritoryOptions.push({
    value: '',
    label: t('page.select_territory')
  });
  if (TerritoryList !== undefined) {
    TerritoryList.data.map((val) => {
      return TerritoryOptions.push({
        value: val.territory_id,
        label: val.name
      });
    });
  }

  /**
   * !This Api is for Area drop down
   */
  const { isLoading: areaIsLoad, data: AreaList } = useGetAreaData(territryId);
  const AreaOptions = [];
  AreaOptions.push({
    value: '',
    label: t('page.select_area')
  });
  if (AreaList !== undefined) {
    AreaList.data.map((val) => {
      return AreaOptions.push({
        value: val.area_id,
        label: val.area_name
      });
    });
  }

  /**
   * !This Api is for wastecode drop down
   */
  const { isLoading: wastecodeIsLoad, data: WestCodeList } = useGetWestCodeData();
  const WestCodeOptions = [];
  WestCodeOptions.push({
    value: '',
    label: t('page.select_waste_code')
  });
  if (WestCodeList !== undefined) {
    WestCodeList.data.map((val) => {
      return WestCodeOptions.push({
        value: val.waste_code_id,
        label: val.number
      });
    });
  }

  const columnsjson = [
    {
      Header: (
        <div
          onClick={() => {
            handleSort('created_at');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.producer_created_at_producer_id_label')
            }}></span>
          <Sort isActive={sortBy === 'created_at' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'created_at',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return <div>{row.original.created_at}</div>;
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('name');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.producer_name_id_label')
            }}></span>
          <Sort isActive={sortBy === 'name' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'name',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.producer_id} <br />
            {row.original.name}
          </div>
        );
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('contract_start_date');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.producer_contract_detail_label')
            }}></span>
          <Sort isActive={sortBy === 'contract_start_date' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'contract_start_date',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.contract_start_date}
            <br />
            {row.original.contract_end_date}
          </div>
        );
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('territory_name');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.producer_territory_area_label')
            }}></span>
          <Sort isActive={sortBy === 'territory_name' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'territory_name',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.territory_name}
            <br />
            {row.original.area_name}
          </div>
        );
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('waste_code');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: 'Kode Limbah'
            }}></span>
          <Sort isActive={sortBy === 'waste_code' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'waste_code_number',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) =>
        row.original.waste_codes?.map((c, i) => (
          <a key={i} className="m-1" href={`wastecode/view/${c.waste_code_id}`}>
            {c.number}
          </a>
        ))
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('producer_type_name');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.producer_producer_type_label')
            }}></span>
          <Sort isActive={sortBy === 'producer_type_name' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'producer_type_name',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return <div>{row.original.producer_type_name}</div>;
      }
    },
    // {
    //   Header: (
    //     <div
    //       onClick={() => {
    //         handleSort('type_collection_name');
    //       }}
    //       className="sorting-main-div">
    //       <span
    //         className="me-2"
    //         dangerouslySetInnerHTML={{
    //           __html: t('page.producer_collection_type_label')
    //         }}></span>
    //       <Sort isActive={sortBy === 'type_collection_name' ? true : false} orderType={sortOrder} />
    //     </div>
    //   ),
    //   accessor: 'type_collection',
    //   disableSortBy: true,
    //   Cell: ({ value: initialValue, row }) => {
    //     return (
    //       <div>
    //         {row.original.type_collection}
    //         <br />
    //         {row.original.collect_frequence_type}
    //       </div>
    //     );
    //   }
    // },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('default_transporter');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('Transporter')
            }}></span>
          <Sort isActive={sortBy === 'default_transporter'} orderType={sortOrder} />
        </div>
      ),
      accessor: 'default_transporter',
      disableSortBy: true
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('contract_status_name');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.producer_status_label')
            }}></span>
          <Sort isActive={sortBy === 'contract_status_name' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'contract_status_name',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        let onHold = '';

        if (row.original.on_hold !== '') {
          onHold = JSON.parse(row.original.on_hold);
        }

        return (
          <>
            <span
              className={row.original.contract_status === 3 ? 'status-active' : 'status-inactive'}>
              {t(initialValue)}
            </span>

            {onHold !== '' && onHold.status === 1 ? (
              onHold.start_date && onHold.end_date ? (
                <div style={{ position: 'relative' }}>
                  <a
                    className="on-hold"
                    data-tooltip-id={row.original.producer_id}
                    data-tooltip-content={onHold.start_date + ' - ' + onHold.end_date}>
                    On Hold
                  </a>
                  <Tooltip id={row.original.producer_id} />
                </div>
              ) : (
                <div style={{ position: 'relative' }}>
                  <a className="on-hold">On Hold</a>
                </div>
              )
            ) : (
              ''
            )}
          </>
        );
      }
    },
    {
      Header: `${t('page.action_column')}`,
      accessor: 'producer_id',
      Cell: ({ value: initialValue, row }) => {
        return (
          <div className="action_btn">
            <TNButton
              className="table-primary-button"
              producer_id={initialValue}
              producer_user={row.original.producer_user}
              onClick={handleViewClick.bind(this)}>
              {t('page.action_button_text_view')}
            </TNButton>
            <TNButton
              className="table-primary-button"
              producer_id={initialValue}
              producer_user={row.original.producer_user}
              onClick={handleEditClick.bind(this)}>
              {t('page.action_button_text_edit')}
            </TNButton>
          </div>
        );
      },
      disableSortBy: true
    }
  ];

  const [exportLabels, setExportLabels] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  /**
   * !This API will call while Page Load and set data. Once data load we are updating State.
   */
  useListProducer(
    [
      currentPage,
      searchTextFilter,
      selectedStatus,
      fromDate !== '' ? moment(fromDate).format('YYYY-MM-DD') : '',
      toDate !== '' ? moment(toDate).format('YYYY-MM-DD') : '',
      producerTypeID,
      selectedCollection,
      territryId,
      areaId,
      wasteCode,
      selectedDateType,
      sortBy,
      sortOrder
    ],
    (res) => {
      // let data = res.data.producer_listing[0];
      // let columns = [];
      // let columnInclude = [
      //   'created_at',
      //   'name',
      //   'contract_start_date',
      //   'contract_end_date',
      //   'area_name',
      //   'waste_code_number',
      //   'waste_code_name',
      //   'producer_type_name'
      // ];

      // for (let x in data) {
      //   if (columnInclude.includes(x)) columns.push(x);
      // }

      setExportLabels([
        'page.producer_created_at_producer_id_label',
        'page.add_producer_general_details_producer_name_label',
        'page.add_producer_general_details_npwp_number_label',
        'page.add_producer_general_details_contact_number_label',
        'page.add_producer_general_details_producer_type_label',
        'page.add_producer_general_details_address_label',
        'page.notes_label',
        'page.add_producer_general_details_producer_country',
        'page.add_producer_general_details_producer_province',
        'page.add_producer_general_details_producer_city',
        'page.add_producer_general_details_producer_postcode',
        'page.add_producer_general_details_producer_contract_status',
        'page.add_producer_is_buy_container_label',
        'page.add_producer_is_collect_container_label',
        'page.add_producer_collect_details_territory_name_label',
        'page.add_producer_collect_details_area_name_label',
        'page.add_producer_collect_details_type_collection_label',
        'page.add_producer_collect_details_collect_frequence_type_label',
        'page.add_producer_collect_details_waste_code_id_label',
        'page.add_producer_collect_details_producer_current_stock_label',
        'page.add_producer_collect_details_collection_address_label',
        'page.notes_label',
        'page.add_producer_contact_details_contract_number_label',
        'page.add_producer_contract_details_contract_start_date_label',
        'page.add_producer_contract_details_contract_end_date_label',
        'page.add_producer_contract_details_contract_agent_id_label',
        'page.add_producer_owner_first_name_label',
        'page.add_producer_owner_last_name_label',
        'page.add_producer_owner_email_label',
        'page.add_producer_owner_details_owner_phone_label',
        'page.add_producer_owner_details_owner_ktp_label',
        'page.add_producer_person_incharge_first_name_label',
        'page.add_producer_person_incharge_last_name_label',
        'page.add_producer_person_incharge_email_label',
        'page.add_producer_person_incharge_phone_label',
        'page.add_producer_accounting_first_name_label',
        'page.add_producer_accounting_last_name_label',
        'page.add_producer_accounting_email_label',
        'page.add_producer_accounting_phone_label'
      ]);

      setExportColumns([
        //Detail general
        'producer_create_at',
        'producer_name',
        'producer_npwp',
        'producer_contact_number',
        'producer_type', //from producer_type
        'producer_address',
        'producer_address_notes',
        'producer_country', //from countries
        'producer_province',
        'producer_city',
        'producer_postcode',
        'producer_contract_status',
        'producer_is_buy_container',
        'producer_is_collect_container',
        'producer_teritory', //from teritories
        'producer_area', //from areas

        //Detail business collect
        'producer_collection_type',
        'producer_collection_frequence',
        'producer_collection_waste_code', //from wastes
        'producer_current_stock',
        'producer_collection_address',
        'producer_collection_address_note',

        //Detail contract
        'producer_contract_number',
        'producer_contract_start',
        'producer_contract_end',
        'producer_contract_agent',

        //Detail owner
        'producer_owner_first_name',
        'producer_owner_last_name',
        'producer_owner_email',
        'producer_owner_contact',
        'producer_owner_ktp',

        //Detail PIC
        'producer_pic_first_name',
        'producer_pic_last_name',
        'producer_pic_email',
        'producer_pic_contact',

        //Detail Accounting
        'producer_accounting_first_name',
        'producer_accounting_last_name',
        'producer_accounting_email',
        'producer_accounting_contact'
      ]);

      setIsCheck(exportColumns);

      res.data.producer_listing.filter(
        (producer) =>
          producer.name !== '-' && producer.owner_email !== '-' && producer.owner_phone !== '-'
      );
      setData(res.data.producer_listing);
      setPaginationData(res.data.pagination);
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => columnsjson, [sortBy, sortOrder]);
  if (paginationData == null) {
    return null;
  }

  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
  };

  const handleSeach = (search_text) => {
    setCurrentPage(1);
    setSearchText(search_text.target.value);
    if (search_text.target.value.length >= 3) {
      setSearchTextFilter(search_text.target.value);
    } else {
      setSearchTextFilter('');
    }
  };

  const handleClearFilter = () => {
    setSearchText('');
    setSearchTextFilter('');
    setCurrentPage(1);
    setSelectedStatus('');
    setProducerTypeID('');
    setSelectedCollection('');
    setTerritryId('');
    setAreaId('');
    setWasteCode('');
    setSortBy('created_at');
    setSortOrder('desc');
    localStorage.removeItem('producerTable');
  };

  const addAgentHandle = () => {
    navigate(`/producer/add`);
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(exportColumns.map((li) => li));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleExportColumnChecked = (e) => {
    const { id, checked } = e.target;

    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  return (
    <>
      <TabProducerNavigation />
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group controlId="search" className="filter-field-space">
              <Form.Control
                placeholder={t('page.search_text')}
                className="filter-column form-field"
                onKeyUp={handleSeach}
                onChange={handleSeach}
                value={searchText}
              />
            </Form.Group>

            <Button type="button" onClick={handleClearFilter} className="secondary-remove-button">
              {t('page.cancel_search_button')}
            </Button>
            <TNButton
              className="table-add-button"
              loading={false}
              type="button"
              onClick={addAgentHandle}>
              {t('page.add_producer_title')}
            </TNButton>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={producerIsLoad ? producer_options : producer_options}
                value={producer_options.filter((option) => option.value === producerTypeID)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setProducerTypeID(selectedOption.value);
                }}
              />
            </Form.Group>

            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={options}
                value={options.filter((option) => option.value === selectedStatus)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setSelectedStatus(selectedOption.value);
                }}
              />
            </Form.Group>

            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={collectionTypes}
                value={collectionTypes.filter((option) => option.value === selectedCollection)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setSelectedCollection(selectedOption.value);
                }}
              />
            </Form.Group>

            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                placeholder={t('page.terrritory_select_placeholder')}
                options={territoryIsLoad ? TerritoryOptions : TerritoryOptions}
                value={TerritoryOptions.filter((option) => option.value === territryId)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setTerritryId(selectedOption.value);
                }}
              />
            </Form.Group>

            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={areaIsLoad ? AreaOptions : AreaOptions}
                value={AreaOptions.filter((option) => option.value === areaId)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setAreaId(selectedOption.value);
                }}
              />
            </Form.Group>

            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={wastecodeIsLoad ? WestCodeOptions : WestCodeOptions}
                value={WestCodeOptions.filter((option) => option.value === wasteCode)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setWasteCode(selectedOption.value);
                }}
              />
            </Form.Group>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={dateTypes}
                value={dateTypes.filter((option) => option.value === selectedDateType)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setSelectedDateType(selectedOption.value);
                }}
              />
            </Form.Group>

            <div
              className={selectedDateType !== '' ? '' : 'd-none'}
              style={{ display: 'contents' }}>
              <Form.Label className="field-label date-picker-label">
                {t('page.users_from_date_label')}
              </Form.Label>
              <Form.Group className="filter-field-space">
                <DatePicker
                  onKeyDown={dateFormatManualAdd}
                  dropdownMode="select"
                  showMonthDropdown
                  showYearDropdown
                  adjustDateOnChange
                  className="form-control filter-column"
                  placeholderText={dateFormatCommon().toUpperCase()}
                  selected={fromDate}
                  dateFormat={dateFormatCommon()}
                  onChange={(date) => setFromDate(date)}
                  maxDate={toDate !== '' && toDate !== undefined ? toDate : new Date()}
                  onBlur={(date) => {
                    var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                    if (date.currentTarget.value) {
                      if (!date1.isValid()) {
                        toast.error(t('page.invalid_date_format'));
                        setFromDate('');
                      } else {
                        var maxDate = toDate !== '' && toDate !== undefined ? toDate : new Date();
                        if (date1 > maxDate) {
                          toast.error(t('page.from_date_less_than_to_Date'));
                          setFromDate('');
                        }
                      }
                    }
                  }}
                />
              </Form.Group>
              <Form.Label className="field-label date-picker-label">
                {t('page.users_to_date_label')}
              </Form.Label>
              <Form.Group className="filter-field-space">
                <DatePicker
                  onKeyDown={dateFormatManualAdd}
                  dropdownMode="select"
                  showMonthDropdown
                  showYearDropdown
                  adjustDateOnChange
                  className="form-control filter-column"
                  placeholderText={dateFormatCommon().toUpperCase()}
                  minDate={fromDate}
                  selected={toDate}
                  dateFormat={dateFormatCommon()}
                  onChange={(date) => setToDate(date)}
                  onBlur={(date) => {
                    var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                    if (date.currentTarget.value) {
                      if (!date1.isValid()) {
                        toast.error(t('page.invalid_date_format'));
                        setToDate('');
                      } else {
                        var minDate = fromDate !== '' && fromDate !== undefined ? fromDate : date1;
                        if (date1 < minDate) {
                          toast.error(t('page.to_date_greater_than_from_Date'));
                          setToDate('');
                        }
                      }
                    }
                  }}
                />
              </Form.Group>
            </div>
            <TNButton
              className="secondary-add-button"
              loading={false}
              type="button"
              onClick={clearDateFilter}>
              {t('page.user_list_clear_date_filter_button')}
            </TNButton>
            <TNButton
              className="table-add-button ml-0"
              loading={false}
              type="button"
              onClick={() => {
                setModalShowDownload(true);
              }}>
              {t('page.payout_producer_download_button')}
            </TNButton>
          </div>
        </Col>
      </Row>
      <h1>{t('page.producer_list_label')}</h1>
      <TNTable
        columns={columns}
        data={data}
        paginationData={paginationData}
        onSelectPage={pageIndexHandle}
        pageIndexGet={currentPage - 1}
        t={t}
        idName="producer_list"
        key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
      />
      <Modal
        show={modalShowDownload}
        aria-labelledby="contained-modal-title-vcenter"
        size={'xl'}
        centered>
        <Modal.Header className="text-center">
          <h1>{t('page.producer_select_download_button')}</h1>
          <div className="close-popup">
            <FontAwesomeIcon
              icon={faClose}
              onClick={() => {
                setModalShowDownload(false);
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-5">
              <div className="select-all-bar">
                <Checkbox
                  type="checkbox"
                  id="selectAll"
                  name="SelectAll"
                  label={t('page.select_all')}
                  handleClick={handleSelectAll}
                  isChecked={isCheckAll}
                  style={{ textAlign: 'left' }}
                />
              </div>
              <div
                style={{ height: '200px', overflowY: 'scroll', border: '1px solid #ced4da' }}
                className="mb-5">
                {exportColumns.map((exportColumn, index) => {
                  return (
                    <Checkbox
                      key={index}
                      type="checkbox"
                      name={exportColumn}
                      label={convertToPlainText(t(exportLabels[index]))}
                      id={exportColumn}
                      handleClick={handleExportColumnChecked}
                      isChecked={isCheck.includes(exportColumn)}
                      style={{ textAlign: 'left', paddingLeft: '5px' }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="col-4">
              <Form.Label className="field-label date-picker-label">
                {t('page.short_by_label')}
              </Form.Label>
              <div className="row">
                <div className="col-9">
                  <Form.Group>
                    <Select
                      className="filter-column"
                      options={sortByExport.map((option) => {
                        return { value: option.value, label: convertToPlainText(option.label) };
                      })}
                      value={sortByExport.filter((option) => option.value === sortBy)}
                      onChange={(selectedOption) => {
                        setSortBy(selectedOption.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-3">
                  <Form.Group>
                    <Select
                      className="filter-column"
                      options={sortOrderExport.map((option) => {
                        return { value: option.value, label: convertToPlainText(option.label) };
                      })}
                      value={sortOrderExport.filter((option) => option.value === sortOrder)}
                      onChange={(selectedOption) => {
                        setSortOrder(selectedOption.value);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>

              <Form.Label className="field-label date-picker-label mt-3">
                {t('page.group_by_label')}
              </Form.Label>
              <Form.Group>
                <Select
                  className="filter-column mb-3"
                  options={groupByExport}
                  value={groupByExport.filter((option) => option.value === groupBy)}
                  onChange={(selectedOption) => {
                    setGroupBy(selectedOption.value);
                  }}
                />
              </Form.Group>

              <div
                className={groupBy === 'producer_type' ? '' : 'd-none'}
                style={{ display: 'contents' }}>
                <Form.Group controlId="search">
                  <Select
                    className="filter-column"
                    options={producerIsLoad ? producer_options : producer_options}
                    value={producer_options.filter((option) => option.value === producerTypeID)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setProducerTypeID(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
              <div className={groupBy === 'status' ? '' : 'd-none'} style={{ display: 'contents' }}>
                <Form.Group controlId="search">
                  <Select
                    className="filter-column"
                    options={options}
                    value={options.filter((option) => option.value === selectedStatus)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setSelectedStatus(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
              <div
                className={groupBy === 'collection_type' ? '' : 'd-none'}
                style={{ display: 'contents' }}>
                <Form.Group controlId="search">
                  <Select
                    className="filter-column"
                    options={collectionTypes}
                    value={collectionTypes.filter((option) => option.value === selectedCollection)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setSelectedCollection(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
              <div
                className={groupBy === 'territory' ? '' : 'd-none'}
                style={{ display: 'contents' }}>
                <Form.Group controlId="search">
                  <Select
                    className="filter-column"
                    placeholder={t('page.terrritory_select_placeholder')}
                    options={territoryIsLoad ? TerritoryOptions : TerritoryOptions}
                    value={TerritoryOptions.filter((option) => option.value === territryId)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setTerritryId(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
              <div className={groupBy === 'area' ? '' : 'd-none'} style={{ display: 'contents' }}>
                <Form.Group controlId="search">
                  <Select
                    className="filter-column"
                    options={areaIsLoad ? AreaOptions : AreaOptions}
                    value={AreaOptions.filter((option) => option.value === areaId)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setAreaId(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
              <div
                className={groupBy === 'waste_code' ? '' : 'd-none'}
                style={{ display: 'contents' }}>
                <Form.Group controlId="search">
                  <Select
                    className="filter-column"
                    options={wastecodeIsLoad ? WestCodeOptions : WestCodeOptions}
                    value={WestCodeOptions.filter((option) => option.value === wasteCode)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setWasteCode(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="col-3">
              <Form.Label className="field-label date-picker-label">
                {t('page.users_date_type_label')}
              </Form.Label>
              <Form.Group controlId="search" className="mb-3">
                <Select
                  className="filter-column"
                  options={dateTypes}
                  value={dateTypes.filter((option) => option.value === selectedDateType)}
                  onChange={(selectedOption) => {
                    setCurrentPage(1);
                    setSelectedDateType(selectedOption.value);
                  }}
                />
              </Form.Group>
              <div
                className={selectedDateType !== '' ? '' : 'd-none'}
                style={{ display: 'contents' }}>
                <Form.Label className="field-label date-picker-label">
                  {t('page.users_from_date_label')}
                </Form.Label>
                <Form.Group>
                  <DatePicker
                    onKeyDown={dateFormatManualAdd}
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    adjustDateOnChange
                    className="form-control filter-column"
                    placeholderText={dateFormatCommon().toUpperCase()}
                    selected={fromDate}
                    dateFormat={dateFormatCommon()}
                    onChange={(date) => setFromDate(date)}
                    maxDate={toDate !== '' && toDate !== undefined ? toDate : new Date()}
                    onBlur={(date) => {
                      var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                      if (date.currentTarget.value) {
                        if (!date1.isValid()) {
                          toast.error(t('page.invalid_date_format'));
                          setFromDate('');
                        } else {
                          var maxDate = toDate !== '' && toDate !== undefined ? toDate : new Date();
                          if (date1 > maxDate) {
                            toast.error(t('page.from_date_less_than_to_Date'));
                            setFromDate('');
                          }
                        }
                      }
                    }}
                  />
                </Form.Group>
                <Form.Label className="field-label date-picker-label mt-3">
                  {t('page.users_to_date_label')}
                </Form.Label>
                <Form.Group>
                  <DatePicker
                    onKeyDown={dateFormatManualAdd}
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    adjustDateOnChange
                    className="form-control filter-column"
                    placeholderText={dateFormatCommon().toUpperCase()}
                    minDate={fromDate}
                    selected={toDate}
                    dateFormat={dateFormatCommon()}
                    onChange={(date) => setToDate(date)}
                    onBlur={(date) => {
                      var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                      if (date.currentTarget.value) {
                        if (!date1.isValid()) {
                          toast.error(t('page.invalid_date_format'));
                          setToDate('');
                        } else {
                          var minDate =
                            fromDate !== '' && fromDate !== undefined ? fromDate : date1;
                          if (date1 < minDate) {
                            toast.error(t('page.to_date_greater_than_from_Date'));
                            setToDate('');
                          }
                        }
                      }
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="primary-button">
            <TNButton
              type="button"
              loading={reportGenerateLoading}
              onClick={() => {
                downloadApiCall(1);
              }}>
              {t('page.excel_download_button_text')}
            </TNButton>
            <TNButton
              type="button"
              loading={reportGenerateLoading}
              onClick={() => {
                downloadApiCall(3);
              }}
              className="ms-2">
              {t('page.csv_download_button_text')}
            </TNButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
ListProducerPage.propTypes = {
  columns: PropTypes.any,
  row: PropTypes.any,
  value: PropTypes.any,
  data: PropTypes.any,
  paginationData: PropTypes.any,
  t: PropTypes.func
};
export { ListProducerPage };
