import client from '@libs/HttpClient';
class OrderService {
  /* Orders */
  static listOrder(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const is_active = request.queryKey[3];
    const from_date = request.queryKey[4];
    const to_date = request.queryKey[5];
    const warehouseId = request.queryKey[6];
    const territryId = request.queryKey[7];
    const areaId = request.queryKey[8];
    const producerId = request.queryKey[9];
    const deliveryType = request.queryKey[10];
    const sortOrder = request.queryKey[11];
    const sortBy = request.queryKey[12];

    return client.get(
      `/orders/order_list?page=${page_no}&search_text=${searchText}&status=${is_active}&from_date=${from_date}&to_date=${to_date}&warehouse_id=${warehouseId}&territory_id=${territryId}&area_id=${areaId}&producer_id=${producerId}&delivery_type=${deliveryType}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static listOrderByWarehouseAndDeliveryType(request) {
    const warehouseId = request.queryKey[1];
    const from_date = request.queryKey[2];
    const to_date = request.queryKey[3];
    const deliveryType = request.queryKey[4];

    return client.get(
      `/orders/order_list?warehouse_id=${warehouseId}&from_date=${from_date}&to_date=${to_date}&delivery_type=${deliveryType}`,
      request
    );
  }

  static listOrderByDetail(request) {
    const page_no = request.queryKey[1];
    const limit = request.queryKey[2];

    return client.get(`/orders/order_by_detail_list?page=${page_no}&limit=${limit}`, request);
  }

  static viewOrder(request) {
    return client.get(`/producer/orders/order-details/${request}`);
  }

  static updateOrder(request) {
    return client.post(`/orders/status_update/${request.order_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static viewOrderPrepare(request) {
    return client.get(`/orders/order-prepartion/${request}`);
  }

  static viewOrderNewContainer(request) {
    return client.post(`/orders/view-container/${request.order_container_detail_id}`, request);
  }

  static viewOrderLoaded(request) {
    return client.get(`/orders/order-truck-load/${request}`);
  }

  static viewOrderDeliver(request) {
    return client.get(`/orders/deliver-producer/${request}`);
  }

  static generateReport(request) {
    return client.post(`/orders/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static generateFinanceReport(request) {
    return client.post(`/orders/generate-finance-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static bookingGosend(request) {
    return client.post(`/gosend/booking`, request, {
      headers: {
        'Content-type': 'application/json'
      }
    });
  }

  static cancelGosend(request) {
    return client.post(`/gosend/cancel`, request, {
      headers: {
        'Content-type': 'application/json'
      }
    });
  }
}
export { OrderService };
