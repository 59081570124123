import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    backend: {
      loadPath: '../../../../lang/en/frontend.json'
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      wait: true,
      useSuspense: true
    }
  });

export default i18n;
