import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AddTruckPage } from './AddTrucksPage';
import { ListTrucksPage } from './ListTruckPage';
import { EditTruckPage } from './EditTruckPage';
import { ViewTruckPage } from './ViewTruckPage';

const TrucksRoutes = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/add/:transporter_id" element={<AddTruckPage t={t} />} />
      <Route exact path="/list/:transporter_id" element={<ListTrucksPage t={t} />} />
      <Route exact path="/edit/:truck_id" element={<EditTruckPage t={t} />} />
      <Route exact path="/view/:truck_id" element={<ViewTruckPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
TrucksRoutes.propTypes = {
  t: PropTypes.func
};
export default TrucksRoutes;
