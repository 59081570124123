import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import { useDeleteDriverAttachment } from '@hooks';
import { imagePreviewFromik } from '@helpers/';

const EditAttachmentFiles = (props) => {
  // attachment field  referance
  let ownerKTPRef = useRef();
  const [DeleteAttchmentId, setDeleteAttchmentId] = useState(false);
  const selfieWithKTPRef = useRef();
  const proof_of_addressRef = useRef();
  const driver_licenceRef = useRef();
  /**
   * !This Block is for Delete Attachment
   */
  const handleDeleteClick = (tdata) => {
    let attchament_id = tdata.currentTarget.getAttribute('attchament_id');
    let field_name = tdata.currentTarget.getAttribute('field_name');
    if (attchament_id !== '') {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2>{t('page.delete_attachment_popup_message')}</h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    setDeleteAttchmentId(attchament_id);
                    formik.setFieldValue(field_name, '');
                    formik.setFieldValue(`${field_name}_id`, '');

                    onClose();
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      formik.setFieldValue(field_name, '');
      formik.setFieldValue(`${field_name}_id`, '');
    }
  };
  /**
   * !This Block will call delete attachment api
   */
  useDeleteDriverAttachment(
    [DeleteAttchmentId, 'attachment'],
    (res) => {
      toast.success(res.message);
      setDeleteAttchmentId(false);
    },
    (err) => {
      toast.error(err.message);
      setDeleteAttchmentId(false);
    }
  );
  const { t, formik } = props;
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col sm={12} md={4} className="pb-3">
            <Form.Group>
              <Form.Label className="field-label">
                {t('page.add_driver_driver_ktp_attchment_label')}
              </Form.Label>
              <Form.Control
                type="file"
                hidden
                accept="image/*,.pdf"
                ref={ownerKTPRef}
                name="driver_ktp"
                placeholder={t('page.add_driver_driver_ktp_attchment_placeholder')}
                onChange={(event) => {
                  formik.setFieldValue('driver_ktp', event.currentTarget.files[0]);
                }}
              />
              <Button
                type="button"
                onClick={() => ownerKTPRef.current?.click()}
                className={
                  'upload-button' ||
                  (formik.touched.driver_ktp && formik.errors.driver_ktp
                    ? 'form-field-error'
                    : formik.touched.driver_ktp && !formik.errors.driver_ktp
                    ? 'form-field-success'
                    : '')
                }>
                {t('page.file_upload')}
              </Button>
            </Form.Group>
            <div className="form-field-error-text">
              {formik.touched.driver_ktp && formik.errors.driver_ktp ? (
                <div>{t(formik.errors.driver_ktp)}</div>
              ) : null}
            </div>
            {formik.values.driver_ktp && (
              <div className="preview-image">
                <img src={imagePreviewFromik(formik.values.driver_ktp)} alt="profile_img" />
                <FontAwesomeIcon
                  icon={faClose}
                  attchament_id={formik.values.driver_ktp_id}
                  field_name={'driver_ktp'}
                  onClick={(event) => {
                    handleDeleteClick(event);
                    ownerKTPRef.current.value = null;
                  }}
                />
              </div>
            )}
          </Col>
          <Col sm={12} md={4} className="pb-3">
            <Form.Group>
              <Form.Label className="field-label">
                {t('page.add_driver_selfi_with_ktp_attchement_label')}
              </Form.Label>
              <Form.Control
                className={'d-none'}
                type="file"
                accept="image/*"
                ref={selfieWithKTPRef}
                name="selfi_with_ktp"
                placeholder={t('page.add_driver_selfi_with_ktp_attchement_placeholder')}
                onChange={(event) => {
                  formik.setFieldValue('selfi_with_ktp', event.currentTarget.files[0]);
                }}
                hidden
              />
              <Button
                type="button"
                onClick={() => selfieWithKTPRef.current?.click()}
                className={
                  'upload-button' ||
                  (formik.touched.selfi_with_ktp && formik.errors.selfi_with_ktp
                    ? 'form-field-error'
                    : formik.touched.selfi_with_ktp && !formik.errors.selfi_with_ktp
                    ? 'form-field-success'
                    : '')
                }>
                {t('page.file_upload')}
              </Button>
            </Form.Group>
            <div className="form-field-error-text">
              {formik.touched.selfi_with_ktp && formik.errors.selfi_with_ktp ? (
                <div>{t(formik.errors.selfi_with_ktp)}</div>
              ) : null}
            </div>
            {formik.values.selfi_with_ktp && (
              <div className="preview-image">
                <img src={imagePreviewFromik(formik.values.selfi_with_ktp)} alt="profile_img" />
                <FontAwesomeIcon
                  icon={faClose}
                  attchament_id={formik.values.selfi_with_ktp_id}
                  field_name={'selfi_with_ktp'}
                  onClick={(event) => {
                    handleDeleteClick(event);
                    selfieWithKTPRef.current.value = null;
                  }}
                />
              </div>
            )}
          </Col>
          <Col sm={12} md={4} className="pb-3">
            <Form.Group>
              <Form.Label className="field-label">
                {t('page.add_driver_proof_of_address_attchement_label')}
              </Form.Label>
              <Form.Control
                className={'d-none'}
                type="file"
                accept="image/*,.pdf"
                ref={proof_of_addressRef}
                name="proof_of_address"
                placeholder={t('page.add_driver_proof_of_address_attchement_placeholder')}
                onChange={(event) => {
                  formik.setFieldValue('proof_of_address', event.currentTarget.files[0]);
                }}
                hidden
              />
              <Button
                type="button"
                onClick={() => proof_of_addressRef.current?.click()}
                className={
                  'upload-button' ||
                  (formik.touched.proof_of_address && formik.errors.proof_of_address
                    ? 'form-field-error'
                    : formik.touched.proof_of_address && !formik.errors.proof_of_address
                    ? 'form-field-success'
                    : '')
                }>
                {t('page.file_upload')}
              </Button>
            </Form.Group>
            <div className="form-field-error-text">
              {formik.touched.proof_of_address && formik.errors.proof_of_address ? (
                <div>{t(formik.errors.proof_of_address)}</div>
              ) : null}
            </div>
            {formik.values.proof_of_address && (
              <div className="preview-image">
                <img src={imagePreviewFromik(formik.values.proof_of_address)} alt="profile_img" />
                <FontAwesomeIcon
                  icon={faClose}
                  attchament_id={formik.values.proof_of_address_id}
                  field_name={'proof_of_address'}
                  onClick={(event) => {
                    handleDeleteClick(event);
                    proof_of_addressRef.current.value = null;
                  }}
                />
              </div>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
EditAttachmentFiles.propTypes = {
  t: PropTypes.func,
  formik: PropTypes.any
};
export { EditAttachmentFiles };
