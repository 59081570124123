import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { TourSettingService } from '@api/';
const onDefaultError = (error) => {
  toast.error(error.message);
};

/**
 *  Load Setting
 */
const useGetTourSettingDataAlways = (onSuccess, onError) => {
  return useQuery(
    'setting_data_all',
    () => {
      return TourSettingService.getSettingData();
    },
    {
      onSuccess,
      onError
    }
  );
};

const useStoreTourSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(TourSettingService.storeSettingData, {
    onSuccess,
    onError
  });
};

/**
 * First Half Setting
 */

const useGetTourFirstHalfSettingData = (onSuccess, onError) => {
  return useQuery(
    'first_half_setting',
    () => {
      return TourSettingService.getTourFirstHalfSettingData();
    },
    {
      onSuccess,
      onError
    }
  );
};

const useStoreTourFirstHalfSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(TourSettingService.storeTourFirstHalfSettingData, {
    onSuccess,
    onError
  });
};

/**
 * Second Half Setting
 */

const useGetTourSecondHalfSettingData = (onSuccess, onError) => {
  return useQuery(
    'second_half_setting',
    () => {
      return TourSettingService.getTourSecondHalfSettingData();
    },
    {
      onSuccess,
      onError
    }
  );
};

const useStoreTourSecondHalfSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(TourSettingService.storeTourSecondHalfSettingData, {
    onSuccess,
    onError
  });
};

/**
 * Update status of tour
 */

const useUpdateStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(TourSettingService.updateTourStatus, {
    onSuccess,
    onError
  });
};

/**
 * Maximum Stops
 */

const useStoreMaxStopSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(TourSettingService.storeMaxStopSettingData, {
    onSuccess,
    onError
  });
};

const useListMaxStop = (
  [page_no, searchText, sortOrder, sortBy],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['max-stop-list', page_no, searchText, sortOrder, sortBy],
    TourSettingService.listMaxStops,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useViewMaxStop = (maximum_stop_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['max-stop-view', maximum_stop_id], TourSettingService.viewMaxStop, {
    onSuccess,
    onError
  });
};

const useUpdateMaxStop = (onSuccess, onError = onDefaultError) => {
  return useMutation(TourSettingService.updateMaxStop, {
    onSuccess,
    onError
  });
};

const useDeleteMaxStops = (onSuccess, onError = onDefaultError) => {
  return useMutation(TourSettingService.deleteMaxStops, {
    onSuccess,
    onError
  });
};

// Operational time
const useGetOperationalTime = (onSuccess, onError) => {
  return useQuery(
    'operational_time',
    () => {
      return TourSettingService.getOperationalTime();
    },
    {
      onSuccess,
      onError
    }
  );
};

const useUpdateOperationalTime = (onSuccess, onError = onDefaultError) => {
  return useMutation(TourSettingService.updateOperationalTime, {
    onSuccess,
    onError
  });
};

const useGenerateMaxStopReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(TourSettingService.generateReport, {
    onSuccess,
    onError
  });
};

export {
  useGetTourSettingDataAlways,
  useStoreTourSettingData,
  useGetTourFirstHalfSettingData,
  useStoreTourFirstHalfSettingData,
  useGetTourSecondHalfSettingData,
  useStoreTourSecondHalfSettingData,
  useUpdateStatus,
  useStoreMaxStopSettingData,
  useListMaxStop,
  useViewMaxStop,
  useUpdateMaxStop,
  useDeleteMaxStops,
  useGetOperationalTime,
  useUpdateOperationalTime,
  useGenerateMaxStopReport
};
