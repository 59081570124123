import { useMutation, useQuery } from 'react-query';

import { toast } from 'react-toastify';
import { LoadTransportTruckService } from '@api/';
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useLoadTransportTruck = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['tour/transport-truck-loading/list', [params]],
    () => LoadTransportTruckService.viewLoadTransportTruck(params),
    {
      onSuccess,
      onError
    }
  );
};

const useViewLoadTransportTruckDetails = (
  delivery_point_current_session_id,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    'tour/unload-collect-truck/details',
    () =>
      LoadTransportTruckService.viewLoadTransportTruckDetails(delivery_point_current_session_id),
    {
      onSuccess,
      onError
    }
  );
};

const useViewLoadTransportTruckDownloadManifest = (
  delivery_point_current_session_id,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    'tour/unload-collect-truck/download-manifest',
    () =>
      LoadTransportTruckService.viewLoadTransportTruckDownloadManifest(
        delivery_point_current_session_id
      ),
    {
      enabled: false,
      onSuccess,
      onError
    }
  );
};

const useTransportAddNotes = (onSuccess, onError = onDefaultError) => {
  return useMutation(LoadTransportTruckService.useTransportAddNotes, {
    onSuccess,
    onError
  });
};

const useGenerateLoadTransTruckReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(LoadTransportTruckService.generateReport, {
    onSuccess,
    onError
  });
};

export {
  useLoadTransportTruck,
  useViewLoadTransportTruckDetails,
  useTransportAddNotes,
  useViewLoadTransportTruckDownloadManifest,
  useGenerateLoadTransTruckReport
};
