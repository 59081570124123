import { useMutation, useQuery } from 'react-query';

import { DriverService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useStoreDriver = (onSuccess, onError = onDefaultError) => {
  return useMutation(DriverService.storeDriver, {
    onSuccess,
    onError
  });
};
const useListDriver = (
  [page_no, searchText, status, admin_status, transporter_id, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['driver-list', page_no, searchText, status, admin_status, transporter_id, sortBy, sortOrder],
    DriverService.listDriver,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
const useDriverDeleteChange = ([driver_id], onSuccess, onError = onDefaultError) => {
  const sent = driver_id ? true : false;
  return useQuery(['driver-delete', driver_id], DriverService.deleteDriver, {
    onSuccess,
    onError,
    enabled: sent,
    retry: 0
  });
};
const useViewDriver = (driver_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['driver-view', driver_id], DriverService.viewDriver, {
    onSuccess,
    onError
  });
};
const useUpdateDriver = (onSuccess, onError = onDefaultError) => {
  return useMutation(DriverService.updateDriver, {
    onSuccess,
    onError
  });
};
const useDeleteDriverAttachment = ([attachment_id, type], onSuccess, onError = onDefaultError) => {
  const sent = attachment_id ? true : false;
  return useQuery(
    ['driver-attachment-delete', attachment_id, type],
    DriverService.DeleteDriverAttachment,
    {
      onSuccess,
      onError,
      enabled: sent,
      retry: 0
    }
  );
};
const useGenerateDriverReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(DriverService.generateReport, {
    onSuccess,
    onError
  });
};
export {
  useStoreDriver,
  useListDriver,
  useDriverDeleteChange,
  useViewDriver,
  useUpdateDriver,
  useDeleteDriverAttachment,
  useGenerateDriverReport
};
