import client from '@libs/HttpClient';

class UnloadMasterService {
  static listAll(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const from_date = request.queryKey[3];
    const to_date = request.queryKey[4];
    const area_id = request.queryKey[5];
    const tour_type = request.queryKey[6];
    const transporter_id = request.queryKey[7];
    const warehouse_id = request.queryKey[8];
    const factory_id = request.queryKey[9];
    const sortOrder = request.queryKey[10];
    const sortBy = request.queryKey[11];
    const sessionType = request.queryKey[12];

    return client.get(
      `tour/unload-collect-truck/list?page=${page_no}&search_text=${searchText}&from_date=${from_date}&to_date=${to_date}&area_id=${area_id}&tour_type=${tour_type}&transporter_id=${transporter_id}&warehouse_id=${warehouse_id}&factory_id=${factory_id}&sort_by=${sortBy}&sort_order=${sortOrder}&session_type=${sessionType}`
    );
  }

  static viewUnloadCollectTruck(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const from_date = request.queryKey[3];
    const to_date = request.queryKey[4];
    const area_id = request.queryKey[5];
    const tour_type = request.queryKey[6];
    const transporter_id = request.queryKey[7];
    const warehouse_id = request.queryKey[8];
    const factory_id = request.queryKey[9];
    const sortOrder = request.queryKey[10];
    const sortBy = request.queryKey[11];

    return client.get(
      `tour/unload-collect-truck/index?page=${page_no}&search_text=${searchText}&from_date=${from_date}&to_date=${to_date}&area_id=${area_id}&tour_type=${tour_type}&transporter_id=${transporter_id}&warehouse_id=${warehouse_id}&factory_id=${factory_id}&sort_by=${sortBy}&sort_order=${sortOrder}`
    );
  }

  static viewUnloadCollectTruckDetails(delivery_point_current_session_id) {
    return client.get(`tour/unload-collect-truck/details/${delivery_point_current_session_id}`);
  }

  static addNotes(request) {
    return client.post('tour/unload-collect-truck/add-notes', request);
  }

  static generateReport(request) {
    return client.post(`/tour/unload-collect-truck/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}

export { UnloadMasterService };
