import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListDeliveryPointUserPage = React.lazy(() => import('./ListDeliveryPointUserPage'));
const AddDeliveryPointUserPage = React.lazy(() => import('./AddDeliveryPointUserPage'));
const ViewDeliveryPointUserPage = React.lazy(() => import('./ViewDeliveryPointUserPage'));
const EditDeliveryPointUserPage = React.lazy(() => import('./EditDeliveryPointUserPage'));
const ListChecklistHistoryPage = React.lazy(() => import('@common/pages/ListChecklistHistoryPage'));

const DeliveryPointUserRoutes = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/point/user/list" element={<ListDeliveryPointUserPage t={t} />} />
      <Route exact path="/point/user/add" element={<AddDeliveryPointUserPage t={t} />} />
      <Route
        exact
        path="/point/user/view/:delivery_point_user_id"
        element={<ViewDeliveryPointUserPage t={t} />}
      />
      <Route
        exact
        path="/point/user/edit/:delivery_point_user_id"
        element={<EditDeliveryPointUserPage t={t} />}
      />
      <Route
        exact
        path="/point/user/view/:delivery_point_user_id/checklist-history"
        element={<ListChecklistHistoryPage />}
      />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
DeliveryPointUserRoutes.propTypes = {
  t: PropTypes.func
};
export default DeliveryPointUserRoutes;
