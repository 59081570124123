import client from '@libs/HttpClient';
class NotificationService {
  /* Notification */
  static listNotification(request) {
    const page_no = request.queryKey[1];

    return client.get(`/notification/notificaton_list?page=${page_no}`, request);
  }

  static deleteNotification(request) {
    return client.post(`/notification/remove-notificaton`, request);
  }

  static readNotification(request) {
    return client.post(`/notification/read-notificaton`, request);
  }

  static latestNotification(request) {
    return client.get(`/notification/latest-notificaton`, request);
  }
}
export { NotificationService };
