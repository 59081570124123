import { useMutation, useQuery } from 'react-query';

import { AgentService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

/* Delivery Point User */
const useListAgent = (
  [page_no, searchText, selectedStatus, fromDate, toDate, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['agent-list', page_no, searchText, selectedStatus, fromDate, toDate, sortBy, sortOrder],
    AgentService.listAgent,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
const useAddAgent = (onSuccess, onError = onDefaultError) => {
  return useMutation(AgentService.addAgent, {
    onSuccess,
    onError
  });
};
const useAgentDelete = ([agent_id], onSuccess, onError = onDefaultError) => {
  const sent = agent_id ? true : false;
  return useQuery(['agent-delete', agent_id], AgentService.deleteAgent, {
    onSuccess,
    onError,
    enabled: sent,
    retry: 0
  });
};
const useViewAgent = (agent_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['agent-view', agent_id], AgentService.viewAgent, {
    onSuccess,
    onError
  });
};

const useUpdateAgent = (onSuccess, onError = onDefaultError) => {
  return useMutation(AgentService.updateAgent, {
    onSuccess,
    onError
  });
};

const useDeleteAgentAttachment = ([attachment_id, type], onSuccess, onError = onDefaultError) => {
  const sent = attachment_id ? true : false;
  return useQuery(
    ['agent-attachment-delete', attachment_id, type],
    AgentService.deleteAgentAttachment,
    {
      onSuccess,
      onError,
      enabled: sent,
      retry: 0
    }
  );
};

const useGenerateAgentDownloadReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(AgentService.generateReport, {
    onSuccess,
    onError
  });
};
export {
  useListAgent,
  useAddAgent,
  useAgentDelete,
  useViewAgent,
  useUpdateAgent,
  useDeleteAgentAttachment,
  useGenerateAgentDownloadReport
};
