import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ListTransporterPayout from './ListTransporterPayout';
import ViewTransporterPayout from './ViewTransporterPayout';

const TransporterPayoutRoutes = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/list" element={<ListTransporterPayout t={t} />} />

      <Route
        exact
        path="/view/:date/:type/:reference_id/:per_kg_commision"
        element={<ViewTransporterPayout t={t} />}
      />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
TransporterPayoutRoutes.propTypes = {
  t: PropTypes.func
};
export default TransporterPayoutRoutes;
