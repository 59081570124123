import client from '@libs/HttpClient';

class PendingIncinerationService {
  static viewPendingIncinerationService(params) {
    return client.get(`tour/incineration/pending/list`, { params });
  }

  static generateReport(request) {
    return client.post(`/tour/incineration/pending/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}

export { PendingIncinerationService };
