import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Accordion, Button, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useStoreTrucks } from '@hooks';
import { Form, Formik } from 'formik';
import { validationSchema } from './AddTrucksValidation';
import { AttachmentFiles, GeneralDetails } from './components';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import AlertCommon from '@common/components/AlertCommon';
import { TNButton } from '@common/components/';
import TNBreadCurm from '@common/components/TNBreadCurm';
import moment from 'moment';
import { StnkDetails } from './components/StnkDetails';
import { KirDetails } from './components/KirDetails';

const AddTruckPage = ({ t }) => {
  const [accrdKey, setAccrdKey] = useState(0);
  let { transporter_id } = useParams();
  // From this api we are getting country code list.
  const navigate = useNavigate();
  //const { isLoading: countryIsLoad } = useGetCountryCodeList();

  /**
   * This function is use when click on cancel button
   */

  const handleCancel = (dirty) => {
    if (dirty && dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message')
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(`/transporter/truck/list/${transporter_id}`);
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      navigate(`/transporter/truck/list/${transporter_id}`);
    }
  };
  /**
   * !This block will call on click submit button
   */
  const {
    mutate: doStoreTransporter,
    isLoading,
    error: addTRList,
    isError: addTRError
  } = useStoreTrucks(
    (res) => {
      toast.success(res.message);
      navigate(`/transporter/truck/list/${transporter_id}`);
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );
  /**
   * !This block is for initial form values
   */
  const initialValues = {
    transporter: '',
    brand_name: '',
    model_name: '',
    road_date: new Date(),
    plate_number: '',
    weight: '',
    max_weight_kg: 500,
    max_weight_ltr: 1562,
    is_active: 1,
    type: 1,
    conformity_certificate: '',
    truck_photos: [],
    alliance_portal_id: transporter_id,
    stnk: '',
    stnk_expired_date: '',
    registration_card: '',
    kir: '',
    kir_expired_date: '',
    kir_card: '',
    gps_tracker: ''
  };

  const breadcurmArray = [
    {
      label: t('page.transporter_list_title'),
      link: `/transporter/list`,
      active: ''
    },
    {
      label: t('page.transporter_view_details'),
      link: `/transporter/truck/list/${transporter_id}`,
      active: ''
    },
    {
      label: t('page.add_truck_title'),
      link: `/transporter/truck/list/${transporter_id}`,
      active: 'active'
    }
  ];

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />

      <AlertCommon key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)} is_error={addTRError}>
        {addTRList}
      </AlertCommon>
      <h1>{t('page.add_truck_title')} </h1>
      <Card>
        <Card.Body className="p-0">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const formData = new FormData();
              for (let key in values) {
                let value = values[key];

                if (key === 'truck_photos') {
                  for (let keycoll in value) {
                    let valuecolle = value[keycoll];
                    formData.append(`truck_photos[${keycoll}]`, valuecolle);
                  }
                } else {
                  formData.append(key, value);
                }
              }

              if (values.road_date !== '') {
                formData.append('road_date', moment(values.road_date).format('YYYY-MM-DD'));
              }

              if (values.stnk_expired_date !== '') {
                formData.append(
                  'stnk_expired_date',
                  moment(values.stnk_expired_date).format('YYYY-MM-DD')
                );
              }

              if (values.kir_expired_date !== '') {
                formData.append(
                  'kir_expired_date',
                  moment(values.kir_expired_date).format('YYYY-MM-DD')
                );
              }

              doStoreTransporter(formData);
            }}>
            {({ values, errors, dirty }) => {
              if (values.road_date !== '') {
                if (values.road_date !== '0000-00-00') {
                  values.road_date = new Date(values.road_date);
                } else {
                  values.road_date = '';
                }
              }

              if (values.stnk_expired_date !== '') {
                if (values.stnk_expired_date !== '0000-00-00') {
                  values.stnk_expired_date = new Date(values.stnk_expired_date);
                } else {
                  values.stnk_expired_date = '';
                }
              }

              if (values.kir_expired_date !== '') {
                if (values.kir_expired_date !== '0000-00-00') {
                  values.kir_expired_date = new Date(values.kir_expired_date);
                } else {
                  values.kir_expired_date = new Date(values.road_date);
                }
              }

              return (
                <Form>
                  <Accordion
                    key={accrdKey}
                    alwaysOpen
                    defaultActiveKey={accrdKey && accrdKey !== 0 ? accrdKey : 1}>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 1}
                      className="my-1">
                      <Accordion.Header className="text-info fw-bold shadow-nond">
                        {t('page.add_truck_general_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <GeneralDetails t={t}></GeneralDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 2}
                      className="my-1">
                      <Accordion.Header className="text-info fw-bold shadow-nond">
                        {t('page.add_truck_stnk_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <StnkDetails t={t}></StnkDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 3}
                      className="my-1">
                      <Accordion.Header className="text-info fw-bold shadow-nond">
                        {t('page.add_truck_kir_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <KirDetails t={t}></KirDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 4}
                      className="border-bottom my-1">
                      <Accordion.Header>{t('page.add_trucks_attachment_title')}</Accordion.Header>
                      <Accordion.Body className="p-0">
                        <AttachmentFiles t={t}></AttachmentFiles>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="primary-button">
                    <span className="link-center" onClick={() => handleCancel(dirty)}>
                      {t('page.add_transporter_cancel_button')}
                    </span>
                    <TNButton
                      loading={isLoading}
                      onClick={() => {
                        setAccrdKey(1);
                      }}
                      type="submit"
                      isdirtyform={dirty && dirty !== undefined ? 1 : 0}>
                      {t('page.add_transporter_submit_button')}
                    </TNButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
};
AddTruckPage.propTypes = {
  t: PropTypes.func,
  isdirtyform: PropTypes.any
};
export { AddTruckPage };
