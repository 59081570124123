import { useQuery } from 'react-query';

import { toast } from 'react-toastify';
import { CommissionListService } from '@api/';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useListCommissionFee = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['transporter/commision-fee-list', [params]],
    () => CommissionListService.listCommissionFee(params),
    {
      onSuccess,
      onError
    }
  );
};

const useGetTransporterTerritoryData = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'transporter/commision-fee-transporter',
    () => {
      return CommissionListService.getTransportTerritoryData(params);
    },
    {
      onSuccess,
      onError
    }
  );
};

export { useListCommissionFee, useGetTransporterTerritoryData };
