import client from '@libs/HttpClient';
class ScanPointService {
  /* delivery point user */
  static listScanPoint(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const is_active = request.queryKey[3];
    const alliance_portal_id = request.queryKey[4];
    const type = request.queryKey[5];
    const selectedLicense = request.queryKey[6];
    const sortBy = request.queryKey[7];
    const sortOrder = request.queryKey[8];

    return client.get(
      `/scan-point-user/index/${type}/${alliance_portal_id}?page=${page_no}&search_text=${searchText}&is_active=${is_active}&is_type=${selectedLicense}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static addScanPoint(request) {
    return client.post('/scan-point-user/store', request);
  }
  static deleteScanPoint(request) {
    const scan_point_id = request.queryKey[1];
    return client.get(`/scan-point-user/destroy/${scan_point_id}`, request);
  }
  static viewScanPoint(request) {
    const scan_point_id = request.queryKey[1];
    return client.get(`/scan-point-user/view/${scan_point_id}`, request);
  }
  static updateScanPoint(request) {
    return client.post(`/scan-point-user/update/${request.scan_point_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static generateReport(request) {
    return client.post(`/scan-point-user/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { ScanPointService };
