import client from '@libs/HttpClient';
class AgentPayoutService {
  static agentPayoutList(request) {
    const page = request.queryKey[1];
    const from_date = request.queryKey[2];
    const to_date = request.queryKey[3];
    const invoice_status = request.queryKey[4];
    const search_text = request.queryKey[5];
    const reference_id = request.queryKey[6];
    const sortBy = request.queryKey[7];
    const sortOrder = request.queryKey[8];

    return client.get(
      `finance/agent/index?page=${page}&search_text=${search_text}&from_date=${from_date}&to_date=${to_date}&invoice_status=${invoice_status}&search_text=${search_text}&reference_id=${reference_id}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static agentListFilter(request) {
    return client.get(`finance/agent/agent-dropdown`);
  }

  static agentPayoutView(date, type, reference_id, per_kg_commision) {
    return client.get(`finance/agent/detail/${date}/${type}/${reference_id}/${per_kg_commision}`);
  }

  static generateReport(request) {
    return client.post(`finance/agent/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { AgentPayoutService };
