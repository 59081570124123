import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { AvailableCollectService } from '@api/';
const onDefaultError = (error) => {
  toast.error(error.message);
};

/**
 * Available Collect
 */

const useAvailableCollect = (
  [page_no, searchText, territryId, areaId, selectedType, sortOrder, sortBy],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'available-collect-list',
      page_no,
      searchText,
      territryId,
      areaId,
      selectedType,
      sortOrder,
      sortBy
    ],
    AvailableCollectService.listAvailableCollect,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useStoreAvailableCollect = (onSuccess, onError = onDefaultError) => {
  return useMutation(AvailableCollectService.storeAvailableCollect, {
    onSuccess,
    onError
  });
};

const useViewAvailableTour = (tour_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'available-view-tour-driver',
    () => AvailableCollectService.viewAvailableTour(tour_id),
    {
      onSuccess,
      onError
    }
  );
};

const useGetDriverDataForTour = (onSuccess, onError = onDefaultError) => {
  return useMutation(AvailableCollectService.viewAvailableTourDriver, {
    onSuccess,
    onError
  });
};

const useCronRun = (onSuccess, onError = onDefaultError) => {
  return useMutation(AvailableCollectService.runCronTour, {
    onSuccess,
    onError
  });
};

const useGenerateAvailableCollectReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(AvailableCollectService.generateReport, {
    onSuccess,
    onError
  });
};
export {
  useAvailableCollect,
  useStoreAvailableCollect,
  useViewAvailableTour,
  useGetDriverDataForTour,
  useCronRun,
  useGenerateAvailableCollectReport
};
