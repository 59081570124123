import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, useParams, NavLink } from 'react-router-dom';
import { Card, Row, Col, Form, Modal, Nav } from 'react-bootstrap';
import moment from 'moment';

import { useViewWarehouse } from '@hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { imagePreviewFromik, urlToGetExnsion } from '@helpers/';
import TNBreadCurm from '@common/components/TNBreadCurm';

const ViewWarehousePage = ({ t }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalImgUrl, setModalImgUrl] = useState('');

  let { warehouse_id } = useParams();
  /**
   * !This API will call when page set. When response came we are setting up data into the form
   */
  const { data: warehouse_data } = useViewWarehouse(warehouse_id);

  if (!warehouse_data) {
    return null;
  }

  const breadcurmArray = [
    {
      label: t('page.warehouse_list_label'),
      link: '/warehouse/list',
      active: ''
    },
    {
      label: t('page.view_warehouse_title'),
      link: '/warehouse/list',
      active: 'active'
    }
  ];

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />
      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to={`/${'warehouse'}/view/${warehouse_id}`}>{t('page.general_details')}</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/${'warehouse'}/${warehouse_id}/delivery/point/user/list`}>
            {t('page.delivery_point_user')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/${'warehouse'}/${warehouse_id}/scan/point/list`}>
            {t('page.manage_scan_points')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/warehouse/pricing/${warehouse_id}`}>Biaya</NavLink>
        </Nav.Item>
      </Nav>
      <h1 className="text-center">{t('page.view_warehouse_title')} </h1>
      <Card>
        <Card.Header>
          <Card.Title>{t('page.add_warehouse_general_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_general_details_warehouse_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.name}</span>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_general_details_npwp_number_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.warehouse_npwp_number}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">{t('page.simpel_id')}</Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.simpel_id}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.simpel_registration_number')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.simpel_registration_number}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_general_details_general_contact_number_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  {warehouse_data.data.company_phone !== '' ? (
                    <span>
                      +{warehouse_data.data.country_code}&nbsp;
                      {warehouse_data.data.company_phone}
                    </span>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_general_details_territory_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.territory_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_general_details_address_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={12}>
                  <span>{warehouse_data.data.address}</span>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_general_details_address_notes_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={12}>
                  <span>{warehouse_data.data.address_notes}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_general_details_area_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.area_name}</span>
                </Col>
              </Row>
            </Col>

            {warehouse_data.data.factory_name.length > 0 ? (
              <Col lg={6} md={12} sm={12}>
                <Row>
                  <Col lg={6} xs={6}>
                    <Form.Label className="field-label">
                      Factory Name
                      {/* {t('page.add_warehouse_general_details_area_name_label')} */}
                    </Form.Label>
                  </Col>
                  <Col lg={1} xs={1}>
                    :
                  </Col>
                  <Col lg={5} xs={5}>
                    <span>{warehouse_data.data.factory_name}</span>
                  </Col>
                </Row>
              </Col>
            ) : (
              ''
            )}

            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.view_warehouse_city_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.city}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.view_warehouse_state_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.state}</span>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.view_warehouse_postcode_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.postcode}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.view_warehouse_country_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.country}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_general_details_status_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.contract_status_name}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_warehouse_contact_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_contact_details_contract_number_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.contract_number}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_contract_details_contract_start_date_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>
                    {warehouse_data.data.contract_start_date !== ''
                      ? moment(warehouse_data.data.contract_start_date).format('DD-MM-YYYY')
                      : ''}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_contract_details_contract_end_date_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>
                    {warehouse_data.data.contract_end_date !== ''
                      ? moment(warehouse_data.data.contract_end_date).format('DD-MM-YYYY')
                      : ''}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_warehouse_owner_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_owner_first_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.owner_first_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_owner_last_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.owner_last_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_owner_email_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.owner_email}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_owner_details_owner_phone_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  {warehouse_data.data.owner_phone !== '' ? (
                    <span>
                      +{warehouse_data.data.owner_country_code}&nbsp;
                      {warehouse_data.data.owner_phone}
                    </span>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_owner_details_owner_ktp_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.owner_ktp}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_warehouse_person_in_charge_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_person_incharge_first_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.person_incharge_first_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_person_incharge_last_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.person_incharge_last_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_person_incharge_email_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.person_incharge_email}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_person_incharge_phone_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  {warehouse_data.data.person_incharge_phone !== '' ? (
                    <span>
                      +{warehouse_data.data.person_country_code}&nbsp;
                      {warehouse_data.data.person_incharge_phone}
                    </span>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_warehouse_accounting_person_details_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_accounting_first_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.accounting_first_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_accounting_last_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.accounting_last_name}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_accounting_email_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  <span>{warehouse_data.data.accounting_email}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Row>
                <Col lg={6} xs={6}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_accounting_phone_label')}
                  </Form.Label>
                </Col>
                <Col lg={1} xs={1}>
                  :
                </Col>
                <Col lg={5} xs={5}>
                  {warehouse_data.data.accounting_phone !== '' ? (
                    <span>
                      +{warehouse_data.data.accounting_country_code}&nbsp;
                      {warehouse_data.data.accounting_phone}
                    </span>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Header>
          <Card.Title>{t('page.add_warehouse_attachment_title')}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col lg={4} md={6} sm={12}>
              <Row>
                <Col lg={12} xs={12}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_owner_ktp_attchment_label')}
                  </Form.Label>
                  {warehouse_data.data.owner_ktp_attchment === '' ? null : urlToGetExnsion(
                      warehouse_data.data.owner_ktp_attchment
                    ) === 'pdf' ? (
                    <a
                      target="_blank"
                      href={warehouse_data.data.owner_ktp_attchment}
                      rel="noreferrer">
                      <span>
                        <div className="preview-image">
                          <img
                            src={imagePreviewFromik(warehouse_data.data.owner_ktp_attchment)}
                            alt="profile_img"
                          />
                        </div>
                      </span>
                    </a>
                  ) : (
                    <span>
                      <div className="preview-image">
                        <img
                          src={imagePreviewFromik(warehouse_data.data.owner_ktp_attchment)}
                          alt="profile_img"
                          onClick={() => {
                            setModalImgUrl(warehouse_data.data.owner_ktp_attchment);
                            setModalShow(true);
                          }}
                        />
                      </div>
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Row>
                <Col lg={12} xs={12}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_selfi_with_ktp_attchement_label')}
                  </Form.Label>
                  {warehouse_data.data.selfi_with_ktp_attchement === '' ? null : urlToGetExnsion(
                      warehouse_data.data.selfi_with_ktp_attchement
                    ) === 'pdf' ? (
                    <a
                      target="_blank"
                      href={warehouse_data.data.selfi_with_ktp_attchement}
                      rel="noreferrer">
                      <span>
                        <div className="preview-image">
                          <img
                            src={imagePreviewFromik(warehouse_data.data.selfi_with_ktp_attchement)}
                            alt="profile_img"
                          />
                        </div>
                      </span>
                    </a>
                  ) : (
                    <span>
                      <div className="preview-image">
                        <img
                          src={imagePreviewFromik(warehouse_data.data.selfi_with_ktp_attchement)}
                          alt="profile_img"
                          onClick={() => {
                            setModalImgUrl(warehouse_data.data.selfi_with_ktp_attchement);
                            setModalShow(true);
                          }}
                        />
                      </div>
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Row>
                <Col lg={12} xs={12}>
                  <Form.Label className="field-label">
                    {t('page.add_transporter_stamp_label')}
                  </Form.Label>
                  {warehouse_data.data.stamp === '' ? null : urlToGetExnsion(
                      warehouse_data.data.stamp
                    ) === 'pdf' ? (
                    <a target="_blank" href={warehouse_data.data.stamp} rel="noreferrer">
                      <span>
                        <div className="preview-image">
                          <img
                            src={imagePreviewFromik(warehouse_data.data.stamp)}
                            alt="profile_img"
                          />
                        </div>
                      </span>
                    </a>
                  ) : (
                    <span>
                      <div className="preview-image">
                        <img
                          src={imagePreviewFromik(warehouse_data.data.stamp)}
                          alt="profile_img"
                          onClick={() => {
                            setModalImgUrl(warehouse_data.data.stamp);
                            setModalShow(true);
                          }}
                        />
                      </div>
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={12} md={12} sm={12}>
              <Row>
                <Col lg={12} xs={12}>
                  <Form.Label className="field-label">
                    {t('page.add_warehouse_attachments_attachment_name_label')}
                  </Form.Label>
                </Col>
                <Col lg={12} xs={12}>
                  <Row>
                    {warehouse_data.data.additional_attachment.length > 0 &&
                      warehouse_data.data.additional_attachment.map((item, i) => {
                        return (
                          <Col key={i} lg={6} xs={6}>
                            {item.additional_attachement_file === '' ? null : urlToGetExnsion(
                                item.additional_attachement_file
                              ) === 'pdf' ? (
                              <a
                                target="_blank"
                                href={item.additional_attachement_file}
                                rel="noreferrer">
                                <span>
                                  <Col lg={5} xs={5}>
                                    <span>{item.additional_attachement_name}</span>
                                  </Col>
                                  <div className="preview-image">
                                    <img
                                      src={imagePreviewFromik(item.additional_attachement_file)}
                                      alt="profile_img"
                                    />
                                  </div>
                                </span>
                              </a>
                            ) : (
                              <span>
                                <Col lg={5} xs={5}>
                                  <span>{item.additional_attachement_name}</span>
                                </Col>
                                <div className="preview-image">
                                  <img
                                    src={imagePreviewFromik(item.additional_attachement_file)}
                                    alt="profile_img"
                                    onClick={() => {
                                      setModalImgUrl(item.additional_attachement_file);
                                      setModalShow(true);
                                    }}
                                  />
                                </div>
                              </span>
                            )}
                          </Col>
                        );
                      })}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <div>
        <div className="primary-button">
          <Link to={`/warehouse/list`} className="link-center pe-0">
            {t('page.add_warehouse_cancel_button')}
          </Link>
        </div>
        <Modal show={modalShow} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body className="text-center">
            <div className="close-popup">
              <FontAwesomeIcon icon={faClose} onClick={() => setModalShow(false)} />
            </div>
            <img className="text-center" src={modalImgUrl} alt="profile_img" />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
ViewWarehousePage.propTypes = {
  t: PropTypes.func
};
export { ViewWarehousePage };
