import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ManifestList = React.lazy(() => import('./Manifests'));
const ManifestManualCreate = React.lazy(() => import('./ManifestManual'));

const ContainerHistoryRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/" element={<ManifestList t={t} />} />
      <Route index exact path="/create" element={<ManifestManualCreate t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ContainerHistoryRoutes.propTypes = {
  t: PropTypes.func
};
export default ContainerHistoryRoutes;
