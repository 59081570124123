import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useFormikContext } from 'formik';

const AccountingPersonDetails = (props) => {
  const formik = useFormikContext();
  const defaultValue = (option, value) => {
    return option ? option.find((option) => option.value === value) : '';
  };
  const {
    t,
    countryIsLoad,
    countryCodeOptions,
    copyData,
    setCopyData,
    sectionCopy,
    setSectionCopy
  } = props;
  const [isCopy, setIsCopy] = useState(false);
  const [duplicateCopy, setDuplicateCopy] = useState(false);

  const doCopyData = () => {
    let _copyData = {
      ...copyData,
      first_name: formik.values.accounting_first_name,
      last_name: formik.values.accounting_last_name,
      email: formik.values.accounting_email,
      phone: formik.values.accounting_phone
    };

    setCopyData(_copyData);
  };

  useEffect(() => {
    if (isCopy) {
      doCopyData();
    }
  }, [isCopy]);

  useEffect(() => {
    if (duplicateCopy) {
      formik.setFieldValue('accounting_first_name', copyData.first_name);
      formik.setFieldValue('accounting_last_name', copyData.last_name);
      formik.setFieldValue('accounting_email', copyData.email);
      formik.setFieldValue('accounting_phone', copyData.phone);
    }
  }, [duplicateCopy]);

  return (
    <Card>
      <Card.Body>
        {(sectionCopy == 'accountingDetails' || !sectionCopy) && (
          <Form.Check
            style={{ marginRight: '10px' }}
            label={t('page.section_copy_data')}
            checked={sectionCopy == 'accountingDetails' ? true : false}
            onChange={() => {
              setIsCopy(!isCopy);
              setSectionCopy(sectionCopy == 'accountingDetails' ? null : 'accountingDetails');
            }}
          />
        )}
        {sectionCopy && sectionCopy != 'accountingDetails' ? (
          <Form.Check
            label={t('page.section_duplicate_data')}
            checked={duplicateCopy}
            onChange={() => {
              setDuplicateCopy(!duplicateCopy);
            }}
          />
        ) : null}
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_warehouse_accounting_first_name_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.accounting_first_name && formik.errors.accounting_first_name
                    ? 'form-field-error'
                    : formik.touched.accounting_first_name && !formik.errors.accounting_first_name
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="accounting_first_name"
                placeholder={t('page.add_warehouse_accounting_first_name_placeholder')}
                onChange={(event) => {
                  formik.handleChange(event);
                  setIsCopy(false);
                  sectionCopy == 'accountingDetails' && setSectionCopy(null);
                  setDuplicateCopy(false);
                }}
                onBlur={formik.handleBlur}
                value={duplicateCopy ? copyData.first_name : formik.values.accounting_first_name}
              />
              <div className="form-field-error-text">
                {formik.touched.accounting_first_name && formik.errors.accounting_first_name ? (
                  <div>{t(formik.errors.accounting_first_name)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_warehouse_accounting_last_name_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.accounting_last_name && formik.errors.accounting_last_name
                    ? 'form-field-error'
                    : formik.touched.accounting_last_name && !formik.errors.accounting_last_name
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="accounting_last_name"
                placeholder={t('page.add_warehouse_accounting_last_name_placeholder')}
                onChange={(event) => {
                  formik.handleChange(event);
                  setIsCopy(false);
                  sectionCopy == 'accountingDetails' && setSectionCopy(null);
                  setDuplicateCopy(false);
                }}
                onBlur={formik.handleBlur}
                value={duplicateCopy ? copyData.last_name : formik.values.accounting_last_name}
              />
              <div className="form-field-error-text">
                {formik.touched.accounting_last_name && formik.errors.accounting_last_name ? (
                  <div>{t(formik.errors.accounting_last_name)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_warehouse_accounting_email_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.accounting_email && formik.errors.accounting_email
                    ? 'form-field-error'
                    : formik.touched.accounting_email && !formik.errors.accounting_email
                    ? 'form-field-success'
                    : '')
                }
                type="email"
                name="accounting_email"
                placeholder={t('page.add_warehouse_accounting_email_placeholder')}
                onChange={(event) => {
                  formik.handleChange(event);
                  setIsCopy(false);
                  sectionCopy == 'accountingDetails' && setSectionCopy(null);
                  setDuplicateCopy(false);
                }}
                onBlur={formik.handleBlur}
                value={duplicateCopy ? copyData.email : formik.values.accounting_email}
              />
              <div className="form-field-error-text">
                {formik.touched.accounting_email && formik.errors.accounting_email ? (
                  <div>{t(formik.errors.accounting_email)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Label className="field-label field-label-top">
              {t('page.add_warehouse_accounting_phone_label')}
            </Form.Label>
            <Row>
              <Col lg={6} xs={6}>
                <Select
                  name="accounting_country_id"
                  placeholder={t('page.add_warehouse_accounting_accounting_country_id_placeholder')}
                  value={defaultValue(countryCodeOptions, formik.values.accounting_country_id)}
                  defaultValue={formik.values.accounting_country_id}
                  onChange={(selectedOption) => {
                    if (selectedOption !== null) {
                      formik.setFieldValue('accounting_country_id', selectedOption.value);
                    } else {
                      formik.setFieldValue('accounting_country_id', '');
                    }
                  }}
                  className={
                    'contry-code ' +
                    (formik.touched.accounting_country_id && formik.errors.accounting_country_id
                      ? 'form-select-error'
                      : formik.touched.accounting_country_id && !formik.errors.accounting_country_id
                      ? 'form-select-success'
                      : '')
                  }
                  options={countryIsLoad ? countryCodeOptions : countryCodeOptions}
                />
                <div className="form-field-error-text">
                  {formik.touched.accounting_country_id && formik.errors.accounting_country_id ? (
                    <div>{t(formik.errors.accounting_country_id)}</div>
                  ) : null}
                </div>
              </Col>
              <Col lg={6} xs={6}>
                <Form.Control
                  className={
                    'contact-number ' +
                    (formik.touched.accounting_phone && formik.errors.accounting_phone
                      ? 'form-field-error'
                      : formik.touched.accounting_phone && !formik.errors.accounting_phone
                      ? 'form-field-success'
                      : '')
                  }
                  type="text"
                  name="accounting_phone"
                  placeholder={t('page.add_warehouse_accounting_phone_placeholder')}
                  onChange={(event) => {
                    formik.handleChange(event);
                    setIsCopy(false);
                    sectionCopy == 'accountingDetails' && setSectionCopy(null);
                    setDuplicateCopy(false);
                  }}
                  onBlur={formik.handleBlur}
                  value={duplicateCopy ? copyData.phone : formik.values.accounting_phone}
                />
                <div className="form-field-error-text">
                  {formik.touched.accounting_phone && formik.errors.accounting_phone ? (
                    <div>{t(formik.errors.accounting_phone)}</div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
AccountingPersonDetails.propTypes = {
  t: PropTypes.func,
  countryIsLoad: PropTypes.bool,
  countryCodeOptions: PropTypes.any
};
export { AccountingPersonDetails };
