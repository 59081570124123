import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateFormatCommon, dateFormatManualAdd } from '@helpers/commonHelpers';
import { useGetAgentList } from '@hooks';
import client from '@libs/HttpClient';
import { useFormikContext } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { toast } from 'react-toastify';

const ContractDetails = (props) => {
  const formik = useFormikContext();
  const defaultValue = (option, value) => {
    return option ? option.find((option) => option.value === value) : '';
  };
  const { t } = props;

  let agentOptions = [];
  /**
   * !This Api is for territory drop down
   */
  agentOptions.push({
    value: '',
    label: t('page.add_producer_contract_details_contract_agent_id_option_blank')
  });
  const { isSuccess: agentisSuccess, data: agentList } = useGetAgentList();
  if (agentisSuccess !== false) {
    agentList.data.map((item, i) => {
      return agentOptions.push({ value: item.agent_id, label: item.full_name });
    });
  }

  const generateNumber = () => {
    var number =
      'CMI/P/' +
      formik.values.producer_id +
      '/' +
      parseInt(moment().month() + 1) +
      '/' +
      moment().year();

    formik.values.contract_number = number;

    return number;
  };

  const handleContractNumber = (value) => {
    if (value === null || value === '') {
      return generateNumber();
    }
    return value;
  };

  const handleContractStartDate = (value) => {
    if (value || value !== '') return value;

    if (formik.values.contract_status === '1') {
      let today = new Date();

      const aYearFromNow = new Date(today);
      formik.setFieldValue(
        'contract_end_date',
        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
      );
      formik.setFieldValue('contract_start_date', today);

      return today;
    }
  };

  const download = (image) => {
    const fileName = image.split('/').pop();
    image = image.replace(window.origin, '');
    var link = document.createElement('a');
    link.href = image;
    link.target = '_blank';
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click'));
  };

  const [loadingGenerateMOU, setLoadingGenerateMou] = useState(false);

  const doGenerateMOU = async () => {
    setLoadingGenerateMou(true);
    try {
      const response = await client.post(
        `producer/generate-mou-contract/${formik.values.producer_id}`,
        {
          contract_number: formik.values.contract_number,
          start_date: formik.values.contract_start_date,
          end_date: formik.values.contract_end_date
        }
      );

      download(response.data);
    } catch (err) {
      toast.error(err.message ?? 'gagal genereate mou contract');
      console.error(err);
    }
    setLoadingGenerateMou(false);
  };

  const handleResendMou = async () => {
    toast.info('Mengirimkan Kembali MOU...');
    await client
      .post(`mekari-esign/global-sign-mou/${formik.values.producer_id}`)
      .then(() => toast.success('Mengirim Ulang MOU Berhasil'))
      .catch((err) => toast.error(err));
  };

  const handleContractNumberIsReadonly = () => {
    return true;
  };

  const checkValueEmpty = (val, res) => {
    if (!val) toast.error(t(res));
  };

  return (
    <Card className="bg-white rounded">
      <Card.Body>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_contact_details_contract_number_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.contract_number && formik.errors.contract_number
                    ? 'form-field-error'
                    : formik.touched.contract_number && !formik.errors.contract_number
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="contract_number"
                placeholder={t('page.add_producer_contact_details_contract_number_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={handleContractNumber(formik.values.contract_number)}
                disabled={handleContractNumberIsReadonly()}
              />
              <div className="form-field-error-text">
                {formik.touched.contract_number && formik.errors.contract_number ? (
                  <div>{t(formik.errors.contract_number)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_contract_details_contract_start_date_label')}
              </Form.Label>
              <DatePicker
                onKeyDown={dateFormatManualAdd}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column"
                placeholderText={dateFormatCommon().toUpperCase()}
                maxDate={
                  formik.values.contract_end_date !== '' &&
                  formik.values.contract_end_date !== undefined
                    ? formik.values.contract_end_date
                    : new Date()
                }
                selected={handleContractStartDate(formik.values.contract_start_date)}
                dateFormat={dateFormatCommon()}
                onChange={(date) => {
                  formik.setFieldValue('contract_start_date', date);
                }}
                onSelect={(date) => {
                  const aYearFromNow = new Date(date);
                  formik.setFieldValue(
                    'contract_end_date',
                    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
                  );
                }}
                onBlur={(date) => {
                  var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');

                  if (date.currentTarget.value) {
                    if (!date1.isValid()) {
                      toast.error(t('page.invalid_date_format'));
                      formik.setFieldValue('contract_start_date', '');
                    } else {
                      const aYearFromNow = new Date(date1);
                      formik.setFieldValue(
                        'contract_end_date',
                        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
                      );
                    }
                  } else {
                    formik.setFieldValue('contract_start_date', '');
                  }
                }}
              />
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.contract_start_date && formik.errors.contract_start_date
                    ? 'form-field-error'
                    : formik.touched.contract_start_date && !formik.errors.contract_start_date
                    ? 'form-field-success'
                    : '')
                }
                type="hidden"
                name="contract_start_date"
                placeholder={t(
                  'page.add_producer_contract_details_contract_start_date_placeholder'
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contract_start_date}
              />
              <div className="form-field-error-text">
                {formik.touched.contract_start_date && formik.errors.contract_start_date ? (
                  <div>{t(formik.errors.contract_start_date)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_contract_details_contract_end_date_label')}
                <span className="preview-image">
                  {formik.values.contract_start_date !== '' &&
                  formik.values.contract_end_date !== '' ? (
                    <FontAwesomeIcon
                      icon={faClose}
                      onClick={() => {
                        formik.setFieldValue('contract_end_date', '');
                      }}
                    />
                  ) : (
                    ''
                  )}
                </span>
              </Form.Label>
              <DatePicker
                onKeyDown={dateFormatManualAdd}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column"
                placeholderText={dateFormatCommon().toUpperCase()}
                selected={formik.values.contract_end_date}
                dateFormat={dateFormatCommon()}
                minDate={formik.values.contract_start_date}
                onChange={(date) => {
                  formik.setFieldValue('contract_end_date', date);
                }}
                onBlur={(date) => {
                  var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                  if (date.currentTarget.value) {
                    if (!date1.isValid()) {
                      toast.error(t('page.invalid_date_format'));
                      formik.setFieldValue('contract_end_date', '');
                    } else {
                      var minDate =
                        formik.values.contract_start_date !== '' &&
                        formik.values.contract_start_date !== undefined
                          ? formik.values.contract_start_date
                          : new Date();
                      if (date1 < minDate) {
                        toast.error(t('page.to_date_greater_than_from_Date'));
                        formik.setFieldValue('contract_end_date', '');
                      }
                    }
                  }
                }}
              />
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.contract_end_date && formik.errors.contract_end_date
                    ? 'form-field-error'
                    : formik.touched.contract_end_date && !formik.errors.contract_end_date
                    ? 'form-field-success'
                    : '')
                }
                type="hidden"
                name="contract_end_date"
                placeholder={t('page.add_producer_contract_details_contract_end_date_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contract_end_date}
              />
              <div className="form-field-error-text">
                {formik.touched.contract_end_date && formik.errors.contract_end_date ? (
                  <div>{t(formik.errors.contract_end_date)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_contract_details_contract_agent_id_label')}
              </Form.Label>
              <Select
                isClearable={true}
                name="agent_id"
                options={agentOptions}
                placeholder={t('page.add_producer_contract_details_contract_agent_id_placeholder')}
                onChange={(selected) => {
                  if (selected !== null) {
                    formik.setFieldValue('agent_id', selected.value);
                  } else {
                    formik.setFieldValue('agent_id', '');
                  }
                }}
                defaultValue={formik.values.agent_id}
                value={defaultValue(agentOptions, formik.values.agent_id)}
                onBlur={formik.handleBlur}
                className={
                  'form-field ' +
                  (formik.touched.agent_id && formik.errors.agent_id
                    ? 'form-select-error'
                    : formik.touched.agent_id && !formik.errors.agent_id
                    ? 'form-select-success'
                    : '')
                }
              />
              <div className="form-field-error-text">
                {formik.touched.agent_id && formik.errors.agent_id ? (
                  <div>{t(formik.errors.agent_id)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.surat_keputusan')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.surat_keputusan && formik.errors.surat_keputusan
                    ? 'form-field-error'
                    : formik.touched.surat_keputusan && !formik.errors.surat_keputusan
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="surat_keputusan"
                placeholder={t('page.surat_keputusan_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.surat_keputusan}
              />
              <div className="form-field-error-text">
                {formik.touched.surat_keputusan && formik.errors.surat_keputusan ? (
                  <div>{t(formik.errors.surat_keputusan)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.nomor_surat_keputusan')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.nomor_surat_keputusan && formik.errors.nomor_surat_keputusan
                    ? 'form-field-error'
                    : formik.touched.nomor_surat_keputusan && !formik.errors.nomor_surat_keputusan
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="nomor_surat_keputusan"
                placeholder={t('page.nomor_surat_keputusan_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nomor_surat_keputusan}
              />
              <div className="form-field-error-text">
                {formik.touched.nomor_surat_keputusan && formik.errors.nomor_surat_keputusan ? (
                  <div>{t(formik.errors.nomor_surat_keputusan)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4}>
            <Form.Label className="field-label field-label-top">
              {t('page.add_producer_contact_details_contract_generate_mou_label')}
            </Form.Label>
            <br />
            <button
              type="button"
              className="btn btn-lg btn-outline-primary"
              disabled={loadingGenerateMOU || formik.values.is_collect_container === 1}
              onClick={() => {
                if (!formik.dirty) {
                  const check = formik.values;
                  if (
                    check.surat_keputusan &&
                    check.nomor_surat_keputusan &&
                    check.name &&
                    check.owner_first_name &&
                    check.owner_last_name &&
                    check.contract_start_date &&
                    check.contract_end_date &&
                    check.address
                  ) {
                    doGenerateMOU();
                  } else {
                    checkValueEmpty(
                      check.nomor_surat_keputusan,
                      'page.nomor_surat_keputusan_diperlukan'
                    );
                    checkValueEmpty(check.surat_keputusan, 'page.surat_keputusan_diperlukan');
                    checkValueEmpty(
                      check.name,
                      'page.add_producer_general_details_producer_name_validation_required'
                    );
                    checkValueEmpty(
                      check.owner_first_name,
                      'page.add_producer_owner_details_first_name_validation_required'
                    );
                    checkValueEmpty(
                      check.owner_last_name,
                      'page.add_producer_owner_details_last_name_validation_required'
                    );
                    checkValueEmpty(
                      check.contract_start_date,
                      'page.add_producer_contract_details_contract_start_date_validation_required'
                    );
                    checkValueEmpty(
                      check.contract_end_date,
                      'page.add_producer_contract_details_contract_end_date_validation_required'
                    );
                    checkValueEmpty(
                      check.address,
                      'page.add_producer_general_details_address_validation_required'
                    );
                  }
                } else toast.error(t('page.add_producer_contract_detail_must_save_first'));
              }}>
              {t('page.add_producer_contact_details_contract_generate_mou')}
            </button>
          </Col>
          {formik.values.mou_contract && (
            <Col sm={12} md={4}>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_contact_details_contract_download_mou_label')}
              </Form.Label>
              <br />
              <a
                className="btn btn-lg btn-outline-secondary"
                href={formik.values.mou_contract + '?t=' + Date.now()}
                rel="noreferrer"
                target="_blank">
                {t('page.add_producer_contact_details_contract_download_mou')}
              </a>
            </Col>
          )}
          {formik.values.mou_status === 1 && formik.values.is_collect_container === 0 && (
            <Col sm={12} md={4}>
              <Form.Label className="field-label field-label-top">Resend MOU</Form.Label>
              <br />
              <button
                className="btn btn-lg btn-outline-primary"
                type="button"
                onClick={handleResendMou}>
                Resend MOU
              </button>
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};
ContractDetails.propTypes = {
  t: PropTypes.func
};
export { ContractDetails };
