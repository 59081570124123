import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ContainerRecycleHistoryList = React.lazy(() => import('./ContainerRecycleHistoryList'));
const ContainerRecycleHistoryDetail = React.lazy(() => import('./ContainerRecycleHistoryDetail'));

const ContainerRecycleHistoryRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/" element={<ContainerRecycleHistoryList t={t} />} />
      <Route exact path="/:id" element={<ContainerRecycleHistoryDetail t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ContainerRecycleHistoryRoutes.propTypes = {
  t: PropTypes.func
};
export default ContainerRecycleHistoryRoutes;
