import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

function AlertCommon(props) {
  const [show, setShow] = useState(true);
  if (!props.is_error) {
    return null;
  }
  if (show) {
    return (
      <Alert
        key="danger"
        onClose={() => {
          setShow(false);
        }}
        variant="danger"
        dismissible>
        {props.children !== undefined &&
        props.children !== null &&
        props.children.message !== undefined
          ? props.children.message
          : ''}
      </Alert>
    );
  }
}

AlertCommon.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.any,
  is_error: PropTypes.any
};
export default AlertCommon;
