import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { RealTimeSettingService } from '@api/';
const onDefaultError = (error) => {
  toast.error(error.message);
};

/**
 * First Half Setting
 */

const useGetTourRealTimeSettingData = (onSuccess, onError) => {
  return useQuery(
    'real_time_setting',
    () => {
      return RealTimeSettingService.getTourRealTimeSettingData();
    },
    {
      onSuccess,
      onError
    }
  );
};

const useStoreTourRealTimeSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(RealTimeSettingService.storeTourRealTimeSettingData, {
    onSuccess,
    onError
  });
};

export { useGetTourRealTimeSettingData, useStoreTourRealTimeSettingData };
