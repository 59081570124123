import { useMutation, useQuery } from 'react-query';

import { TruckService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListTrucks = (
  [page_no, searchText, status, transporter_id, truckType, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['trucks-list', page_no, searchText, status, transporter_id, truckType, sortBy, sortOrder],
    TruckService.listTrucks,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
const useTruckDelete = ([truck_id], onSuccess, onError = onDefaultError) => {
  const sent = truck_id ? true : false;
  return useQuery(['trucks/destroy', truck_id], TruckService.deleteTruck, {
    onSuccess,
    onError,
    enabled: sent,
    retry: 0
  });
};

const useStoreTrucks = (onSuccess, onError = onDefaultError) => {
  return useMutation(TruckService.storeTruck, {
    onSuccess,
    onError
  });
};

const useViewTruck = (truck_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['truck-view', truck_id], TruckService.viewTruck, {
    onSuccess,
    onError
  });
};
const useUpdateTruck = (onSuccess, onError = onDefaultError) => {
  return useMutation(TruckService.updateTruck, {
    onSuccess,
    onError
  });
};

const useDeleteTruckAttachment = ([attachment_id, type], onSuccess, onError = onDefaultError) => {
  const sent = attachment_id ? true : false;
  return useQuery(
    ['driver-attachment-delete', attachment_id, type],
    TruckService.deleteTruckAttachment,
    {
      onSuccess,
      onError,
      enabled: sent,
      retry: 0
    }
  );
};
const useGenerateTruckReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(TruckService.generateReport, {
    onSuccess,
    onError
  });
};

export {
  useListTrucks,
  useTruckDelete,
  useStoreTrucks,
  useViewTruck,
  useUpdateTruck,
  useDeleteTruckAttachment,
  useGenerateTruckReport
};
