import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ContainerDetailPage = React.lazy(() => import('./ContainerDetailPage'));
const ContainerList = React.lazy(() => import('./ContainerList'));
const ContainerAssign = React.lazy(() => import('./ContainerAssign'));
const ContainerPostpaidPricing = React.lazy(() => import('./ContainerPostpaidPricing'));

const ContainerRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ContainerList t={t} />} />
      <Route index exact path="/assign" element={<ContainerAssign t={t} />} />
      <Route index exact path="/container-details" element={<ContainerDetailPage t={t} />} />
      <Route index exact path="/postpaid-tier" element={<ContainerPostpaidPricing t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ContainerRoutes.propTypes = {
  t: PropTypes.func
};
export default ContainerRoutes;
