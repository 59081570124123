import { TransporterStepperService } from '@api/';
import { useMutation, useQuery } from 'react-query';

const { toast } = require('react-toastify');

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useTransporterGetGeneralDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'view-generaldetail-transporter-stepper',
    () => TransporterStepperService.getGeneralDetails(),
    {
      onSuccess,
      onError
    }
  );
};

const useTransporterStoreGeneralDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(TransporterStepperService.storeGeneralDetails, {
    onSuccess,
    onError
  });
};

const useTransporterGetOwnerDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'view-ownerdetail-transporter-stepper',
    () => TransporterStepperService.getOwnerDetails(),
    {
      onSuccess,
      onError
    }
  );
};

const useTransporterStoreOwnerDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(TransporterStepperService.storeOwnerDetails, {
    onSuccess,
    onError
  });
};
const useGetTransporterPersonInChargeDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'view-person-incharge-transporter-stepper',
    () => TransporterStepperService.getPersonInChargeDetails(),
    {
      onSuccess,
      onError
    }
  );
};

const useStoreTransporterPersonInChargeDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(TransporterStepperService.storePersonInchargeDetails, {
    onSuccess,
    onError
  });
};

const useGetTransporterAccountingDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'view-accounting-detail-transporter-stepper',
    () => TransporterStepperService.getAccountingDetails(),
    {
      onSuccess,
      onError
    }
  );
};

const useStoreTransporterAccountingDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(TransporterStepperService.storeAccountingDetails, {
    onSuccess,
    onError
  });
};

const useGetTransporterSurveillanceCard = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'view-surveillance-card-transporter-stepper',
    () => TransporterStepperService.getSurveillanceCard(),
    {
      onSuccess,
      onError
    }
  );
};

const useStoreTransporterSurveillanceCard = (onSuccess, onError = onDefaultError) => {
  return useMutation(TransporterStepperService.storeSurveillanceCard, {
    onSuccess,
    onError
  });
};

const useGetTransporterAttchmentsDetails = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'view-attachment-transporter-stepper',
    () => TransporterStepperService.getAttchmentsDetails(),
    {
      onSuccess,
      onError
    }
  );
};

const useStoreTransporterAttchmentsDetails = (onSuccess, onError = onDefaultError) => {
  return useMutation(TransporterStepperService.storeAttchmentsDetails, {
    onSuccess,
    onError
  });
};

const useSignupDeleteTransporterAttachment = (onSuccess, onError = onDefaultError) => {
  return useMutation(TransporterStepperService.deleteAttchmentsDetails, {
    onSuccess,
    onError
  });
};

export {
  useTransporterGetGeneralDetails,
  useTransporterStoreGeneralDetails,
  useTransporterGetOwnerDetails,
  useTransporterStoreOwnerDetails,
  useGetTransporterPersonInChargeDetails,
  useStoreTransporterPersonInChargeDetails,
  useGetTransporterAccountingDetails,
  useStoreTransporterAccountingDetails,
  useGetTransporterSurveillanceCard,
  useStoreTransporterSurveillanceCard,
  useGetTransporterAttchmentsDetails,
  useStoreTransporterAttchmentsDetails,
  useSignupDeleteTransporterAttachment
};
