import TNBreadCurm from '@common/components/TNBreadCurm';
import TNTable from '@common/components/TNTable';
import client from '@libs/HttpClient';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Nav, Row } from 'react-bootstrap';
import { Link, NavLink, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export function FactoryProducer({ t }) {
  let { factory_id } = useParams();

  const [producer, setProducer] = useState(null);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [paginationData, setPaginationData] = useState(null);

  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
  };

  const handleSearch = (searchText) => {
    setCurrentPage(1);
    setSearch(searchText.target.value);
  };

  const clearSearch = () => {
    setCurrentPage(1);
    setSearch('');
  };

  const handleDelete = async (id) => {
    setLoading(true);
    const resp = await client.delete(`/factory/${factory_id}/producer/${id}`);
    toast.success(resp.message);
    getProducer();
    setLoading(false);
  };

  const getProducer = useCallback(async () => {
    const { data, pagination } = await client.get(`/factory/${factory_id}/producer`, {
      params: {
        page: currentPage,
        producer: search
      }
    });

    setProducer(data);
    setPaginationData(pagination);
  }, [currentPage, factory_id, search]);

  useEffect(() => {
    getProducer();
  }, [getProducer]);

  const breadcurmArray = [
    {
      label: t('page.factory_list_title'),
      link: '/factory/list'
    },

    {
      label: t('page.producer'),
      active: 'active'
    }
  ];

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      disableSortBy: true
    },
    {
      Header: 'Producer',
      accessor: 'producer.name',
      disableSortBy: true
    },
    {
      Header: t('page.price'),
      accessor: 'price',
      disableSortBy: true,
      Cell: ({ value }) =>
        Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
          minimumFractionDigits: 0
        }).format(value)
    },
    {
      Header: t('page.created_at'),
      accessor: 'created_at',
      disableSortBy: true,
      Cell: ({ value }) => <span>{moment(value).format('DD-MM-YYYY HH:mm')}</span>
    },
    // {
    //   Header: 'Tipe',
    //   accessor: 'tour_producer_id',
    //   disableSortBy: true,
    //   Cell: ({ value }) => <span>{value === null ? 'Manual' : 'Sistem'}</span>
    // },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center gap-4 ">
            <Link disabled={loading} className="btn btn-warning" to={`${row.original.id}`}>
              {t('page.edit')}
            </Link>
            <Button
              disabled={loading}
              className="btn btn-danger"
              onClick={() => handleDelete(row.original.id)}>
              {t('page.delete')}
            </Button>
          </div>
        );
      },
      disableSortBy: true
    }
  ];

  if (!producer) {
    return <>loading</>;
  }

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />

      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to={`/factory/view/${factory_id}`}>{t('page.general_details')}</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/factory/${factory_id}/producer`}>{t('page.producer')}</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/factory/${factory_id}/pricing`}>Harga</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/factory/${factory_id}/delivery/point/user/list`}>
            {t('page.delivery_point_user')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/factory/${factory_id}/scan/point/list`}>
            {t('page.manage_scan_points')}
          </NavLink>
        </Nav.Item>
      </Nav>
      <h1 className="page-heading-center"> {t('page.list_factory_producer')} </h1>

      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group controlId="search" className="filter-field-space">
              <Form.Control
                placeholder="Cari Producer"
                className="filter-column form-field"
                onChange={handleSearch}
                value={search}
              />
            </Form.Group>
            <Button
              type="button"
              size="sm"
              onClick={clearSearch}
              className="secondary-remove-button ml-0">
              {t('page.cancel_search_button')}
            </Button>

            <Link to="new">
              <Button className="table-primary-button">{t('page.add_producer')}</Button>
            </Link>
          </div>
        </Col>
      </Row>
      <TNTable
        columns={columns}
        data={producer}
        paginationData={paginationData}
        onSelectPage={pageIndexHandle}
        t={t}
        key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
        pageIndexGet={currentPage - 1}
      />
    </>
  );
}
