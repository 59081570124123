import client from '@libs/HttpClient';
class AvailableCollectService {
  static listAvailableCollect(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const territryId = request.queryKey[3];
    const areaId = request.queryKey[4];
    const selectedType = request.queryKey[5];
    const sortOrder = request.queryKey[6];
    const sortBy = request.queryKey[7];
    return client.get(
      `tour/avialable-tour?page=${page_no}&search_text=${searchText}&territory_id=${territryId}&area_id=${areaId}&type=${selectedType}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }
  static storeAvailableCollect(request) {
    return client.post('/tour/assign-driver', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static viewAvailableTour(request) {
    return client.get(`/tour/view-avialable-tour/${request}`);
  }

  static viewAvailableTourDriver(request) {
    return client.post(`/tour/view-avialable-tour-driver`, request);
  }

  static runCronTour(request) {
    return client.post(`/tour/run-cron-tour/${request.type}`);
  }
  static generateReport(request) {
    return client.post(`/tour/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { AvailableCollectService };
