import client from '@libs/HttpClient';
class PrintStickerServices {
  static getList(request) {
    const page = request.queryKey[1];
    return client.get(`/print-request/list?page=${page}`, request);
  }

  static getDetail(request) {
    const id = request.queryKey[1];
    return client.get(`/print-request/detail/${id}`, request);
  }

  static requestSticker(request) {
    return client.post('/print-request/add', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static changeStatus(request, id) {
    return client.post(`/print-request/status/${id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { PrintStickerServices };
