import { useMutation, useQuery } from 'react-query';

import { StockServices } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user Stocks
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useUpdateStockHistory = (onSuccess, onError = onDefaultError) => {
  return useMutation(StockServices.updateStock, {
    onSuccess,
    onError
  });
};

const useListStocksHistory = (
  [warehouse_id, page_no, searchText, filterType, actionBy, fromDate, toDate, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'own-stock-history',
      warehouse_id,
      page_no,
      searchText,
      filterType,
      actionBy,
      fromDate,
      toDate,
      sortBy,
      sortOrder
    ],
    StockServices.getListStock,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useListStockByWarehouse = (
  [warehouse_id, fromDate, toDate],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['warehouse-stock', warehouse_id, fromDate, toDate],
    StockServices.getListStockByWarehouse,
    {
      onSuccess,
      onError
    }
  );
};

const useOwnWarehouseList = (onSuccess, onError = onDefaultError) => {
  return useQuery('own-warehouse-stocks', StockServices.ownWarehouseList, {
    onSuccess,
    onError
  });
};

const useContainerVariableList = (onSuccess, onError = onDefaultError) => {
  return useQuery('container-variable-list', StockServices.containerVariableList, {
    onSuccess,
    onError
  });
};
const useGenerateStockHistoryReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(StockServices.generateReport, {
    onSuccess,
    onError
  });
};
export {
  useUpdateStockHistory,
  useListStocksHistory,
  useOwnWarehouseList,
  useContainerVariableList,
  useListStockByWarehouse,
  useGenerateStockHistoryReport
};
