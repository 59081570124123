import client from '@libs/HttpClient';
class DeliveryChargeService {
  static storeDeliveryCharge(request) {
    return client.post('delivery-charges/store', request);
  }

  static viewDeliveryCharge(request) {
    const delivery_charge_id = request.queryKey[1];
    return client.get(`/delivery-charges/view/${delivery_charge_id}`, request);
  }

  static updateDeliveryCharge(request) {
    return client.post(`delivery-charges/update/${request.delivery_charge_id}`, request);
  }

  /* delivery point user */
  static listDeliveryCharges(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const userStatus = request.queryKey[3];
    const sortBy = request.queryKey[4];
    const sortOrder = request.queryKey[5];

    return client.get(
      `/delivery-charges/index?page=${page_no}&territory_id=${searchText}&area_id=${userStatus}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }
  static deleteDeliveryCharge(request) {
    const delivery_charge_id = request.queryKey[1];
    return client.get(`/delivery-charges/destroy/${delivery_charge_id}`, request);
  }
  static generateReport(request) {
    return client.post(`/delivery-charges/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}

export { DeliveryChargeService };
