import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { dateFormatCommon, dateFormatManualAdd } from '@helpers/commonHelpers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { imagePreviewFromik } from '@helpers/';

const StnkDetails = (props) => {
  const formik = useFormikContext();

  let registrationAttachmentRef = useRef();

  const handleStnk = () => {
    registrationAttachmentRef.current?.click();
  };

  const { t } = props;

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_trucks_stnk_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.stnk && formik.errors.stnk
                    ? 'form-field-error'
                    : formik.touched.stnk && !formik.errors.stnk
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="stnk"
                placeholder={t('page.add_trucks_stnk_label_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.stnk}
              />
              <div className="form-field-error-text">
                {formik.touched.stnk && formik.errors.stnk ? (
                  <div>{t(formik.errors.stnk)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_trucks_stnk_expired_date_label')}
              </Form.Label>
              <DatePicker
                onKeyDown={dateFormatManualAdd}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column"
                placeholderText={dateFormatCommon().toUpperCase()}
                selected={formik.values.stnk_expired_date}
                dateFormat={dateFormatCommon()}
                minDate={new Date()}
                onChange={(date) => {
                  formik.setFieldValue('stnk_expired_date', date);
                }}
                onBlur={(date) => {
                  var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                  if (date.currentTarget.value) {
                    if (!date1.isValid()) {
                      toast.error(t('page.invalid_date_format'));
                      formik.setFieldValue('stnk_expired_date', '');
                    } else {
                      var currentdate = new Date();
                      if (date1 < currentdate) {
                        toast.error(t('page.from_date_less_than_to_Date'));
                        formik.setFieldValue('stnk_expired_date', '');
                      }
                    }
                  }
                }}
              />
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.stnk_expired_date && formik.errors.stnk_expired_date
                    ? 'form-field-error'
                    : formik.touched.stnk_expired_date && !formik.errors.stnk_expired_date
                    ? 'form-field-success'
                    : '')
                }
                type="hidden"
                name="stnk_expired_date"
                placeholder={t('page.add_trucks_stnk_expired_date_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.stnk_expired_date}
              />
              <div className="form-field-error-text">
                {formik.touched.stnk_expired_date && formik.errors.stnk_expired_date ? (
                  <div>{t(formik.errors.stnk_expired_date)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}></Col>
        </Row>
        <Row className="mt-3">
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label">
                {t('page.add_truck_registration_card_label')}
              </Form.Label>
              <Form.Control
                type="file"
                hidden
                accept="image/*,.pdf"
                ref={registrationAttachmentRef}
                name="registration_card"
                placeholder={t('page.add_truck_registration_card_label_placeholder')}
                onChange={(event) => {
                  formik.setFieldValue('registration_card', event.currentTarget.files[0]);
                }}
              />
              <Button
                type="button"
                onClick={handleStnk}
                className={
                  'upload-button' ||
                  (formik.touched.registration_card && formik.errors.registration_card
                    ? 'form-field-error'
                    : formik.touched.registration_card && !formik.errors.registration_card
                    ? 'form-field-success'
                    : '')
                }>
                {t('page.file_upload')}
              </Button>
            </Form.Group>
            <div className="form-field-error-text">
              {formik.touched.registration_card && formik.errors.registration_card ? (
                <div>{t(formik.errors.registration_card)}</div>
              ) : null}
            </div>
          </Col>
          <Col sm={12} md={4}></Col>
          <Col sm={12} md={4}></Col>
        </Row>
        <Row>
          <Col sm={12} md={4}>
            {formik.values.registration_card && (
              <div className="preview-image">
                <img src={imagePreviewFromik(formik.values.registration_card)} alt="profile_img" />
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={() => {
                    formik.setFieldValue('registration_card', '');
                  }}
                />
              </div>
            )}
          </Col>
          <Col sm={12} md={4}></Col>
          <Col sm={12} md={4}></Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
StnkDetails.propTypes = {
  t: PropTypes.func
};
export { StnkDetails };
