import client from '@libs/HttpClient';
class DriverService {
  static storeDriver(request) {
    return client.post('driver/store', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static listDriver(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const status_admin = request.queryKey[4];
    const transporter_id = request.queryKey[5];
    const sortBy = request.queryKey[6];
    const sortOrder = request.queryKey[7];

    return client.get(
      `/driver/index?page=${page_no}&search_text=${searchText}&user_status=${status}&admin_status=${status_admin}&transporter_id=${transporter_id}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }
  static deleteDriver(request) {
    const driver_id = request.queryKey[1];
    return client.get(`/driver/destroy/${driver_id}`, request);
  }
  static viewDriver(request) {
    const driver_id = request.queryKey[1];
    return client.get(`/driver/view/${driver_id}`, request);
  }
  static updateDriver(request) {
    return client.post(`/driver/update/${request.driver_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static DeleteDriverAttachment(request) {
    const attachment_id = request.queryKey[1];
    const type = request.queryKey[2];
    return client.get(`/driver/delete-attachment/${attachment_id}/${type}`, request);
  }

  static generateReport(request) {
    return client.post(`/driver/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { DriverService };
