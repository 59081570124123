import TNBreadCurm from '@common/components/TNBreadCurm';
import { Nav, InputGroup, Form } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import client from '@libs/HttpClient';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import Select from 'react-select';

const formatter = Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
  minimumFractionDigits: 0
});

const PricingRow = ({ factory_id, pricing, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(pricing.price);
  const [edit, setEdit] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const update = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await client.put(`v2/factory/${factory_id}/pricing`, {
        warehouse_id: pricing.warehouse_id,
        price: price
      });
      toast.success('Berhasil mengubah harga pabrik');
      await refresh();
    } catch (error) {
      toast.error('Gagal mengubah harga pabrik');
      console.error(error);
    }
    setLoading(false);
  };

  const deletePricing = async () => {
    if (!deleteConfirm) {
      setDeleteConfirm(true);
      setTimeout(() => {
        setDeleteConfirm(false);
      }, 5000);
      return;
    }

    setLoading(true);
    try {
      await client.delete(`v2/factory/${factory_id}/pricing/${pricing.id}`);
      toast.success('Berhasil menghapus harga pabrik');
      await refresh();
    } catch (error) {
      toast.error('Gagal menghapus harga pabrik');
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <tr>
      <td>
        <form id={`edit_${pricing.id}`} onSubmit={update} />
        {pricing.warehouse.name}
      </td>
      <td>
        {edit ? (
          <InputGroup>
            <InputGroup.Text>Rp</InputGroup.Text>
            <Form.Control
              type="number"
              placeholder="Harga"
              onInput={(value) => setPrice(value.target.value)}
              value={price}
              form={`edit_${pricing.id}`}
              required
              style={{ width: '150px' }}
            />
            <InputGroup.Text>/kg</InputGroup.Text>
          </InputGroup>
        ) : (
          formatter.format(pricing.price)
        )}
      </td>
      <td>
        {edit ? (
          <>
            <button
              className="btn btn-primary me-2"
              disabled={loading}
              form={`edit_${pricing.id}`}
              type="submit"
              key="submitButton">
              Simpan
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setEdit(false);
              }}>
              Batal
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-primary me-2"
              type="button"
              onClick={() => {
                setEdit(true);
              }}>
              Edit
            </button>
            <button type="button" className="btn btn-danger" onClick={deletePricing}>
              {deleteConfirm ? 'Yakin hapus?' : 'Hapus'}
            </button>
          </>
        )}
      </td>
    </tr>
  );
};

const FactoryPricing = ({ t }) => {
  let { factory_id } = useParams();
  const [pricings, setPricings] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [price, setPrice] = useState('');
  const [loading, setLoading] = useState(false);

  const breadcurmArray = [
    {
      label: t('page.factory_list_title'),
      link: '/factory/list'
    },

    {
      label: 'Harga Pabrik',
      active: 'active'
    }
  ];

  const getWarehouseList = async () => {
    try {
      const { data } = await client.get(`warehouse-list`);
      setWarehouses(data);
    } catch (error) {
      toast.error('Gagal mengambil daftar gudang');
      console.error(error);
    }
  };

  const getPricings = useCallback(async () => {
    setLoading(true);
    try {
      const data = await client.get(`v2/factory/${factory_id}/pricing`);
      setPricings(data);
    } catch (error) {
      toast.error('Gagal mengambil harga pabrik');
      console.error(error);
    }
    setLoading(false);
  }, [factory_id]);

  const updateWarehouseOptions = useCallback(() => {
    const options = warehouses
      .filter((e) => !pricings.find((f) => f.warehouse_id === e.warehouse_id))
      .map((warehouse) => ({
        value: warehouse.warehouse_id,
        label: warehouse.name
      }));
    setWarehouseOptions(options);
    setSelectedWarehouse(null);
  }, [pricings, warehouses]);

  const create = async (e) => {
    e.preventDefault();
    if (selectedWarehouse === null) {
      toast.error('Silahkan pilih gudang');
      return;
    }

    setLoading(true);
    try {
      await client.post(`v2/factory/${factory_id}/pricing`, {
        warehouse_id: selectedWarehouse.value,
        price: price
      });
      toast.success('Berhasil menambahkan harga pabrik');
      await getPricings();
      updateWarehouseOptions();
      setPrice('');
    } catch (error) {
      toast.error('Gagal menambahkan harga pabrik');
      console.error(error);
    }
    setLoading(false);
  };

  const refresh = async () => {
    await getPricings();
    updateWarehouseOptions();
  };

  useEffect(() => {
    async function getData() {
      await getWarehouseList();
      await getPricings();
    }
    getData();
  }, [getPricings]);

  useEffect(() => {
    updateWarehouseOptions();
  }, [updateWarehouseOptions]);

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />
      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to={`/factory/view/${factory_id}`}>{t('page.general_details')}</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/factory/${factory_id}/producer`}>{t('page.producer')}</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/factory/${factory_id}/pricing`}>Harga</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/factory/${factory_id}/delivery/point/user/list`}>
            {t('page.delivery_point_user')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/factory/${factory_id}/scan/point/list`}>
            {t('page.manage_scan_points')}
          </NavLink>
        </Nav.Item>
      </Nav>
      <h1>Harga Pabrik</h1>
      <form id="create" onSubmit={create}></form>
      <table className="simple-table">
        <thead>
          <tr>
            <td>Asal Gudang</td>
            <td>Harga/kg</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {!loading && pricings.length === 0 && (
            <tr>
              <td colSpan="3">Tidak ada data</td>
            </tr>
          )}
          {loading && (
            <tr>
              <td colSpan="3">Loading...</td>
            </tr>
          )}
          {!loading &&
            pricings.map((pricing) => (
              <PricingRow
                key={pricing.id}
                pricing={pricing}
                factory_id={factory_id}
                refresh={refresh}
              />
            ))}
          <tr>
            <td>
              <Select
                value={selectedWarehouse}
                onChange={(value) => setSelectedWarehouse(value)}
                options={warehouseOptions}
                form="create"
                required
              />
            </td>
            <td>
              <InputGroup>
                <InputGroup.Text>Rp</InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Harga"
                  onInput={(value) => setPrice(value.target.value)}
                  value={price}
                  form="create"
                  required
                  style={{ width: '150px' }}
                />
                <InputGroup.Text>/kg</InputGroup.Text>
              </InputGroup>
            </td>
            <td>
              <button disabled={loading} className="btn btn-primary" form="create" type="submit">
                Simpan
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default FactoryPricing;
