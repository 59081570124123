import { useMutation, useQuery } from 'react-query';

import { UserService } from '@api/';
import { toast } from 'react-toastify';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetProfile = (onSuccess, onError = onDefaultError) => {
  return useQuery('get-profile', UserService.getProfile, {
    onSuccess,
    onError
  });
};

const useGetCountryCodeList = (onSuccess, onError = onDefaultError) => {
  return useQuery('country-code-list', UserService.getCountryCodeList, {
    onSuccess,
    onError
  });
};

const useUpdateProfile = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserService.updateProfile, {
    onSuccess,
    onError
  });
};

const useChangePassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserService.updatePassword, {
    onSuccess,
    onError
  });
};
const useUpdateEmailVerify = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserService.updateEmailVerify, {
    onSuccess,
    onError
  });
};
const useUpdateEmail = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserService.updateEmail, {
    onSuccess,
    onError
  });
};

export {
  useGetProfile,
  useUpdateProfile,
  useChangePassword,
  useGetCountryCodeList,
  useUpdateEmailVerify,
  useUpdateEmail
};
