import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListAbortPage = React.lazy(() => import('./ListAbortPage'));
const AddAbortPage = React.lazy(() => import('./AddAbortPage'));
const EditAbortPage = React.lazy(() => import('./EditAbortPage'));

const AbortRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index path="/list" element={<ListAbortPage t={t} />} />
      <Route path="/add" element={<AddAbortPage t={t} />} />
      <Route path="/edit/:abort_reason_id" element={<EditAbortPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AbortRoutes.propTypes = {
  t: PropTypes.func
};
export default AbortRoutes;
