import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const AddCouponPage = React.lazy(() => import('./AddCouponPage'));
const EditCouponPage = React.lazy(() => import('./EditCouponPage'));
const ViewCouponPage = React.lazy(() => import('./ViewCouponPage'));
const ListCouponPage = React.lazy(() => import('./ListCouponPage'));
const ListUnArchievePage = React.lazy(() => import('./ListUnArchievePage'));

const AgentRoutes = ({ t }) => {
  return (
    <Routes>
      <Route path="/list" element={<ListCouponPage t={t} />} />
      <Route path="/unarchieve" element={<ListUnArchievePage t={t} />} />
      <Route path="/add" element={<AddCouponPage t={t} />} />
      <Route path="/edit/:coupon_id" element={<EditCouponPage t={t} />} />
      <Route path="/view/:coupon_id" element={<ViewCouponPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AgentRoutes.propTypes = {
  t: PropTypes.func
};
export default AgentRoutes;
