import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListWasteCodePage = React.lazy(() => import('./ListWasteCodePage'));
const AddWasteCodePage = React.lazy(() => import('./AddWasteCodePage'));
const EditWasteCodePage = React.lazy(() => import('./EditWasteCodePage'));
const ViewWasteCodePage = React.lazy(() => import('./ViewWasteCodePage'));

const WasteCodeRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListWasteCodePage t={t} />} />
      <Route exact path="/add" element={<AddWasteCodePage t={t} />} />
      <Route exact path="/edit/:waste_code_id" element={<EditWasteCodePage t={t} />} />
      <Route exact path="/view/:waste_code_id" element={<ViewWasteCodePage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
WasteCodeRoutes.propTypes = {
  t: PropTypes.func
};
export default WasteCodeRoutes;
