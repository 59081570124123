import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ViewCompletedIncinerationDetails = React.lazy(() =>
  import('./ViewCompletedIncinerationDetails')
);
const ListCompletedIncineration = React.lazy(() => import('./ListCompletedIncineration'));

const CompletedIncinerationRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index path="/list" element={<ListCompletedIncineration t={t} />} />
      <Route
        path="/details/:delivery_point_current_session_id"
        element={<ViewCompletedIncinerationDetails t={t} />}
      />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
CompletedIncinerationRoutes.propTypes = {
  t: PropTypes.func
};
export default CompletedIncinerationRoutes;
