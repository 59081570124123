import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const PostpaidInvoice = React.lazy(() => import('./PostpaidInvoice'));
const PostpaidInvoiceDetail = React.lazy(() => import('./PostpaidInvoiceDetail'));

const PostpaidInvoiceRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/" element={<PostpaidInvoice t={t} />} />
      <Route index exact path="/detail/:id" element={<PostpaidInvoiceDetail t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
PostpaidInvoiceRoute.propTypes = {
  t: PropTypes.func
};
export default PostpaidInvoiceRoute;
