import { useQuery, useMutation } from 'react-query';

import { FactoryService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useStoreFactory = (onSuccess, onError = onDefaultError) => {
  return useMutation(FactoryService.storeFactory, {
    onSuccess,
    onError
  });
};

const useListFactory = (
  [
    page_no,
    searchText,
    selectedStatus,
    fromDate,
    toDate,
    territryId,
    areaId,
    selectedDateType,
    sortBy,
    sortOrder
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'factory-list',
      page_no,
      searchText,
      selectedStatus,
      fromDate,
      toDate,
      territryId,
      areaId,
      selectedDateType,
      sortBy,
      sortOrder
    ],
    FactoryService.listFactory,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useViewFactory = (factory_id, onSuccess, onError = onDefaultError) => {
  return useQuery('factory-view', () => FactoryService.viewFactory(factory_id), {
    onSuccess,
    onError
  });
};

const useDeleteFactoryAttachment = (onSuccess, onError = onDefaultError) => {
  return useMutation(FactoryService.deleteFactoryAttachment, {
    onSuccess,
    onError
  });
};

const useUpdateFactory = (onSuccess, onError = onDefaultError) => {
  return useMutation(FactoryService.updateFactory, {
    onSuccess,
    onError
  });
};

const useGenerateFactoryDownloadReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(FactoryService.generateFactoryReport, {
    onSuccess,
    onError
  });
};
export {
  useStoreFactory,
  useListFactory,
  useViewFactory,
  useDeleteFactoryAttachment,
  useUpdateFactory,
  useGenerateFactoryDownloadReport
};
