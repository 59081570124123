import client from '@libs/HttpClient';
class TransporterPayoutService {
  static transporterPayoutList(request) {
    const page = request.queryKey[1];
    const from_date = request.queryKey[2];
    const to_date = request.queryKey[3];
    const invoice_mode = request.queryKey[4];
    const invoice_status = request.queryKey[5];
    const search_text = request.queryKey[6];
    const type = request.queryKey[7];
    const reference_id = request.queryKey[8];
    const sortBy = request.queryKey[9];
    const sortOrder = request.queryKey[10];

    return client.get(
      `finance/transporter/index?page=${page}&search_text=${search_text}&from_date=${from_date}&to_date=${to_date}&invoice_mode=${invoice_mode}&invoice_status=${invoice_status}&search_text=${search_text}&type=${type}&reference_id=${reference_id}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static transporterListFilter(request) {
    return client.get(`finance/transporter/transporter-dropdown`);
  }

  static transporterPayoutView(date, type, reference_id, per_kg_commision) {
    return client.get(
      `finance/transporter/detail/${date}/${type}/${reference_id}/${per_kg_commision}`
    );
  }

  static raisedInvoice(request) {
    return client.post(`finance/transporter/raised-invoice`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static generateReport(request) {
    return client.post(`finance/transporter/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { TransporterPayoutService };
