import { useMutation, useQuery } from 'react-query';

import { toast } from 'react-toastify';
import { CompletedIncinerationService } from '@api/';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useCompletedIncineration = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['tour/incineration-complete/list', [params]],
    () => CompletedIncinerationService.viewCompletedIncinerationService(params),
    {
      onSuccess,
      onError
    }
  );
};

const useViewCompletedIncinerationDetails = (
  delivery_point_current_session_id,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    'tour/incineration-complete/details',
    () =>
      CompletedIncinerationService.viewCompletedIncinerationServiceDetails(
        delivery_point_current_session_id
      ),
    {
      onSuccess,
      onError
    }
  );
};

const useCompletedIncinerationAddNotes = (onSuccess, onError = onDefaultError) => {
  return useMutation(CompletedIncinerationService.useCompletedIncinerationServiceAddNotes, {
    onSuccess,
    onError
  });
};

const useGenerateCompleteIncenerationReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(CompletedIncinerationService.generateReport, {
    onSuccess,
    onError
  });
};
export {
  useCompletedIncineration,
  useViewCompletedIncinerationDetails,
  useCompletedIncinerationAddNotes,
  useGenerateCompleteIncenerationReport
};
