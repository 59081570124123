import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ListAgentPayout from './ListAgentPayout';
import ViewAgentPayout from './ViewAgentPayout';

const AgentPayoutRoutes = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/list" element={<ListAgentPayout t={t} />} />

      <Route exact path="/view/:date/:type/:reference_id" element={<ViewAgentPayout t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AgentPayoutRoutes.propTypes = {
  t: PropTypes.func
};
export default AgentPayoutRoutes;
