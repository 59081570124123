import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const OrderDetailPage = React.lazy(() => import('./OrderDetailPage'));
const OrderListPage = React.lazy(() => import('./OrderListPage'));
const OrderPreparePage = React.lazy(() => import('./OrderPreparePage'));
const OrderTruckLoadingPage = React.lazy(() => import('./OrderTruckLoadingPage'));
const OrderDeliverPage = React.lazy(() => import('./OrderDeliverPage'));
const OrderGosendStatus = React.lazy(() => import('./OrderGosendStatus'));
const OrderBiteshipStatus = React.lazy(() => import('./OrderBiteshipStatus'));

const OrderRoutes = ({ t }) => {
  return (
    <Routes>
      <Route path="/list" element={<OrderListPage t={t} />} />
      <Route exact path="/detail/:order_id" element={<OrderDetailPage t={t} />} />
      <Route exact path="/prepare/:order_id" element={<OrderPreparePage t={t} />} />
      <Route exact path="/load/:order_id" element={<OrderTruckLoadingPage t={t} />} />
      <Route exact path="/deliver/:order_id" element={<OrderDeliverPage t={t} />} />
      <Route exact path="/gosend/:order_id" element={<OrderGosendStatus t={t} />} />
      <Route exact path="/biteship/:order_id" element={<OrderBiteshipStatus t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
OrderRoutes.propTypes = {
  t: PropTypes.func
};
export default OrderRoutes;
