import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const PartnerInvoice = React.lazy(() => import('./PartnerInvoice'));
const PartnerInvoiceDetail = React.lazy(() => import('./PartnerInvoiceDetail'));

const PartnerInvoiceRoute = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/" element={<PartnerInvoice t={t} />} />
      <Route index exact path="/in" element={<PartnerInvoice t={t} type="IN" />} />
      <Route index exact path="/detail/:id" element={<PartnerInvoiceDetail t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
PartnerInvoiceRoute.propTypes = {
  t: PropTypes.func
};
export default PartnerInvoiceRoute;
