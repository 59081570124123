import client from '@libs/HttpClient';
class FactoryService {
  static storeFactory(request) {
    return client.post('factory/store', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static listFactory(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const fromDate = request.queryKey[4];
    const toDate = request.queryKey[5];
    const territryId = request.queryKey[6];
    const areaId = request.queryKey[7];
    const selectedDateType = request.queryKey[8];
    const sortBy = request.queryKey[9];
    const sortOrder = request.queryKey[10];

    return client.get(
      `factory/index?page=${page_no}&search_text=${searchText}&status=${status}&from_date=${fromDate}&to_date=${toDate}&territory_id=${territryId}&area_id=${areaId}&date_type=${selectedDateType}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static viewFactory(request) {
    return client.get(`/factory/view/${request}`);
  }

  static deleteFactoryAttachment(request) {
    return client.put(
      `/factory/delete-attachment/${request.attachment_id}/${request.type}`,
      request
    );
  }

  static updateFactory(request) {
    const factory_id = request.get('factory_id');
    return client.post(`/factory/update/${factory_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static generateFactoryReport(request) {
    return client.post(`/factory/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { FactoryService };
