import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Accordion, Card, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import AlertCommon from '@common/components/AlertCommon';
import { useGetCountryCodeList, useNavigatingAway, useStoreDriver } from '@hooks';
import { validationSchema } from './AddDriverValidation';
import { AttachmentFiles, GeneralDetails } from './components';
import { TNButton } from '@common/components/';
import TNBreadCurm from '@common/components/TNBreadCurm';
import moment from 'moment';
import { DialogLeavingPage } from '@common/components/AlertClosePage';
import { DriverLicenceDetails } from './components/DriverLicenceDetails';
import { B1TypeLicenceDetails } from './components/B1TypeLicenceDetails';
import { B3DriverCertificateDetails } from './components/B3DriverCertificate';

const AddDriverPage = ({ t }) => {
  const [accrdKey, setAccrdKey] = useState(0);
  const [formState, setFormState] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(formState);
  let { transporter_id } = useParams();
  const navigate = useNavigate();
  /**
   * !This Block will call Country Code List api
   */
  const { isLoading: countryIsLoad, data: countryCodeList } = useGetCountryCodeList();
  let countryCodeOptions = [];
  if (countryCodeList !== undefined) {
    countryCodeOptions = countryCodeList.data.map((item, i) => {
      return { value: item.country_id, label: item.phone_code };
    });
  }
  // Driver Store Api Call
  const {
    mutate: doStoreDriver,
    isLoading,
    error: addDRList,
    isError: addDRrror
  } = useStoreDriver(
    (res) => {
      toast.success(res.message);
      navigate(`/transporter/driver/list/${transporter_id}`);
      formik.resetForm();
    },
    (error) => {
      window.scrollTo(0, 0);
      formik.values.date_of_birth = new Date(formik.values.date_of_birth);
      formik.values.licence_expiry_date = new Date(formik.values.licence_expiry_date);
      formik.values.b1_type_licence_expiry_date = new Date(
        formik.values.b1_type_licence_expiry_date
      );
    }
  );

  /**
   * this function is used when click on cancel button
   */

  const handleCancel = () => {
    navigate(`/transporter/driver/list/${transporter_id}`);
  };

  // form initial value
  const initialValues = {
    alliance_portal_id: transporter_id,
    first_name: '',
    last_name: '',
    country_id: parseInt(process.env.MIX_DEFAULT_PHONE_CODE),
    phone: '',
    email: '',
    date_of_birth: '',
    address: '',
    address_notes: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
    latitude: '',
    longitude: '',
    owner_ktp: '',
    photo: '',
    driver_ktp: '',
    selfi_with_ktp: '',
    proof_of_address: '',
    admin_status: '',
    user_status: '',
    licence_number: '',
    licence_expiry_date: '',
    driver_licence: '',
    b1_type_licence: '',
    b1_type_licence_expiry_date: '',
    b1_type_licence_attachment: '',
    b3_driver_certificate: '',
    b3_driver_certificate_attachment: ''
  };
  /**
   * This Block is execute on Form Submit for validation
   */
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setFormState(false);

      values.date_of_birth = moment(values.date_of_birth).format('YYYY-MM-DD');
      values.licence_expiry_date = moment(values.licence_expiry_date).format('YYYY-MM-DD');
      values.b1_type_licence_expiry_date = moment(values.b1_type_licence_expiry_date).format(
        'YYYY-MM-DD'
      );

      doStoreDriver(values);
    }
  });

  useEffect(() => {
    setFormState(formik.dirty);
  }, [formik.dirty]);

  if (isLoading) {
    return null;
  }

  const breadcurmArray = [
    {
      label: t('page.transporter_list_title'),
      link: '/transporter/list',
      active: ''
    },
    {
      label: t('page.view_transporter_title'),
      link: `/transporter/driver/list/${transporter_id}`,
      active: ''
    },
    {
      label: t('page.add_driver_title'),
      link: `/transporter/driver/list/${transporter_id}`,
      active: 'active'
    }
  ];

  return (
    <>
      <DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={formik.dirty}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
        t={t}
      />
      <TNBreadCurm breadcurmArray={breadcurmArray} />
      <AlertCommon key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)} is_error={addDRrror}>
        {addDRList}
      </AlertCommon>
      <h1>{t('page.add_driver_title')} </h1>
      <Card>
        <Card.Body className="p-0">
          <Form onSubmit={formik.handleSubmit}>
            <Accordion
              key={accrdKey}
              alwaysOpen
              defaultActiveKey={accrdKey && accrdKey !== 0 ? accrdKey : 1}>
              <Accordion.Item eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 1} className="my-1">
                <Accordion.Header className="text-info fw-bold shadow-nond">
                  {t('page.add_driver_general_details_title')}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <GeneralDetails
                    t={t}
                    formik={formik}
                    countryCodeOptions={countryCodeOptions}
                    countryIsLoad={countryIsLoad}></GeneralDetails>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 2} className="my-1">
                <Accordion.Header className="text-info fw-bold shadow-nond">
                  {t('page.add_driver_driver_licence_details_title')}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <DriverLicenceDetails t={t} formik={formik}></DriverLicenceDetails>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 3} className="my-1">
                <Accordion.Header className="text-info fw-bold shadow-nond">
                  {t('page.add_driver_b1_type_licence_details_title')}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <B1TypeLicenceDetails t={t} formik={formik}></B1TypeLicenceDetails>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 4} className="my-1">
                <Accordion.Header className="text-info fw-bold shadow-nond">
                  {t('page.add_driver_b3_driver_certificate_details_title')}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <B3DriverCertificateDetails t={t} formik={formik}></B3DriverCertificateDetails>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item
                eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 5}
                className="border-bottom my-1">
                <Accordion.Header>{t('page.add_driver_attachment_title')} </Accordion.Header>
                <Accordion.Body className="p-0">
                  <AttachmentFiles t={t} formik={formik}></AttachmentFiles>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="primary-button">
              <span className="link-center" onClick={handleCancel}>
                {t('page.add_drive_cancel_button')}
              </span>
              <TNButton
                loading={isLoading}
                isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}
                onClick={() => {
                  setAccrdKey(1);
                }}
                type="submit">
                {t('page.add_drive_submit_button')}
              </TNButton>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
AddDriverPage.propTypes = {
  t: PropTypes.func,
  isdirtyform: PropTypes.any
};
export { AddDriverPage };
