import client from '@libs/HttpClient';
class DeliveryPointUserService {
  /* delivery point user */
  static listDeliveryPointUser(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const userStatus = request.queryKey[3];
    const adminStatus = request.queryKey[4];
    const license = request.queryKey[5];
    const alliance_portal_id = request.queryKey[6];
    const type = request.queryKey[7];
    const sortBy = request.queryKey[8];
    const sortOrder = request.queryKey[9];
    return client.get(
      `/delivery-point-user/index/${type}/${alliance_portal_id}?page=${page_no}&search_text=${searchText}&user_status=${userStatus}&admin_status=${adminStatus}&by_session=${license}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static addDeliveryPointUser(request) {
    return client.post('/delivery-point-user/store', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static deleteDeliveryPointUser(request) {
    const delivery_point_user_id = request.queryKey[1];
    return client.get(`/delivery-point-user/destroy/${delivery_point_user_id}`, request);
  }
  static viewDeliveryPointUser(request) {
    const delivery_point_user_id = request.queryKey[1];
    return client.get(`/delivery-point-user/view/${delivery_point_user_id}`, request);
  }
  static updateDeliveryPointUser(request) {
    return client.post(`/delivery-point-user/update/${request.delivery_point_user_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static generateReport(request) {
    return client.post(`/delivery-point-user/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { DeliveryPointUserService };
