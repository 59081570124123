import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Accordion, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  useGetCountryCodeList,
  useUpdateWarehouse,
  useViewWarehouse,
  useNavigatingAway
} from '@hooks';
import { Formik, Form } from 'formik';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import AlertCommon from '@common/components/AlertCommon';
import validationSchema from './AddWarehouseValidation';
import {
  AccountingPersonDetails,
  EditAttachmentFiles,
  ContactrDetails,
  GeneralDetails,
  OwnerDetails,
  PersonInCharge
} from './components';
import { TNButton } from '@common/components/';
import TNBreadCurm from '@common/components/TNBreadCurm';
import { DialogLeavingPage } from '@common/components/AlertClosePage';

const EditWarehousePage = ({ t }) => {
  const [formState, setFormState] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(formState);
  const [accrdKey, setAccrdKey] = useState(0);
  const [copyData, setCopyData] = useState({
    country_id: '',
    phone: '',
    email: '',
    ktp: '',
    first_name: '',
    last_name: ''
  });
  const [sectionCopy, setSectionCopy] = useState(null);

  // From this api we are getting country code list.
  const navigate = useNavigate();
  let { warehouse_id } = useParams();
  const { isLoading: countryIsLoad, data: countryCodeList } = useGetCountryCodeList();
  let countryCodeOptions = [];
  if (countryCodeList !== undefined) {
    countryCodeOptions = countryCodeList.data.map((item, i) => {
      return { value: item.country_id, label: item.phone_code };
    });
  }

  /**
   * This function is use when click on cancel button
   */

  const handleCancel = (dirty) => {
    if (dirty && dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message')
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(`/warehouse/list/`);
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      navigate(`/warehouse/list/`);
    }
  };
  /**
   * !This API will call when page set. When response came we are setting up data into the form
   */

  const { data: warehouse_data, refetch } = useViewWarehouse(warehouse_id);

  /**
   * !This Api will call on click submit button
   */
  const {
    mutate: doStoreWarehouse,
    isLoading,
    error: addWHList,
    isError: addWHError
  } = useUpdateWarehouse(
    (res) => {
      toast.success(res.message);
      refetch();
      // navigate('/warehouse/list/');
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );
  if (!warehouse_data) {
    return null;
  }
  /**
   * !This block is for form initial values
   */
  const initialValues = {
    warehouse_id: warehouse_data.data.warehouse_id,
    name: warehouse_data.data.name,
    simpel_id: warehouse_data.data.simpel_id,
    simpel_registration_number: warehouse_data.data.simpel_registration_number,
    warehouse_npwp_number: warehouse_data.data.warehouse_npwp_number,
    country_id:
      warehouse_data.data.country_id !== '' && warehouse_data.data.country_id !== null
        ? warehouse_data.data.country_id
        : parseInt(process.env.MIX_DEFAULT_PHONE_CODE),

    company_phone: warehouse_data.data.company_phone,
    territory_id: warehouse_data.data.territory_id,
    area_id: warehouse_data.data.area_id,
    address: warehouse_data.data.address,
    address_notes: warehouse_data.data.address_notes,
    city: warehouse_data.data.city,
    state: warehouse_data.data.state,
    postcode: warehouse_data.data.postcode,
    country: warehouse_data.data.country,
    latitude: warehouse_data.data.latitude,
    longitude: warehouse_data.data.longitude,
    contract_status: warehouse_data.data.contract_status,
    contract_number: warehouse_data.data.contract_number,
    contract_start_date: warehouse_data.data.contract_start_date,
    contract_end_date: warehouse_data.data.contract_end_date,
    owner_first_name:
      sectionCopy == 'ownerDetails' ? copyData.first_name : warehouse_data.data.owner_first_name,
    owner_last_name:
      sectionCopy == 'ownerDetails' ? copyData.last_name : warehouse_data.data.owner_last_name,
    owner_email: sectionCopy == 'ownerDetails' ? copyData.email : warehouse_data.data.owner_email,
    owner_phone: sectionCopy == 'ownerDetails' ? copyData.phone : warehouse_data.data.owner_phone,
    owner_ktp: warehouse_data.data.owner_ktp,
    person_incharge_first_name:
      sectionCopy == 'personInCharge'
        ? copyData.first_name
        : warehouse_data.data.person_incharge_first_name,
    person_incharge_last_name:
      sectionCopy == 'personInCharge'
        ? copyData.last_name
        : warehouse_data.data.person_incharge_last_name,
    person_incharge_email:
      sectionCopy == 'personInCharge' ? copyData.email : warehouse_data.data.person_incharge_email,
    person_incharge_phone:
      sectionCopy == 'personInCharge' ? copyData.phone : warehouse_data.data.person_incharge_phone,
    accounting_first_name:
      sectionCopy == 'accountingDetails'
        ? copyData.first_name
        : warehouse_data.data.accounting_first_name,
    accounting_last_name:
      sectionCopy == 'accountingDetails'
        ? copyData.last_name
        : warehouse_data.data.accounting_last_name,
    accounting_email:
      sectionCopy == 'accountingDetails' ? copyData.email : warehouse_data.data.accounting_email,
    accounting_phone:
      sectionCopy == 'accountingDetails' ? copyData.phone : warehouse_data.data.accounting_phone,
    owner_country_id:
      warehouse_data.data.owner_country_id !== '' && warehouse_data.data.owner_country_id !== null
        ? warehouse_data.data.owner_country_id
        : parseInt(process.env.MIX_DEFAULT_PHONE_CODE),

    person_incharge_country_id:
      warehouse_data.data.person_incharge_country_id !== '' &&
      warehouse_data.data.person_incharge_country_id !== null
        ? warehouse_data.data.person_incharge_country_id
        : parseInt(process.env.MIX_DEFAULT_PHONE_CODE),

    accounting_country_id:
      warehouse_data.data.accounting_country_id !== '' &&
      warehouse_data.data.accounting_country_id !== null
        ? warehouse_data.data.accounting_country_id
        : parseInt(process.env.MIX_DEFAULT_PHONE_CODE),

    collect_truck_destination: warehouse_data.data.factory_id,
    friends: [],
    owner_ktp_attchment: warehouse_data.data.owner_ktp_attchment,
    owner_ktp_attchment_id: warehouse_data.data.owner_ktp_attchment_id,

    selfi_with_ktp_attchement: warehouse_data.data.selfi_with_ktp_attchement,
    selfi_with_ktp_attchement_id: warehouse_data.data.selfi_with_ktp_attchement_id,

    stamp: warehouse_data.data.stamp,
    stamp_id: warehouse_data.data.stamp_id,

    additional_attachement: warehouse_data.data.additional_attachment
  };

  const breadcurmArray = [
    {
      label: t('page.warehouse_list_label'),
      link: '/warehouse/list',
      active: ''
    },
    {
      label: t('page.edit_warehouse_title'),
      link: '/warehouse/list',
      active: 'active'
    }
  ];

  return (
    <>
      <DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={formState}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
        t={t}
      />
      <TNBreadCurm breadcurmArray={breadcurmArray} />

      <AlertCommon key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)} is_error={addWHError}>
        {addWHList}
      </AlertCommon>
      <h1>{t('page.edit_warehouse_title')} </h1>
      <Card>
        <Card.Body className="p-0">
          <Formik
            enableReinitialize={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const formData = new FormData();
              for (let key in values) {
                let value = values[key];
                if (key === 'additional_attachement') {
                  for (let keyatt in value) {
                    let valueatt = value[keyatt];
                    formData.append(
                      `additional_attachement[${keyatt}][additional_attachement_name]`,
                      valueatt.additional_attachement_name
                    );
                    formData.append(
                      `additional_attachement[${keyatt}][additional_attachement_file]`,
                      valueatt.additional_attachement_file
                    );
                    formData.append(
                      `additional_attachement[${keyatt}][additional_attachment_id]`,
                      valueatt.additional_attachment_id
                    );
                  }
                } else {
                  formData.append(key, value);
                }
              }
              if (values.contract_start_date !== '') {
                formData.append(
                  'contract_start_date',
                  moment(values.contract_start_date).format('YYYY-MM-DD')
                );
              }
              if (values.contract_end_date !== '') {
                formData.append(
                  'contract_end_date',
                  moment(values.contract_end_date).format('YYYY-MM-DD')
                );
              }
              setFormState(false);
              doStoreWarehouse(formData);
            }}>
            {({ values, errors, dirty }) => {
              values.contract_start_date =
                values.contract_start_date !== '' ? new Date(values.contract_start_date) : '';
              values.contract_end_date =
                values.contract_end_date !== '' ? new Date(values.contract_end_date) : '';
              return (
                <Form>
                  <Accordion
                    key={accrdKey}
                    alwaysOpen
                    defaultActiveKey={accrdKey && accrdKey !== 0 ? accrdKey : 1}>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 1}
                      className="my-1">
                      <Accordion.Header className="text-info fw-bold shadow-nond">
                        {t('page.add_warehouse_general_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <GeneralDetails
                          t={t}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}></GeneralDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 2}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_warehouse_contact_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <ContactrDetails t={t}></ContactrDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 3}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_warehouse_owner_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <OwnerDetails
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          sectionCopy={sectionCopy}
                          setSectionCopy={setSectionCopy}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}></OwnerDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 4}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_warehouse_person_in_charge_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <PersonInCharge
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          sectionCopy={sectionCopy}
                          setSectionCopy={setSectionCopy}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}></PersonInCharge>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 5}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_warehouse_accounting_person_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <AccountingPersonDetails
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          sectionCopy={sectionCopy}
                          setSectionCopy={setSectionCopy}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}></AccountingPersonDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 6}
                      className="border-bottom my-1">
                      <Accordion.Header>
                        {t('page.add_warehouse_attachment_title')}{' '}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <EditAttachmentFiles t={t} refetch={refetch}></EditAttachmentFiles>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="primary-button">
                    <span className="link-center" onClick={() => handleCancel(dirty)}>
                      {t('page.add_warehouse_cancel_button')}
                    </span>
                    <TNButton
                      loading={isLoading}
                      onClick={() => {
                        setAccrdKey(1);
                      }}
                      isdirtyform={dirty && dirty !== undefined ? 1 : 0}
                      type="submit">
                      {t('page.add_warehouse_submit_button')}
                    </TNButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
};
EditWarehousePage.propTypes = {
  t: PropTypes.func
};
export { EditWarehousePage };
