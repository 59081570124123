import PropTypes from 'prop-types';
import React from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import { NavLink, Navigate, Route, Routes } from 'react-router-dom';

const FirstHalfSetting = React.lazy(() => import('./TourSettingPages/FirstHalfSetting'));
const SecondHalfSetting = React.lazy(() => import('./TourSettingPages/SecondHalfSetting'));
const LoadCapacitySetting = React.lazy(() => import('./TourSettingPages/LoadCapacitySetting'));
const ListMaximumStop = React.lazy(() => import('./TourStopPages/ListMaximumStop'));
const AddMaximumStop = React.lazy(() => import('./TourStopPages/AddMaximumStop'));
const EditMaximumStop = React.lazy(() => import('./TourStopPages/EditMaximumStop'));
const OperationalTimeSetting = React.lazy(() =>
  import('./TourSettingPages/OperationalTimeSetting')
);
const RealTimeSetting = React.lazy(() => import('./TourSettingPages/RealTimeSetting'));
const TourWarehouseList = React.lazy(() => import('./Tours/TourWarehouseList'));
const DailySchedule = React.lazy(() => import('./TourSettingPages/DailySchedulePage'));

const TourRoutes = ({ t }) => {
  return (
    <>
      <Row>
        <Col lg={12}>
          <Nav className="tab-navigation">
            <Nav.Item>
              <NavLink to={`/tour/first_half`}>{t('page.first_half_setting_title')}</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={`/tour/second_half`}>{t('page.second_half_setting_title')}</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={`/tour/real_time_setting`}>{t('page.real_time_setting')}</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={`/tour/load_setting`}>{t('page.load_setting_title')}</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={`/tour/maximum_stop`}>{t('page.maximum_stop_title')}</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={`/tour/operational_time`}>{t('page.operational_time_title')}</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="/tour/daily-schedule">Daily Schedule</NavLink>
            </Nav.Item>
          </Nav>
        </Col>
        <Col lg={12}></Col>
      </Row>
      <Routes>
        <Route exact path="/first_half" element={<FirstHalfSetting t={t} />} />
        <Route path="/second_half" element={<SecondHalfSetting t={t} />} />
        <Route path="/load_setting" element={<LoadCapacitySetting t={t} />} />
        <Route path="/maximum_stop" element={<ListMaximumStop t={t} />} />
        <Route path="/maximum_stop/add" element={<AddMaximumStop t={t} />} />
        <Route path="/maximum_stop/edit/:maximum_stop_id" element={<EditMaximumStop t={t} />} />
        <Route path="/operational_time" element={<OperationalTimeSetting t={t} />} />
        <Route path="/real_time_setting" element={<RealTimeSetting t={t} />} />
        <Route path="/warehouse" element={<TourWarehouseList t={t} />} />
        <Route exact path="daily-schedule" element={<DailySchedule t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};
TourRoutes.propTypes = {
  t: PropTypes.func
};
export default TourRoutes;
