import React from 'react';
import PropTypes from 'prop-types';
import { Card, Nav, Row, Col, Form, Button } from 'react-bootstrap';
import { useFormikContext, FieldArray } from 'formik';
import Select from 'react-select';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import moment from 'moment';

const WorkDetails = (props) => {
  const { t } = props;
  const formik = useFormikContext();
  const sloatgenratedata = (starttime, endtime) => {
    //Data
    let x = {
      slotInterval: process.env.MIX_TIME_SLOT_INTERVAL_MINS,
      openTime: starttime,
      closeTime: endtime
    };

    let startTime = moment(x.openTime, 'HH:mm');
    let endTime = moment(x.closeTime, 'HH:mm');
    let allTimes = [];
    while (startTime <= endTime) {
      allTimes.push({ label: startTime.format('hh:mm A'), value: startTime.format('HH:mm') });
      startTime.add(x.slotInterval, 'minutes');
    }
    return allTimes;
  };
  const slotOptionList = sloatgenratedata('01:00', '24:00');
  return (
    <Card>
      <Card.Body>
        <Nav className="tab-navigation">
          <Nav.Item>
            <NavLink to="">{t('page.add_producer_working_details_header_text')}</NavLink>
          </Nav.Item>
        </Nav>
        {/* day 1 */}
        <Row>
          <Col lg={12}>
            <FieldArray name="days_slot_1">
              {({ remove, push }) => {
                const days_slot_1s = formik.values.days_slot_1;
                return (
                  <>
                    {days_slot_1s.length > 0 &&
                      days_slot_1s.map((days_slot_1, index) => {
                        const days_startErrors =
                          (formik.errors.days_slot_1?.length &&
                            formik.errors.days_slot_1[index]?.days_start) ||
                          '';
                        const days_startTouched =
                          (formik.touched.days_slot_1?.length &&
                            formik.touched.days_slot_1[index]?.days_start) ||
                          '';
                        const days_endErrors =
                          (formik.errors.days_slot_1?.length &&
                            formik.errors.days_slot_1[index]?.days_end) ||
                          '';
                        const days_endTouched =
                          (formik.touched.days_slot_1?.length &&
                            formik.touched.days_slot_1[index]?.days_end) ||
                          '';
                        const dayscheckboxErrors =
                          (formik.errors.days_slot_1?.length &&
                            formik.errors.days_slot_1[index]?.dayscheckbox) ||
                          '';
                        const dayscheckboxTouched =
                          (formik.touched.days_slot_1?.length &&
                            formik.touched.days_slot_1[index]?.dayscheckbox) ||
                          '';
                        return (
                          <div key={index.toString()} className="working-details">
                            <Row className="working-text-center">
                              <Col lg={2} xs={12}>
                                {index === 0 ? (
                                  <>
                                    <Form.Check
                                      type={'checkbox'}
                                      label={t('page.add_producer_work_details_label_1')}
                                      name={`days_slot_1.${index}.dayscheckbox`}
                                      disabled={formik.values.days_slot_1[index].is_close}
                                      value={formik.values.days_slot_1[index].dayscheckbox}
                                      checked={formik.values.days_slot_1[index].dayscheckbox}
                                      onChange={(event) => {
                                        for (var i = 0; i < formik.values.days_slot_1.length; ++i) {
                                          formik.setFieldValue(
                                            `days_slot_1.${i}.dayscheckbox`,
                                            event.currentTarget.checked
                                          );
                                        }
                                      }}
                                    />
                                    <div className="form-field-error-text">
                                      {dayscheckboxTouched && dayscheckboxErrors ? (
                                        <div>{t(dayscheckboxErrors)}</div>
                                      ) : null}
                                    </div>
                                  </>
                                ) : null}
                              </Col>
                              <Col lg={3} xs={12}>
                                <Row className="working-text-center">
                                  <Col lg={3}>
                                    <span className="working-details-lable">
                                      {t('page.add_producer_work_details_start_time_label')}
                                    </span>
                                  </Col>
                                  <Col lg={9}>
                                    <Select
                                      isDisabled={
                                        formik.values.days_slot_1[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_1[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      isClearable={true}
                                      name={`days_slot_1.${index}.days_start`}
                                      options={slotOptionList}
                                      placeholder={t(
                                        'page.add_producer_work_details_start_time_placeholder'
                                      )}
                                      onChange={(selected) => {
                                        if (selected !== null) {
                                          formik.setFieldValue(
                                            `days_slot_1.${index}.days_start`,
                                            selected.value
                                          );
                                        } else {
                                          formik.setFieldValue(
                                            `days_slot_2.${index}.days_start`,
                                            ''
                                          );
                                        }
                                      }}
                                      className={
                                        days_startTouched && days_startErrors
                                          ? 'form-select-error'
                                          : days_startTouched && !days_startErrors
                                          ? 'form-select-success'
                                          : ''
                                      }
                                    />
                                  </Col>
                                  <div
                                    className={
                                      'working-details-hours ' +
                                      (index > 0 ? 'working-list-hours' : '')
                                    }>
                                    <div className="form-field-error-text">
                                      {days_startTouched && days_startErrors ? (
                                        <div>{t(days_startErrors)}</div>
                                      ) : null}
                                    </div>
                                  </div>
                                </Row>
                              </Col>

                              <Col lg={3} xs={12}>
                                <Row className="working-text-center">
                                  <Col lg={3}>
                                    <span className="working-details-lable">
                                      {t('page.add_producer_work_details_end_time_label')}
                                    </span>
                                  </Col>
                                  <Col lg={9}>
                                    <Select
                                      isDisabled={
                                        formik.values.days_slot_1[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_1[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      isClearable={true}
                                      name={`days_slot_1.${index}.days_end`}
                                      options={slotOptionList}
                                      placeholder={t(
                                        'page.add_producer_work_details_end_time_placeholder'
                                      )}
                                      onChange={(selected) => {
                                        if (selected !== null) {
                                          formik.setFieldValue(
                                            `days_slot_1.${index}.days_end`,
                                            selected.value
                                          );
                                        } else {
                                          formik.setFieldValue(`days_slot_1.${index}.days_end`, '');
                                        }
                                      }}
                                      className={
                                        days_endTouched && days_endErrors
                                          ? 'form-select-error'
                                          : days_endTouched && !days_endErrors
                                          ? 'form-select-success'
                                          : ''
                                      }
                                    />
                                  </Col>
                                </Row>
                                <div className="working-details-hours">
                                  <div className="form-field-error-text">
                                    {days_endTouched && days_endErrors ? (
                                      <div>{t(days_endErrors)}</div>
                                    ) : null}
                                  </div>
                                </div>
                              </Col>
                              <Col lg={3} xs={12}>
                                <div>
                                  {index > 0 ? (
                                    <Button
                                      disabled={
                                        formik.values.days_slot_1[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_1[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      type="button"
                                      className={'secondary-remove-button'}
                                      onClick={() => remove(index)}>
                                      {t(
                                        'page.add_producer_work_details_working_remove_button_text'
                                      )}
                                    </Button>
                                  ) : (
                                    <div>
                                      <Button
                                        disabled={
                                          formik.values.days_slot_1[0].is_close === true
                                            ? true
                                            : formik.values.days_slot_1[0].dayscheckbox === false
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          const startDay = formik.values.days_slot_1.filter(
                                            (value) => {
                                              return value.days_start === '';
                                            }
                                          );
                                          const endDay = formik.values.days_slot_1.filter(
                                            (value) => {
                                              return value.days_end === '';
                                            }
                                          );
                                          if (startDay.length > 0 && endDay.length > 0) {
                                            toast.error(t('page.select_previous_day_time_first'));
                                            return;
                                          }
                                          push({
                                            dayscheckbox: formik.values.days_slot_1[0].dayscheckbox,
                                            is_close: formik.values.days_slot_1[0].is_close,
                                            days_start: '',
                                            days_end: ''
                                          });
                                        }}
                                        className={'secondary-add-button d-none'}>
                                        {t(
                                          'page.add_producer_work_details_working_add_button_text'
                                        )}
                                      </Button>
                                      {formik.values.days_slot_1[index].is_close === false ? (
                                        <Button
                                          type="button"
                                          className="secondary-remove-button"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `days_slot_1.${index}.is_close`,
                                              true
                                            );
                                            formik.setFieldValue(
                                              `days_slot_1.${index}.dayscheckbox`,
                                              true
                                            );
                                          }}>
                                          {t(
                                            'page.add_producer_work_details_working_close_button_text'
                                          )}
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="secondary-add-button"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `days_slot_1.${index}.is_close`,
                                              false
                                            );
                                          }}>
                                          {t(
                                            'page.add_producer_work_details_working_open_button_text'
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  </>
                );
              }}
            </FieldArray>
          </Col>
        </Row>
        {/* day 2 */}
        <Row>
          <Col lg={12}>
            <FieldArray name="days_slot_2">
              {({ remove, push }) => {
                const days_slot_2s = formik.values.days_slot_2;
                return (
                  <>
                    {days_slot_2s.length > 0 &&
                      days_slot_2s.map((days_slot_2, index) => {
                        const days_startErrors =
                          (formik.errors.days_slot_2?.length &&
                            formik.errors.days_slot_2[index]?.days_start) ||
                          '';
                        const days_startTouched =
                          (formik.touched.days_slot_2?.length &&
                            formik.touched.days_slot_2[index]?.days_start) ||
                          '';
                        const days_endErrors =
                          (formik.errors.days_slot_2?.length &&
                            formik.errors.days_slot_2[index]?.days_end) ||
                          '';
                        const days_endTouched =
                          (formik.touched.days_slot_2?.length &&
                            formik.touched.days_slot_2[index]?.days_end) ||
                          '';
                        const dayscheckboxErrors =
                          (formik.errors.days_slot_2?.length &&
                            formik.errors.days_slot_2[index]?.dayscheckbox) ||
                          '';
                        const dayscheckboxTouched =
                          (formik.touched.days_slot_2?.length &&
                            formik.touched.days_slot_2[index]?.dayscheckbox) ||
                          '';
                        return (
                          <div key={index.toString()} className="working-details">
                            <Row className="working-text-center">
                              <Col lg={2} xs={12}>
                                {index === 0 ? (
                                  <>
                                    <Form.Check
                                      label={t('page.add_producer_work_details_label_2')}
                                      name={`days_slot_2.${index}.dayscheckbox`}
                                      disabled={formik.values.days_slot_2[0].is_close}
                                      checked={formik.values.days_slot_2[0].dayscheckbox}
                                      value={formik.values.days_slot_2[0].dayscheckbox}
                                      onChange={(event) => {
                                        for (var i = 0; i < formik.values.days_slot_2.length; ++i) {
                                          formik.setFieldValue(
                                            `days_slot_2.${i}.dayscheckbox`,
                                            event.currentTarget.checked
                                          );
                                        }
                                      }}
                                    />
                                    <div className="form-field-error-text">
                                      {dayscheckboxTouched && dayscheckboxErrors ? (
                                        <div>{t(dayscheckboxErrors)}</div>
                                      ) : null}
                                    </div>
                                  </>
                                ) : null}
                              </Col>
                              <Col lg={3} xs={12}>
                                <Row className="working-text-center">
                                  <Col lg={3}>
                                    <span className="working-details-lable">
                                      {t('page.add_producer_work_details_start_time_label')}
                                    </span>
                                  </Col>
                                  <Col lg={9}>
                                    <Select
                                      isDisabled={
                                        formik.values.days_slot_2[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_2[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      isClearable={true}
                                      name={`days_slot_2.${index}.days_start`}
                                      options={slotOptionList}
                                      placeholder={t(
                                        'page.add_producer_work_details_start_time_placeholder'
                                      )}
                                      onChange={(selected) => {
                                        if (selected !== null) {
                                          formik.setFieldValue(
                                            `days_slot_2.${index}.days_start`,
                                            selected.value
                                          );
                                        } else {
                                          formik.setFieldValue(
                                            `days_slot_2.${index}.days_start`,
                                            ''
                                          );
                                        }
                                      }}
                                      className={
                                        days_startTouched && days_startErrors
                                          ? 'form-select-error'
                                          : days_startTouched && !days_startErrors
                                          ? 'form-select-success'
                                          : ''
                                      }
                                    />
                                  </Col>
                                  <div
                                    className={
                                      'working-details-hours ' +
                                      (index > 0 ? 'working-list-hours' : '')
                                    }>
                                    <div className="form-field-error-text">
                                      {days_startTouched && days_startErrors ? (
                                        <div>{t(days_startErrors)}</div>
                                      ) : null}
                                    </div>
                                  </div>
                                </Row>
                              </Col>

                              <Col lg={3} xs={12}>
                                <Row className="working-text-center">
                                  <Col lg={3}>
                                    <span className="working-details-lable">
                                      {t('page.add_producer_work_details_end_time_label')}
                                    </span>
                                  </Col>
                                  <Col lg={9}>
                                    <Select
                                      isDisabled={
                                        formik.values.days_slot_2[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_2[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      isClearable={true}
                                      name={`days_slot_2.${index}.days_end`}
                                      options={slotOptionList}
                                      placeholder={t(
                                        'page.add_producer_work_details_end_time_placeholder'
                                      )}
                                      onChange={(selected) => {
                                        if (selected !== null) {
                                          formik.setFieldValue(
                                            `days_slot_2.${index}.days_end`,
                                            selected.value
                                          );
                                        } else {
                                          formik.setFieldValue(`days_slot_2.${index}.days_end`, '');
                                        }
                                      }}
                                      className={
                                        days_endTouched && days_endErrors
                                          ? 'form-select-error'
                                          : days_endTouched && !days_endErrors
                                          ? 'form-select-success'
                                          : ''
                                      }
                                    />
                                  </Col>
                                </Row>
                                <div className="working-details-hours">
                                  <div className="form-field-error-text">
                                    {days_endTouched && days_endErrors ? (
                                      <div>{t(days_endErrors)}</div>
                                    ) : null}
                                  </div>
                                </div>
                              </Col>
                              <Col lg={3} xs={12}>
                                <div>
                                  {index > 0 ? (
                                    <Button
                                      disabled={
                                        formik.values.days_slot_2[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_2[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      type="button"
                                      className={'secondary-remove-button '}
                                      onClick={() => remove(index)}>
                                      {t(
                                        'page.add_producer_work_details_working_remove_button_text'
                                      )}
                                    </Button>
                                  ) : (
                                    <div>
                                      <Button
                                        disabled={
                                          formik.values.days_slot_2[0].is_close === true
                                            ? true
                                            : formik.values.days_slot_2[0].dayscheckbox === false
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          const startDay = formik.values.days_slot_2.filter(
                                            (value) => {
                                              return value.days_start === '';
                                            }
                                          );
                                          const endDay = formik.values.days_slot_2.filter(
                                            (value) => {
                                              return value.days_end === '';
                                            }
                                          );
                                          if (startDay.length > 0 && endDay.length > 0) {
                                            toast.error(t('page.select_previous_day_time_first'));
                                            return;
                                          }
                                          push({
                                            dayscheckbox: formik.values.days_slot_2[0].dayscheckbox,
                                            is_close: formik.values.days_slot_2[0].is_close,
                                            days_start: '',
                                            days_end: ''
                                          });
                                        }}
                                        className={'secondary-add-button  d-none'}>
                                        {t(
                                          'page.add_producer_work_details_working_add_button_text'
                                        )}
                                      </Button>
                                      {formik.values.days_slot_2[index].is_close === false ? (
                                        <Button
                                          type="button"
                                          className="secondary-remove-button"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `days_slot_2.${index}.is_close`,
                                              true
                                            );
                                            formik.setFieldValue(
                                              `days_slot_2.${index}.dayscheckbox`,
                                              true
                                            );
                                          }}>
                                          {t(
                                            'page.add_producer_work_details_working_close_button_text'
                                          )}
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="secondary-add-button"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `days_slot_2.${index}.is_close`,
                                              false
                                            );
                                          }}>
                                          {t(
                                            'page.add_producer_work_details_working_open_button_text'
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  </>
                );
              }}
            </FieldArray>
          </Col>
        </Row>
        {/* day 3 */}
        <Row>
          <Col lg={12}>
            <FieldArray name="days_slot_3">
              {({ remove, push }) => {
                const days_slot_3s = formik.values.days_slot_3;
                return (
                  <>
                    {days_slot_3s.length > 0 &&
                      days_slot_3s.map((days_slot_3, index) => {
                        const days_startErrors =
                          (formik.errors.days_slot_3?.length &&
                            formik.errors.days_slot_3[index]?.days_start) ||
                          '';
                        const days_startTouched =
                          (formik.touched.days_slot_3?.length &&
                            formik.touched.days_slot_3[index]?.days_start) ||
                          '';
                        const days_endErrors =
                          (formik.errors.days_slot_3?.length &&
                            formik.errors.days_slot_3[index]?.days_end) ||
                          '';
                        const days_endTouched =
                          (formik.touched.days_slot_3?.length &&
                            formik.touched.days_slot_3[index]?.days_end) ||
                          '';
                        const dayscheckboxErrors =
                          (formik.errors.days_slot_3?.length &&
                            formik.errors.days_slot_3[index]?.dayscheckbox) ||
                          '';
                        const dayscheckboxTouched =
                          (formik.touched.days_slot_3?.length &&
                            formik.touched.days_slot_3[index]?.dayscheckbox) ||
                          '';
                        return (
                          <div key={index.toString()} className="working-details">
                            <Row className="working-text-center">
                              <Col lg={2} xs={12}>
                                {index === 0 ? (
                                  <>
                                    <Form.Check
                                      label={t('page.add_producer_work_details_label_3')}
                                      name={`days_slot_3.${index}.dayscheckbox`}
                                      disabled={formik.values.days_slot_3[0].is_close}
                                      value={formik.values.days_slot_3[0].dayscheckbox}
                                      checked={formik.values.days_slot_3[0].dayscheckbox}
                                      onChange={(event) => {
                                        for (var i = 0; i < formik.values.days_slot_3.length; ++i) {
                                          formik.setFieldValue(
                                            `days_slot_3.${i}.dayscheckbox`,
                                            event.currentTarget.checked
                                          );
                                        }
                                      }}
                                    />
                                    <div className="form-field-error-text">
                                      {dayscheckboxTouched && dayscheckboxErrors ? (
                                        <div>{t(dayscheckboxErrors)}</div>
                                      ) : null}
                                    </div>
                                  </>
                                ) : null}
                              </Col>
                              <Col lg={3} xs={12}>
                                <Row className="working-text-center">
                                  <Col lg={3}>
                                    <span className="working-details-lable">
                                      {t('page.add_producer_work_details_start_time_label')}
                                    </span>
                                  </Col>
                                  <Col lg={9}>
                                    <Select
                                      isDisabled={
                                        formik.values.days_slot_3[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_3[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      isClearable={true}
                                      name={`days_slot_3.${index}.days_start`}
                                      options={slotOptionList}
                                      placeholder={t(
                                        'page.add_producer_work_details_start_time_placeholder'
                                      )}
                                      onChange={(selected) => {
                                        if (selected !== null) {
                                          formik.setFieldValue(
                                            `days_slot_3.${index}.days_start`,
                                            selected.value
                                          );
                                        } else {
                                          formik.setFieldValue(
                                            `days_slot_3.${index}.days_start`,
                                            ''
                                          );
                                        }
                                      }}
                                      className={
                                        days_startTouched && days_startErrors
                                          ? 'form-select-error'
                                          : days_startTouched && !days_startErrors
                                          ? 'form-select-success'
                                          : ''
                                      }
                                    />
                                  </Col>
                                  <div
                                    className={
                                      'working-details-hours ' +
                                      (index > 0 ? 'working-list-hours' : '')
                                    }>
                                    <div className="form-field-error-text">
                                      {days_startTouched && days_startErrors ? (
                                        <div>{t(days_startErrors)}</div>
                                      ) : null}
                                    </div>
                                  </div>
                                </Row>
                              </Col>

                              <Col lg={3} xs={12}>
                                <Row className="working-text-center">
                                  <Col lg={3}>
                                    <span className="working-details-lable">
                                      {t('page.add_producer_work_details_end_time_label')}
                                    </span>
                                  </Col>
                                  <Col lg={9}>
                                    <Select
                                      isDisabled={
                                        formik.values.days_slot_3[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_3[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      isClearable={true}
                                      name={`days_slot_3.${index}.days_end`}
                                      options={slotOptionList}
                                      placeholder={t(
                                        'page.add_producer_work_details_end_time_placeholder'
                                      )}
                                      onChange={(selected) => {
                                        if (selected !== null) {
                                          formik.setFieldValue(
                                            `days_slot_3.${index}.days_end`,
                                            selected.value
                                          );
                                        } else {
                                          formik.setFieldValue(`days_slot_3.${index}.days_end`, '');
                                        }
                                      }}
                                      className={
                                        days_endTouched && days_endErrors
                                          ? 'form-select-error'
                                          : days_endTouched && !days_endErrors
                                          ? 'form-select-success'
                                          : ''
                                      }
                                    />
                                  </Col>
                                </Row>
                                <div className="working-details-hours">
                                  <div className="form-field-error-text">
                                    {days_endTouched && days_endErrors ? (
                                      <div>{t(days_endErrors)}</div>
                                    ) : null}
                                  </div>
                                </div>
                              </Col>
                              <Col lg={3} xs={12}>
                                <div>
                                  {index > 0 ? (
                                    <Button
                                      disabled={
                                        formik.values.days_slot_3[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_3[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      type="button"
                                      className={'secondary-remove-button '}
                                      onClick={() => remove(index)}>
                                      {t(
                                        'page.add_producer_work_details_working_remove_button_text'
                                      )}
                                    </Button>
                                  ) : (
                                    <div>
                                      <Button
                                        disabled={
                                          formik.values.days_slot_3[0].is_close === true
                                            ? true
                                            : formik.values.days_slot_3[0].dayscheckbox === false
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          const startDay = formik.values.days_slot_3.filter(
                                            (value) => {
                                              return value.days_start === '';
                                            }
                                          );
                                          const endDay = formik.values.days_slot_3.filter(
                                            (value) => {
                                              return value.days_end === '';
                                            }
                                          );
                                          if (startDay.length > 0 && endDay.length > 0) {
                                            toast.error(t('page.select_previous_day_time_first'));
                                            return;
                                          }
                                          push({
                                            dayscheckbox: formik.values.days_slot_3[0].dayscheckbox,
                                            is_close: formik.values.days_slot_3[0].is_close,
                                            days_start: '',
                                            days_end: ''
                                          });
                                        }}
                                        className={'secondary-add-button  d-none'}>
                                        {t(
                                          'page.add_producer_work_details_working_add_button_text'
                                        )}
                                      </Button>
                                      {formik.values.days_slot_3[index].is_close === false ? (
                                        <Button
                                          type="button"
                                          className="secondary-remove-button"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `days_slot_3.${index}.is_close`,
                                              true
                                            );
                                            formik.setFieldValue(
                                              `days_slot_3.${index}.dayscheckbox`,
                                              true
                                            );
                                          }}>
                                          {t(
                                            'page.add_producer_work_details_working_close_button_text'
                                          )}
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="secondary-add-button"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `days_slot_3.${index}.is_close`,
                                              false
                                            );
                                          }}>
                                          {t(
                                            'page.add_producer_work_details_working_open_button_text'
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  </>
                );
              }}
            </FieldArray>
          </Col>
        </Row>
        {/* day 4 */}
        <Row>
          <Col lg={12}>
            <FieldArray name="days_slot_4">
              {({ remove, push }) => {
                const days_slot_4s = formik.values.days_slot_4;
                return (
                  <>
                    {days_slot_4s.length > 0 &&
                      days_slot_4s.map((days_slot_4, index) => {
                        const days_startErrors =
                          (formik.errors.days_slot_4?.length &&
                            formik.errors.days_slot_4[index]?.days_start) ||
                          '';
                        const days_startTouched =
                          (formik.touched.days_slot_4?.length &&
                            formik.touched.days_slot_4[index]?.days_start) ||
                          '';
                        const days_endErrors =
                          (formik.errors.days_slot_4?.length &&
                            formik.errors.days_slot_4[index]?.days_end) ||
                          '';
                        const days_endTouched =
                          (formik.touched.days_slot_4?.length &&
                            formik.touched.days_slot_4[index]?.days_end) ||
                          '';
                        const dayscheckboxErrors =
                          (formik.errors.days_slot_4?.length &&
                            formik.errors.days_slot_4[index]?.dayscheckbox) ||
                          '';
                        const dayscheckboxTouched =
                          (formik.touched.days_slot_4?.length &&
                            formik.touched.days_slot_4[index]?.dayscheckbox) ||
                          '';
                        return (
                          <div key={index.toString()} className="working-details">
                            <Row className="working-text-center">
                              <Col lg={2} xs={12}>
                                {index === 0 ? (
                                  <>
                                    <Form.Check
                                      label={t('page.add_producer_work_details_label_4')}
                                      name={`days_slot_4.${index}.dayscheckbox`}
                                      disabled={formik.values.days_slot_4[0].is_close}
                                      value={formik.values.days_slot_4[0].dayscheckbox}
                                      checked={formik.values.days_slot_4[0].dayscheckbox}
                                      onChange={(event) => {
                                        for (var i = 0; i < formik.values.days_slot_4.length; ++i) {
                                          formik.setFieldValue(
                                            `days_slot_4.${i}.dayscheckbox`,
                                            event.currentTarget.checked
                                          );
                                        }
                                      }}
                                    />
                                    <div className="form-field-error-text">
                                      {dayscheckboxTouched && dayscheckboxErrors ? (
                                        <div>{t(dayscheckboxErrors)}</div>
                                      ) : null}
                                    </div>
                                  </>
                                ) : null}
                              </Col>
                              <Col lg={3} xs={12}>
                                <Row className="working-text-center">
                                  <Col lg={3}>
                                    <span className="working-details-lable">
                                      {t('page.add_producer_work_details_start_time_label')}
                                    </span>
                                  </Col>
                                  <Col lg={9}>
                                    <Select
                                      isDisabled={
                                        formik.values.days_slot_4[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_4[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      isClearable={true}
                                      name={`days_slot_4.${index}.days_start`}
                                      options={slotOptionList}
                                      placeholder={t(
                                        'page.add_producer_work_details_start_time_placeholder'
                                      )}
                                      onChange={(selected) => {
                                        if (selected !== null) {
                                          formik.setFieldValue(
                                            `days_slot_4.${index}.days_start`,
                                            selected.value
                                          );
                                        } else {
                                          formik.setFieldValue(
                                            `days_slot_4.${index}.days_start`,
                                            ''
                                          );
                                        }
                                      }}
                                      className={
                                        days_startTouched && days_startErrors
                                          ? 'form-select-error'
                                          : days_startTouched && !days_startErrors
                                          ? 'form-select-success'
                                          : ''
                                      }
                                    />
                                  </Col>
                                  <div
                                    className={
                                      'working-details-hours ' +
                                      (index > 0 ? 'working-list-hours' : '')
                                    }>
                                    <div className="form-field-error-text">
                                      {days_startTouched && days_startErrors ? (
                                        <div>{t(days_startErrors)}</div>
                                      ) : null}
                                    </div>
                                  </div>
                                </Row>
                              </Col>

                              <Col lg={3} xs={12}>
                                <Row className="working-text-center">
                                  <Col lg={3}>
                                    <span className="working-details-lable">
                                      {t('page.add_producer_work_details_end_time_label')}
                                    </span>
                                  </Col>
                                  <Col lg={9}>
                                    <Select
                                      isDisabled={
                                        formik.values.days_slot_4[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_4[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      isClearable={true}
                                      name={`days_slot_4.${index}.days_end`}
                                      options={slotOptionList}
                                      placeholder={t(
                                        'page.add_producer_work_details_end_time_placeholder'
                                      )}
                                      onChange={(selected) => {
                                        if (selected !== null) {
                                          formik.setFieldValue(
                                            `days_slot_4.${index}.days_end`,
                                            selected.value
                                          );
                                        } else {
                                          formik.setFieldValue(`days_slot_4.${index}.days_end`, '');
                                        }
                                      }}
                                      className={
                                        days_endTouched && days_endErrors
                                          ? 'form-select-error'
                                          : days_endTouched && !days_endErrors
                                          ? 'form-select-success'
                                          : ''
                                      }
                                    />
                                  </Col>
                                </Row>
                                <div className="working-details-hours">
                                  <div className="form-field-error-text">
                                    {days_endTouched && days_endErrors ? (
                                      <div>{t(days_endErrors)}</div>
                                    ) : null}
                                  </div>
                                </div>
                              </Col>
                              <Col lg={3} xs={12}>
                                <div>
                                  {index > 0 ? (
                                    <Button
                                      disabled={
                                        formik.values.days_slot_4[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_4[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      type="button"
                                      className={'secondary-remove-button '}
                                      onClick={() => remove(index)}>
                                      {t(
                                        'page.add_producer_work_details_working_remove_button_text'
                                      )}
                                    </Button>
                                  ) : (
                                    <div>
                                      <Button
                                        disabled={
                                          formik.values.days_slot_4[0].is_close === true
                                            ? true
                                            : formik.values.days_slot_4[0].dayscheckbox === false
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          const startDay = formik.values.days_slot_4.filter(
                                            (value) => {
                                              return value.days_start === '';
                                            }
                                          );
                                          const endDay = formik.values.days_slot_4.filter(
                                            (value) => {
                                              return value.days_end === '';
                                            }
                                          );
                                          if (startDay.length > 0 && endDay.length > 0) {
                                            toast.error(t('page.select_previous_day_time_first'));
                                            return;
                                          }
                                          push({
                                            dayscheckbox: formik.values.days_slot_4[0].dayscheckbox,
                                            is_close: formik.values.days_slot_4[0].is_close,
                                            days_start: '',
                                            days_end: ''
                                          });
                                        }}
                                        className={'secondary-add-button  d-none'}>
                                        {t(
                                          'page.add_producer_work_details_working_add_button_text'
                                        )}
                                      </Button>
                                      {formik.values.days_slot_4[index].is_close === false ? (
                                        <Button
                                          type="button"
                                          className="secondary-remove-button"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `days_slot_4.${index}.is_close`,
                                              true
                                            );
                                            formik.setFieldValue(
                                              `days_slot_4.${index}.dayscheckbox`,
                                              true
                                            );
                                          }}>
                                          {t(
                                            'page.add_producer_work_details_working_close_button_text'
                                          )}
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="secondary-add-button"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `days_slot_4.${index}.is_close`,
                                              false
                                            );
                                          }}>
                                          {t(
                                            'page.add_producer_work_details_working_open_button_text'
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  </>
                );
              }}
            </FieldArray>
          </Col>
        </Row>
        {/* day 5 */}
        <Row>
          <Col lg={12}>
            <FieldArray name="days_slot_5">
              {({ remove, push }) => {
                const days_slot_5s = formik.values.days_slot_5;
                return (
                  <>
                    {days_slot_5s.length > 0 &&
                      days_slot_5s.map((days_slot_5, index) => {
                        const days_startErrors =
                          (formik.errors.days_slot_5?.length &&
                            formik.errors.days_slot_5[index]?.days_start) ||
                          '';
                        const days_startTouched =
                          (formik.touched.days_slot_5?.length &&
                            formik.touched.days_slot_5[index]?.days_start) ||
                          '';
                        const days_endErrors =
                          (formik.errors.days_slot_5?.length &&
                            formik.errors.days_slot_5[index]?.days_end) ||
                          '';
                        const days_endTouched =
                          (formik.touched.days_slot_5?.length &&
                            formik.touched.days_slot_5[index]?.days_end) ||
                          '';
                        const dayscheckboxErrors =
                          (formik.errors.days_slot_5?.length &&
                            formik.errors.days_slot_5[index]?.dayscheckbox) ||
                          '';
                        const dayscheckboxTouched =
                          (formik.touched.days_slot_5?.length &&
                            formik.touched.days_slot_5[index]?.dayscheckbox) ||
                          '';
                        return (
                          <div key={index.toString()} className="working-details">
                            <Row className="working-text-center">
                              <Col lg={2} xs={12}>
                                {index === 0 ? (
                                  <>
                                    <Form.Check
                                      label={t('page.add_producer_work_details_label_5')}
                                      name={`days_slot_5.${index}.dayscheckbox`}
                                      disabled={formik.values.days_slot_5[0].is_close}
                                      value={formik.values.days_slot_5[0].dayscheckbox}
                                      checked={formik.values.days_slot_5[0].dayscheckbox}
                                      onChange={(event) => {
                                        for (var i = 0; i < formik.values.days_slot_5.length; ++i) {
                                          formik.setFieldValue(
                                            `days_slot_5.${i}.dayscheckbox`,
                                            event.currentTarget.checked
                                          );
                                        }
                                      }}
                                    />
                                    <div className="form-field-error-text">
                                      {dayscheckboxTouched && dayscheckboxErrors ? (
                                        <div>{t(dayscheckboxErrors)}</div>
                                      ) : null}
                                    </div>
                                  </>
                                ) : null}
                              </Col>
                              <Col lg={3} xs={12}>
                                <Row className="working-text-center">
                                  <Col lg={3}>
                                    <span className="working-details-lable">
                                      {t('page.add_producer_work_details_start_time_label')}
                                    </span>
                                  </Col>
                                  <Col lg={9}>
                                    <Select
                                      isDisabled={
                                        formik.values.days_slot_5[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_5[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      isClearable={true}
                                      name={`days_slot_5.${index}.days_start`}
                                      options={slotOptionList}
                                      placeholder={t(
                                        'page.add_producer_work_details_start_time_placeholder'
                                      )}
                                      onChange={(selected) => {
                                        if (selected !== null) {
                                          formik.setFieldValue(
                                            `days_slot_5.${index}.days_start`,
                                            selected.value
                                          );
                                        } else {
                                          formik.setFieldValue(
                                            `days_slot_5.${index}.days_start`,
                                            ''
                                          );
                                        }
                                      }}
                                      className={
                                        days_startTouched && days_startErrors
                                          ? 'form-select-error'
                                          : days_startTouched && !days_startErrors
                                          ? 'form-select-success'
                                          : ''
                                      }
                                    />
                                  </Col>
                                  <div
                                    className={
                                      'working-details-hours ' +
                                      (index > 0 ? 'working-list-hours' : '')
                                    }>
                                    <div className="form-field-error-text">
                                      {days_startTouched && days_startErrors ? (
                                        <div>{t(days_startErrors)}</div>
                                      ) : null}
                                    </div>
                                  </div>
                                </Row>
                              </Col>

                              <Col lg={3} xs={12}>
                                <Row className="working-text-center">
                                  <Col lg={3}>
                                    <span className="working-details-lable">
                                      {t('page.add_producer_work_details_end_time_label')}
                                    </span>
                                  </Col>
                                  <Col lg={9}>
                                    <Select
                                      isDisabled={
                                        formik.values.days_slot_5[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_5[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      isClearable={true}
                                      name={`days_slot_5.${index}.days_end`}
                                      options={slotOptionList}
                                      placeholder={t(
                                        'page.add_producer_work_details_end_time_placeholder'
                                      )}
                                      onChange={(selected) => {
                                        if (selected !== null) {
                                          formik.setFieldValue(
                                            `days_slot_5.${index}.days_end`,
                                            selected.value
                                          );
                                        } else {
                                          formik.setFieldValue(`days_slot_5.${index}.days_end`, '');
                                        }
                                      }}
                                      className={
                                        days_endTouched && days_endErrors
                                          ? 'form-select-error'
                                          : days_endTouched && !days_endErrors
                                          ? 'form-select-success'
                                          : ''
                                      }
                                    />
                                  </Col>
                                </Row>
                                <div className="working-details-hours">
                                  <div className="form-field-error-text">
                                    {days_endTouched && days_endErrors ? (
                                      <div>{t(days_endErrors)}</div>
                                    ) : null}
                                  </div>
                                </div>
                              </Col>
                              <Col lg={3} xs={12}>
                                <div>
                                  {index > 0 ? (
                                    <Button
                                      disabled={
                                        formik.values.days_slot_5[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_5[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      type="button"
                                      className={'secondary-remove-button '}
                                      onClick={() => remove(index)}>
                                      {t(
                                        'page.add_producer_work_details_working_remove_button_text'
                                      )}
                                    </Button>
                                  ) : (
                                    <div>
                                      <Button
                                        disabled={
                                          formik.values.days_slot_5[0].is_close === true
                                            ? true
                                            : formik.values.days_slot_5[0].dayscheckbox === false
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          const startDay = formik.values.days_slot_5.filter(
                                            (value) => {
                                              return value.days_start === '';
                                            }
                                          );
                                          const endDay = formik.values.days_slot_5.filter(
                                            (value) => {
                                              return value.days_end === '';
                                            }
                                          );
                                          if (startDay.length > 0 && endDay.length > 0) {
                                            toast.error(t('page.select_previous_day_time_first'));
                                            return;
                                          }
                                          push({
                                            dayscheckbox: formik.values.days_slot_5[0].dayscheckbox,
                                            is_close: formik.values.days_slot_5[0].is_close,
                                            days_start: '',
                                            days_end: ''
                                          });
                                        }}
                                        className={'secondary-add-button  d-none'}>
                                        {t(
                                          'page.add_producer_work_details_working_add_button_text'
                                        )}
                                      </Button>
                                      {formik.values.days_slot_5[index].is_close === false ? (
                                        <Button
                                          type="button"
                                          className="secondary-remove-button"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `days_slot_5.${index}.is_close`,
                                              true
                                            );
                                            formik.setFieldValue(
                                              `days_slot_5.${index}.dayscheckbox`,
                                              true
                                            );
                                          }}>
                                          {t(
                                            'page.add_producer_work_details_working_close_button_text'
                                          )}
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="secondary-add-button"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `days_slot_5.${index}.is_close`,
                                              false
                                            );
                                          }}>
                                          {t(
                                            'page.add_producer_work_details_working_open_button_text'
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  </>
                );
              }}
            </FieldArray>
          </Col>
        </Row>
        {/* day 6 */}
        <Row>
          <Col lg={12}>
            <FieldArray name="days_slot_6">
              {({ remove, push }) => {
                const days_slot_6s = formik.values.days_slot_6;
                return (
                  <>
                    {days_slot_6s.length > 0 &&
                      days_slot_6s.map((days_slot_6, index) => {
                        const days_startErrors =
                          (formik.errors.days_slot_6?.length &&
                            formik.errors.days_slot_6[index]?.days_start) ||
                          '';
                        const days_startTouched =
                          (formik.touched.days_slot_6?.length &&
                            formik.touched.days_slot_6[index]?.days_start) ||
                          '';
                        const days_endErrors =
                          (formik.errors.days_slot_6?.length &&
                            formik.errors.days_slot_6[index]?.days_end) ||
                          '';
                        const days_endTouched =
                          (formik.touched.days_slot_6?.length &&
                            formik.touched.days_slot_6[index]?.days_end) ||
                          '';
                        const dayscheckboxErrors =
                          (formik.errors.days_slot_6?.length &&
                            formik.errors.days_slot_6[index]?.dayscheckbox) ||
                          '';
                        const dayscheckboxTouched =
                          (formik.touched.days_slot_6?.length &&
                            formik.touched.days_slot_6[index]?.dayscheckbox) ||
                          '';
                        return (
                          <div key={index.toString()} className="working-details">
                            <Row className="working-text-center">
                              <Col lg={2} xs={12}>
                                {index === 0 ? (
                                  <>
                                    <Form.Check
                                      label={t('page.add_producer_work_details_label_6')}
                                      name={`days_slot_6.${index}.dayscheckbox`}
                                      disabled={formik.values.days_slot_6[0].is_close}
                                      value={formik.values.days_slot_6[0].dayscheckbox}
                                      checked={formik.values.days_slot_6[0].dayscheckbox}
                                      onChange={(event) => {
                                        for (var i = 0; i < formik.values.days_slot_6.length; ++i) {
                                          formik.setFieldValue(
                                            `days_slot_6.${i}.dayscheckbox`,
                                            event.currentTarget.checked
                                          );
                                        }
                                      }}
                                    />
                                    <div className="form-field-error-text">
                                      {dayscheckboxTouched && dayscheckboxErrors ? (
                                        <div>{t(dayscheckboxErrors)}</div>
                                      ) : null}
                                    </div>
                                  </>
                                ) : null}
                              </Col>
                              <Col lg={3} xs={12}>
                                <Row className="working-text-center">
                                  <Col lg={3}>
                                    <span className="working-details-lable">
                                      {t('page.add_producer_work_details_start_time_label')}
                                    </span>
                                  </Col>
                                  <Col lg={9}>
                                    <Select
                                      isDisabled={
                                        formik.values.days_slot_6[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_6[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      isClearable={true}
                                      name={`days_slot_6.${index}.days_start`}
                                      options={slotOptionList}
                                      placeholder={t(
                                        'page.add_producer_work_details_start_time_placeholder'
                                      )}
                                      onChange={(selected) => {
                                        if (selected !== null) {
                                          formik.setFieldValue(
                                            `days_slot_6.${index}.days_start`,
                                            selected.value
                                          );
                                        } else {
                                          formik.setFieldValue(
                                            `days_slot_6.${index}.days_start`,
                                            ''
                                          );
                                        }
                                      }}
                                      className={
                                        days_startTouched && days_startErrors
                                          ? 'form-select-error'
                                          : days_startTouched && !days_startErrors
                                          ? 'form-select-success'
                                          : ''
                                      }
                                    />
                                  </Col>
                                  <div
                                    className={
                                      'working-details-hours ' +
                                      (index > 0 ? 'working-list-hours' : '')
                                    }>
                                    <div className="form-field-error-text">
                                      {days_startTouched && days_startErrors ? (
                                        <div>{t(days_startErrors)}</div>
                                      ) : null}
                                    </div>
                                  </div>
                                </Row>
                              </Col>

                              <Col lg={3} xs={12}>
                                <Row className="working-text-center">
                                  <Col lg={3}>
                                    <span className="working-details-lable">
                                      {t('page.add_producer_work_details_end_time_label')}
                                    </span>
                                  </Col>
                                  <Col lg={9}>
                                    <Select
                                      isDisabled={
                                        formik.values.days_slot_6[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_6[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      isClearable={true}
                                      name={`days_slot_6.${index}.days_end`}
                                      options={slotOptionList}
                                      placeholder={t(
                                        'page.add_producer_work_details_end_time_placeholder'
                                      )}
                                      onChange={(selected) => {
                                        if (selected !== null) {
                                          formik.setFieldValue(
                                            `days_slot_6.${index}.days_end`,
                                            selected.value
                                          );
                                        } else {
                                          formik.setFieldValue(`days_slot_6.${index}.days_end`, '');
                                        }
                                      }}
                                      className={
                                        days_endTouched && days_endErrors
                                          ? 'form-select-error'
                                          : days_endTouched && !days_endErrors
                                          ? 'form-select-success'
                                          : ''
                                      }
                                    />
                                  </Col>
                                </Row>
                                <div className="working-details-hours">
                                  <div className="form-field-error-text">
                                    {days_endTouched && days_endErrors ? (
                                      <div>{t(days_endErrors)}</div>
                                    ) : null}
                                  </div>
                                </div>
                              </Col>
                              <Col lg={3} xs={12}>
                                <div>
                                  {index > 0 ? (
                                    <Button
                                      disabled={
                                        formik.values.days_slot_6[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_6[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      type="button"
                                      className={'secondary-remove-button '}
                                      onClick={() => remove(index)}>
                                      {t(
                                        'page.add_producer_work_details_working_remove_button_text'
                                      )}
                                    </Button>
                                  ) : (
                                    <div>
                                      <Button
                                        disabled={
                                          formik.values.days_slot_6[0].is_close === true
                                            ? true
                                            : formik.values.days_slot_6[0].dayscheckbox === false
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          const startDay = formik.values.days_slot_6.filter(
                                            (value) => {
                                              return value.days_start === '';
                                            }
                                          );
                                          const endDay = formik.values.days_slot_6.filter(
                                            (value) => {
                                              return value.days_end === '';
                                            }
                                          );
                                          if (startDay.length > 0 && endDay.length > 0) {
                                            toast.error(t('page.select_previous_day_time_first'));
                                            return;
                                          }
                                          push({
                                            dayscheckbox: formik.values.days_slot_6[0].dayscheckbox,
                                            is_close: formik.values.days_slot_6[0].is_close,
                                            days_start: '',
                                            days_end: ''
                                          });
                                        }}
                                        className={'secondary-add-button  d-none'}>
                                        {t(
                                          'page.add_producer_work_details_working_add_button_text'
                                        )}
                                      </Button>
                                      {formik.values.days_slot_6[index].is_close === false ? (
                                        <Button
                                          type="button"
                                          className="secondary-remove-button"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `days_slot_6.${index}.is_close`,
                                              true
                                            );
                                            formik.setFieldValue(
                                              `days_slot_6.${index}.dayscheckbox`,
                                              true
                                            );
                                          }}>
                                          {t(
                                            'page.add_producer_work_details_working_close_button_text'
                                          )}
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="secondary-add-button"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `days_slot_6.${index}.is_close`,
                                              false
                                            );
                                          }}>
                                          {t(
                                            'page.add_producer_work_details_working_open_button_text'
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  </>
                );
              }}
            </FieldArray>
          </Col>
        </Row>
        {/* day 7 */}
        <Row>
          <Col lg={12}>
            <FieldArray name="days_slot_7">
              {({ remove, push }) => {
                const days_slot_7s = formik.values.days_slot_7;
                return (
                  <>
                    {days_slot_7s.length > 0 &&
                      days_slot_7s.map((days_slot_7, index) => {
                        const days_startErrors =
                          (formik.errors.days_slot_7?.length &&
                            formik.errors.days_slot_7[index]?.days_start) ||
                          '';
                        const days_startTouched =
                          (formik.touched.days_slot_7?.length &&
                            formik.touched.days_slot_7[index]?.days_start) ||
                          '';
                        const days_endErrors =
                          (formik.errors.days_slot_7?.length &&
                            formik.errors.days_slot_7[index]?.days_end) ||
                          '';
                        const days_endTouched =
                          (formik.touched.days_slot_7?.length &&
                            formik.touched.days_slot_7[index]?.days_end) ||
                          '';
                        const dayscheckboxErrors =
                          (formik.errors.days_slot_7?.length &&
                            formik.errors.days_slot_7[index]?.dayscheckbox) ||
                          '';
                        const dayscheckboxTouched =
                          (formik.touched.days_slot_7?.length &&
                            formik.touched.days_slot_7[index]?.dayscheckbox) ||
                          '';
                        return (
                          <div key={index.toString()} className="working-details">
                            <Row className="working-text-center">
                              <Col lg={2} xs={12}>
                                {index === 0 ? (
                                  <>
                                    <Form.Check
                                      label={t('page.add_producer_work_details_label_7')}
                                      name={`days_slot_7.${index}.dayscheckbox`}
                                      disabled={formik.values.days_slot_7[0].is_close}
                                      value={formik.values.days_slot_7[0].dayscheckbox}
                                      checked={formik.values.days_slot_7[0].dayscheckbox}
                                      onChange={(event) => {
                                        for (var i = 0; i < formik.values.days_slot_7.length; ++i) {
                                          formik.setFieldValue(
                                            `days_slot_7.${i}.dayscheckbox`,
                                            event.currentTarget.checked
                                          );
                                        }
                                      }}
                                    />
                                    <div className="form-field-error-text">
                                      {dayscheckboxTouched && dayscheckboxErrors ? (
                                        <div>{t(dayscheckboxErrors)}</div>
                                      ) : null}
                                    </div>
                                  </>
                                ) : null}
                              </Col>
                              <Col lg={3} xs={12}>
                                <Row className="working-text-center">
                                  <Col lg={3}>
                                    <span className="working-details-lable">
                                      {t('page.add_producer_work_details_start_time_label')}
                                    </span>
                                  </Col>
                                  <Col lg={9}>
                                    <Select
                                      isDisabled={
                                        formik.values.days_slot_7[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_7[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      isClearable={true}
                                      name={`days_slot_7.${index}.days_start`}
                                      options={slotOptionList}
                                      placeholder={t(
                                        'page.add_producer_work_details_start_time_placeholder'
                                      )}
                                      onChange={(selected) => {
                                        if (selected !== null) {
                                          formik.setFieldValue(
                                            `days_slot_7.${index}.days_start`,
                                            selected.value
                                          );
                                        } else {
                                          formik.setFieldValue(
                                            `days_slot_7.${index}.days_start`,
                                            ''
                                          );
                                        }
                                      }}
                                      className={
                                        days_startTouched && days_startErrors
                                          ? 'form-select-error'
                                          : days_startTouched && !days_startErrors
                                          ? 'form-select-success'
                                          : ''
                                      }
                                    />
                                  </Col>
                                  <div
                                    className={
                                      'working-details-hours ' +
                                      (index > 0 ? 'working-list-hours' : '')
                                    }>
                                    <div className="form-field-error-text">
                                      {days_startTouched && days_startErrors ? (
                                        <div>{t(days_startErrors)}</div>
                                      ) : null}
                                    </div>
                                  </div>
                                </Row>
                              </Col>

                              <Col lg={3} xs={12}>
                                <Row className="working-text-center">
                                  <Col lg={3}>
                                    <span className="working-details-lable">
                                      {t('page.add_producer_work_details_end_time_label')}
                                    </span>
                                  </Col>
                                  <Col lg={9}>
                                    <Select
                                      isDisabled={
                                        formik.values.days_slot_7[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_7[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      isClearable={true}
                                      name={`days_slot_7.${index}.days_end`}
                                      options={slotOptionList}
                                      placeholder={t(
                                        'page.add_producer_work_details_end_time_placeholder'
                                      )}
                                      onChange={(selected) => {
                                        if (selected !== null) {
                                          formik.setFieldValue(
                                            `days_slot_7.${index}.days_end`,
                                            selected.value
                                          );
                                        } else {
                                          formik.setFieldValue(`days_slot_7.${index}.days_end`, '');
                                        }
                                      }}
                                      className={
                                        days_endTouched && days_endErrors
                                          ? 'form-select-error'
                                          : days_endTouched && !days_endErrors
                                          ? 'form-select-success'
                                          : ''
                                      }
                                    />
                                  </Col>
                                </Row>
                                <div className="working-details-hours">
                                  <div className="form-field-error-text">
                                    {days_endTouched && days_endErrors ? (
                                      <div>{t(days_endErrors)}</div>
                                    ) : null}
                                  </div>
                                </div>
                              </Col>
                              <Col lg={3} xs={12}>
                                <div>
                                  {index > 0 ? (
                                    <Button
                                      disabled={
                                        formik.values.days_slot_7[0].is_close === true
                                          ? true
                                          : formik.values.days_slot_7[0].dayscheckbox === false
                                          ? true
                                          : false
                                      }
                                      type="button"
                                      className={'secondary-remove-button '}
                                      onClick={() => remove(index)}>
                                      {t(
                                        'page.add_producer_work_details_working_remove_button_text'
                                      )}
                                    </Button>
                                  ) : (
                                    <div>
                                      <Button
                                        disabled={
                                          formik.values.days_slot_7[0].is_close === true
                                            ? true
                                            : formik.values.days_slot_7[0].dayscheckbox === false
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          const startDay = formik.values.days_slot_7.filter(
                                            (value) => {
                                              return value.days_start === '';
                                            }
                                          );
                                          const endDay = formik.values.days_slot_7.filter(
                                            (value) => {
                                              return value.days_end === '';
                                            }
                                          );
                                          if (startDay.length > 0 && endDay.length > 0) {
                                            toast.error(t('page.select_previous_day_time_first'));
                                            return;
                                          }
                                          push({
                                            dayscheckbox: formik.values.days_slot_7[0].dayscheckbox,
                                            is_close: formik.values.days_slot_7[0].is_close,
                                            days_start: '',
                                            days_end: ''
                                          });
                                        }}
                                        className={'secondary-add-button  d-none'}>
                                        {t(
                                          'page.add_producer_work_details_working_add_button_text'
                                        )}
                                      </Button>
                                      {formik.values.days_slot_7[index].is_close === false ? (
                                        <Button
                                          type="button"
                                          className="secondary-remove-button"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `days_slot_7.${index}.dayscheckbox`,
                                              true
                                            );
                                            formik.setFieldValue(
                                              `days_slot_7.${index}.is_close`,
                                              true
                                            );
                                          }}>
                                          {t(
                                            'page.add_producer_work_details_working_close_button_text'
                                          )}
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          className="secondary-add-button"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `days_slot_7.${index}.is_close`,
                                              false
                                            );
                                          }}>
                                          {t(
                                            'page.add_producer_work_details_working_open_button_text'
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  </>
                );
              }}
            </FieldArray>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
WorkDetails.propTypes = {
  t: PropTypes.func
};
export { WorkDetails };
