import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListFactoryPages } from './ListFactoryPages';
import { AddFactoryPages } from './AddFactoryPages';
import { ViewFactoryPage } from './ViewFactoryPage';
import { EditFactoryPage } from './EditFactoryPage';
import { FactoryProducer } from './producer/List';
import { AddFactoryProducer } from './producer/Add';
import { EditFactoryProducer } from './producer/Edit';
import FactoryPricing from './FactoryPricing';
const FactoryRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index path="/list/" element={<ListFactoryPages t={t} />} />
      <Route path="/add" element={<AddFactoryPages t={t} />} />
      <Route path="/view/:factory_id" element={<ViewFactoryPage t={t} />} />
      <Route path="/:factory_id/producer" element={<FactoryProducer t={t} />} />
      <Route path="/:factory_id/producer/new" element={<AddFactoryProducer t={t} />} />
      <Route
        path="/:factory_id/producer/:factory_producer_id"
        element={<EditFactoryProducer t={t} />}
      />
      <Route path="/edit/:factory_id" element={<EditFactoryPage t={t} />} />
      <Route path="/:factory_id/pricing" element={<FactoryPricing t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
FactoryRoutes.propTypes = {
  t: PropTypes.func
};
export default FactoryRoutes;
