import client from '@libs/HttpClient';
class OwnWarehouseTransporterService {
  static storeOwnWarehouseTransporter(request) {
    return client.post('/own-warehouse-transporter/store', request);
  }
  static listOwnWarehouseTransporter(request) {
    const page_no = request.queryKey[1];
    const territoryId = request.queryKey[2];
    const transporterId = request.queryKey[3];
    const warehouseId = request.queryKey[4];
    const sortBy = request.queryKey[5];
    const sortOrder = request.queryKey[6];
    return client.get(
      `/own-warehouse-transporter/index?page=${page_no}&territory_id=${territoryId}&transporter_id=${transporterId}&warehouse_id=${warehouseId}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }
  static deleteOwnWarehouseTransporter(request) {
    const own_warehouse_transporter_id = request.queryKey[1];
    return client.get(
      `/own-warehouse-transporter/destroy/${own_warehouse_transporter_id}`,
      request
    );
  }
  static ownwarehouseView(request) {
    const ownwarehouse_id = request.queryKey[1];
    return client.get(`/own-warehouse-transporter/view/${ownwarehouse_id}`, request);
  }

  static updateOwnWarehouseTransporter(request) {
    return client.post(`/own-warehouse-transporter/update/${request.ownwarehouse_id}`, request);
  }

  static checkOwnWarehouseOrNot(request) {
    const warehouse_id = request.queryKey[1];
    return client.get(
      `/own-warehouse-transporter/check-own-warehouse-or-not/${warehouse_id}`,
      request
    );
  }
  static generateReport(request) {
    return client.post(`/own-warehouse-transporter/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { OwnWarehouseTransporterService };
