import client from '@libs/HttpClient';
class CommonService {
  static getTerritoryData() {
    return client.get('/territory/dropdown-data');
  }
  static getAreaData(territory_id) {
    return client.get('/area/dropdown-data/' + (territory_id ? territory_id : 0));
  }
  static getAllAreaData() {
    return client.get('/area/all-dropdown-data');
  }

  static producerContainerData() {
    return client.get('/producer/container/all-dropdown-data');
  }

  static getCollectAreaData() {
    return client.get('area/collect/dropdown-data');
  }
  static getTransportAreaData() {
    return client.get('area/trsansport/dropdown-data');
  }
  static getProducerTypeData() {
    return client.get('producertype/dropdown-data');
  }
  static producerTypeWithTest = () => client.get('producertype/dropdown-data?with_test_type');
  static getWestCodeData() {
    return client.get('wastecode/dropdown-data');
  }
  static getAgentList() {
    return client.get('agent/dropdown-data');
  }
  static getTransporterList(request) {
    return client.get(`/transporter-list`, request);
  }
  static getDriverData(request) {
    return client.get(
      `/tour/driver-dropdown-data/${request.transporter_id ? request.transporter_id : 0}/${
        request.tour_id
      }`
    );
  }

  static getWarehouseListArea() {
    return client.get('/warehouse-list-area-dropdown');
  }

  static getFactoryListArea() {
    return client.get('/factory-list-area-dropdown');
  }
}
export { CommonService };
