import { useQuery, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { settingData } from '../admin/store/features/settingSlice';
import { SettingService } from '@api/';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetSettingData = (onSuccess, onError) => {
  const getSettingData = useSelector(settingData);
  return useQuery(
    'setting_data',
    () => {
      return SettingService.getSettingData();
    },
    {
      onSuccess,
      onError,
      enabled: getSettingData.setting_get
    }
  );
};

const useGetSettingDataAlways = (onSuccess, onError) => {
  return useQuery(
    'setting_data_all',
    () => {
      return SettingService.getSettingData();
    },
    {
      onSuccess,
      onError
    }
  );
};

const useStoreSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeSettingData, {
    onSuccess,
    onError
  });
};

const useAdminDashboardData = ([territoryId, areaId], onSuccess, onError) => {
  return useQuery(['admin-dashboard-data', territoryId, areaId], SettingService.getAdminDashboard, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};

const useDownloadLink = (onSuccess, onError) => {
  return useQuery(
    'setting_download_data',
    () => {
      return SettingService.getDownloadLink();
    },
    {
      onSuccess,
      onError
    }
  );
};

const useStoreDownloadLink = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeDownloadLink, {
    onSuccess,
    onError
  });
};

export {
  useGetSettingData,
  useStoreSettingData,
  useGetSettingDataAlways,
  useDownloadLink,
  useStoreDownloadLink,
  useAdminDashboardData
};
