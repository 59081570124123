import client from '@libs/HttpClient';
class AbortService {
  /**
   * Abort Master
   */

  static storeAbortData(request) {
    return client.post('/abort/store', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static listAborts(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const sortBy = request.queryKey[3];
    const sortOrder = request.queryKey[4];

    return client.get(
      `/abort/index/?page=${page_no}&search_text=${searchText}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static viewAbort(request) {
    const abort_reason_id = request.queryKey[1];
    return client.get(`/abort/show/${abort_reason_id}`, request);
  }

  static updateAbort(request) {
    return client.post(`/abort/update/${request.abort_reason_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static deleteAborts(request) {
    return client.put(`/abort/delete/${request.abort_reason_id}`, request);
  }
  static generateReport(request) {
    return client.post(`abort/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { AbortService };
