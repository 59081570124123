import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Accordion, Card, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import AlertCommon from '@common/components/AlertCommon';
import { useGetCountryCodeList, useNavigatingAway, useUpdateDriver, useViewDriver } from '@hooks';
import { validationSchema } from './AddDriverValidation';
import { EditAttachmentFiles, EditGeneralDetails } from './components';
import { TNButton } from '@common/components/';
import TNBreadCurm from '@common/components/TNBreadCurm';
import moment from 'moment';
import { DialogLeavingPage } from '@common/components/AlertClosePage';
import { DriverLicenceDetails } from './components/DriverLicenceDetails';
import { B1TypeLicenceDetails } from './components/B1TypeLicenceDetails';
import { B3DriverCertificateDetails } from './components/B3DriverCertificate';

const EditDriverPage = ({ t }) => {
  const [accrdKey, setAccrdKey] = useState(0);
  const [formState, setFormState] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(formState);

  let { transporter_id, driver_id } = useParams();
  const navigate = useNavigate();

  /**
   * this function is used when click on cancel button
   */

  const handleCancel = () => {
    navigate(`/transporter/driver/list/${transporter_id}`);
  };

  // From this api we are getting country code list.
  const { isLoading: countryIsLoad, data: countryCodeList } = useGetCountryCodeList();
  let countryCodeOptions = [];
  if (countryCodeList !== undefined) {
    countryCodeOptions = countryCodeList.data.map((item, i) => {
      return { value: item.country_id, label: item.phone_code };
    });
  }

  /**
   * form initial value
   */
  const initialValues = {
    alliance_portal_id: transporter_id,
    user_id: '',
    driver_id: '',
    first_name: '',
    last_name: '',
    country_id: parseInt(process.env.MIX_DEFAULT_PHONE_CODE),
    phone: '',
    email: '',
    date_of_birth: '',
    address: '',
    address_notes: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
    latitude: '',
    longitude: '',
    owner_ktp: '',
    photo: '',
    photo_id: '',
    driver_ktp: '',
    driver_ktp_id: '',
    selfi_with_ktp: '',
    selfi_with_ktp_id: '',
    proof_of_address: '',
    proof_of_address_id: '',
    admin_status: '',
    user_status: '',
    licence_number: '',
    licence_expiry_date: '',
    driver_licence: '',
    driver_licence_id: '',
    b1_type_licence: '',
    b1_type_licence_expiry_date: '',
    b1_type_licence_attachment: '',
    b1_type_licence_attachment_id: '',
    b3_driver_certificate: '',
    b3_driver_certificate_attachment: '',
    b3_driver_certificate_attachment_id: ''
  };
  /**
   * !This API will call when page set. When response came we are setting up data into the form
   */
  useViewDriver(driver_id, ({ data: driver }) => {
    if (driver) {
      formik.values.alliance_portal_id = transporter_id;
      formik.values.first_name = driver.first_name;
      formik.values.last_name = driver.last_name;
      formik.values.country_id = driver.country_id;
      formik.values.phone = driver.phone;
      formik.values.email = driver.email;
      formik.values.date_of_birth =
        driver.date_of_birth !== '' ? new Date(driver.date_of_birth) : '';
      formik.values.address = driver.address;
      formik.values.address_notes = driver.address_notes;
      formik.values.city = driver.city;
      formik.values.state = driver.state;
      formik.values.postcode = driver.postcode;
      formik.values.country = driver.country;
      formik.values.latitude = driver.latitude;
      formik.values.longitude = driver.longitude;
      formik.values.owner_ktp = driver.owner_ktp;
      formik.values.photo = driver.photo;
      formik.values.photo_id = driver.photo_id;
      formik.values.driver_ktp = driver.driver_ktp;
      formik.values.driver_ktp_id = driver.driver_ktp_id;
      formik.values.selfi_with_ktp = driver.selfi_with_ktp;
      formik.values.selfi_with_ktp_id = driver.selfi_with_ktp_id;
      formik.values.proof_of_address = driver.proof_of_address;
      formik.values.proof_of_address_id = driver.proof_of_address_id;
      formik.values.admin_status = driver.admin_status;
      formik.values.user_status = driver.user_status;
      formik.values.user_id = driver.user_id;
      formik.values.driver_id = driver.driver_id;

      formik.values.licence_number = driver.licence_number;
      formik.values.licence_expiry_date =
        driver.licence_expiry_date !== '' ? new Date(driver.licence_expiry_date) : '';
      formik.values.driver_licence = driver.driver_licence;
      formik.values.driver_licence_id = driver.driver_licence_id;

      formik.values.b1_type_licence = driver.b1_type_licence;
      formik.values.b1_type_licence_expiry_date =
        driver.b1_type_licence_expiry_date !== ''
          ? new Date(driver.b1_type_licence_expiry_date)
          : '';
      formik.values.b1_type_licence_attachment = driver.b1_type_licence_attachment;
      formik.values.b1_type_licence_attachment_id = driver.b1_type_licence_attachment_id;

      formik.values.b3_driver_certificate = driver.b3_driver_certificate;
      formik.values.b3_driver_certificate_attachment = driver.b3_driver_certificate_attachment;
      formik.values.b3_driver_certificate_attachment_id =
        driver.b3_driver_certificate_attachment_id;
    }
  });
  /**
   * !This API will call when user click on Submit Button
   */
  const {
    mutate: doUpdateDriver,
    isLoading,
    error: addDRList,
    isError: addDRrror
  } = useUpdateDriver(
    (res) => {
      toast.success(res.message);
      navigate(`/transporter/driver/list/${transporter_id}`);
      formik.resetForm();
    },
    (error) => {
      window.scrollTo(0, 0);
      formik.values.date_of_birth = new Date(formik.values.date_of_birth);
      formik.values.licence_expiry_date = new Date(formik.values.licence_expiry_date);
      formik.values.b1_type_licence_expiry_date = new Date(
        formik.values.b1_type_licence_expiry_date
      );
    }
  );

  /**
   * !This API will call when user click on Submit Button
   */
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setFormState(false);

      values.date_of_birth = moment(values.date_of_birth).format('YYYY-MM-DD');
      values.licence_expiry_date = moment(values.licence_expiry_date).format('YYYY-MM-DD');
      values.b1_type_licence_expiry_date = moment(values.b1_type_licence_expiry_date).format(
        'YYYY-MM-DD'
      );

      doUpdateDriver(values);
    }
  });

  useEffect(() => {
    setFormState(formik.dirty);
  }, [formik.dirty]);

  if (isLoading) {
    return null;
  }

  const breadcurmArray = [
    {
      label: t('page.transporter_list_title'),
      link: '/transporter/list',
      active: ''
    },
    {
      label: t('page.view_transporter_title'),
      link: `/transporter/driver/list/${transporter_id}`,
      active: ''
    },
    {
      label: t('page.edit_driver_title'),
      link: `/transporter/driver/list/${transporter_id}`,
      active: 'active'
    }
  ];

  return (
    <>
      <DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={formik.dirty}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
        t={t}
      />
      <TNBreadCurm breadcurmArray={breadcurmArray} />
      <AlertCommon key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)} is_error={addDRrror}>
        {addDRList}
      </AlertCommon>
      <h1>{t('page.edit_driver_title')} </h1>
      <Card>
        <Card.Body className="p-0">
          <Form onSubmit={formik.handleSubmit}>
            <Accordion
              key={accrdKey}
              alwaysOpen
              defaultActiveKey={accrdKey && accrdKey !== 0 ? accrdKey : 1}>
              <Accordion.Item eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 1} className="my-1">
                <Accordion.Header className="text-info fw-bold shadow-nond">
                  {t('page.add_driver_general_details_title')}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <EditGeneralDetails
                    t={t}
                    formik={formik}
                    countryCodeOptions={countryCodeOptions}
                    countryIsLoad={countryIsLoad}
                  />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 2} className="my-1">
                <Accordion.Header className="text-info fw-bold shadow-nond">
                  {t('page.add_driver_driver_licence_details_title')}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <DriverLicenceDetails t={t} formik={formik}></DriverLicenceDetails>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 3} className="my-1">
                <Accordion.Header className="text-info fw-bold shadow-nond">
                  {t('page.add_driver_b1_type_licence_details_title')}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <B1TypeLicenceDetails t={t} formik={formik}></B1TypeLicenceDetails>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 4} className="my-1">
                <Accordion.Header className="text-info fw-bold shadow-nond">
                  {t('page.add_driver_b3_driver_certificate_details_title')}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <B3DriverCertificateDetails t={t} formik={formik}></B3DriverCertificateDetails>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item
                eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 5}
                className="border-bottom my-1">
                <Accordion.Header>{t('page.add_driver_attachment_title')} </Accordion.Header>
                <Accordion.Body className="p-0">
                  <EditAttachmentFiles t={t} formik={formik} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="primary-button">
              <span className="link-center" onClick={handleCancel}>
                {t('page.add_drive_cancel_button')}
              </span>
              <TNButton
                loading={isLoading}
                isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}
                onClick={() => {
                  setAccrdKey(1);
                }}
                type="submit">
                {t('page.add_drive_submit_button')}
              </TNButton>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
EditDriverPage.propTypes = {
  t: PropTypes.func
};
export { EditDriverPage };
