import { useMutation, useQuery } from 'react-query';

import { OrderService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

/* Orders */
const useListOrder = (
  [
    page_no,
    searchText,
    selectedStatus,
    fromDate,
    toDate,
    warehouseId,
    territryId,
    areaId,
    producerId,
    deliveryType,
    sortOrder,
    sortBy
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'order-list',
      page_no,
      searchText,
      selectedStatus,
      fromDate,
      toDate,
      warehouseId,
      territryId,
      areaId,
      producerId,
      deliveryType,
      sortOrder,
      sortBy
    ],
    OrderService.listOrder,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useListOrderByWarehouseAndDeliveryType = (
  [warehouseId, fromDate, toDate, deliveryType],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ['order-list-by-warehouse-and-delivery-type', warehouseId, fromDate, toDate, deliveryType],
    OrderService.listOrderByWarehouseAndDeliveryType,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useListOrderByDetail = ([page_no, limit], onSuccess, onError = onDefaultError) => {
  return useQuery(['order-list-by-detail', page_no, limit], OrderService.listOrderByDetail, {
    onSuccess,
    keepPreviousData: true,
    onError
  });
};

const useViewOrder = (order_id, onSuccess, onError = onDefaultError) => {
  return useQuery('order-view', () => OrderService.viewOrder(order_id), {
    onSuccess,
    onError
  });
};

const useUpdateOrderStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderService.updateOrder, {
    onSuccess,
    onError
  });
};

const useViewOrderPrepare = (order_id, onSuccess, onError = onDefaultError) => {
  return useQuery('order-prepare-view', () => OrderService.viewOrderPrepare(order_id), {
    onSuccess,
    onError
  });
};

const useViewOrderLoaded = (order_id, onSuccess, onError = onDefaultError) => {
  return useQuery('order-loaded-view', () => OrderService.viewOrderLoaded(order_id), {
    onSuccess,
    onError
  });
};

const useViewOrderNewContainer = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderService.viewOrderNewContainer, {
    onSuccess,
    onError
  });
};

const useViewOrderDeliver = (order_id, onSuccess, onError = onDefaultError) => {
  return useQuery('order-deliver', () => OrderService.viewOrderDeliver(order_id), {
    onSuccess,
    onError
  });
};

const useGenerateOrderListReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderService.generateReport, {
    onSuccess,
    onError
  });
};

const useGenerateOrderListFinanceReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderService.generateFinanceReport, {
    onSuccess,
    onError
  });
};

const useBookingGosend = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderService.bookingGosend, {
    onSuccess,
    onError
  });
};

const useCancelGosend = (onSuccess, onError = onDefaultError) => {
  return useMutation(OrderService.cancelGosend, {
    onSuccess,
    onError
  });
};

export {
  useListOrder,
  useViewOrder,
  useUpdateOrderStatus,
  useViewOrderPrepare,
  useViewOrderNewContainer,
  useViewOrderLoaded,
  useViewOrderDeliver,
  useListOrderByWarehouseAndDeliveryType,
  useListOrderByDetail,
  useGenerateOrderListReport,
  useGenerateOrderListFinanceReport,
  useBookingGosend,
  useCancelGosend
};
