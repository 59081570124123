import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: 0
};

export const counterMessageSlice = createSlice({
  name: 'counter',
  initialState: initialState,
  reducers: {
    setCounter: (state, { payload }) => {
      state.count = payload.count;
      return state;
    },
    decrementValue: (state, { payload }) => {
      state.count = payload.count - 1;
      return state;
    }
  }
});
export const { decrementValue, setCounter } = counterMessageSlice.actions;
export const currentVal = (state) => state;
export default counterMessageSlice.reducer;
