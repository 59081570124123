import TNBreadCurm from '@common/components/TNBreadCurm';
import { TNButton } from '@common/components/TNButton';
import { useProducerList } from '@hooks';
import client from '@libs/HttpClient';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { validationSchema } from './Edit';

export function AddFactoryProducer({ t }) {
  let { factory_id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const breadcurmArray = [
    {
      label: t('page.factory_list_title'),
      link: '/factory/list'
    },
    {
      label: t('page.view_factory_title'),
      link: `/factory/${factory_id}/producer`
    },
    {
      label: t('page.add_factory_producer'),
      active: 'active'
    }
  ];
  const defaultValue = (option, value) => {
    return option ? option.find((option) => option.value === value) : '';
  };

  let producer_options = [];
  const { isSuccess: ProducerSuccess, data: producerList } = useProducerList();

  if (ProducerSuccess !== false) {
    producer_options = producerList.data.map((item, i) => {
      return { value: item.producer_id, label: item.name };
    });
  }

  const formik = useFormik({
    initialValues: {
      producer_id: undefined,
      price: undefined
    },
    validationSchema: validationSchema,
    onSubmit: async (values, errors) => {
      setLoading(true);
      try {
        const resp = await client.post(`/factory/${factory_id}/producer`, values);
        toast.success(resp.message);
        navigate(`/factory/${factory_id}/producer`);
      } catch (error) {
        console.error(error);
        errors.setFieldError('producer_id', t('duplicate producer'));
      }

      setLoading(false);
    }
  });

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />

      <Card>
        <h1 className="page-heading-center">{t('page.add_factory_producer')}</h1>
        <Form onSubmit={formik.handleSubmit} className="edit-profile-form">
          <Form.Group>
            <Form.Label className="field-label field-label-top">{'Produser'}</Form.Label>
            <Select
              name="producer_id"
              placeholder={t('page.producer_select_placeholder')}
              options={producer_options}
              onChange={(selected) => {
                if (selected !== null) {
                  formik.setFieldValue('producer_id', selected.value);
                } else {
                  formik.setFieldValue('producer_id', '');
                }
              }}
              value={defaultValue(producer_options, formik.value?.producer_id)}
              defaultValue={formik.value?.producer_id}
              onBlur={formik.handleBlur}
              className={
                'form-field ' +
                (formik.touched.producer_id && formik.errors.producer_id
                  ? 'form-field-error'
                  : formik.touched.producer_id && !formik.errors.producer_id
                  ? 'form-field-success'
                  : '')
              }
            />
            <div className="form-field-error-text">
              {formik.touched.producer_id && formik.errors.producer_id ? (
                <div>{t(formik.errors.producer_id)}</div>
              ) : null}
            </div>
          </Form.Group>

          <Form.Group>
            <Form.Label className="field-label field-label-top">{'Harga'}</Form.Label>
            <Form.Control
              className={
                'form-field ' +
                (formik.touched.price && formik.errors.price
                  ? 'form-field-error'
                  : formik.touched.price && !formik.errors.price
                  ? 'form-field-success'
                  : '')
              }
              type="text"
              name="price"
              placeholder={'Harga'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.price}
            />
            <div className="form-field-error-text">
              {formik.touched.price && formik.errors.price ? (
                <div>{t(formik.errors.price)}</div>
              ) : null}
            </div>
          </Form.Group>

          <div className="primary-button">
            <span
              className="link-center"
              onClick={() => navigate(`/factory/${factory_id}/producer`)}>
              {t('page.cancel_button_text')}
            </span>
            <TNButton
              type="submit"
              loading={isLoading}
              isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
              {t('page.save_button_text')}
            </TNButton>
          </div>
        </Form>
      </Card>
    </>
  );
}
