import { useMutation, useQuery } from 'react-query';

import { DeliveryChargeService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useDeliveryChargeStore = (onSuccess, onError = onDefaultError) => {
  return useMutation(DeliveryChargeService.storeDeliveryCharge, {
    onSuccess,
    onError
  });
};

const useDeliveryChargesView = (delivery_charge_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['delivery_charge_data', delivery_charge_id],
    DeliveryChargeService.viewDeliveryCharge,
    {
      onSuccess,
      onError
    }
  );
};

const useDeliveryChargeUpdate = (onSuccess, onError = onDefaultError) => {
  return useMutation(DeliveryChargeService.updateDeliveryCharge, {
    onSuccess,
    onError
  });
};
/* Delivery Point User */
const useListDeliveryCharges = (
  [
    page_no,
    searchText,
    userStatus,
    adminStatus,
    liscense,
    alliance_portal_id,
    type,
    sortBy,
    sortOrder
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'delivery-charges-list',
      page_no,
      searchText,
      userStatus,
      adminStatus,
      liscense,
      alliance_portal_id,
      type,
      sortBy,
      sortOrder
    ],
    DeliveryChargeService.listDeliveryCharges,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useDeliveryChargeDelete = ([delivery_charge_id], onSuccess, onError = onDefaultError) => {
  const sent = delivery_charge_id ? true : false;
  return useQuery(
    ['delivery-charge-delete', delivery_charge_id],
    DeliveryChargeService.deleteDeliveryCharge,
    {
      onSuccess,
      onError,
      enabled: sent,
      retry: 0
    }
  );
};

const useGenerateDeliveryChargeReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(DeliveryChargeService.generateReport, {
    onSuccess,
    onError
  });
};
export {
  useDeliveryChargeStore,
  useDeliveryChargesView,
  useDeliveryChargeUpdate,
  useListDeliveryCharges,
  useDeliveryChargeDelete,
  useGenerateDeliveryChargeReport
};
