import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { Nav, InputGroup, Button } from 'react-bootstrap';
import TNBreadCurm from '@common/components/TNBreadCurm';
import client from '@libs/HttpClient';
import { Field, Formik, Form as FormikForm } from 'formik';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const RowSchema = Yup.object({
  partnerType: Yup.object({
    value: Yup.string().required('Tipe harus diisi')
  }).typeError('Tipe harus diisi'),
  partnerId: Yup.object({
    value: Yup.number().required('Partner harus diisi')
  }).typeError('Partner harus diisi'),
  price: Yup.number().required('Harga harus diisi')
});

const ValidationSchema = Yup.object({
  warehouse: RowSchema,
  transporter: RowSchema,
  incineration: RowSchema,
  decontamination: RowSchema
});

const WarehousePricingRow = ({
  warehouses,
  transporters,
  factories,
  label,
  name,
  partnerTypes,
  t
}) => {
  const [partnerOptions, setPartnerOptions] = useState(warehouses);
  const setOptions = (type) => {
    switch (type) {
      case 'warehouse':
        setPartnerOptions(warehouses);
        break;
      case 'transporter':
        setPartnerOptions(transporters);
        break;
      case 'factory':
        setPartnerOptions(factories);
        break;
      default:
        break;
    }
  };

  return (
    <tr>
      <td style={{ padding: '10px' }}>{label}</td>
      <td style={{ padding: '10px' }}>
        <Field name={`${name}.partnerType`}>
          {({ field: { value }, form, meta: { touched, error } }) => (
            <>
              <Select
                value={value}
                onChange={(val) => {
                  form.setFieldValue(`${name}.partnerType`, val);
                }}
                options={partnerTypes}
              />
              {error && <div className="text-danger">{error}</div>}
            </>
          )}
        </Field>
      </td>
      <td style={{ padding: '10px' }}>
        <Field name={`${name}.partnerId`}>
          {({ field: { value }, form, meta: { touched, error } }) => {
            setOptions(form.values[name].partnerType?.value);
            return (
              <>
                <Select
                  value={value}
                  onChange={(val) => {
                    form.setFieldValue(`${name}.partnerId`, val);
                  }}
                  options={partnerOptions}
                />
                {error && <div className="text-danger">{error}</div>}
              </>
            );
          }}
        </Field>
      </td>
      <td style={{ padding: '10px' }}>
        <Field name={`${name}.price`}>
          {({ field, form, meta: { touched, error } }) => (
            <>
              <InputGroup>
                <InputGroup.Text>Rp</InputGroup.Text>
                <input
                  min={0}
                  type="number"
                  placeholder="Harga"
                  className={`form-control form-field-height ${
                    touched && error ? 'is-invalid' : ''
                  }`}
                  {...field}
                />
                <InputGroup.Text>/KG</InputGroup.Text>
              </InputGroup>
              {error && <div className="text-danger">{error}</div>}
            </>
          )}
        </Field>
      </td>
    </tr>
  );
};

const WarehousePricing = ({ t }) => {
  let { warehouse_id } = useParams();
  const [pricing, setPricing] = useState(null);
  const [warehouse, setWarehouse] = useState(null);
  const [warehouses, setWarehouses] = useState(null);
  const [transporters, setTransporters] = useState(null);
  const [factories, setFactories] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const breadcurmArray = [
    {
      label: t('page.warehouse_list_label'),
      link: '/warehouse/list',
      active: ''
    },
    {
      label: 'Biaya',
      link: '/warehouse/list',
      active: 'active'
    }
  ];

  const partnerTypes = useMemo(
    () => [
      { label: 'Gudang', value: 'warehouse' },
      { label: 'Transport', value: 'transporter' },
      { label: 'Pabrik', value: 'factory' }
    ],
    []
  );

  const getPricing = useCallback(async () => {
    const data = await client.get(`v2/warehouse/${warehouse_id}/pricing`);

    const pricing = {
      warehouse: {
        price: 0,
        id: 0,
        partnerId: warehouses[0],
        partnerType: partnerTypes[0]
      },
      transporter: {
        price: 0,
        id: 0,
        partnerId: 0,
        partnerType: partnerTypes[0]
      },
      incineration: {
        price: 0,
        id: 0,
        partnerId: 0,
        partnerType: partnerTypes[0]
      },
      decontamination: {
        price: 0,
        id: 0,
        partnerId: 0,
        partnerType: partnerTypes[0]
      }
    };

    const partners = {
      warehouse: warehouses,
      transporter: transporters,
      factory: factories
    };

    for (const p of data) {
      pricing[p.type] = {
        price: p.price,
        id: p.id,
        partnerId: partners[p.partner_type_string].find((t) => t.value === p.partner_id),
        partnerType: partnerTypes.find((t) => t.value === p.partner_type_string)
      };
    }

    console.log(pricing);

    setPricing(pricing);
  }, [warehouse_id, partnerTypes, warehouses, transporters, factories]);

  const getWarehouse = useCallback(async () => {
    const data = await client.get(`warehouse/show/${warehouse_id}`);
    setWarehouse(data.data);
    setWarehouses([{ value: data.data.warehouse_id, label: data.data.name }]);
  }, [warehouse_id]);

  const getTransporters = async () => {
    const data = await client.get(`transporter-list`);
    setTransporters(data.data.map((e) => ({ value: e.transporter_id, label: e.name })));
  };

  const getFactories = async () => {
    const data = await client.get(`factory-list`);
    setFactories(data.data.map((e) => ({ value: e.factory_id, label: e.name })));
  };

  const upsert = async (values) => {
    await client.post(`v2/warehouse/${warehouse_id}/pricing`, values);
  };

  const submit = async (values) => {
    setLoadingSubmit(true);
    for (const [type, value] of Object.entries(values)) {
      await upsert({
        type,
        partner_type: value.partnerType.value,
        partner_id: value.partnerId.value,
        price: value.price
      });
    }
    toast.success('Berhasil menyimpan biaya gudang');
    setLoadingSubmit(false);
  };

  useEffect(() => {
    async function fetchData() {
      await getTransporters();
      await getFactories();
      await getWarehouse();
    }
    fetchData();
  }, [getWarehouse]);

  useEffect(() => {
    if (!warehouses || !transporters || !factories) return;
    getPricing();
  }, [getPricing, warehouses, transporters, factories]);

  if (!warehouse || !pricing || !transporters || !factories) {
    return 'Loading...';
  }

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />
      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to={`/${'warehouse'}/view/${warehouse_id}`}>{t('page.general_details')}</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/${'warehouse'}/${warehouse_id}/delivery/point/user/list`}>
            {t('page.delivery_point_user')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/${'warehouse'}/${warehouse_id}/scan/point/list`}>
            {t('page.manage_scan_points')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/warehouse/pricing/${warehouse_id}`}>Biaya</NavLink>
        </Nav.Item>
      </Nav>
      <h1 className="text-center">Biaya Gudang {warehouse.name}</h1>
      <Formik
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          submit(values);
        }}
        initialValues={pricing}>
        {(formik) => (
          <FormikForm style={{ minWidth: '500px' }}>
            <table>
              <tbody>
                <WarehousePricingRow
                  label="Gudang"
                  name="warehouse"
                  partnerTypes={partnerTypes}
                  warehouses={warehouses}
                  transporters={transporters}
                  factories={factories}
                />
                <WarehousePricingRow
                  label="Transporter"
                  name="transporter"
                  partnerTypes={partnerTypes}
                  warehouses={warehouses}
                  transporters={transporters}
                  factories={factories}
                />
                <WarehousePricingRow
                  label="Insinerasi"
                  name="incineration"
                  partnerTypes={partnerTypes}
                  warehouses={warehouses}
                  transporters={transporters}
                  factories={factories}
                />
                <WarehousePricingRow
                  label="Dekontaminasi"
                  name="decontamination"
                  partnerTypes={partnerTypes}
                  warehouses={warehouses}
                  transporters={transporters}
                  factories={factories}
                />
              </tbody>
            </table>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <Button disabled={loadingSubmit} type="submit">
                {loadingSubmit ? 'Menyimpan...' : 'Simpan'}
              </Button>
            </div>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};

export default WarehousePricing;
