import React from 'react';

const Checkbox = ({ id, type, name, label, handleClick, isChecked, style }) => {
  return (
    <div style={style}>
      <input
        id={id}
        name={name}
        type={type}
        onChange={handleClick}
        checked={isChecked}
        style={{ marginRight: '3px' }}
      />
      <label>{label}</label>
    </div>
  );
};

export default Checkbox;
