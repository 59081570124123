import client from '@libs/HttpClient';
class CouponService {
  /* coupon */
  static listCoupon(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const is_active = request.queryKey[3];
    const type = request.queryKey[4];
    const agent_id = request.queryKey[5];
    const sortBy = request.queryKey[6];
    const sortOrder = request.queryKey[7];

    return client.get(
      `/coupon/index?page=${page_no}&search_text=${searchText}&is_active=${is_active}&type=${type}&agent_id=${agent_id}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static listArchieveCoupon(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const is_active = request.queryKey[3];
    const type = request.queryKey[4];
    const agent_id = request.queryKey[5];
    const sortBy = request.queryKey[6];
    const sortOrder = request.queryKey[7];

    return client.get(
      `/coupon/index?page=${page_no}&search_text=${searchText}&is_active=${is_active}&type=${type}&agent_id=${agent_id}&is_archieve=2&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static addCoupon(request) {
    return client.post('/coupon/store', request, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
  static archieveCoupon(request) {
    const coupon_id = request.queryKey[1];
    const coupon_type = request.queryKey[2];
    return client.get(`/coupon/coupon-archieve-status/${coupon_id}/${coupon_type}`, request);
  }
  static viewCoupon(request) {
    const coupon_id = request.queryKey[1];
    return client.get(`/coupon/view/${coupon_id}`, request);
  }
  static updateCoupon(request) {
    return client.post(`/coupon/update/${request.coupon_id}`, request, {
      headers: {
        'Content-type': 'application/json'
      }
    });
  }
  static deleteCouponAttachment(request) {
    const attachment_id = request.queryKey[1];
    const type = request.queryKey[2];
    return client.get(`/coupon/delete-attachment/${attachment_id}/${type}`, request);
  }

  static getProducerTypeList(request) {
    return client.get(`/producertype-list`, request);
  }

  static getSubProducerTypeList(id) {
    return client.get(`v2/sub-producer-type/producer-type/${id}`);
  }

  static getProducerList(request) {
    return client.get(`/coupon/producer-list`, request);
  }

  static getContainerList(request) {
    return client.get(`/stocks/get-container-variable`, request);
  }
  static getAgentCouponList(request) {
    return client.get(`/coupon/agents_listing`, request);
  }

  static generateReport(request) {
    return client.post(`/coupon/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { CouponService };
