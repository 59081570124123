import { memo, useState } from 'react';
import { useQuery } from 'react-query';
import client from '@libs/HttpClient';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';
import '@assets/scss/page/_dashboard.scss';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend, Colors } from 'chart.js';

ChartJS.register(ArcElement, Title, Tooltip, Legend, Colors);

const formatter = Intl.NumberFormat('id-ID');

const colors = {
  Farmasi: '#818cf8',
  Puskesmas: '#a3e635',
  'Dokter Umum': '#c084fc',
  'Klinik Kecantikan': '#a78bfa',
  Tattoo: '#4ade80',
  Laboratorium: '#22d3ee',
  Hotel: '#2dd4bf',
  'Dokter Hewan': '#fbbf24',
  'Dokter Gigi': '#60a5fa',
  'Klinik Umum': '#f87171',
  Sekolah: '#fb923c',
  'Rumah Tangga': '#34d399',
  Test: '#a8a29e',
  'Klinik Pratama': '#e879f9',
  'Klinik Utama': '#f472b6'
};

const ProducerPieCard = memo(function TourCard(time) {
  const now = moment();
  const [[start, end], setRange] = useState([
    now.clone().subtract('7', 'days'),
    now.clone().endOf('day')
  ]);
  const [selected, setSelected] = useState('7day');

  const { data: tour } = useQuery(['dashboard-tour-chart', start, end, time], () =>
    client.get(`admin/dashboard/producer-type/chart`, {
      params: {
        start_date: start.format('YYYY-MM-DD'),
        end_date: end.format('YYYY-MM-DD')
      }
    })
  );

  const { data: summary } = useQuery(['dashboard-tour-summary', time], () =>
    client.get('admin/dashboard/tour/summary')
  );

  const changeDate = (event) => {
    const type = event.target.value;
    setSelected(type);

    switch (type) {
      case 'today':
        setRange([now.clone().startOf('day'), now.clone().endOf('day')]);
        break;
      case 'yesterday':
        setRange([
          now.clone().subtract('1', 'days').startOf('day'),
          now.clone().subtract('1', 'days').endOf('day')
        ]);
        break;
      case 'this_week':
        setRange([now.clone().startOf('week'), now.clone().endOf('week')]);
        break;
      case 'last_week':
        const week = now.clone().subtract(1, 'week');
        setRange([week.clone().startOf('week'), week.clone().endOf('week')]);
        break;
      case 'this_month':
        setRange([now.clone().startOf('month'), now.clone().endOf('month')]);
        break;
      case 'last_month':
        const month = now.clone().subtract(1, 'month');
        setRange([month.clone().startOf('month'), month.clone().endOf('month')]);
        break;
      case 'this_year':
        setRange([now.clone().startOf('year'), now.clone().endOf('year')]);
        break;
      case 'last_year':
        const year = now.clone().subtract(1, 'year');
        setRange([year.clone().startOf('year'), year.clone().endOf('year')]);
        break;
      case '7day':
        setRange([now.clone().subtract('7', 'days'), now.clone().endOf('day')]);
        break;
      case '30day':
        setRange([now.clone().subtract('30', 'days'), now.clone().endOf('day')]);
        break;
      case '6month':
        setRange([now.clone().subtract('6', 'months'), now.clone().endOf('day')]);
        break;
      case '12month':
        setRange([now.clone().subtract('12', 'months'), now.clone().endOf('day')]);
        break;
      case 'all':
        setRange([now.clone().subtract('10', 'years'), now.clone().endOf('day')]);
        break;
    }
  };

  if (!tour) {
    return null;
  }

  const labels = tour.map((item) => item.producer_type);
  const data = tour.map((item) => item.weight);
  const sum = data.reduce((a, b) => a + b, 0);
  const backgroundColors = tour.map((item) => colors[item.producer_type]);

  const dataset = {
    labels: labels,
    datasets: [
      {
        label: 'Berat Terkumpul',
        data: data,
        backgroundColor: backgroundColors,
        borderWidth: 1,
        polyline: {
          formatter: (value, label) => `${label}: ${formatter.format(value)} kg`
        }
      }
    ]
  };

  // source: https://jsfiddle.net/emit077/Lagfhs6z/10/
  const getSuitableY = (y, yArray = [], direction) => {
    const offset = 14;
    let result = y;
    yArray.forEach((existedY) => {
      if (existedY - offset < result && existedY + offset > result) {
        if (direction === 'right') {
          result = existedY + offset;
        } else {
          result = existedY - offset;
        }
      }
    });
    return result;
  };
  const getOriginPoints = (source, center, l) => {
    // console.log(source, center, l)

    let a = { x: 0, y: 0 };
    var dx = (center.x - source.x) / l;
    var dy = (center.y - source.y) / l;
    a.x = center.x + l * dx;
    a.y = center.y + l * dy;
    return a;
  };
  const plugin = {
    afterDraw: (chart) => {
      const ctx = chart.ctx;
      // console.log('chart==', chart.config._config);
      ctx.save();
      const leftLabelCoordinates = [];
      const rightLabelCoordinates = [];
      const chartCenterPoint = {
        x: (chart.chartArea.right - chart.chartArea.left) / 2 + chart.chartArea.left,
        y: (chart.chartArea.bottom - chart.chartArea.top) / 2 + chart.chartArea.top
      };
      chart.config.data.labels.forEach((label, i) => {
        /* console.log(chart.config._config.data.datasets[0].backgroundColor[i]) */
        const meta = chart.getDatasetMeta(0);
        const arc = meta.data[i];
        const dataset = chart.config.data.datasets[0];
        // Prepare data to draw
        // important point 1
        const centerPoint = arc.getCenterPoint();
        //console.log("arc==", arc.outerRadius)
        // const model = arc._model;
        let color = chart.config._config.data.datasets[0].backgroundColor[i]; //"red";//model.borderColor;
        // let labelColor = chart.config._config.data.datasets[0].backgroundColor[i]; //"red";//model.borderColor;
        // if (dataset.polyline && dataset.polyline.color) {
        //   color = dataset.polyline.color;
        // }

        // if (dataset.polyline && dataset.polyline.labelColor) {
        //   labelColor = "#000"//dataset.polyline.labelColor;
        // }

        const angle = Math.atan2(
          centerPoint.y - chartCenterPoint.y,
          centerPoint.x - chartCenterPoint.x
        );
        // important point 2, this point overlapsed with existed points
        // so we will reduce y by 14 if it's on the right
        // or add by 14 if it's on the left
        let originPoint = getOriginPoints(chartCenterPoint, centerPoint, arc.outerRadius);
        const point2X =
          chartCenterPoint.x +
          Math.cos(angle) *
            (centerPoint.x < chartCenterPoint.x ? arc.outerRadius + 0 : arc.outerRadius + 0);
        let point2Y =
          chartCenterPoint.y +
          Math.sin(angle) *
            (centerPoint.y < chartCenterPoint.y ? arc.outerRadius + 0 : arc.outerRadius + 0);

        let suitableY;
        if (point2X < chartCenterPoint.x) {
          // on the left
          suitableY = getSuitableY(point2Y, leftLabelCoordinates, 'left');
        } else {
          // on the right

          suitableY = getSuitableY(point2Y, rightLabelCoordinates, 'right');
        }

        point2Y = suitableY;

        let value = dataset.data[i];
        if (dataset.polyline && dataset.polyline.formatter) {
          value = dataset.polyline.formatter(value, label);
        }
        let edgePointX =
          point2X < chartCenterPoint.x
            ? chartCenterPoint.x - arc.outerRadius - 10
            : chartCenterPoint.x + arc.outerRadius + 10;

        if (point2X < chartCenterPoint.x) {
          leftLabelCoordinates.push(point2Y);
        } else {
          rightLabelCoordinates.push(point2Y);
        }

        //DRAW CODE
        // first line: connect between arc's center point and outside point
        ctx.lineWidth = 2;
        ctx.strokeStyle = color;
        ctx.beginPath();
        ctx.moveTo(originPoint.x, originPoint.y);
        ctx.lineTo(point2X, point2Y);
        ctx.stroke();
        // second line: connect between outside point and chart's edge
        ctx.beginPath();
        ctx.moveTo(point2X, point2Y);
        ctx.lineTo(edgePointX, point2Y);
        ctx.stroke();
        //fill custom label
        const labelAlignStyle = edgePointX < chartCenterPoint.x ? 'right' : 'left';
        const labelX = edgePointX < chartCenterPoint.x ? edgePointX : edgePointX + 0;
        const labelY = point2Y + 7;
        ctx.textAlign = labelAlignStyle;
        ctx.textBaseline = 'bottom';
        ctx.font = 'bold 12px Montserrat';
        // ctx.fillStyle = labelColor;
        ctx.fillText(value, labelX, labelY);
      });
      ctx.restore();
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 50,
        bottom: 20
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.formattedValue}kg`
        }
      }
      // datalabels: {
      //   labels: {
      //     title: {
      //       color: 'black',
      //       font: {
      //         weight: 'bold'
      //       },
      //       // padding: 6,
      //       anchor: 'end',
      //       align: 'end',
      //       offset: 10,
      //       formatter: (value, context) => context.chart.data.labels[context.dataIndex]
      //     },
      //     value: {
      //       color: 'black',
      //       font: {
      //         weight: 'bold'
      //       },
      //       anchor: 'center',
      //       align: 'center',
      //       formatter: (value, context) => `${Math.round(value)} kg`,
      //       display: 'auto'
      //     }
      //   }
      // }
    }
  };

  const cells = {
    today: 'Hari Ini ' + moment().format('DD/MM/YYYY'),
    this_week: 'Minggu Ini',
    this_month: 'Bulan Ini',
    this_year: 'Tahun Ini',
    all: 'Semua',
    yesterday: 'Kemarin',
    last_week: 'Minggu Lalu',
    last_month: 'Bulan Lalu',
    last_year: 'Tahun Lalu',
    last_7_days: '7 Hari Terakhir',
    last_30_days: '30 Hari Terakhir',
    last_6_months: '6 Bulan Terakhir',
    last_12_months: '12 Bulan Terakhir'
  };

  return (
    <div className="flex-grow-1 d-flex flex-column">
      <div className="d-flex align-items-center gap-4">
        <h2>Pengumpulan Tipe Produser</h2>
        <select value={selected} onChange={changeDate}>
          <option value="today">Today</option>
          <option value="yesterday">Yesterday</option>
          <option value="this_week">This Week</option>
          <option value="last_week">Last Week</option>
          <option value="this_month">This Month</option>
          <option value="last_month">Last Month</option>
          <option value="this_year">This Year</option>
          <option value="last_year">Last Year</option>
          <option value="7day">Last 7 Day</option>
          <option value="30day">Last 30 Day</option>
          <option value="6month">Last 6 Month</option>
          <option value="12month">Last 12 Month</option>
          <option value="all">All</option>
        </select>
      </div>
      <div className="d-flex flex-column gap-4 flex-grow-1">
        <div style={{ width: '700px', minHeight: '300px' }} className="flex-grow-1">
          {tour?.length === 0 ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: '100%' }}>
              No Collect in this period
            </div>
          ) : (
            <Pie options={options} data={dataset} plugins={[plugin]} />
          )}
        </div>
        <div style={{ width: 'fit-content' }}>
          <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
            Total: {formatter.format(sum)} kg
          </p>
          <table className="table-dashboard-summary">
            <tbody>
              <tr>
                {Object.keys(cells)
                  .splice(0, 5)
                  .map((key) => (
                    <td key={key} colSpan={2}>
                      {cells[key]}
                    </td>
                  ))}
              </tr>
              <tr>
                {Object.keys(cells)
                  .splice(0, 5)
                  .map((key, i) => (
                    <>
                      <td rowSpan={i === 4 ? 5 : 1}>{summary?.[key][0]}</td>
                      <td rowSpan={i === 4 ? 5 : 1}>{formatter.format(summary?.[key][1])} kg</td>
                    </>
                  ))}
              </tr>
              <tr>
                {Object.keys(cells)
                  .splice(5, 4)
                  .map((key) => (
                    <td key={key} colSpan={2}>
                      {cells[key]}
                    </td>
                  ))}
              </tr>
              <tr>
                {Object.keys(cells)
                  .splice(5, 4)
                  .map((key) => (
                    <>
                      <td>{summary?.[key][0]}</td>
                      <td>{formatter.format(summary?.[key][1])} kg</td>
                    </>
                  ))}
              </tr>
              <tr>
                {Object.keys(cells)
                  .splice(9, 4)
                  .map((key) => (
                    <td key={key} colSpan={2}>
                      {cells[key]}
                    </td>
                  ))}
              </tr>
              <tr>
                {Object.keys(cells)
                  .splice(9, 4)
                  .map((key) => (
                    <>
                      <td>{summary?.[key][0]}</td>
                      <td>{formatter.format(summary?.[key][1])} kg</td>
                    </>
                  ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

export default ProducerPieCard;
