import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useFormikContext } from 'formik';
import {
  currencyFormatIntVal,
  dateFormatCommon,
  dateFormatManualAdd
} from '@helpers/commonHelpers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { toast } from 'react-toastify';

const GeneralDetails = (props) => {
  const formik = useFormikContext();
  const defaultValue = (option, value) => {
    return option ? option.find((option) => option.value === value) : '';
  };

  const { t } = props;
  /**
   * !This block is for status drop down
   */
  const options = [
    { value: 1, label: `${t('page.active_status_name')}` },
    { value: 0, label: `${t('page.in_active_status_name')}` }
  ];
  const handleChangeWeight = (max_weight) => {
    if (max_weight.target.value !== '') {
      let max_weight_val = max_weight.target.value;

      if (parseFloat(max_weight_val.replace(',', '')) <= 1000) {
        let calculate = (parseFloat(max_weight_val.replace(',', '')) / 0.32).toFixed(2);
        formik.setFieldValue('max_weight_ltr', calculate);
      } else {
        formik.setFieldValue('max_weight_ltr', 0);
      }
    } else {
      formik.setFieldValue('max_weight_ltr', 0);
    }
    formik.setFieldValue(
      'max_weight_kg',
      max_weight.target.value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    );
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_trucks_brand_name_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.brand_name && formik.errors.brand_name
                    ? 'form-field-error'
                    : formik.touched.brand_name && !formik.errors.brand_name
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="brand_name"
                placeholder={t('page.add_trucks_brand_name_label_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.brand_name}
              />
              <div className="form-field-error-text">
                {formik.touched.brand_name && formik.errors.brand_name ? (
                  <div>{t(formik.errors.brand_name)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_truck_model_name_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.model_name && formik.errors.model_name
                    ? 'form-field-error'
                    : formik.touched.model_name && !formik.errors.model_name
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="model_name"
                placeholder={t('page.add_truck_model_name_label_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.model_name}
              />
              <div className="form-field-error-text">
                {formik.touched.model_name && formik.errors.model_name ? (
                  <div>{t(formik.errors.model_name)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_trucks_date_put_on_road_label')}
              </Form.Label>
              <DatePicker
                onKeyDown={dateFormatManualAdd}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column"
                placeholderText={dateFormatCommon().toUpperCase()}
                selected={formik.values.road_date}
                dateFormat={dateFormatCommon()}
                minDate={new Date()}
                onChange={(date) => {
                  formik.setFieldValue('road_date', date);
                }}
                onBlur={(date) => {
                  var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                  if (date.currentTarget.value) {
                    if (!date1.isValid()) {
                      toast.error(t('page.invalid_date_format'));
                      formik.setFieldValue('road_date', '');
                    } else {
                      var currentDate = new Date();
                      if (currentDate > date1) {
                        formik.setFieldValue('road_date', '');
                      }
                    }
                  }
                }}
              />
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.road_date && formik.errors.road_date
                    ? 'form-field-error'
                    : formik.touched.road_date && !formik.errors.road_date
                    ? 'form-field-success'
                    : '')
                }
                type="hidden"
                name="road_date"
                placeholder={t('page.add_trucks_date_put_on_road_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.road_date}
              />
              <div className="form-field-error-text">
                {formik.touched.road_date && formik.errors.road_date ? (
                  <div>{t(formik.errors.road_date)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_trucks_plate_number_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.plate_number && formik.errors.plate_number
                    ? 'form-field-error'
                    : formik.touched.plate_number && !formik.errors.plate_number
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="plate_number"
                placeholder={t('page.add_trucks_plate_number_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.plate_number}
                // onKeyUp={(e) => {
                //   const tempPlate = numberPlateFormat(e);
                //   formik.setFieldValue('plate_number', tempPlate);
                // }}
              />
              <div className="form-field-error-text">
                {formik.touched.plate_number && formik.errors.plate_number ? (
                  <div>{t(formik.errors.plate_number)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_truck_weight')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.weight && formik.errors.weight
                    ? 'form-field-error'
                    : formik.touched.weight && !formik.errors.weight
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="weight"
                placeholder={t('page.add_truck_weight_placeholder')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={currencyFormatIntVal(formik.values.weight)}
              />
              <div className="form-field-error-text">
                {formik.touched.weight && formik.errors.weight ? (
                  <div>{t(formik.errors.weight)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_trucks_max_load_in_kg_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.max_weight_kg && formik.errors.max_weight_kg
                    ? 'form-field-error'
                    : formik.touched.max_weight_kg && !formik.errors.max_weight_kg
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="max_weight_kg"
                placeholder={t('page.add_trucks_max_load_in_kg_placeholder')}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={currencyFormatIntVal(formik.values.max_weight_kg)}
                onKeyUp={handleChangeWeight}
                minLength={4}
                maxLength={4}
              />
              <div className="form-field-error-text">
                {formik.touched.max_weight_kg && formik.errors.max_weight_kg ? (
                  <div>{t(formik.errors.max_weight_kg)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_truck_max_load_in_l_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.max_weight_ltr && formik.errors.max_weight_ltr
                    ? 'form-field-error'
                    : formik.touched.max_weight_ltr && !formik.errors.max_weight_ltr
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="max_weight_ltr"
                placeholder={t('page.add_truck_max_load_in_l_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.max_weight_ltr}
                readOnly={true}
              />
              <div className="form-field-error-text">
                {formik.touched.max_weight_ltr && formik.errors.max_weight_ltr ? (
                  <div>{t(formik.errors.max_weight_ltr)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.trucks_is_active_label')}
              </Form.Label>
              <Select
                placeholder={t('page.select_status')}
                options={options}
                className={
                  'form-field ' +
                  (formik.touched.is_active && formik.errors.is_active
                    ? 'form-select-error'
                    : formik.touched.is_active && !formik.errors.is_active
                    ? 'form-select-success'
                    : '')
                }
                value={defaultValue(options, formik.values.is_active)}
                onChange={(selectedOption) => {
                  formik.setFieldValue('is_active', selectedOption.value);
                }}
              />
              <div className="form-field-error-text">
                {formik.touched.is_active && formik.errors.is_active ? (
                  <div>{t(formik.errors.is_active)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Label className="field-label field-label-top">
              {t('page.add_trucks_truck_type_label')}
            </Form.Label>
            <br></br>
            <Form.Check
              type="radio"
              label={t('page.add_trucks_truck_type_collect_truck')}
              name="type"
              id="radio1"
              value="1"
              checked={formik.values.type === 1}
              onChange={(e) => {
                formik.setFieldValue('type', parseInt(e.currentTarget.value));
              }}
            />
            <Form.Check
              type="radio"
              label={t('page.add_trucks_truck_type_transport_truck')}
              name="type"
              id="radio2"
              value="2"
              checked={formik.values.type === 2}
              onChange={(e) => {
                formik.setFieldValue('type', parseInt(e.currentTarget.value));
              }}
              className="ml-5"
            />
            <div className="form-field-error-text">
              {formik.touched.type && formik.errors.type ? (
                <div>{t(formik.errors.type)}</div>
              ) : null}
            </div>
          </Col>

          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_trucks_gps_tracker_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.gps_tracker && formik.errors.gps_tracker
                    ? 'form-field-error'
                    : formik.touched.gps_tracker && !formik.errors.gps_tracker
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="gps_tracker"
                placeholder={t('page.add_trucks_gps_tracker_label_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gps_tracker}
              />
              <div className="form-field-error-text">
                {formik.touched.gps_tracker && formik.errors.gps_tracker ? (
                  <div>{t(formik.errors.gps_tracker)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
GeneralDetails.propTypes = {
  t: PropTypes.func,
  countryIsLoad: PropTypes.bool,
  countryCodeOptions: PropTypes.any
};
export { GeneralDetails };
