import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Row, Col, Nav, Card, Form, Modal } from 'react-bootstrap';
import { Link, NavLink, useParams } from 'react-router-dom';
import moment from 'moment';

import { useViewFactory } from '@hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { imagePreviewFromik, urlToGetExnsion } from '@helpers/';

const ViewFactoryPage = ({ t }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalImgUrl, setModalImgUrl] = useState('');
  let { factory_id } = useParams();

  /**
   * !This API will call when page set. When response came we are setting up data into the form
   */
  const { data: factory_data } = useViewFactory(factory_id);

  if (!factory_data) {
    return null;
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/factory/list' }}>
          {t('page.factory_list_title')}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{t('page.view_factory_title')}</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col lg={12}>
          <Nav className="tab-navigation">
            <Nav.Item>
              <NavLink to={`/factory/view/${factory_id}`}>{t('page.general_details')}</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={`/factory/${factory_id}/producer`}>{t('page.producer')}</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={`/factory/${factory_id}/pricing`}>Harga</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={`/factory/${factory_id}/delivery/point/user/list`}>
                {t('page.delivery_point_user')}
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={`/factory/${factory_id}/scan/point/list`}>
                {t('page.manage_scan_points')}
              </NavLink>
            </Nav.Item>
          </Nav>
          <h1 className="page-heading-center"> {t('page.factory_view_header_label')} </h1>
          <Card className="inner-box">
            <Card.Header>
              <Card.Title>{t('page.add_factory_general_details_title')}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_general_details_factory_name_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.name}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_general_details_npwp_number_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.factory_npwp_number}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">{t('page.simpel_id')}</Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.simpel_id}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.simpel_registration_number')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.simpel_registration_number}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_general_details_general_contact_number_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>
                        +{factory_data.data.country_code}&nbsp;
                        {factory_data.data.company_phone}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_general_details_territory_name_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.territory_name}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_general_details_area_name_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.area_name}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_general_details_address_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={12}>
                      <span>{factory_data.data.address}</span>
                    </Col>
                  </Row>
                </Col>

                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_general_details_address_notes_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={12}>
                      <span>{factory_data.data.address_notes}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.view_factory_city_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.city}</span>
                    </Col>
                  </Row>
                </Col>

                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.view_factory_state_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.state}</span>
                    </Col>
                  </Row>
                </Col>

                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.view_factory_postcode_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.postcode}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.view_factory_country_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.country}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_general_details_status_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.contract_status_name}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-2">
            <Card.Header>
              <Card.Title>{t('page.add_factory_contact_details_title')}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_contact_details_contract_number_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.contract_number}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_contract_details_contract_start_date_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>
                        {factory_data.data.contract_start_date !== ''
                          ? moment(factory_data.data.contract_start_date).format('DD-MM-YYYY')
                          : ''}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_contract_details_contract_end_date_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>
                        {factory_data.data.contract_end_date !== ''
                          ? moment(factory_data.data.contract_end_date).format('DD-MM-YYYY')
                          : ''}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_contact_details_current_incineration_price_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.current_incineration_price}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-2">
            <Card.Header>
              <Card.Title>{t('page.add_factory_owner_details_title')}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_owner_first_name_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.owner_first_name}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_owner_last_name_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.owner_last_name}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_owner_email_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.owner_email}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_owner_details_owner_phone_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>
                        +{factory_data.data.owner_country_code}&nbsp;
                        {factory_data.data.owner_phone}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_owner_details_owner_ktp_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.owner_ktp}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-2">
            <Card.Header>
              <Card.Title>{t('page.add_factory_person_in_charge_title')}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_person_incharge_first_name_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.person_incharge_first_name}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_person_incharge_last_name_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.person_incharge_last_name}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_person_incharge_email_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.person_incharge_email}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_person_incharge_phone_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>
                        +{factory_data.data.person_country_code}&nbsp;
                        {factory_data.data.person_incharge_phone}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-2">
            <Card.Header>
              <Card.Title>{t('page.add_factory_accounting_person_details_title')}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_accounting_first_name_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.accounting_first_name}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_accounting_last_name_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.accounting_last_name}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_accounting_email_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>{factory_data.data.accounting_email}</span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Row>
                    <Col lg={6} xs={6}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_accounting_phone_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={1} xs={1}>
                      :
                    </Col>
                    <Col lg={5} xs={5}>
                      <span>
                        +{factory_data.data.accounting_country_code}&nbsp;
                        {factory_data.data.accounting_phone}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-2">
            <Card.Header>
              <Card.Title>{t('page.add_factory_attachment_title')}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <Row>
                    <Col lg={12} xs={12}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_owner_ktp_attchment_label')}
                      </Form.Label>

                      {urlToGetExnsion(factory_data.data.owner_ktp_attchment) === 'pdf' ? (
                        <a
                          target="_blank"
                          href={factory_data.data.owner_ktp_attchment}
                          rel="noreferrer">
                          <span>
                            <div className="preview-image">
                              <img
                                src={imagePreviewFromik(factory_data.data.owner_ktp_attchment)}
                                alt="profile_img"
                              />
                            </div>
                          </span>
                        </a>
                      ) : (
                        <span>
                          <div className="preview-image">
                            <img
                              src={imagePreviewFromik(factory_data.data.owner_ktp_attchment)}
                              alt="profile_img"
                              onClick={() => {
                                setModalImgUrl(factory_data.data.owner_ktp_attchment);
                                setModalShow(true);
                              }}
                            />
                          </div>
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
                <br />
                <Col lg={4} md={6} sm={12}>
                  <Row>
                    <Col lg={12} xs={12}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_selfi_with_ktp_attchement_label')}
                      </Form.Label>

                      {urlToGetExnsion(factory_data.data.selfi_with_ktp) === 'pdf' ? (
                        <a target="_blank" href={factory_data.data.selfi_with_ktp} rel="noreferrer">
                          <span>
                            <div className="preview-image">
                              <img
                                src={imagePreviewFromik(factory_data.data.selfi_with_ktp)}
                                alt="profile_img"
                              />
                            </div>
                          </span>
                        </a>
                      ) : (
                        <span>
                          <div className="preview-image">
                            <img
                              src={imagePreviewFromik(factory_data.data.selfi_with_ktp)}
                              alt="profile_img"
                              onClick={() => {
                                setModalImgUrl(factory_data.data.selfi_with_ktp);
                                setModalShow(true);
                              }}
                            />
                          </div>
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <Row>
                    <Col lg={12} xs={12}>
                      <Form.Label className="field-label">
                        {t('page.add_transporter_stamp_label')}
                      </Form.Label>

                      {urlToGetExnsion(factory_data.data.stamp) === 'pdf' ? (
                        <a target="_blank" href={factory_data.data.stamp} rel="noreferrer">
                          <span>
                            <div className="preview-image">
                              <img
                                src={imagePreviewFromik(factory_data.data.stamp)}
                                alt="profile_img"
                              />
                            </div>
                          </span>
                        </a>
                      ) : (
                        <span>
                          <div className="preview-image">
                            <img
                              src={imagePreviewFromik(factory_data.data.stamp)}
                              alt="profile_img"
                              onClick={() => {
                                setModalImgUrl(factory_data.data.stamp);
                                setModalShow(true);
                              }}
                            />
                          </div>
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <br />
              <Row className="mt-2">
                <Col lg={12} md={12} sm={12}>
                  <Row>
                    <Col lg={12} xs={12}>
                      <Form.Label className="field-label">
                        {t('page.add_factory_attachments_attachment_name_label')}
                      </Form.Label>
                    </Col>
                    <Col lg={12} xs={12}>
                      <Row>
                        {factory_data.data.additional_attachment.length > 0 &&
                          factory_data.data.additional_attachment.map((item, i) => {
                            return (
                              <Col key={i} lg={6} xs={6}>
                                {urlToGetExnsion(item.additional_attachement_file) === 'pdf' ? (
                                  <a
                                    target="_blank"
                                    href={item.additional_attachement_file}
                                    rel="noreferrer">
                                    <span>
                                      <Col lg={5} xs={5}>
                                        <span>{item.additional_attachement_name}</span>
                                      </Col>
                                      <div className="preview-image">
                                        <img
                                          src={imagePreviewFromik(item.additional_attachement_file)}
                                          alt="profile_img"
                                        />
                                      </div>
                                    </span>
                                  </a>
                                ) : (
                                  <span>
                                    <Col lg={5} xs={5}>
                                      <span>{item.additional_attachement_name}</span>
                                    </Col>
                                    <div className="preview-image">
                                      <img
                                        src={imagePreviewFromik(item.additional_attachement_file)}
                                        alt="profile_img"
                                        onClick={() => {
                                          setModalImgUrl(item.additional_attachement_file);
                                          setModalShow(true);
                                        }}
                                      />
                                    </div>
                                  </span>
                                )}
                              </Col>
                            );
                          })}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div>
        <div className="primary-button">
          <Link to={`/factory/list`} className="link-center pe-0">
            {t('page.add_factory_cancel_button')}
          </Link>
        </div>
        <Modal show={modalShow} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body className="text-center">
            <div className="close-popup">
              <FontAwesomeIcon icon={faClose} onClick={() => setModalShow(false)} />
            </div>
            <img className="text-center" src={modalImgUrl} alt="profile_img" />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
ViewFactoryPage.propTypes = {
  t: PropTypes.func
};
export { ViewFactoryPage };
