import { useMutation, useQuery } from 'react-query';

import { ScanPointService } from '@api/';
import { toast } from 'react-toastify';

/**
 * Hook for user login
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};

/* Delivery Point User */
const useListScanPoint = (
  [page_no, searchText, is_active, alliance_portal_id, type, selectedLicense, sortBy, sortOrder],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'scan-point-list',
      page_no,
      searchText,
      is_active,
      alliance_portal_id,
      type,
      selectedLicense,
      sortBy,
      sortOrder
    ],
    ScanPointService.listScanPoint,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};
const useAddScanPoint = (onSuccess, onError = onDefaultError) => {
  return useMutation(ScanPointService.addScanPoint, {
    onSuccess,
    onError
  });
};
const useScanPointDelete = ([scan_point_id], onSuccess, onError = onDefaultError) => {
  const sent = scan_point_id ? true : false;
  return useQuery(['scan-point-delete', scan_point_id], ScanPointService.deleteScanPoint, {
    onSuccess,
    onError,
    enabled: sent,
    retry: 0
  });
};
const useViewScanPoint = (scan_point_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['scan-point-view', scan_point_id], ScanPointService.viewScanPoint, {
    onSuccess,
    onError
  });
};

const useUpdateScanPoint = (onSuccess, onError = onDefaultError) => {
  return useMutation(ScanPointService.updateScanPoint, {
    onSuccess,
    onError
  });
};

const useGenerateScanPointReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(ScanPointService.generateReport, {
    onSuccess,
    onError
  });
};
export {
  useListScanPoint,
  useAddScanPoint,
  useScanPointDelete,
  useViewScanPoint,
  useUpdateScanPoint,
  useGenerateScanPointReport
};
