import PropTypes from 'prop-types';
import UpArrowWhite from '@assets/images/upsortwhite.png';
import DownArrowWhite from '@assets/images/downsortwhite.png';
import UpArrowActive from '@assets/images/uparrowactive.png';
import DownArrowActive from '@assets/images/downarrowactive.png';

const Sort = (props) => {
  const isActive = props.isActive;
  const orderType = props.orderType;
  return (
    <>
      <div className="sorting">
        <div className="d-grid">
          {isActive === true ? (
            <>
              {orderType === 'desc' ? (
                <img src={'/admin' + UpArrowActive} alt="upsortwhite" />
              ) : (
                <img src={'/admin' + UpArrowWhite} alt="upsortwhite" />
              )}

              {orderType === 'asc' ? (
                <img src={'/admin' + DownArrowActive} alt="downsortwhite" />
              ) : (
                <img src={'/admin' + DownArrowWhite} alt="upsortwhite" />
              )}
            </>
          ) : (
            <>
              <img src={'/admin' + UpArrowWhite} alt="upsortwhite" />
              <img src={'/admin' + DownArrowWhite} alt="downsortwhite" />
            </>
          )}
        </div>
      </div>
    </>
  );
};

Sort.propTypes = {
  isActive: PropTypes.any.isRequired,
  orderType: PropTypes.any.isRequired
};
export default Sort;
