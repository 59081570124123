import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { TourMasterService } from '@api/';
const onDefaultError = (error) => {
  toast.error(error.message);
};

/**
 * current Collect
 */

const useTourAllList = (
  [
    page_no,
    searchText,
    territryId,
    areaId,
    transporterId,
    selectedType,
    statusNumber,
    sortOrder,
    sortBy
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'current-collect-list',
      page_no,
      searchText,
      territryId,
      areaId,
      transporterId,
      selectedType,
      statusNumber,
      sortOrder,
      sortBy
    ],
    TourMasterService.listAll,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useTourCurrentCollect = (
  [page_no, searchText, territryId, areaId, transporterId, selectedType, type, sortOrder, sortBy],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'current-collect-list',
      page_no,
      searchText,
      territryId,
      areaId,
      transporterId,
      selectedType,
      type,
      sortOrder,
      sortBy
    ],
    TourMasterService.listCurrentCollect,
    {
      onSuccess,
      keepPreviousData: true,
      onError
    }
  );
};

const useViewTourDetail = (tour_id, onSuccess, onError = onDefaultError) => {
  return useQuery('view-tour-detail', () => TourMasterService.viewTourDetail(tour_id), {
    onSuccess,
    onError
  });
};

const useViewOrderScanContainer = (onSuccess, onError = onDefaultError) => {
  return useMutation(TourMasterService.viewOrderScanContainer, {
    onSuccess,
    onError
  });
};

const useGenerateCurrentTourReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(TourMasterService.generateReport, {
    onSuccess,
    onError
  });
};

const useGenerateCompleteTourReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(TourMasterService.generateCompleteTourReport, {
    onSuccess,
    onError
  });
};

const useGenerateCanceledTourReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(TourMasterService.generateCanceledTourReport, {
    onSuccess,
    onError
  });
};
export {
  useTourCurrentCollect,
  useViewTourDetail,
  useViewOrderScanContainer,
  useGenerateCurrentTourReport,
  useGenerateCompleteTourReport,
  useGenerateCanceledTourReport,
  useTourAllList
};
