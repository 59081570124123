import { React, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useForgotPassword } from '@hooks';
import validationSchema from './FogetPasswordValidation';
import { PublicLayout, LogoUrl } from '@adminlayout';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import AlertCommon from '@common/components/AlertCommon';
import { TNButton } from '@common/components';

const ForgetPasswordPage = (props) => {
  const { t } = props;
  const navigate = useNavigate();

  // Add class in body
  useEffect(() => {
    document.body.classList.add('bg-box');
    document.body.classList.add('signup-box');
  }, []);

  /**
   * This function is call when click on cancel button
   */

  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message')
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(`/login/`);
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      navigate(`/login/`);
    }
  };

  // This Block is execute on Form Submit when we'll get encrypted email and storing to localstorage as it will use in OTP Verification Page
  const {
    mutate: doForgotPassword,
    isLoading,
    error: addFPList,
    isError: addFPError
  } = useForgotPassword(
    (res) => {
      toast.success(res.message);
      localStorage.forgot_token = res.data.user_id;
      localStorage.isAdmin = true;
      navigate('/otp-verification');
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );

  // This Block is execute on Form Submit
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema,
    onSubmit: (values) => {
      values.user_type = [1, 2];
      values.alliance_type = [1];
      doForgotPassword(values);
    }
  });

  return (
    <PublicLayout>
      <div className="auth-pages">
        <Container>
          <div className="background-box">
            <div>
              <div className="brand-logo">
                <LogoUrl />
              </div>
              <div>
                <h1 className="page-heading-center">{t('page.forget_password_header_text')}</h1>
                <div className="page-sub-heading">
                  <span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t('page.forget_password_sub_header_text')
                      }}></div>
                  </span>
                </div>
              </div>
              <div>
                <Form onSubmit={formik.handleSubmit}>
                  <AlertCommon
                    key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
                    is_error={addFPError}>
                    {addFPList}
                  </AlertCommon>
                  <Form.Group>
                    <Form.Label className="field-label">
                      {t('page.forget_password_email_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.email && formik.errors.email
                          ? 'form-field-error'
                          : formik.touched.email && !formik.errors.email
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="email"
                      placeholder={t('page.forget_password_email_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.email && formik.errors.email ? (
                        <div>{t(formik.errors.email)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <div className="primary-button">
                    <span className="link-center" onClick={handleCancel}>
                      {t('page.forget_password_login_link_text')}
                    </span>
                    <TNButton
                      loading={isLoading}
                      type="submit"
                      isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                      {t('page.forget_password_submit_button_text')}
                    </TNButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </PublicLayout>
  );
};
ForgetPasswordPage.propTypes = {
  t: PropTypes.func,
  isdirtyform: PropTypes.any
};
export { ForgetPasswordPage };
