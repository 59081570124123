import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListAvailableCollectPage = React.lazy(() => import('./ListAvailableCollectPage'));
const ViewAvailableCollect = React.lazy(() => import('./ViewAvailableCollect'));
const AvailableCollectRoutes = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/list" element={<ListAvailableCollectPage t={t} />} />
      <Route path="/view/:tour_id" element={<ViewAvailableCollect t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AvailableCollectRoutes.propTypes = {
  t: PropTypes.func
};
export default AvailableCollectRoutes;
