import client from '@libs/HttpClient';
class TourSettingService {
  /**
   *  Load Setting
   */
  static getSettingData() {
    return client.get('/tour/load-list');
  }

  static storeSettingData(request) {
    return client.post('/tour/store-load', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  /**
   * First Half Setting
   */
  static getTourFirstHalfSettingData() {
    return client.get('/tour/list-first-half');
  }

  static storeTourFirstHalfSettingData(request) {
    return client.post('/tour/store-first-half', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  /**
   * Second Half Setting
   */

  static getTourSecondHalfSettingData() {
    return client.get('/tour/list-second-half');
  }

  static storeTourSecondHalfSettingData(request) {
    return client.post('/tour/store-second-half', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  /**
   * Update status of tour
   */

  static updateTourStatus(request) {
    return client.post(`/tour/update-status`, request);
  }

  /**
   * Maximum Stops
   */

  static storeMaxStopSettingData(request) {
    return client.post('/maximum-stop/store', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static listMaxStops(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const sortOrder = request.queryKey[3];
    const sortBy = request.queryKey[4];

    return client.get(
      `/maximum-stop/index/?page=${page_no}&search_text=${searchText}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static viewMaxStop(request) {
    const maximum_stop_id = request.queryKey[1];
    return client.get(`/maximum-stop/show/${maximum_stop_id}`, request);
  }

  static updateMaxStop(request) {
    return client.post(`/maximum-stop/update/${request.maximum_stop_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static deleteMaxStops(request) {
    return client.put(`/maximum-stop/delete/${request.maximum_stop_id}`, request);
  }

  // Operational time setting

  static getOperationalTime() {
    return client.get('/tour/operational-time');
  }

  static updateOperationalTime(request) {
    return client.post('/tour/operational-time', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static generateReport(request) {
    return client.post(`/maximum-stop/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { TourSettingService };
