import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Modal, Nav, Row } from 'react-bootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import { useDriverDeleteChange, useGenerateDriverReport, useListDriver } from '@hooks';
import { TNButton } from '@common/components/TNButton';
import TNTable from '@common/components/TNTable';
import TNBreadCurm from '@common/components/TNBreadCurm';
import Sort from '@common/components/Sorting';
import Checkbox from '../../common/components/Checkbox';
import { convertToPlainText } from '../../../helpers/commonHelpers';

const ListDriverPage = ({ t }) => {
  let { transporter_id } = useParams();
  const navigate = useNavigate();
  const [modalShowDownload, setModalShowDownload] = useState(false);
  /**
   * !This Block is for User status drop down
   */
  const optionsUser = [
    { value: '', label: `${t('page.users_user_status_name')}` },
    { value: 1, label: `${t('page.active_status_name')}` },
    { value: 0, label: `${t('page.in_active_status_name')}` }
  ];
  /**
   * !This Block is for Admin status drop down
   */
  const optionsAdmin = [
    { value: '', label: `${t('page.users_admin_status_name')}` },
    { value: 1, label: `${t('page.active_status_name')}` },
    { value: 0, label: `${t('page.in_active_status_name')}` }
  ];

  const sortByExport = [
    { value: 'created_at', label: `${t('page.driver_created_at')}` },
    { value: 'first_name', label: `${t('page.driver_name_label')}` },
    { value: 'email', label: `${t('page.driver_phone_email_label')}` },
    { value: 'owner_ktp', label: `${t('page.driver_ktp_label')}` },
    {
      value: 'user_status',
      label: `${t('page.driver_user_status_and_admin_status_label')}`
    }
  ];

  const sortOrderExport = [
    { value: 'asc', label: `${t('page.asccending')}` },
    { value: 'desc', label: `${t('page.desccending')}` }
  ];

  const groupByExport = [
    { value: 'user_status', label: `${t('page.users_user_status_name')}` },
    { value: 'admin_status', label: `${t('page.users_admin_status_name')}` }
  ];

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    localStorage.adminDriverTable !== undefined && localStorage.adminDriverTable !== ''
      ? JSON.parse(localStorage.adminDriverTable).currentPage
      : 1
  );
  const [searchText, setSearchText] = useState(
    localStorage.adminDriverTable !== undefined && localStorage.adminDriverTable !== ''
      ? JSON.parse(localStorage.adminDriverTable).searchText
      : ''
  );

  const [searchTextFilter, setSearchTextFilter] = useState(
    localStorage.adminDriverTable !== undefined && localStorage.adminDriverTable !== ''
      ? JSON.parse(localStorage.adminDriverTable).searchTextFilter
      : ''
  );

  const [paginationData, setPaginationData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(
    localStorage.adminDriverTable !== undefined && localStorage.adminDriverTable !== ''
      ? JSON.parse(localStorage.adminDriverTable).selectedStatus
      : ''
  );
  const [selectedStatusAdmin, setSelectedStatusAdmin] = useState(
    localStorage.adminDriverTable !== undefined && localStorage.adminDriverTable !== ''
      ? JSON.parse(localStorage.adminDriverTable).selectedStatusAdmin
      : ''
  );
  const [isDeleteChange, setIsDeleteChange] = useState(false);

  const [sortBy, setSortBy] = useState(
    localStorage.adminDriverTable !== undefined && localStorage.adminDriverTable !== ''
      ? JSON.parse(localStorage.adminDriverTable).sortBy
      : 'created_at'
  );

  const [sortOrder, setSortOrder] = useState(
    localStorage.adminDriverTable !== undefined && localStorage.adminDriverTable !== ''
      ? JSON.parse(localStorage.adminDriverTable).sortOrder
      : 'desc'
  );

  const [groupBy, setGroupBy] = useState(
    localStorage.adminDriverTable !== undefined && localStorage.adminDriverTable !== ''
      ? JSON.parse(localStorage.adminDriverTable).sortOrder
      : ''
  );

  /**
   * !This block is for set filter value in localstorage
   */
  useEffect(() => {
    let adminDriverTable = {
      searchText: searchText,
      searchTextFilter: searchTextFilter,
      currentPage: currentPage,
      selectedStatus: selectedStatus,
      selectedStatusAdmin: selectedStatusAdmin,
      sortBy: sortBy,
      sortOrder: sortOrder
    };
    localStorage.adminDriverTable = JSON.stringify(adminDriverTable);
  }, [
    currentPage,
    searchText,
    searchTextFilter,
    selectedStatus,
    selectedStatusAdmin,
    sortBy,
    sortOrder
  ]);

  const handleSort = (name) => {
    setSortBy(name);
    sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc');
  };

  const handleClearFilter = () => {
    setSearchText('');
    setSearchTextFilter('');
    setCurrentPage(1);
    setSelectedStatus('');
    setSelectedStatusAdmin('');
    setSortBy('created_at');
    setSortOrder('desc');
    localStorage.removeItem('adminDriverTable');
  };
  /**
   * !This Function will call when user clicks on Edit Button
   * @param {*} tdata which is current element of button
   */
  const handleEditClick = (tdata) => {
    let driver_id = tdata.currentTarget.getAttribute('driver_id');
    navigate(`/transporter/driver/edit/${transporter_id}/${driver_id}`);
  };
  /**
   * !This Function will call when user clicks on View Button
   */
  const handleViewActivityClick = (tdata) => {
    let driver_id = tdata.currentTarget.getAttribute('driver_id');
    navigate(`/transporter/driver/view/${transporter_id}/${driver_id}/checklist-history`);
  };
  const handleViewClick = (tdata) => {
    let driver_id = tdata.currentTarget.getAttribute('driver_id');
    navigate(`/transporter/driver/view/${transporter_id}/${driver_id}`);
  };
  /**
   * !This Function will call when user clicks on Delete Button
   */
  const handleDeleteClick = (tdata) => {
    let driver_id = tdata.currentTarget.getAttribute('driver_id');
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
              }}
            />
            <div className="alert-popup">
              <h2>{t('page.admin_driver_delete_alert_popup_message')}</h2>
              <Button
                className="table-delete-button"
                onClick={() => {
                  setIsDeleteChange(driver_id);
                  onClose();
                }}>
                {t('page.alert_popup_yes_button')}
              </Button>
              <Button className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </Button>
            </div>
          </div>
        );
      }
    });
  };
  /**
   * !This Api will call when user clicks on Delete Button
   */
  useDriverDeleteChange(
    [isDeleteChange],
    (res) => {
      refetch();
      toast.success(res.message);
      setIsDeleteChange(false);
    },
    (err) => {
      toast.error(err.message);
      setIsDeleteChange(false);
    }
  );
  /**
   * !This Block is making Headers for the column
   * @param Not Required
   */

  const columnsjson = [
    {
      Header: (
        <div
          onClick={() => {
            handleSort('created_at');
          }}
          className="sorting-main-div">
          <div className="me-2">{t('page.driver_created_at')}</div>
          <Sort isActive={sortBy === 'created_at' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'created_at',
      disableSortBy: true
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('first_name');
          }}
          className="sorting-main-div">
          <div className="me-2">{t('page.driver_name_label')}</div>
          <Sort isActive={sortBy === 'first_name' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'name',
      disableSortBy: true
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('email');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.driver_phone_email_label')
            }}></span>
          <Sort isActive={sortBy === 'email' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'phone',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            <span>{row.original.email}</span>
            <br />
            <span>{initialValue}</span>
          </div>
        );
      }
    },

    {
      Header: (
        <div
          onClick={() => {
            handleSort('owner_ktp');
          }}
          className="sorting-main-div">
          <div className="me-2">{t('page.driver_ktp_label')}</div>
          <Sort isActive={sortBy === 'owner_ktp' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'owner_ktp',
      disableSortBy: true
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('user_status');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.driver_user_status_and_admin_status_label')
            }}></span>
          <Sort isActive={sortBy === 'user_status' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'user_status_name',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            <span
              driver_id={row.original.driver_id}
              className={row.original.user_status === 1 ? 'status-active' : 'status-inactive'}
              type="user_status">
              {t(initialValue)}
            </span>
            <br></br>
            <span
              driver_id={row.original.driver_id}
              className={row.original.admin_status === 1 ? 'status-active' : 'status-inactive'}
              type="admin_status">
              {row.original.admin_status_name}
            </span>
          </div>
        );
      }
    },
    {
      Header: `${t('page.action_column')}`,
      accessor: 'driver_id',
      Cell: ({ value: initialValue }) => {
        return (
          <div className="action_btn">
            <TNButton
              className="table-primary-button"
              driver_id={initialValue}
              onClick={handleViewActivityClick.bind(this)}>
              Activity
            </TNButton>
            <TNButton
              className="table-primary-button"
              driver_id={initialValue}
              onClick={handleViewClick.bind(this)}>
              {t('page.action_button_text_view')}
            </TNButton>
            <TNButton
              className="table-primary-button"
              driver_id={initialValue}
              onClick={handleEditClick.bind(this)}>
              {t('page.action_button_text_edit')}
            </TNButton>
            <TNButton
              className="table-delete-button"
              driver_id={initialValue}
              onClick={handleDeleteClick.bind(this)}>
              {t('page.action_button_text_delete')}
            </TNButton>
          </div>
        );
      },
      disableSortBy: true
    }
  ];

  const [exportLabels, setExportLabels] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  /**
   * !This API will call while Page Load and set data. Once data load we are updating State
   */
  const { refetch } = useListDriver(
    [
      currentPage,
      searchTextFilter,
      selectedStatus,
      selectedStatusAdmin,
      transporter_id,
      sortBy,
      sortOrder
    ],
    (res) => {
      setExportLabels([
        'admin.driver_report_created_at',
        'admin.driver_report_full_name',
        'admin.driver_report_email',
        'admin.driver_report_contact_number',
        'admin.driver_report_ktp_number',
        'admin.driver_report_user_status',
        'admin.driver_report_admin_status',
        'admin.driver_report_address',
        'admin.driver_report_licence_number',
        'admin.driver_report_licence_expiry_date'
      ]);

      setExportColumns([
        'driver_created_at',
        'driver_full_name',
        'driver_email',
        'driver_contact_number',
        'driver_owner_ktp',
        'driver_user_status',
        'driver_admin_status',
        'driver_address',
        'driver_licence_number',
        'driver_licence_expiry_date'
      ]);

      setIsCheck(exportColumns);

      setData(res.data.driver_list);
      setPaginationData(res.data.pagination);
    }
  );

  const { mutate: doGenerateReport, isLoading: reportGenerateLoading } = useGenerateDriverReport(
    (response) => {
      toast.success(response.message);
      download(response.data.report_url);
      setModalShowDownload(false);
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );

  const download = (image) => {
    const fileName = image.split('/').pop();
    image = image.replace(window.origin, '');
    var link = document.createElement('a');
    link.href = image;
    link.target = '_blank';
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click'));
  };

  const downloadApiCall = (type) => {
    const values = {};
    values.report_type = type;
    values.search_text = searchText;
    values.select_status = selectedStatus;
    values.admin_status = selectedStatusAdmin;
    values.transporter_id = transporter_id;

    values.column_export = JSON.stringify(isCheck);

    doGenerateReport(values);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => columnsjson, [sortBy, sortOrder]);
  if (paginationData === null) {
    return null;
  }
  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
  };

  /**
   * !This Function will call when user type in search text
   */
  const handleSeach = (search_text) => {
    setCurrentPage(1);
    setSearchText(search_text.target.value);
    if (search_text.target.value.length >= 3) {
      setSearchTextFilter(search_text.target.value);
    } else {
      setSearchTextFilter('');
    }
  };

  const breadcurmArray = [
    {
      label: t('page.transporter_list_title'),
      link: '/transporter/list',
      active: ''
    },
    {
      label: t('page.view_transporter_title'),
      link: '/transporter/list',
      active: 'active'
    }
  ];

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(exportColumns.map((li) => li));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleExportColumnChecked = (e) => {
    const { id, checked } = e.target;

    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />
      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to={`/transporter/view/${transporter_id}`}>
            {t('page.transporter_general_details_label')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/transporter/driver/list/${transporter_id}`}>
            {t('page.transporter_driver_details_label')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/transporter/truck/list/${transporter_id}`}>
            {t('page.transporter_truck_details_label')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/transporter/commission/list/${transporter_id}`}>
            {t('page.transporter_commission_details_label')}
          </NavLink>
        </Nav.Item>
      </Nav>
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group className="filter-field-space">
              <Select
                className="filter-column"
                options={optionsUser}
                value={optionsUser.filter((option) => option.value === selectedStatus)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setSelectedStatus(selectedOption.value);
                }}
              />
            </Form.Group>

            <Form.Group className="filter-field-space">
              <Select
                className="filter-column"
                options={optionsAdmin}
                value={optionsAdmin.filter((option) => option.value === selectedStatusAdmin)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setSelectedStatusAdmin(selectedOption.value);
                }}
              />
            </Form.Group>
            <Form.Group controlId="search" className="filter-field-space">
              <Form.Control
                placeholder={t('page.search_text')}
                className="filter-column form-field"
                onKeyUp={handleSeach}
                onChange={handleSeach}
                value={searchText}
              />
            </Form.Group>
            <Button
              type="button"
              onClick={handleClearFilter}
              className="secondary-remove-button ml-0">
              {t('page.cancel_search_button')}
            </Button>
            <div>
              <TNButton
                className="table-add-button"
                loading={false}
                type="button"
                onClick={() => navigate(`/transporter/driver/add/${transporter_id}`)}>
                {t('page.driver_add_link_text')}
              </TNButton>
            </div>
          </div>
        </Col>
        <Col lg={12} className="text-end mb-2">
          <TNButton
            className="table-add-button ml-0"
            loading={false}
            type="button"
            onClick={() => {
              setModalShowDownload(true);
            }}>
            {t('page.driver_download_button')}
          </TNButton>
        </Col>
      </Row>
      <TNTable
        columns={columns}
        data={data}
        paginationData={paginationData}
        onSelectPage={pageIndexHandle}
        t={t}
        key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
        pageIndexGet={currentPage - 1}
      />
      <Modal
        show={modalShowDownload}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header className="text-center">
          <h1>{t('page.driver_select_download_button')}</h1>
          <div className="close-popup">
            <FontAwesomeIcon
              icon={faClose}
              onClick={() => {
                setModalShowDownload(false);
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              <div className="select-all-bar">
                <Checkbox
                  type="checkbox"
                  id="selectAll"
                  name="SelectAll"
                  label={t('page.select_all')}
                  handleClick={handleSelectAll}
                  isChecked={isCheckAll}
                  style={{ textAlign: 'left' }}
                />
              </div>
              <div
                style={{ height: '200px', overflowY: 'scroll', border: '1px solid #ced4da' }}
                className="mb-5">
                {exportColumns.map((exportColumn, index) => {
                  return (
                    <Checkbox
                      key={index}
                      type="checkbox"
                      name={exportColumn}
                      label={t(exportLabels[index])}
                      id={exportColumn}
                      handleClick={handleExportColumnChecked}
                      isChecked={isCheck.includes(exportColumn)}
                      style={{ textAlign: 'left', paddingLeft: '5px' }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="col-6">
              <Form.Label className="field-label date-picker-label">
                {t('page.short_by_label')}
              </Form.Label>
              <div className="row">
                <div className="col-9">
                  <Form.Group>
                    <Select
                      className="filter-column"
                      options={sortByExport.map((option) => {
                        option.label = convertToPlainText(option.label);

                        return option;
                      })}
                      value={sortByExport.filter((option) => option.value === sortBy)}
                      onChange={(selectedOption) => {
                        setSortBy(selectedOption.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-3">
                  <Form.Group>
                    <Select
                      className="filter-column"
                      options={sortOrderExport.map((option) => {
                        option.label = convertToPlainText(option.label);

                        return option;
                      })}
                      value={sortOrderExport.filter((option) => option.value === sortOrder)}
                      onChange={(selectedOption) => {
                        setSortOrder(selectedOption.value);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
              <Form.Label className="field-label date-picker-label mt-3">
                {t('page.group_by_label')}
              </Form.Label>
              <Form.Group>
                <Select
                  className="filter-column mb-3"
                  options={groupByExport}
                  value={groupByExport.filter((option) => option.value === groupBy)}
                  onChange={(selectedOption) => {
                    setGroupBy(selectedOption.value);
                  }}
                />
              </Form.Group>
              <div
                className={groupBy === 'user_status' ? '' : 'd-none'}
                style={{ display: 'contents' }}>
                <Form.Group>
                  <Select
                    className="filter-column"
                    options={optionsUser}
                    value={optionsUser.filter((option) => option.value === selectedStatus)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setSelectedStatus(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
              <div
                className={groupBy === 'admin_status' ? '' : 'd-none'}
                style={{ display: 'contents' }}>
                <Form.Group>
                  <Select
                    className="filter-column"
                    options={optionsAdmin}
                    value={optionsAdmin.filter((option) => option.value === selectedStatusAdmin)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setSelectedStatusAdmin(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="primary-button">
            <TNButton
              type="button"
              loading={reportGenerateLoading}
              onClick={() => {
                downloadApiCall(1);
              }}>
              {t('page.excel_download_button_text')}
            </TNButton>
            <TNButton
              type="button"
              loading={reportGenerateLoading}
              onClick={() => {
                downloadApiCall(3);
              }}
              className="ms-2">
              {t('page.csv_download_button_text')}
            </TNButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
ListDriverPage.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  paginationData: PropTypes.any,
  t: PropTypes.func,
  row: PropTypes.any,
  value: PropTypes.any
};
export { ListDriverPage };
