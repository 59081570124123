import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListWarehousePage } from './ListWarehousePage';
import { AddWarehousePage } from './AddWarehousePage';
import { EditWarehousePage } from './EditWarehousePage';
import { ViewWarehousePage } from './ViewWarehousePage';
import WarehousePricing from './WarehousePricing';

const WarehouseRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index path="/list/" element={<ListWarehousePage t={t} />} />
      <Route path="/add" element={<AddWarehousePage t={t} />} />
      <Route path="/edit/:warehouse_id" element={<EditWarehousePage t={t} />} />
      <Route path="/view/:warehouse_id" element={<ViewWarehousePage t={t} />} />
      <Route path="/pricing/:warehouse_id" element={<WarehousePricing t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
WarehouseRoutes.propTypes = {
  t: PropTypes.func
};
export default WarehouseRoutes;
