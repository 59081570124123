import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Table, Row, Col, Card, Form, Modal, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useGetAgentViewDetail, useRaisedInvoice } from '@hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import TNBreadCurm from '@common/components/TNBreadCurm';
import { TNButton } from '@common/components/TNButton';
import { useFormik } from 'formik';
import { imagePreviewFromik, currencyFormatFloatVal } from '@helpers/commonHelpers';
import { toast } from 'react-toastify';
import validationSchema from './RaisedInvoiceValidation';

const ViewAgentPayout = ({ t }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShowRaisedInvoice, setModalShowRaisedInvoice] = useState(false);

  const [modalImgUrl, setModalImgUrl] = useState('');
  let { date, type, reference_id, per_kg_commision } = useParams();

  const invoicePDFRef = useRef();

  const [transporterCommisionValus, setTransporterCommisionValus] = useState(0);
  const [transporterCommisionWeight, setTransporterCommisionWeight] = useState(0);

  const formik = useFormik({
    initialValues: {
      notes: '',
      commision_calculation_id_list: '',
      invoice_pdf: '',
      type: 2
    },
    validationSchema,
    onSubmit: async (values) => {
      doRaisedInvoice(values);
    }
  });

  const {
    data: payoutData,
    refetch,
    isLoading
  } = useGetAgentViewDetail(date, type, reference_id, per_kg_commision, (res) => {
    formik.setFieldValue('commision_calculation_id_list', res.data.commision_calculation_id);
    setTransporterCommisionValus(res.data.total_commision);
    setTransporterCommisionWeight(res.data.calculated_weight);
  });

  /**
   * !This block is for store scan point data
   */
  const { mutate: doRaisedInvoice } = useRaisedInvoice(
    (res) => {
      if (res.data.is_error === 1) {
        toast.error(res.message);
      } else {
        toast.success(res.message);
      }
      formik.resetForm();
      refetch();
      setModalShowRaisedInvoice(false);
    },
    (error) => {
      toast.error(error.message);
      window.scrollTo(0, 0);
    }
  );

  if (!payoutData) {
    return null;
  }

  const breadcurmArray = [
    {
      label: t('page.finance_list_label'),
      link: '/',
      active: ''
    },
    {
      label: t('page.agent_finance_list_label'),
      link: '/finance/agent/list',
      active: ''
    },
    {
      label: t('page.agent_finance_view_label'),
      link: '/finance/agent/list',
      active: 'active'
    }
  ];

  return (
    <div>
      <Modal show={modalShow} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body className="text-center">
          <div className="close-popup">
            <FontAwesomeIcon icon={faClose} onClick={() => setModalShow(false)} />
          </div>
          <img className="text-center" src={modalImgUrl} alt="profile_img" />
        </Modal.Body>
      </Modal>
      <TNBreadCurm breadcurmArray={breadcurmArray} />
      <Row className="align-items-center">
        <Col>
          <h1>{t('page.agent_finance_view_label')}</h1>
        </Col>
        <Col className="text-end">
          {payoutData.data.invoice_status === 0 ? (
            <TNButton
              className="table-add-button text-center"
              loading={false}
              type="button"
              onClick={() => {
                setModalShowRaisedInvoice(true);
              }}>
              {t('page.payout_transporter_raised_invoice_button')}
            </TNButton>
          ) : null}
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <Row>
            <Col lg={3} xs={12} className="mt-lg-0 mt-2">
              <span className="fw-bold">
                {t('page.agent_finance_date_label')}: {payoutData.data.date}
              </span>
            </Col>
            <Col lg={5} xs={12} className="mt-lg-0 mt-2"></Col>
            <Col lg={4} xs={12} className="mt-lg-0 mt-2">
              <span className="fw-bold">
                {t('page.agent_finance_invoice_status_label')} :
                <span
                  className={
                    'p-2 ms-2 ' +
                    (payoutData.data.invoice_status === 0
                      ? ' pending-bg'
                      : payoutData.data.invoice_status === 1
                      ? ' raised-bg'
                      : ' paid-bg')
                  }>
                  {payoutData.data.invoice_status_name}
                </span>
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={12} md={12} sm={12}>
          <Row>
            <Col lg={3} xs={12} className="mt-lg-0 mt-2">
              <span className="collection-tag">
                <Form.Label className="field-label me-2">
                  {t('page.payout_agent_total_weight_view')}
                </Form.Label>
                {payoutData.data.calculated_weight}
              </span>
            </Col>
            <Col lg={5} xs={12} className="mt-lg-0 mt-2">
              <span className="collection-tag">
                <Form.Label className="field-label me-2">
                  {t('page.payout_agent_commision_per_kg_view')}
                </Form.Label>{' '}
                {payoutData.data.per_kg_commision}
              </span>
            </Col>
            <Col lg={4} xs={12} className="mt-lg-0 mt-2">
              <span className="collection-tag">
                <Form.Label className="field-label me-2">
                  {t('page.payout_agent_total_commision_view')}
                </Form.Label>{' '}
                {payoutData.data.total_commision}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Card className="mt-3">
        <Card.Body>
          <div>{t('page.payout_agent_detail_view')}</div>
          <Row className="order-section align-items-center">
            <Col lg={2}>
              <div className="profile-img mb-3 text-center">
                <img
                  src={payoutData.data.agent_image}
                  className="rounded-pill"
                  width="100px"
                  height="100px"
                  alt="profile_img"
                  onClick={() => {
                    setModalImgUrl(payoutData.data.agent_image);
                    setModalShow(true);
                  }}
                />
              </div>
            </Col>
            <Col lg={5}>
              <Row>
                <Col xs={6}>
                  <Form.Label className="field-label me-2">
                    {t('page.payout_agent_detail_name_view')}
                  </Form.Label>
                </Col>
                <Col xs={6}>
                  <span>{payoutData.data.agent_name}</span>
                </Col>
                <Col xs={6}>
                  <Form.Label className="field-label me-2">
                    {t('page.payout_agent_detail_contact_no_view')}
                  </Form.Label>
                </Col>
                <Col xs={6}>{payoutData.data.agent_number}</Col>
              </Row>
            </Col>
            <Col lg={5}>
              <Row>
                <Col xs={6}>
                  <Form.Label className="field-label me-2">
                    {t('page.payout_agent_detail_agent_id_view')}
                  </Form.Label>
                </Col>
                <Col xs={6}>
                  <span>{payoutData.data.agent_id}</span>
                </Col>
                <Col xs={6}>
                  <Form.Label className="field-label me-2"></Form.Label>
                </Col>
                <Col xs={6}>
                  <span></span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row className="py-3 text-center">
        <Col lg={12}>
          <div className="dashboard-table">
            <Table responsive>
              <thead>
                <tr style={{ verticalAlign: 'middle' }}>
                  <th>{t('page.payout_agent_list_time_view')}</th>
                  <th>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('page.payout_agent_producer_name_id_view')
                      }}></span>
                  </th>
                  <th>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('page.payout_agent_sr_no_view')
                      }}></span>
                  </th>
                  <th>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('page.payout_agent_total_weight_view')
                      }}></span>
                  </th>

                  <th>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('page.payout_agent_total_commision_view')
                      }}></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {payoutData.data.records.map((key) => {
                  return (
                    <tr key={key.commision_calculation_id} style={{ verticalAlign: 'middle' }}>
                      <td>{key.time}</td>
                      <td>
                        {key.producer_name}
                        <br />
                        {key.producer_id}
                      </td>
                      <td>{key.sr_no}</td>
                      <td>{key.calculated_weight_string}</td>
                      <td>{key.total_commision_string}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      <Modal show={modalShowRaisedInvoice} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="text-center">
          <h1>{t('page.payout_transporter_raised_invoice_button')}</h1>
          <div className="close-popup">
            <FontAwesomeIcon icon={faClose} onClick={() => setModalShowRaisedInvoice(false)} />
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Form onSubmit={formik.handleSubmit}>
            <h4>
              {t('page.total_commision_value') +
                ': ' +
                currencyFormatFloatVal(transporterCommisionValus) +
                ' ' +
                t('page.finance_rupaiya_label')}
            </h4>
            <h4>
              {t('page.total_commision_weight') +
                ': ' +
                currencyFormatFloatVal(transporterCommisionWeight) +
                ' ' +
                t('page.weight_kg')}
            </h4>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.trasnporter_payout_notes_label')}
              </Form.Label>
              <Form.Control
                className={
                  ' ' +
                  (formik.touched.notes && formik.errors.notes
                    ? 'form-field-error'
                    : formik.touched.notes && !formik.errors.notes
                    ? 'form-field-success'
                    : '')
                }
                as="textarea"
                rows={3}
                name="notes"
                onChange={formik.handleChange}
                value={formik.values.notes}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.trasnporter_payout_invoice_pdf_label')}
              </Form.Label>
              <Form.Control
                className={'d-none'}
                type="file"
                accept="application/pdf"
                ref={invoicePDFRef}
                name="invoice_pdf"
                onChange={(event) => {
                  formik.setFieldValue('invoice_pdf', event.currentTarget.files[0]);
                }}
                hidden
              />
              <Button
                type="button"
                onClick={() => invoicePDFRef.current?.click()}
                className={
                  'upload-button' ||
                  (formik.touched.invoice_pdf && formik.errors.invoice_pdf
                    ? 'form-field-error'
                    : formik.touched.invoice_pdf && !formik.errors.invoice_pdf
                    ? 'form-field-success'
                    : '')
                }>
                {t('page.file_upload')}
              </Button>
            </Form.Group>
            <div className="form-field-error-text">
              {formik.touched.invoice_pdf && formik.errors.invoice_pdf ? (
                <div>{t(formik.errors.invoice_pdf)}</div>
              ) : null}
            </div>
            {formik.values.invoice_pdf && (
              <div className="text-start">
                <div className="preview-image">
                  <img
                    width="100px"
                    src={imagePreviewFromik(formik.values.invoice_pdf)}
                    alt="profile_img"
                  />
                  <FontAwesomeIcon
                    icon={faClose}
                    onClick={() => {
                      invoicePDFRef.current.value = null;
                      formik.setFieldValue('invoice_pdf', '');
                    }}
                  />
                </div>
              </div>
            )}
            <div className="primary-button">
              <span
                className="link-center"
                onClick={() => {
                  setModalShowRaisedInvoice(false);
                }}>
                {t('page.cancel_button_text')}
              </span>
              <TNButton
                type="submit"
                loading={isLoading}
                // isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}
              >
                {t('page.save_button_text')}
              </TNButton>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
ViewAgentPayout.propTypes = {
  t: PropTypes.func
};
export default ViewAgentPayout;
