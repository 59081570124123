import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListTerritoriesPage = React.lazy(() => import('./ListTerritoriesPage'));
const AddTerritoryPage = React.lazy(() => import('./AddTerritoryPage'));
const EditTerritoryPage = React.lazy(() => import('./EditTerritoryPage'));

const TerritoryRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/list" element={<ListTerritoriesPage t={t} />} />
      <Route exact path="/add" element={<AddTerritoryPage t={t} />} />
      <Route exact path="/edit/:territory_id" element={<EditTerritoryPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
TerritoryRoutes.propTypes = {
  t: PropTypes.func
};
export default TerritoryRoutes;
