import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListAgentPage = React.lazy(() => import('./ListAgentPage'));
const AddAgentPage = React.lazy(() => import('./AddAgentPage'));
const EditAgentPage = React.lazy(() => import('./EditAgentPage'));
const ViewAgentPage = React.lazy(() => import('./ViewAgentPage'));

const VisitPage = React.lazy(() => import('./Visit/Index'));
const VisitDetailPage = React.lazy(() => import('./Visit/Detail'));

const VisitByDayPage = React.lazy(() => import('./Visit/ByDay/Index'));
const VisitByDayDetailPage = React.lazy(() => import('./Visit/ByDay/Detail'));

const AgentRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index path="/list" element={<ListAgentPage t={t} />} />
      <Route path="/add" element={<AddAgentPage t={t} />} />
      <Route path="/edit/:agent_id" element={<EditAgentPage t={t} />} />
      <Route path="/view/:agent_id" element={<ViewAgentPage t={t} />} />

      <Route path="/visit" element={<VisitPage t={t} />} />
      <Route path="/visit/:id" element={<VisitDetailPage t={t} />} />

      <Route path="/visit-day" element={<VisitByDayPage t={t} />} />
      <Route path="/visit-day/:agent_id/:visit_date" element={<VisitByDayDetailPage t={t} />} />

      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AgentRoutes.propTypes = {
  t: PropTypes.func
};
export default AgentRoutes;
