import client from '@libs/HttpClient';
class TruckService {
  static storeTruck(request) {
    return client.post('transporter/trucks/store', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static listTrucks(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const transporter_id = request.queryKey[4];
    const truckType = request.queryKey[5];
    const sortBy = request.queryKey[6];
    const sortOrder = request.queryKey[7];

    return client.get(
      `transporter/trucks/index/${transporter_id}?page=${page_no}&search_text=${searchText}&status=${status}&transporter_id=${transporter_id}&type=${truckType}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }
  static deleteTruck(request) {
    const truck_id = request.queryKey[1];
    return client.get(`/transporter/trucks/delete/${truck_id}`, request);
  }
  static viewTruck(request) {
    const truck_id = request.queryKey[1];
    return client.get(`/transporter/trucks/view/${truck_id}`, request);
  }
  static updateTruck(request) {
    const truck_id = request.get('truck_id');
    return client.post(`/transporter/trucks/update/${truck_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static deleteTruckAttachment(request) {
    const attachment_id = request.queryKey[1];
    return client.get(`/transporter/trucks/delete-attachment/${attachment_id}`, request);
  }
  static generateReport(request) {
    return client.post(`/transporter/trucks/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { TruckService };
