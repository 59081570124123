import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const MasterReportPage = React.lazy(() => import('./MasterReport/MasterReportPage'));
const StatisticReportPage = React.lazy(() =>
  import('@common/components/statisticPage/StatisticReport')
);

const ReportRoutes = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/master-report" element={<MasterReportPage t={t} />} />
      <Route exact path="/statistic-report" element={<StatisticReportPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ReportRoutes.propTypes = {
  t: PropTypes.func
};
export default ReportRoutes;
