import React, { useRef } from 'react';
import { useMemo, useState, useEffect } from 'react';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  dateFormatCommon,
  imagePreviewFromik,
  areEqual,
  currencyFormatFloatVal,
  dateFormatManualAdd
} from '@helpers/commonHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import moment from 'moment';

import validationSchema from './RaisedInvoiceValidation';
import { IndeterminateCheckbox, ReactTable } from '@common/components/ReactTable';
import { TNButton } from '@common/components/TNButton';
import {
  useGetFactoryPayout,
  useGetFactoryFilterList,
  useRaisedInvoice,
  useGenerateFactoryReport
} from '@hooks';
import TNBreadCurm from '@common/components/TNBreadCurm';
import Sort from '@common/components/Sorting';

const ListFactoryPayout = ({ t }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowDownload, setModalShowDownload] = useState(false);
  const [reportType, setReportType] = useState(0);
  const invoicePDFRef = useRef();

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    localStorage.adminFinanceFactoryTable !== undefined &&
      localStorage.adminFinanceFactoryTable !== ''
      ? JSON.parse(localStorage.adminFinanceFactoryTable).currentPage
      : 1
  );
  const [searchText, setSearchText] = useState(
    localStorage.adminFinanceFactoryTable !== undefined &&
      localStorage.adminFinanceFactoryTable !== ''
      ? JSON.parse(localStorage.adminFinanceFactoryTable).searchText
      : ''
  );
  const [searchTextFilter, setSearchTextFilter] = useState(
    localStorage.adminFinanceFactoryTable !== undefined &&
      localStorage.adminFinanceFactoryTable !== ''
      ? JSON.parse(localStorage.adminFinanceFactoryTable).searchTextFilter
      : ''
  );

  const [referenceIdFactory, setReferenceIdFactory] = useState(
    localStorage.adminFinanceFactoryTable !== undefined &&
      localStorage.adminFinanceFactoryTable !== ''
      ? JSON.parse(localStorage.adminFinanceFactoryTable).referenceIdFactory
      : ''
  );

  const [invoiceStatus, setInvoiceStatus] = useState(
    localStorage.adminFinanceFactoryTable !== undefined &&
      localStorage.adminFinanceFactoryTable !== ''
      ? JSON.parse(localStorage.adminFinanceFactoryTable).invoiceStatus
      : ''
  );

  const [fromDate, setFromDate] = useState(
    localStorage.adminFinanceFactoryTable !== undefined &&
      localStorage.adminFinanceFactoryTable !== '' &&
      JSON.parse(localStorage.adminFinanceFactoryTable).fromDate !== ''
      ? new Date(JSON.parse(localStorage.adminFinanceFactoryTable).fromDate)
      : ''
  );
  const [toDate, setToDate] = useState(
    localStorage.adminFinanceFactoryTable !== undefined &&
      localStorage.adminFinanceFactoryTable !== '' &&
      JSON.parse(localStorage.adminFinanceFactoryTable).toDate !== ''
      ? new Date(JSON.parse(localStorage.adminFinanceFactoryTable).toDate)
      : ''
  );

  const [sortBy, setSortBy] = useState(
    localStorage.adminFinanceFactoryTable !== undefined &&
      localStorage.adminFinanceFactoryTable !== ''
      ? JSON.parse(localStorage.adminFinanceFactoryTable).sortBy
      : 'created_at'
  );

  const [sortOrder, setSortOrder] = useState(
    localStorage.adminFinanceFactoryTable !== undefined &&
      localStorage.adminFinanceFactoryTable !== ''
      ? JSON.parse(localStorage.adminFinanceFactoryTable).sortOrder
      : 'desc'
  );

  const [singleFactoryCheckbox, setSingleFactoryCheckbox] = useState(
    localStorage.singleFactoryCheckbox !== undefined && localStorage.singleFactoryCheckbox !== ''
      ? JSON.parse(localStorage.singleFactoryCheckbox)
      : []
  );

  const [factoryCommisionValus, setFactoryCommisionValus] = useState(
    localStorage.factoryCommisionValus !== undefined && localStorage.factoryCommisionValus !== ''
      ? localStorage.factoryCommisionValus
      : 0
  );

  const [factoryCommisionWeight, setFactoryCommisionWeight] = useState(
    localStorage.factoryCommisionWeight !== undefined && localStorage.factoryCommisionWeight !== ''
      ? localStorage.factoryCommisionWeight
      : 0
  );

  const [allPIds, setAllPIds] = useState([]);
  const [allPIdCommision, setAllPIdCommision] = useState(0);
  const [allPIdWeight, setAllPIdWeight] = useState(0);

  const [paginationData, setPaginationData] = useState(null);

  const handleClearFilter = () => {
    setSearchText('');
    setSearchTextFilter('');
    setCurrentPage(1);
    // setFromDate('');
    setReferenceIdFactory('');
    setInvoiceStatus('');
    setSortBy('created_at');
    setSortOrder('desc');
    // setToDate('');
    localStorage.removeItem('adminFinanceFactoryTable');
  };

  /**
   * !This Api will call on delete Max Stop
   */

  const clearDateFilter = (date) => {
    setCurrentPage(1);
    setFromDate('');
    setToDate('');
  };

  /**
   * !This Function will call when user clicks on Edit Button
   * @param {*} tdata which is current element of button
   */
  const handleEditClick = (tdata) => {
    navigate(
      `/finance/factory/view/${tdata.original.created_at}/${tdata.original.type_data}/${tdata.original.reference_id}/${tdata.original.total_per_kg_commision}`
    );
  };

  /**
   * !This API will call while Page Load and set data. Once data load we are updating State
   */

  const { data: filterData, isLoading } = useGetFactoryFilterList();
  const { refetch } = useGetFactoryPayout(
    [
      currentPage,
      fromDate !== '' ? moment(fromDate).format('YYYY-MM-DD') : '',
      toDate !== '' ? moment(toDate).format('YYYY-MM-DD') : '',
      invoiceStatus,
      searchTextFilter,
      referenceIdFactory,
      sortBy,
      sortOrder
    ],
    (res) => {
      setData(res.data.commmision_payout);
      setPaginationData(res.data.pagination);
      setAllPIds(res.data.total_commision_pending_ids);
      setAllPIdCommision(res.data.total_commision);
      setAllPIdWeight(res.data.total_pid_weight);
    }
  );

  /**
   * !This block is for set filter value in localstorage
   */
  useEffect(() => {
    let adminFinanceFactoryTable = {
      searchText: searchText,
      searchTextFilter: searchTextFilter,
      fromDate: fromDate,
      toDate: toDate,
      referenceIdFactory: referenceIdFactory,
      invoiceStatus: invoiceStatus,
      currentPage: currentPage,
      sortBy: sortBy,
      sortOrder: sortOrder
    };

    localStorage.adminFinanceFactoryTable = JSON.stringify(adminFinanceFactoryTable);
  }, [
    currentPage,
    fromDate,
    toDate,
    invoiceStatus,
    searchText,
    searchTextFilter,
    referenceIdFactory,
    sortBy,
    sortOrder
  ]);

  const download = (image) => {
    const fileName = image.split('/').pop();
    image = image.replace(window.origin, '');
    var link = document.createElement('a');
    link.href = image;
    link.target = '_blank';
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click'));
  };

  const { mutate: doGenerateFactoryReport, isLoading: reportGenerateLoading } =
    useGenerateFactoryReport(
      (response) => {
        toast.success(response.message);
        download(response.data.report_url);
        setReportType(0);
        setModalShowDownload(false);
      },
      (error) => {
        window.scrollTo(0, 0);
      }
    );
  const downloadApiCall = (type) => {
    const values = {};
    values.report_type = reportType;
    values.report_short_detail = type;
    values.search_text = searchText;
    values.from_date = fromDate !== '' ? moment(fromDate).format('YYYY-MM-DD') : '';
    values.to_date = toDate !== '' ? moment(toDate).format('YYYY-MM-DD') : '';
    values.reference_id = referenceIdFactory;
    values.invoice_status = invoiceStatus;
    values.checkbox_data = JSON.stringify(singleFactoryCheckbox);
    doGenerateFactoryReport(values);
  };
  /**
   * !This block is for store scan point data
   */
  const { mutate: doRaisedInvoice } = useRaisedInvoice(
    (res) => {
      if (res.data.is_error === 1) {
        toast.error(res.message);
      } else {
        toast.success(res.message);
      }
      formik.resetForm();
      refetch();
      setModalShow(false);

      setSingleFactoryCheckbox([]);
      localStorage.setItem('singleFactoryCheckbox', []);

      setFactoryCommisionValus(0);
      localStorage.setItem('factoryCommisionValus', 0);

      setFactoryCommisionWeight(0);
      localStorage.setItem('factoryCommisionWeight', 0);
    },
    (error) => {
      toast.error(error.message);
      window.scrollTo(0, 0);
    }
  );

  const formik = useFormik({
    initialValues: {
      notes: '',
      commision_calculation_id_list: singleFactoryCheckbox,
      invoice_pdf: '',
      type: 3
    },
    validationSchema,
    onSubmit: async (values) => {
      values.commision_calculation_id_list = JSON.stringify(singleFactoryCheckbox);
      doRaisedInvoice(values);
    }
  });

  const handleSort = (name) => {
    setSortBy(name);
    sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc');
  };

  const columnsjson = [
    {
      id: 'selection',
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}>
          {singleFactoryCheckbox.map((key, val) => {
            singleFactoryCheckbox.val = key.split(',').sort().join(',');
            singleFactoryCheckbox[val] = key.split(',').sort().join(',');
            return true;
          })}

          {allPIds.map((key, val) => {
            allPIds.val = key.split(',').sort().join(',');
            allPIds[val] = key.split(',').sort().join(',');
            return true;
          })}

          <IndeterminateCheckbox
            className={data.length > 0 && allPIds.length > 0 ? '' : 'd-none'}
            checked={'checked'}
            {...getToggleAllRowsSelectedProps({
              checked: areEqual(allPIds, singleFactoryCheckbox) ? true : false
            })}
            onChange={(e) => {
              if (e.currentTarget.checked) {
                setSingleFactoryCheckbox(allPIds);
                localStorage.singleFactoryCheckbox = JSON.stringify(allPIds);

                setFactoryCommisionValus(allPIdCommision);
                localStorage.factoryCommisionValus = allPIdCommision;

                setFactoryCommisionWeight(allPIdWeight);
                localStorage.factoryCommisionWeight = allPIdWeight;
              } else {
                setSingleFactoryCheckbox([]);
                localStorage.singleFactoryCheckbox = [];

                setFactoryCommisionValus(0);
                localStorage.factoryCommisionValus = 0;

                setFactoryCommisionWeight(0);
                localStorage.factoryCommisionWeight = 0;
              }
            }}
            label={t('page.payout_trasnporter_payout_status')}
          />
        </div>
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => (
        <div>
          {singleFactoryCheckbox.map((key, val) => {
            singleFactoryCheckbox.val = key.split(',').sort().join(',');
            singleFactoryCheckbox[val] = key.split(',').sort().join(',');
            return true;
          })}

          {row.original.invoice_status === 0 && row.original.is_today !== 1 ? (
            <div>
              <IndeterminateCheckbox
                checked={'checked'}
                commision_calculation_id={row.original.commision_calculation_id_list}
                {...row.getToggleRowSelectedProps({
                  checked: singleFactoryCheckbox.includes(
                    row.original.commision_calculation_id_list.split(',').sort().join(',')
                  )
                    ? true
                    : false
                })}
                label={
                  <span
                    className="text-pending"
                    // eslint-disable-next-line react/no-unknown-property
                    commision_calculation_id_list={row.original.commision_calculation_id_list}>
                    {row.original.invoice_status_name}
                  </span>
                }
                onChange={(e) => {
                  singleFactoryCheckbox.map((key, val) => {
                    singleFactoryCheckbox.val = key.split(',').sort().join(',');
                    singleFactoryCheckbox[val] = key.split(',').sort().join(',');
                    return true;
                  });

                  var clIds = singleFactoryCheckbox;
                  var names = singleFactoryCheckbox;
                  var commisionIds = row.original.commision_calculation_id_list
                    .split(',')
                    .sort()
                    .join(',');

                  var amountPassed = parseFloat(factoryCommisionValus);
                  var weightPassed = parseFloat(factoryCommisionWeight);

                  if (e.currentTarget.checked) {
                    names.push(commisionIds);

                    amountPassed += row.original.total_commision;
                    weightPassed += row.original.total_calculated_weight;
                  } else {
                    var arra1 = commisionIds;
                    var arra2 = clIds;

                    amountPassed -= row.original.total_commision;
                    weightPassed -= row.original.total_calculated_weight;

                    names = arra2.filter((fruit) => fruit !== arra1);
                  }
                  names = names.filter((data, index) => {
                    return names.indexOf(data) === index;
                  });

                  setSingleFactoryCheckbox(names);
                  localStorage.setItem('singleFactoryCheckbox', JSON.stringify(names));

                  setFactoryCommisionValus(amountPassed);
                  localStorage.factoryCommisionValus = amountPassed;

                  setFactoryCommisionWeight(weightPassed);
                  localStorage.factoryCommisionWeight = weightPassed;
                }}
                name={row.original.sourceId}
                // checked={row.original.included}
              />
            </div>
          ) : row.original.invoice_status === 2 ? (
            <span className="text-completed">{row.original.invoice_status_name}</span>
          ) : (
            <span className="text-current">{row.original.invoice_status_name}</span>
          )}
        </div>
      )
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('created_at');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.payout_factory_created_at')
            }}></span>
          <Sort isActive={sortBy === 'created_at' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'created_at',
      disableSortBy: true
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('factory_name');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.payout_factory_name_label')
            }}></span>
          <Sort isActive={sortBy === 'factory_name' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'factory_name',
      disableSortBy: true
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('total_calculated_weight');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.payout_factory_total_weight')
            }}></span>
          <Sort
            isActive={sortBy === 'total_calculated_weight' ? true : false}
            orderType={sortOrder}
          />
        </div>
      ),
      accessor: 'total_calculated_weight_string',
      disableSortBy: true
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('total_per_kg_commision');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.payout_factory_commision_per_kg')
            }}></span>
          <Sort
            isActive={sortBy === 'total_per_kg_commision' ? true : false}
            orderType={sortOrder}
          />
        </div>
      ),
      accessor: 'total_per_kg_commision_string',
      disableSortBy: true
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('total_commision');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.payout_factory_total_commision')
            }}></span>
          <Sort isActive={sortBy === 'total_commision' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'total_commision_string',
      disableSortBy: true
    },
    {
      Header: `${t('page.action_column')}`,
      accessor: 'abort_reason_id',
      Cell: ({ row }) => {
        return (
          <div className="action_btn">
            <TNButton className="table-primary-button" onClick={() => handleEditClick(row)}>
              {t('page.action_button_text_view')}
            </TNButton>
          </div>
        );
      },
      disableSortBy: true
    }
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(
    () => columnsjson,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      allPIds,
      singleFactoryCheckbox,
      allPIdCommision,
      allPIdWeight,
      factoryCommisionValus,
      factoryCommisionWeight,
      sortOrder,
      sortBy
    ]
  );
  if (paginationData === null) {
    return null;
  }

  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
  };

  const handleSeach = (search_text) => {
    setCurrentPage(1);
    setSearchText(search_text.target.value);
    if (search_text.target.value.length >= 3) {
      setSearchTextFilter(search_text.target.value);
    } else {
      setSearchTextFilter('');
    }
  };

  const breadcurmArray = [
    {
      label: t('page.finance_list_label'),
      link: '/',
      active: ''
    },
    {
      label: t('page.factory_finance_list_label'),
      link: '/',
      active: 'active'
    }
  ];

  const invoiceOptions = [
    { value: '', label: `${t('page.transporter_invoice_select')}` },
    { value: '0', label: `${t('page.transporter_invoice_pending')}` },
    { value: '1', label: t('page.transporter_invoice_paid') },
    { value: '2', label: t('page.transporter_invoice_raised') }
  ];

  // const handleCheckboxSelection = () => {};

  if (isLoading) {
    return null;
  }

  const referenceOptions = [];
  referenceOptions.push({
    value: '',
    label: t('page.factory_select_transporter_options')
  });
  if (filterData.data !== undefined) {
    filterData.data.map((val) => {
      return referenceOptions.push({
        value: val.reference_id,
        label: val.reference_name
      });
    });
  }

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />

      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group controlId="search" className="filter-field-space">
              <Form.Control
                placeholder={t('page.search_text')}
                className="filter-column form-field"
                onKeyUp={handleSeach}
                onChange={handleSeach}
                value={searchText}
              />
            </Form.Group>
            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={invoiceOptions}
                value={invoiceOptions.filter((option) => option.value === invoiceStatus)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setInvoiceStatus(selectedOption.value);
                }}
              />
            </Form.Group>
            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={referenceOptions}
                value={referenceOptions.filter((option) => option.value === referenceIdFactory)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setReferenceIdFactory(selectedOption.value);
                }}
              />
            </Form.Group>
            <Button type="button" onClick={handleClearFilter} className="secondary-remove-button">
              {t('page.cancel_search_button')}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Label className="field-label date-picker-label">
              {t('page.users_from_date_label')}
            </Form.Label>
            <Form.Group className="filter-field-space">
              <DatePicker
                onKeyDown={dateFormatManualAdd}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column"
                placeholderText={dateFormatCommon().toUpperCase()}
                selected={fromDate}
                dateFormat={dateFormatCommon()}
                onChange={(date) => {
                  setCurrentPage(1);
                  setFromDate(date);
                }}
                maxDate={toDate !== '' && toDate !== undefined ? toDate : new Date()}
                onBlur={(date) => {
                  var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                  if (date.currentTarget.value) {
                    if (!date1.isValid()) {
                      toast.error(t('page.invalid_date_format'));
                      setFromDate('');
                    } else {
                      var maxDate = toDate !== '' && toDate !== undefined ? toDate : new Date();
                      if (date1 > maxDate) {
                        toast.error(t('page.from_date_less_than_to_Date'));
                        setFromDate('');
                      }
                    }
                  }
                }}
              />
            </Form.Group>
            <Form.Label className="field-label date-picker-label">
              {t('page.users_to_date_label')}
            </Form.Label>
            <Form.Group className="filter-field-space">
              <DatePicker
                onKeyDown={dateFormatManualAdd}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column"
                placeholderText={dateFormatCommon().toUpperCase()}
                minDate={fromDate}
                selected={toDate}
                dateFormat={dateFormatCommon()}
                onChange={(date) => {
                  setCurrentPage(1);
                  setToDate(date);
                }}
                onBlur={(date) => {
                  var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                  if (date.currentTarget.value) {
                    if (!date1.isValid()) {
                      toast.error(t('page.invalid_date_format'));
                      setToDate('');
                    } else {
                      var minDate = fromDate !== '' && fromDate !== undefined ? fromDate : date1;
                      if (date1 < minDate) {
                        toast.error(t('page.to_date_greater_than_from_Date'));
                        setToDate('');
                      }
                    }
                  }
                }}
              />
            </Form.Group>
            <TNButton
              className="secondary-add-button"
              loading={false}
              type="button"
              onClick={clearDateFilter}>
              {t('page.user_list_clear_date_filter_button')}
            </TNButton>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="filter">
            <div className="filter-field-space">
              <TNButton
                className="table-add-button"
                loading={false}
                type="button"
                onClick={() => {
                  if (singleFactoryCheckbox.length === 0) {
                    toast.error(t('page.transport_please_select_any_commision_first'));
                    return;
                  }
                  setModalShow(true);
                }}>
                {t('page.payout_factory_raised_invoice_button')}
              </TNButton>
              <TNButton
                className={'table-add-button ' + (data.length > 0 ? '' : 'd-none')}
                loading={false}
                type="button"
                onClick={() => {
                  setModalShowDownload(true);
                }}>
                {t('page.payout_factory_download_button')}
              </TNButton>
            </div>
          </div>
        </Col>
      </Row>
      <h1>{t('page.factory_finance_list_label')}</h1>
      <ReactTable
        columns={columns}
        data={data}
        paginationData={paginationData}
        onSelectPage={pageIndexHandle}
        t={t}
        key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
        pageIndexGet={currentPage - 1}
      />
      <Modal show={modalShow} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="text-center">
          <h1>{t('page.payout_factory_raised_invoice_button')}</h1>
          <div className="close-popup">
            <FontAwesomeIcon icon={faClose} onClick={() => setModalShow(false)} />
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Form onSubmit={formik.handleSubmit}>
            <h4>
              {t('page.total_commision_value') +
                ': ' +
                currencyFormatFloatVal(factoryCommisionValus) +
                ' ' +
                t('page.finance_rupaiya_label')}
            </h4>
            <h4>
              {t('page.total_commision_weight') +
                ': ' +
                currencyFormatFloatVal(factoryCommisionWeight) +
                ' ' +
                t('page.weight_kg')}
            </h4>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.trasnporter_payout_notes_label')}
              </Form.Label>
              <Form.Control
                className={
                  ' ' +
                  (formik.touched.notes && formik.errors.notes
                    ? 'form-field-error'
                    : formik.touched.notes && !formik.errors.notes
                    ? 'form-field-success'
                    : '')
                }
                as="textarea"
                rows={3}
                name="notes"
                onChange={formik.handleChange}
                value={formik.values.notes}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.trasnporter_payout_invoice_pdf_label')}
              </Form.Label>
              <Form.Control
                className={'d-none'}
                type="file"
                accept="application/pdf"
                ref={invoicePDFRef}
                name="invoice_pdf"
                onChange={(event) => {
                  formik.setFieldValue('invoice_pdf', event.currentTarget.files[0]);
                }}
                hidden
              />
              <Button
                type="button"
                onClick={() => invoicePDFRef.current?.click()}
                className={
                  'upload-button' ||
                  (formik.touched.invoice_pdf && formik.errors.invoice_pdf
                    ? 'form-field-error'
                    : formik.touched.invoice_pdf && !formik.errors.invoice_pdf
                    ? 'form-field-success'
                    : '')
                }>
                {t('page.file_upload')}
              </Button>
            </Form.Group>
            <div className="form-field-error-text">
              {formik.touched.invoice_pdf && formik.errors.invoice_pdf ? (
                <div>{t(formik.errors.invoice_pdf)}</div>
              ) : null}
            </div>
            {formik.values.invoice_pdf && (
              <div className="text-start">
                <div className="preview-image">
                  <img
                    width="100px"
                    src={imagePreviewFromik(formik.values.invoice_pdf)}
                    alt="profile_img"
                  />
                  <FontAwesomeIcon
                    icon={faClose}
                    onClick={() => {
                      invoicePDFRef.current.value = null;
                      formik.setFieldValue('invoice_pdf', '');
                    }}
                  />
                </div>
              </div>
            )}
            <div className="primary-button">
              <span
                className="link-center"
                onClick={() => {
                  setModalShow(false);
                }}>
                {t('page.cancel_button_text')}
              </span>
              <TNButton
                type="submit"
                loading={isLoading}
                // isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}
              >
                {t('page.save_button_text')}
              </TNButton>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={modalShowDownload} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="text-center">
          <h1>{t('page.payout_factory_select_download_button')}</h1>
          <div className="close-popup">
            <FontAwesomeIcon
              icon={faClose}
              onClick={() => {
                setModalShowDownload(false);
                setReportType(0);
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="primary-button">
            <TNButton
              type="button"
              loading={false}
              onClick={() => {
                setReportType(1);
              }}>
              {t('page.csv_download_button_text')}
            </TNButton>
            <TNButton
              type="button"
              loading={false}
              onClick={() => {
                setReportType(2);
              }}
              className="ms-2">
              {t('page.pdf_download_button_text')}
            </TNButton>
          </div>
          <div className={'primary-button ' + (reportType === 0 ? ' d-none' : '')}>
            {reportType === 1 ? (
              <>
                <TNButton
                  type="button"
                  loading={reportGenerateLoading}
                  onClick={() => {
                    downloadApiCall(1);
                  }}>
                  {t('page.csv_short_report_download_button_text')}
                </TNButton>
                <TNButton
                  type="button"
                  loading={reportGenerateLoading}
                  onClick={() => {
                    downloadApiCall(2);
                  }}
                  className="ms-2">
                  {t('page.csv_detail_report_download_button_text')}
                </TNButton>
              </>
            ) : (
              <>
                <TNButton
                  type="button"
                  loading={reportGenerateLoading}
                  onClick={() => {
                    downloadApiCall(1);
                  }}>
                  {t('page.pdf_short_report_download_button_text')}
                </TNButton>
                <TNButton
                  type="button"
                  loading={reportGenerateLoading}
                  onClick={() => {
                    downloadApiCall(2);
                  }}
                  className="ms-2">
                  {t('page.pdf_detail_report_download_button_text')}
                </TNButton>
              </>
            )}

            {/* {reportType === 1 ? (
              <TNButton
                type="button"
                loading={reportGenerateLoading}
                onClick={() => {
                  downloadApiCall(2);
                }}
                className="ms-2">
                {t('page.csv_detail_report_download_button_text')}
              </TNButton>
            ) : (
              <TNButton
                type="button"
                loading={reportGenerateLoading}
                onClick={() => {
                  downloadApiCall(2);
                }}
                className="ms-2">
                {t('page.pdf_detail_report_download_button_text')}
              </TNButton>
            )} */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
ListFactoryPayout.propTypes = {
  getToggleAllRowsSelectedProps: PropTypes.any,
  row: PropTypes.any,
  value: PropTypes.any,
  columns: PropTypes.any,
  data: PropTypes.any,
  paginationData: PropTypes.any,
  t: PropTypes.func
};
export default ListFactoryPayout;
