import { React, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { useResendOtp, useOTPVerify } from '@hooks';
import validationSchema from './OtpVerificationValidation';
import { PublicLayout, LogoUrl } from '@adminlayout';
import { TNButton } from '@common/components';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import AlertCommon from '@common/components/AlertCommon';
import { showLoader, hideLoader } from '../../../store/features/loaderSlice';

const OtpVerificationPage = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // This Block will execute on Resend Link Click
  const { mutate: doResendOtp } = useResendOtp(
    (res) => {
      dispatch(hideLoader());
      toast.success(res.message);
    },
    (err) => {
      dispatch(hideLoader());
    }
  );

  /**
   * This function is call when click on cancel button
   */

  const handleLoginButton = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.login_reset_alert_popup_message')
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(`/login/`);
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      navigate(`/login/`);
    }
  };

  // This Block will execute on Verify OTP. On success we'll get token and that token will use on Reset Password Page
  const {
    mutate: doOtpVerify,
    isLoading: isLoadingOtpVerify,
    error: addOVList,
    isError: addOVError
  } = useOTPVerify(
    (res) => {
      toast.success(res.message);
      localStorage.verify_otp_token = res.data.user_id;
      localStorage.removeItem('forgot_token');
      navigate('/reset-password');
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );

  // If localstorage does not have forgot token then we are sending them to Login Page from here on load.
  useEffect(() => {
    document.body.classList.add('bg-box');
    document.body.classList.add('signup-box');
    if (
      localStorage.isAdmin === undefined ||
      localStorage.isAdmin === '' ||
      localStorage.forgot_token === undefined ||
      localStorage.forgot_token === ''
    ) {
      navigate('/login');
    }
  });

  // This will call when user click on resend Button link.
  const handleResend = () => {
    const values = {};
    values.user_id = localStorage.forgot_token;
    dispatch(showLoader());
    doResendOtp(values);
  };
  // This will call when user click on verify otp.
  const formik = useFormik({
    initialValues: {
      otp: ''
    },
    validationSchema,
    onSubmit: (values) => {
      values.user_id = localStorage.forgot_token;
      doOtpVerify(values);
    }
  });
  return (
    <PublicLayout>
      <div className="auth-pages">
        <Container>
          <div className="background-box">
            <div>
              <div className="brand-logo">
                <LogoUrl />
              </div>
              <div className="admin-heading">
                <h1 className="page-heading-center">{t('page.otpverification_header_text')}</h1>
                <div className="page-sub-heading">
                  <span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t('page.otpverification_sub_header_text')
                      }}></div>
                  </span>
                </div>
              </div>

              <div>
                <Form onSubmit={formik.handleSubmit}>
                  <AlertCommon
                    key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
                    is_error={addOVError}>
                    {addOVList}
                  </AlertCommon>
                  <Form.Group>
                    <Form.Label className="field-label">
                      {t('page.otpverification_otp_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        'form-field ' +
                        (formik.touched.otp && formik.errors.otp
                          ? 'form-field-error'
                          : formik.touched.otp && !formik.errors.otp
                          ? 'form-field-success'
                          : '')
                      }
                      type="text"
                      name="otp"
                      maxLength="4"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder={t('page.otpverification_otp_placeholder')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.otp}
                    />
                    {formik.touched.otp && formik.errors.otp ? (
                      <div className="form-field-error-text">{t(formik.errors.otp)}</div>
                    ) : null}
                  </Form.Group>
                  <div className="link-right">
                    <span style={{ color: '#424242', fontWeight: '500' }}>
                      {t('page.otpverification_resend_link_header_text')}
                    </span>
                    <span className="resend-otp" onClick={handleResend}>
                      {t('page.otpverification_resend_link_text')}
                    </span>
                  </div>
                  <div className="primary-button">
                    <span className="link-center" onClick={handleLoginButton}>
                      {t('page.otp_login_link_text')}
                    </span>
                    <TNButton
                      loading={isLoadingOtpVerify}
                      type="submit"
                      isdirtyform={formik.dirty && formik.dirty !== undefined ? 1 : 0}>
                      {t('page.otpverification_submit_button_text')}
                    </TNButton>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </PublicLayout>
  );
};
OtpVerificationPage.propTypes = {
  t: PropTypes.func,
  isdirtyform: PropTypes.any
};
export { OtpVerificationPage };
