import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { PendingIncinerationService } from '@api/';
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useRQPendingIncineration = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['tour/incineration/pending/list', [params]],
    () => PendingIncinerationService.viewPendingIncinerationService(params),
    {
      onSuccess,
      onError
    }
  );
};

const useGeneratePendingIncinerationReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(PendingIncinerationService.generateReport, {
    onSuccess,
    onError
  });
};

export { useRQPendingIncineration, useGeneratePendingIncinerationReport };
