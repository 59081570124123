import client from '@libs/HttpClient';
class StoreSessionService {
  static viewStoreSession(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const from_date = request.queryKey[3];
    const to_date = request.queryKey[4];
    const warehouse_id = request.queryKey[5];
    const factory_id = request.queryKey[6];
    const sortOrder = request.queryKey[7];
    const sortBy = request.queryKey[8];

    return client.get(
      `tour/store-session/list?page=${page_no}&search_text=${searchText}&from_date=${from_date}&to_date=${to_date}&warehouse_id=${warehouse_id}&factory_id=${factory_id}&sort_by=${sortBy}&sort_order=${sortOrder}`
    );
  }

  static viewStoreSessionDetails(delivery_point_current_session_id) {
    return client.get(`tour/store-session/details/${delivery_point_current_session_id}`);
  }

  static generateReport(request) {
    return client.post(`/tour/store-session/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { StoreSessionService };
