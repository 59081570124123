import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export default function TabProducerNavigation() {
  return (
    <Nav className="tab-navigation">
      <Nav.Item>
        <NavLink to="/producer/list">Daftar Producer</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/mou">Daftar Producer MOU</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to={`/producer/trial`}>Trial</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to={`/producer/survey`}>Survey</NavLink>
      </Nav.Item>
    </Nav>
  );
}
