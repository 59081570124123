import { createSlice } from '@reduxjs/toolkit';
const intialState = {
  setting_get: true,
  dashboard_logo: '',
  front_logo: '',
  favicon_icon: '',
  email_logo: '',
  seo_title: '',
  seo_description: '',
  common_sales_text: '',
  common_precision_factor: '',
  common_dirty_email_data: '',
  common_vat_for_invoice: '',
  common_invoice_email: '',
  common_dashboard_highlight_mins: ''
};
export const settingSlice = createSlice({
  name: 'setting',
  initialState: intialState,
  reducers: {
    addSetting: (state, { payload }) => {
      state.dashboard_logo = payload.dashboard_logo;
      state.front_logo = payload.front_logo;
      state.favicon_icon = payload.favicon_icon;
      state.email_logo = payload.email_logo;
      state.seo_title = payload.seo_title;
      state.seo_description = payload.seo_description;
      state.setting_get = payload.setting_get;
      state.common_dirty_email_data = payload.common_dirty_email_data;
      state.common_sales_text = payload.common_sales_text;
      state.common_precision_factor = payload.common_precision_factor;
      state.common_vat_for_invoice = payload.common_vat_for_invoice;
      state.common_invoice_email = payload.common_invoice_email;
      state.common_dashboard_highlight_mins = payload.common_dashboard_highlight_mins;
      return state;
    },
    clearSetting: () => intialState
  }
});
export const { addSetting, clearSetting } = settingSlice.actions;
export const settingData = (state) => state.setting;
export default settingSlice.reducer;
