import { useMutation, useQuery } from 'react-query';

import { ContainerHistoryService } from '@api/';
import { toast } from 'react-toastify';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useListContainerHistory = (
  [
    page_no,
    searchText,
    sizeStatus,
    producerStatus,
    fromDate,
    toDate,
    isIncineration,
    sortBy,
    sortOrder
  ],
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    [
      'container-history-list',
      page_no,
      searchText,
      sizeStatus,
      producerStatus,
      fromDate,
      toDate,
      isIncineration,
      sortBy,
      sortOrder
    ],
    ContainerHistoryService.getContainerHistory,
    {
      onSuccess,
      onError
    }
  );
};

const useListContainerHistoryFilter = (onSuccess, onError = onDefaultError) => {
  return useQuery('container-history-list', ContainerHistoryService.getContainerHistoryFilter, {
    onSuccess,
    onError
  });
};

const useListContainerHistoryDetail = (sr_no, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'container-history-list-detail',
    () => ContainerHistoryService.getContainerHistoryDetail(sr_no),
    {
      onSuccess,
      onError
    }
  );
};

const useGenerateContainerHistoryReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(ContainerHistoryService.generateReport, {
    onSuccess,
    onError
  });
};
export {
  useListContainerHistory,
  useListContainerHistoryFilter,
  useListContainerHistoryDetail,
  useGenerateContainerHistoryReport
};
