import client from '@libs/HttpClient';

class UnloadTransportTruckService {
  static viewUnloadTransportTruck(params) {
    return client.get(`tour/transport-truck-unload/list`, { params });
  }

  static viewUnloadTransportTruckDetails(delivery_point_current_session_id) {
    return client.get(`tour/transport-truck-unload/details/${delivery_point_current_session_id}`);
  }

  static useUnloadTransportAddNotes(request) {
    return client.post('tour/transport-truck-unload/add-notes', request);
  }

  static generateReport(request) {
    return client.post(`/tour/transport-truck-unload/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}

export { UnloadTransportTruckService };
