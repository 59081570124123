import client from '@libs/HttpClient';
class AgentService {
  /* delivery point user */
  static listAgent(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const is_active = request.queryKey[3];
    const from_date = request.queryKey[4];
    const to_date = request.queryKey[5];
    const sortBy = request.queryKey[6];
    const sortOrder = request.queryKey[7];

    return client.get(
      `/agent/index?page=${page_no}&search_text=${searchText}&is_active=${is_active}&from_date=${from_date}&to_date=${to_date}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static addAgent(request) {
    return client.post('/agent/store', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static deleteAgent(request) {
    const agent_id = request.queryKey[1];
    return client.get(`/agent/destroy/${agent_id}`, request);
  }
  static viewAgent(request) {
    const agent_id = request.queryKey[1];
    return client.get(`/agent/view/${agent_id}`, request);
  }
  static updateAgent(request) {
    const agent_id = request.get('agent_id');
    return client.post(`/agent/update/${agent_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static deleteAgentAttachment(request) {
    const attachment_id = request.queryKey[1];
    const type = request.queryKey[2];
    return client.get(`/agent/delete-attachment/${attachment_id}/${type}`, request);
  }

  static generateReport(request) {
    return client.post(`/agent/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { AgentService };
