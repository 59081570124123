import client from '@libs/HttpClient';
class TransporterService {
  static storeTransporter(request) {
    return client.post('transporter/store', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static listTransporter(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const selectedInvoiceMode = request.queryKey[4];
    const fromDate = request.queryKey[5];
    const toDate = request.queryKey[6];
    const territryId = request.queryKey[7];
    const areaId = request.queryKey[8];
    const selectedDateType = request.queryKey[9];
    const sortBy = request.queryKey[10];
    const sortOrder = request.queryKey[11];

    return client.get(
      `transporter/index?page=${page_no}&search_text=${searchText}&status=${status}&invoice_mode=${selectedInvoiceMode}&from_date=${fromDate}&to_date=${toDate}&territory_id=${territryId}&area_id=${areaId}&date_type=${selectedDateType}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static viewTransporter(request) {
    return client.get(`/transporter/view/${request}`);
  }

  static deleteTransporterAttachment(request) {
    return client.put(
      `/transporter/delete-attachment/${request.attachment_id}/${request.type}`,
      request
    );
  }

  static updateTransporter(request) {
    const transporter_id = request.get('transporter_id');
    return client.post(`/transporter/update/${transporter_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static generateReport(request) {
    return client.post(`/transporter/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { TransporterService };
