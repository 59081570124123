import { Button, Col, Form, Modal, Nav, Row } from 'react-bootstrap';
import { TNButton } from '@common/components/TNButton';
import Select from 'react-select';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import TNTable from '@common/components/TNTable';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useGenerateTruckReport, useListTrucks, useTruckDelete, useViewTransporter } from '@hooks';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import TNBreadCurm from '@common/components/TNBreadCurm';
import Sort from '@common/components/Sorting';
import Checkbox from '../../../common/components/Checkbox';
import { convertToPlainText } from '../../../../helpers/commonHelpers';

const ListTrucksPage = ({ t }) => {
  const navigate = useNavigate();
  const [modalShowDownload, setModalShowDownload] = useState(false);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    localStorage.adminTrucksTable !== undefined && localStorage.adminTrucksTable !== ''
      ? JSON.parse(localStorage.adminTrucksTable).currentPage
      : 1
  );
  const [searchText, setSearchText] = useState(
    localStorage.adminTrucksTable !== undefined && localStorage.adminTrucksTable !== ''
      ? JSON.parse(localStorage.adminTrucksTable).searchText
      : ''
  );
  const [searchTextFilter, setSearchTextFilter] = useState(
    localStorage.adminTrucksTable !== undefined && localStorage.adminTrucksTable !== ''
      ? JSON.parse(localStorage.adminTrucksTable).searchTextFilter
      : ''
  );
  const [truckType, setByTruckType] = useState(
    localStorage.adminTrucksTable !== undefined && localStorage.adminTrucksTable !== ''
      ? JSON.parse(localStorage.adminTrucksTable).truckType
      : ''
  );
  const [paginationData, setPaginationData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(
    localStorage.adminTrucksTable !== undefined && localStorage.adminTrucksTable !== ''
      ? JSON.parse(localStorage.adminTrucksTable).selectedStatus
      : ''
  );
  const [isDeleted, setIsDeleted] = useState(false);

  const [sortBy, setSortBy] = useState(
    localStorage.adminTrucksTable !== undefined && localStorage.adminTrucksTable !== ''
      ? JSON.parse(localStorage.adminTrucksTable).sortBy
      : 'created_at'
  );

  const [sortOrder, setSortOrder] = useState(
    localStorage.adminTrucksTable !== undefined && localStorage.adminTrucksTable !== ''
      ? JSON.parse(localStorage.adminTrucksTable).sortOrder
      : 'desc'
  );

  const [groupBy, setGroupBy] = useState(
    localStorage.adminTrucksTable !== undefined && localStorage.adminTrucksTable !== ''
      ? JSON.parse(localStorage.adminTrucksTable).sortOrder
      : ''
  );

  /**
   * !This block is for set filter value in localstorage
   */
  useEffect(() => {
    let adminTrucksTable = {
      searchText: searchText,
      searchTextFilter: searchTextFilter,
      currentPage: currentPage,
      selectedStatus: selectedStatus,
      truckType: truckType,
      sortBy: sortBy,
      sortOrder: sortOrder
    };
    localStorage.adminTrucksTable = JSON.stringify(adminTrucksTable);
  }, [currentPage, searchText, selectedStatus, truckType, searchTextFilter, sortBy, sortOrder]);

  const handleClearFilter = () => {
    setSearchText('');
    setSearchTextFilter('');
    setCurrentPage(1);
    setSelectedStatus('');
    setSortBy('created_at');
    setSortOrder('desc');
    setByTruckType('');
    localStorage.removeItem('adminTrucksTable');
  };
  /**
   * !This block is for status drop down
   */
  const options = [
    { value: '', label: `${t('page.select_status')}` },
    { value: 1, label: `${t('page.active_status_name')}` },
    { value: 0, label: `${t('page.in_active_status_name')}` }
  ];
  /**
   * !This block is for Truck Type drop down
   */
  const truckTypeOptions = [
    { value: '', label: `${t('page.trucks_truck_type_select_text_label')}` },
    { value: 1, label: `${t('page.add_trucks_truck_type_collect_truck')}` },
    { value: 2, label: `${t('page.add_trucks_truck_type_transport_truck')}` }
  ];

  const sortByExport = [
    { value: 'created_at', label: `${t('page.trucks_created_at')}` },
    { value: 'brand_name', label: `${t('page.trucks_brand_and_model_name')}` },
    { value: 'plate_number', label: `${t('page.trucks_types_of_trucks_and_number_plate')}` },
    { value: 'max_weight_kg', label: `${t('page.trucks_max_load_in_kg_and_l')}` },
    {
      value: 'is_active',
      label: `${t('page.trucks_status')}`
    }
  ];

  const sortOrderExport = [
    { value: 'asc', label: `${t('page.asccending')}` },
    { value: 'desc', label: `${t('page.desccending')}` }
  ];

  const groupByExport = [
    { value: 'status', label: `${t('page.select_status')}` },
    { value: 'truck_type', label: `${t('page.select_truck_type')}` }
  ];

  let { transporter_id } = useParams();
  /**
   * !This Api will call on click delete
   */
  useTruckDelete(
    [isDeleted],
    (res) => {
      toast.success(res.message);
      setIsDeleted(false);
      refetch();
    },
    (err) => {
      toast.error(err.message);
      setIsDeleted(false);
    }
  );
  const { mutate: doGenerateReport, isLoading: reportGenerateLoading } = useGenerateTruckReport(
    (response) => {
      toast.success(response.message);
      download(response.data.report_url);
      setModalShowDownload(false);
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );

  const downloadApiCall = (type) => {
    const values = {};
    values.report_type = type;
    values.search_text = searchText;
    values.status = selectedStatus;
    values.type = truckType;
    values.transporter_id = transporter_id;

    values.column_export = JSON.stringify(isCheck);

    doGenerateReport(values);
  };

  /**
   * !This Function will call when user clicks on Edit Button
   * @param {*} tdata which is current element of button
   */
  const handleEditClick = (tdata) => {
    let truck_id = tdata.currentTarget.getAttribute('truck_id');
    navigate(`/transporter/truck/edit/${truck_id}`);
  };
  /**
   * !This Function will call when user clicks on View Button
   * @param {*} tdata which is current element of button
   */
  const handleViewClick = (tdata) => {
    let truck_id = tdata.currentTarget.getAttribute('truck_id');
    navigate(`/transporter/truck/view/${truck_id}`);
  };

  /**
   * !This Function will call when user clicks on Delete Button
   */
  const handleDeleteClick = (tdata) => {
    let truck_id = tdata.currentTarget.getAttribute('truck_id');
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box">
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
              }}
            />
            <div className="alert-popup">
              <h2>{t('page.admin_trucks_delete_alert_popup_message')}</h2>
              <TNButton
                className="table-delete-button"
                onClick={() => {
                  setIsDeleted(truck_id);
                  onClose();
                }}>
                {t('page.alert_popup_yes_button')}
              </TNButton>
              <TNButton className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </TNButton>
            </div>
          </div>
        );
      }
    });
  };
  const handleSort = (name) => {
    setSortBy(name);
    sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc');
  };

  /**
   * ! this block is use for download QR code
   */
  const download = (image) => {
    const fileName = image.split('/').pop();
    image = image.replace(window.origin, '');
    var link = document.createElement('a');
    link.href = image;
    link.target = '_blank';
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click'));
  };

  const transporterData = useRef(null);
  useViewTransporter(transporter_id, (data) => {
    transporterData.current = data;
  });
  const printQrTruck = (truck) => {
    window.open(
      `/print-qr-truck/${truck.truck_id}/${truck.plate_number}/${transporterData.current.data.name}`,
      '_blank'
    );
  };

  /**
   * !This Block is making Headers for the column
   * @param Not Required
   */

  const columnsjson = [
    {
      Header: (
        <div
          onClick={() => {
            handleSort('created_at');
          }}
          className="sorting-main-div">
          <div className="me-2">{t('page.trucks_created_at')}</div>
          <Sort isActive={sortBy === 'created_at' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'created_at',
      disableSortBy: true
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('brand_name');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.trucks_brand_and_model_name')
            }}></span>
          <Sort isActive={sortBy === 'brand_name' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'brand_name',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.brand_name}
            <br />
            {row.original.model_name}
          </div>
        );
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('plate_number');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.trucks_types_of_trucks_and_number_plate')
            }}></span>
          <Sort isActive={sortBy === 'plate_number' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'type',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.type_name}
            <br />
            {row.original.plate_number}
          </div>
        );
      }
    },

    {
      Header: (
        <div
          onClick={() => {
            handleSort('max_weight_kg');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.trucks_max_load_in_kg_and_l')
            }}></span>
          <Sort isActive={sortBy === 'max_weight_kg' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'max_weight_kg',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.max_weight_kg}
            <br />
            {row.original.max_weight_ltr}
          </div>
        );
      }
    },

    {
      Header: (
        <div
          onClick={() => {
            handleSort('is_active');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.trucks_status')
            }}></span>
          <Sort isActive={sortBy === 'is_active' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'status_name',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            <span
              className={row.original.is_active === 1 ? 'status-active' : 'status-inactive'}
              truck_id={row.original.truck_id}>
              {t(initialValue)}
            </span>
          </div>
        );
      }
    },
    {
      Header: (
        <span
          dangerouslySetInnerHTML={{
            __html: t('page.trucks_qr_code')
          }}></span>
      ),
      accessor: 'qr_image',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return row.original.qr_image !== '' ? (
          <div>
            <Button onClick={() => printQrTruck(row.original)}>
              {t('page.download_button_label')}
            </Button>
          </div>
        ) : (
          <div>
            <Button disabled>{t('page.download_button_label')}</Button>
          </div>
        );
      }
    },
    {
      Header: `${t('page.action_column')}`,
      accessor: 'truck_id',
      Cell: ({ value: initialValue }) => {
        return (
          <div className="action_btn">
            <TNButton
              className="table-primary-button"
              truck_id={initialValue}
              onClick={handleViewClick.bind(this)}>
              {t('page.action_button_text_view')}
            </TNButton>
            <TNButton
              className="table-primary-button"
              truck_id={initialValue}
              onClick={handleEditClick.bind(this)}>
              {t('page.action_button_text_edit')}
            </TNButton>
            <TNButton
              className="table-delete-button"
              truck_id={initialValue}
              onClick={handleDeleteClick.bind(this)}>
              {t('page.action_button_text_delete')}
            </TNButton>
          </div>
        );
      },
      disableSortBy: true
    }
  ];

  const [exportLabels, setExportLabels] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  /**
   * !This API will call while Page Load and set data. Once data load we are updating State
   */
  const { refetch } = useListTrucks(
    [currentPage, searchTextFilter, selectedStatus, transporter_id, truckType, sortBy, sortOrder],
    (res) => {
      setExportLabels([
        'admin.truck_report_created_at',
        'admin.truck_report_brand_name',
        'admin.truck_report_model_name',
        'admin.truck_report_truck_type',
        'admin.truck_report_plate_number',
        'admin.truck_report_max_load_kg',
        'admin.truck_report_max_load_ltr',
        'admin.truck_report_road_date',
        'admin.truck_report_weight',
        'admin.truck_report_status'
      ]);

      setExportColumns([
        'truck_created_at',
        'truck_brand_name',
        'truck_model_name',
        'truck_type',
        'truck_plate_number',
        'truck_max_weight_kg',
        'truck_max_weight_ltr',
        'truck_road_date',
        'truck_weight',
        'truck_is_active'
      ]);

      setIsCheck(exportColumns);

      setData(res.data.trucks_list);
      setPaginationData(res.data.pagination);
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => columnsjson, [sortBy, sortOrder]);
  if (paginationData === null) {
    return null;
  }

  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
  };

  /**
   * !This block will call on type in serachbox
   */
  const handleSearch = (search_text) => {
    setCurrentPage(1);
    setSearchText(search_text.target.value);
    if (search_text.target.value.length >= 3) {
      setSearchTextFilter(search_text.target.value);
    } else {
      setSearchTextFilter('');
    }
  };

  const addTrucksHandle = () => {
    navigate(`/transporter/truck/add/${transporter_id}`);
  };

  const breadcurmArray = [
    {
      label: t('page.transporter_list_label'),
      link: `transporter/list/${transporter_id}`,
      active: 'active'
    }
  ];

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(exportColumns.map((li) => li));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleExportColumnChecked = (e) => {
    const { id, checked } = e.target;

    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />

      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to={`/transporter/view/${transporter_id}`}>
            {t('page.transporter_general_details_label')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/transporter/driver/list/${transporter_id}`}>
            {t('page.transporter_driver_details_label')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/transporter/truck/list/${transporter_id}`}>
            {t('page.transporter_truck_details_label')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={`/transporter/commission/list/${transporter_id}`}>
            {t('page.transporter_commission_details_label')}
          </NavLink>
        </Nav.Item>
      </Nav>
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group className="filter-field-space">
              <Select
                className="filter-column"
                options={options}
                value={options.filter((option) => option.value === selectedStatus)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setSelectedStatus(selectedOption.value);
                }}
              />
            </Form.Group>

            <Form.Group className="filter-field-space">
              <Select
                className="filter-column"
                options={truckTypeOptions}
                value={truckTypeOptions.filter((option) => option.value === truckType)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setByTruckType(selectedOption.value);
                }}
              />
            </Form.Group>
            <Form.Group controlId="search" className="filter-field-space">
              <Form.Control
                placeholder={t('page.search_text')}
                className="filter-column form-field"
                onKeyUp={handleSearch}
                onChange={handleSearch}
                value={searchText}
              />
            </Form.Group>
            <Button
              type="button"
              onClick={handleClearFilter}
              className="secondary-remove-button ml-0">
              {t('page.cancel_search_button')}
            </Button>
            <div>
              <TNButton
                className="table-add-button"
                loading={false}
                type="button"
                onClick={addTrucksHandle}>
                {t('page.trucks_add_button')}
              </TNButton>
            </div>
          </div>
        </Col>
        <Col lg={12} className="text-end">
          <TNButton
            className="table-add-button ml-0"
            loading={false}
            type="button"
            onClick={() => {
              setModalShowDownload(true);
            }}>
            {t('page.truck_download_button')}
          </TNButton>
        </Col>
      </Row>
      <h1>{t('page.trucks_list_label')}</h1>
      <TNTable
        columns={columns}
        data={data}
        paginationData={paginationData}
        onSelectPage={pageIndexHandle}
        t={t}
        key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
        pageIndexGet={currentPage - 1}
      />
      <Modal
        show={modalShowDownload}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header className="text-center">
          <h1>{t('page.truck_select_download_button')}</h1>
          <div className="close-popup">
            <FontAwesomeIcon
              icon={faClose}
              onClick={() => {
                setModalShowDownload(false);
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              <div className="select-all-bar">
                <Checkbox
                  type="checkbox"
                  id="selectAll"
                  name="SelectAll"
                  label={t('page.select_all')}
                  handleClick={handleSelectAll}
                  isChecked={isCheckAll}
                  style={{ textAlign: 'left' }}
                />
              </div>
              <div
                style={{ height: '200px', overflowY: 'scroll', border: '1px solid #ced4da' }}
                className="mb-5">
                {exportColumns.map((exportColumn, index) => {
                  return (
                    <Checkbox
                      key={index}
                      type="checkbox"
                      name={exportColumn}
                      label={t(exportLabels[index])}
                      id={exportColumn}
                      handleClick={handleExportColumnChecked}
                      isChecked={isCheck.includes(exportColumn)}
                      style={{ textAlign: 'left', paddingLeft: '5px' }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="col-6">
              <Form.Label className="field-label date-picker-label">
                {t('page.short_by_label')}
              </Form.Label>
              <div className="row">
                <div className="col-9">
                  <Form.Group>
                    <Select
                      className="filter-column"
                      options={sortByExport.map((option) => {
                        option.label = convertToPlainText(option.label);

                        return option;
                      })}
                      value={sortByExport.filter((option) => option.value === sortBy)}
                      onChange={(selectedOption) => {
                        setSortBy(selectedOption.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-3">
                  <Form.Group>
                    <Select
                      className="filter-column"
                      options={sortOrderExport.map((option) => {
                        option.label = convertToPlainText(option.label);

                        return option;
                      })}
                      value={sortOrderExport.filter((option) => option.value === sortOrder)}
                      onChange={(selectedOption) => {
                        setSortOrder(selectedOption.value);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
              <Form.Label className="field-label date-picker-label mt-3">
                {t('page.group_by_label')}
              </Form.Label>
              <Form.Group>
                <Select
                  className="filter-column mb-3"
                  options={groupByExport}
                  value={groupByExport.filter((option) => option.value === groupBy)}
                  onChange={(selectedOption) => {
                    setGroupBy(selectedOption.value);
                  }}
                />
              </Form.Group>
              <div className={groupBy === 'status' ? '' : 'd-none'} style={{ display: 'contents' }}>
                <Form.Group>
                  <Select
                    className="filter-column"
                    options={options}
                    value={options.filter((option) => option.value === selectedStatus)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setSelectedStatus(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
              <div
                className={groupBy === 'truck_type' ? '' : 'd-none'}
                style={{ display: 'contents' }}>
                <Form.Group>
                  <Select
                    className="filter-column"
                    options={truckTypeOptions}
                    value={truckTypeOptions.filter((option) => option.value === truckType)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setByTruckType(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="primary-button">
            <TNButton
              type="button"
              loading={reportGenerateLoading}
              onClick={() => {
                downloadApiCall(1);
              }}>
              {t('page.excel_download_button_text')}
            </TNButton>
            <TNButton
              type="button"
              loading={reportGenerateLoading}
              onClick={() => {
                downloadApiCall(3);
              }}
              className="ms-2">
              {t('page.csv_download_button_text')}
            </TNButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
ListTrucksPage.propTypes = {
  row: PropTypes.any,
  value: PropTypes.any,
  columns: PropTypes.any,
  data: PropTypes.any,
  paginationData: PropTypes.any,
  t: PropTypes.func
};
export { ListTrucksPage };
