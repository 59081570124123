import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { dateFormatCommon, dateFormatManualAdd } from '@helpers/commonHelpers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { imagePreviewFromik } from '@helpers/';

const B1TypeLicenceDetails = (props) => {
  const b1_type_licence_attachmentRef = useRef();

  const { t, formik } = props;

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_driver_general_details_b1_type_licence_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.b1_type_licence && formik.errors.b1_type_licence
                    ? 'form-field-error'
                    : formik.touched.b1_type_licence && !formik.errors.b1_type_licence
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="b1_type_licence"
                placeholder={t('page.add_driver_general_details_b1_type_licence_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.b1_type_licence}
              />
              <div className="form-field-error-text">
                {formik.touched.b1_type_licence && formik.errors.b1_type_licence ? (
                  <div>{t(formik.errors.b1_type_licence)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_driver_general_details_b1_type_licence_expiry_date_label')}
              </Form.Label>
              <DatePicker
                onKeyDown={dateFormatManualAdd}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column"
                placeholderText={dateFormatCommon().toUpperCase()}
                selected={formik.values.b1_type_licence_expiry_date}
                dateFormat={dateFormatCommon()}
                minDate={new Date()}
                onChange={(date) => {
                  formik.setFieldValue('b1_type_licence_expiry_date', date);
                }}
                onBlur={(date) => {
                  var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                  if (date.currentTarget.value) {
                    if (!date1.isValid()) {
                      toast.error(t('page.invalid_date_format'));
                      formik.setFieldValue('b1_type_licence_expiry_date', '');
                    } else {
                      var currentDate = new Date();
                      if (currentDate > date1) {
                        formik.setFieldValue('b1_type_licence_expiry_date', '');
                      }
                    }
                  }
                }}
              />

              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.b1_type_licence_expiry_date &&
                  formik.errors.b1_type_licence_expiry_date
                    ? 'form-field-error'
                    : formik.touched.b1_type_licence_expiry_date &&
                      !formik.errors.b1_type_licence_expiry_date
                    ? 'form-field-success'
                    : '')
                }
                type="hidden"
                name="b1_type_licence_expiry_date"
                placeholder={t(
                  'page.add_driver_general_details_b1_type_licence_expiry_date_placeholder'
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.b1_type_licence_expiry_date || ''}
              />
              <div className="form-field-error-text">
                {formik.touched.b1_type_licence_expiry_date &&
                formik.errors.b1_type_licence_expiry_date ? (
                  <div>{t(formik.errors.b1_type_licence_expiry_date)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}></Col>
        </Row>
        <Row className="mt-3">
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label">
                {t('page.add_driver_b1_type_licence_attachment_label')}
              </Form.Label>
              <Form.Control
                className={'d-none'}
                type="file"
                accept="image/*,.pdf"
                ref={b1_type_licence_attachmentRef}
                name="b1_type_licence_attachment"
                placeholder={t('page.add_driver_b1_type_licence_attachment_placeholder')}
                onChange={(event) => {
                  formik.setFieldValue('b1_type_licence_attachment', event.currentTarget.files[0]);
                }}
                hidden
              />
              <Button
                type="button"
                onClick={() => b1_type_licence_attachmentRef.current?.click()}
                className={
                  'upload-button' ||
                  (formik.touched.b1_type_licence_attachment &&
                  formik.errors.b1_type_licence_attachment
                    ? 'form-field-error'
                    : formik.touched.b1_type_licence_attachment &&
                      !formik.b1_type_licence_attachment
                    ? 'form-field-success'
                    : '')
                }>
                {t('page.file_upload')}
              </Button>
            </Form.Group>
            <div className="form-field-error-text">
              {formik.touched.b1_type_licence_attachment &&
              formik.errors.b1_type_licence_attachment ? (
                <div>{t(formik.errors.b1_type_licence_attachment)}</div>
              ) : null}
            </div>
          </Col>
          <Col sm={12} md={4}></Col>
          <Col sm={12} md={4}></Col>
        </Row>
        <Row>
          <Col sm={12} md={4}>
            {formik.values.b1_type_licence_attachment && (
              <div className="preview-image">
                <img
                  src={imagePreviewFromik(formik.values.b1_type_licence_attachment)}
                  alt="profile_img"
                />
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={() => {
                    b1_type_licence_attachmentRef.current.value = null;
                    formik.setFieldValue('b1_type_licence_attachment', '');
                  }}
                />
              </div>
            )}
          </Col>
          <Col sm={12} md={4}></Col>
          <Col sm={12} md={4}></Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
B1TypeLicenceDetails.propTypes = {
  t: PropTypes.func,
  formik: PropTypes.any
};
export { B1TypeLicenceDetails };
