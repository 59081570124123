import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import { NavLink, Outlet } from 'react-router-dom';

const ReferralList = React.lazy(() => import('./ReferralList'));
const ReferralHistory = React.lazy(() => import('./ReferralHistory'));

const ScanPointRoutes = ({ t }) => {
  return (
    <>
      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to="/finance/referral/list">Daftar Produser</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/finance/referral/history">In / Out</NavLink>
        </Nav.Item>
      </Nav>

      <Outlet />

      <Routes>
        <Route exact path="/list" element={<ReferralList t={t} />} />
        <Route exact path="/history" element={<ReferralHistory t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};
ScanPointRoutes.propTypes = {
  t: PropTypes.func
};
export default ScanPointRoutes;
