import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { Header } from '../Header/Header';
import { Sidebar } from '../Sidebar/Sidebar';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { settingData } from '../../../store/features/settingSlice';
import { setCounter } from '../../../store/features/counterMessageSlice';

import { useLatestNotification } from '@hooks';
const LogoUrlFront = () => {
  const getSettingData = useSelector(settingData);
  return (
    <img
      src={getSettingData.dashboard_logo}
      alt="clenmediclogo"
      width={'100%'}
      className="nav-brand"
    />
  );
};

const PrivateLayout = (props) => {
  const dispatch = useDispatch();
  useLatestNotification((data) => {
    dispatch(setCounter({ count: data.data.unread }));
  });

  const { t } = useTranslation();
  const [isActive, setActive] = useState(false);
  const toggleClass = (status) => {
    setActive(status);
  };
  let bodyElement = document.getElementsByTagName('body')[0];
  bodyElement.className = isActive ? 'overly' : '';
  return (
    <div {...props}>
      <Header toggleClass={toggleClass} t={t} active={isActive} />
      <div id="wrapper" className={isActive ? 'toggled' : ''}>
        <Sidebar toggleClass={toggleClass} active={props.active} t={t} />
        <section id="content-wrapper" onClick={() => (isActive ? toggleClass(!isActive) : false)}>
          <Row>
            <Col lg={12} className="main-section">
              {props.children}
            </Col>
          </Row>
        </section>
      </div>
    </div>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.any.isRequired,
  active: PropTypes.string
};

export { PrivateLayout, LogoUrlFront };
