import * as Yup from 'yup';
let validationSchema = Yup.object({
  password: Yup.string()
    .required('page.reset_password_password_validation_required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'page.reset_password_password_validation_matches'
    ),
  confirm_password: Yup.string()
    .required('page.reset_password_confirm_password_validation_required')
    .oneOf([Yup.ref('password'), null], 'page.reset_passsword_confirm_password_validation_oneOf')
});

export default validationSchema;
