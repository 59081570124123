import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListScanPointPage = React.lazy(() => import('./ListScanPointPage'));
const AddScanPointPage = React.lazy(() => import('./AddScanPointUser'));
const EditScanPointUser = React.lazy(() => import('./EditScanPointUser'));

const ScanPointRoutes = ({ t }) => {
  return (
    <Routes>
      <Route exact path="/point/list" element={<ListScanPointPage t={t} />} />
      <Route exact path="/point/add" element={<AddScanPointPage t={t} />} />
      <Route exact path="/point/edit/:scan_point_id" element={<EditScanPointUser t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ScanPointRoutes.propTypes = {
  t: PropTypes.func
};
export default ScanPointRoutes;
