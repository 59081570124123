import client from '@libs/HttpClient';

class MenuMasterService {
  static getMenuList(params) {
    return client.get(`/role/menu-list`, { params });
  }

  static generateReport(request) {
    return client.post(`/master-report/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}

export { MenuMasterService };
