import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PostCodeRoute from './PostCode/PostCodeRoute';

const ListAreaPage = React.lazy(() => import('./ListAreaPage'));
const AddAreaPage = React.lazy(() => import('./AddAreaPage'));
const EditAreaPage = React.lazy(() => import('./EditAreaPage'));

const AreaRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index path="/list/:territory_id" element={<ListAreaPage t={t} />} />
      <Route path="/add/:territory_id" element={<AddAreaPage t={t} />} />
      <Route path="/edit/:territory_id/:area_id" element={<EditAreaPage t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />

      {/* Commission fee Routes */}
      <Route path="/postcode/*" element={<PostCodeRoute t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AreaRoutes.propTypes = {
  t: PropTypes.func
};
export default AreaRoutes;
