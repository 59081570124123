import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { usePlacesWidget } from 'react-google-autocomplete';
import { Alert, Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert'; // Import
import moment from 'moment';
import { toast } from 'react-toastify';

import { useDeleteDriverAttachment } from '@hooks';
import {
  dateFormatCommon,
  dateFormatManualAdd,
  defaultValue,
  imagePreviewFromik,
  ktpformat
} from '@helpers/';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { TNButton } from '@common/components';

const PlaceAutoComplete = (props) => {
  const { t, formik, setAddress } = props;
  const [firstLoad, setFirstLoad] = useState(true);
  const {
    suggestions: { status, data },
    setValue
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    const results = await getGeocode({ address });
    setAddress(results[0]);
  };

  const getAddressList = async (address) => {
    await setValue(address);
  };

  useEffect(() => {
    setFirstLoad(false);
    if (!firstLoad) {
      if (status == 'OK') {
        handleSelect(data[0].description);
      } else {
        toast.error('No location found');
        formik.setFieldValue('address', '');
      }
    }
  }, [data]);

  return (
    <>
      <Form.Group>
        <Form.Label className="field-label field-label-top">
          {t('page.add_driver_general_details_address_label')}
        </Form.Label>
        <InputGroup className="inner-group-box">
          <Form.Control
            className={
              formik.touched.address && formik.errors.address
                ? 'form-field-error'
                : formik.touched.address && !formik.errors.address
                ? 'form-field-success'
                : ''
            }
            type="text"
            name="address"
            placeholder={t('page.add_driver_general_details_address_placeholder')}
            onKeyDown={(e) => {
              if (e.key !== 'Enter') return;
              e.preventDefault();
              formik.setFieldValue('address', 'Searching...');
              formik.setFieldValue('city', '');
              formik.setFieldValue('state', '');
              formik.setFieldValue('postcode', '');
              formik.setFieldValue('country', '');
              formik.setFieldValue('latitude', '');
              formik.setFieldValue('longitude', '');
              getAddressList(formik.values.address);
            }}
            onChange={(event) => {
              if (event.currentTarget.value === '') {
                formik.setFieldValue('address', '');
                formik.setFieldValue('city', '');
                formik.setFieldValue('state', '');
                formik.setFieldValue('postcode', '');
                formik.setFieldValue('country', '');
                formik.setFieldValue('latitude', '');
                formik.setFieldValue('longitude', '');
              } else {
                formik.setFieldValue('address', event.currentTarget.value);
              }
            }}
            value={formik.values.address}
            onBlur={formik.handleBlur}
          />
          <TNButton
            onClick={() => {
              formik.setFieldValue('address', 'Searching...');
              formik.setFieldValue('city', '');
              formik.setFieldValue('state', '');
              formik.setFieldValue('postcode', '');
              formik.setFieldValue('country', '');
              formik.setFieldValue('latitude', '');
              formik.setFieldValue('longitude', '');
              getAddressList(formik.values.address);
            }}
            type="button">
            {t('page.show_address_results')}
          </TNButton>
        </InputGroup>
        <div className="form-field-error-text">
          {formik.touched.address && formik.errors.address ? (
            <div>{t(formik.errors.address)}</div>
          ) : null}
        </div>
      </Form.Group>
      <div className="map-areas">
        <Row>
          <Col lg={4} sm={12} className={formik.values.city ? '' : 'd-none'}>
            {formik.values.city ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                City:{formik.values.city}
              </Alert>
            ) : (
              ''
            )}
          </Col>
          <Col lg={4} sm={12} className={formik.values.state ? '' : 'd-none'}>
            {formik.values.state ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                State:{formik.values.state}
              </Alert>
            ) : (
              ''
            )}
          </Col>
          <Col lg={4} sm={12} className={formik.values.postcode ? '' : 'd-none'}>
            {formik.values.postcode ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                PostCode:{formik.values.postcode}
              </Alert>
            ) : (
              ''
            )}
          </Col>
          <Col lg={4} sm={12} className={formik.values.country ? '' : 'd-none'}>
            {formik.values.country ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                Country:{formik.values.country}
              </Alert>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

const EditGeneralDetails = (props) => {
  let LogoRef = useRef();
  const { t, countryIsLoad, countryCodeOptions, formik } = props;
  const [DeleteAttchmentId, setDeleteAttchmentId] = useState(false);
  const [DriverCenter, setDriverCenter] = useState({
    lat: -8.675835899999997,
    lng: 115.2127549
  });
  const [DriverZoom, setDriverZoom] = useState(18);

  useEffect(() => {
    setDriverCenter({
      lat: Number(formik.values.latitude),
      lng: Number(formik.values.longitude)
    });
  }, [formik.values.latitude, formik.values.longitude]);

  const containerStyle = {
    width: '100%',
    height: '300px'
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script'
  });

  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // setMap(map);
  }, []);
  const onUnmount = useCallback(function callback(map) {
    // setMap(null);
  }, []);
  const onLoadmarker = (marker) => {};
  /**
   * !This Block is for Delete Attachment
   */
  const handleDeleteClick = (tdata) => {
    let attchament_id = tdata.currentTarget.getAttribute('attchament_id');
    let field_name = tdata.currentTarget.getAttribute('field_name');
    if (attchament_id !== '') {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2>{t('page.delete_attachment_popup_message')}</h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    setDeleteAttchmentId(attchament_id);
                    formik.setFieldValue(field_name, '');
                    formik.setFieldValue(`${field_name}_id`, '');

                    onClose();
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      formik.setFieldValue(field_name, '');
      formik.setFieldValue(`${field_name}_id`, '');
    }
  };
  /**
   * !This Block will call delete attachment api
   */
  useDeleteDriverAttachment(
    [DeleteAttchmentId, 'user'],
    (res) => {
      toast.success(res.message);
      setDeleteAttchmentId(false);
    },
    (err) => {
      toast.error(err.message);
      setDeleteAttchmentId(false);
    }
  );
  // address google autocomplete
  const { ref: driveraddress } = usePlacesWidget({
    onPlaceSelected: (place) => {
      let address1 = place.formatted_address;
      let postcode = '';
      for (const component of place.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }

          case 'route': {
            address1 += component.short_name;
            break;
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }

          case 'locality':
            formik.setFieldValue('city', component.long_name);
            break;

          case 'administrative_area_level_1': {
            formik.setFieldValue('state', component.long_name);
            break;
          }

          case 'country':
            formik.setFieldValue('country', component.long_name);
            break;

          default:
            break;
        }
      }
      formik.setFieldValue('address', address1);
      formik.setFieldValue('postcode', postcode);
      formik.setFieldValue('latitude', place.geometry.location.lat());
      formik.setFieldValue('longitude', place.geometry.location.lng());
    },
    options: {
      types: ['establishment']
    }
  });

  const setAddress = (address) => {
    const place = address;
    const { lat, lng } = getLatLng(place);

    let address1 = place.formatted_address;
    let postcode = '';
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        // case 'street_number': {
        //   address1 = `${component.long_name} ${address1}`;
        //   break;
        // }

        // case 'route': {
        //   address1 += component.short_name;
        //   break;
        // }

        case 'postal_code': {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case 'locality':
          formik.setFieldValue('city', component.long_name);
          break;

        case 'administrative_area_level_1': {
          formik.setFieldValue('state', component.long_name);
          break;
        }
        case 'country':
          formik.setFieldValue('country', component.long_name);
          break;

        default:
          break;
      }
    }
    formik.setFieldValue('address', address1);
    formik.setFieldValue('postcode', postcode);
    formik.setFieldValue('latitude', lat);
    formik.setFieldValue('longitude', lng);
    setDriverCenter({
      lat: lat,
      lng: lng
    });
    setDriverZoom(18);
  };

  // user status option
  const StatusOption = [
    { value: 1, label: `${t('page.active_status_name')}` },
    { value: 0, label: `${t('page.in_active_status_name')}` }
  ];

  const getAddressFromLatLng = async (lat, lng) => {
    try {
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.MIX_GOOGLE_MAPS_API_KEY}`
      );
      const json = await res.json();

      for (const component of json.results[0].address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case 'postal_code': {
            formik.setFieldValue('postcode', component.long_name);
            break;
          }

          case 'administrative_area_level_1': {
            formik.setFieldValue('state', component.long_name);
            break;
          }

          case 'administrative_area_level_2': {
            formik.setFieldValue('city', component.long_name);
            break;
          }

          case 'country':
            formik.setFieldValue('country', component.long_name);
            break;

          default:
            break;
        }
      }

      return json.results[0].formatted_address;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_driver_general_details_first_name_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.first_name && formik.errors.first_name
                    ? 'form-field-error'
                    : formik.touched.first_name && !formik.errors.first_name
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="first_name"
                placeholder={t('page.add_driver_general_details_first_name_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
              />
              <div className="form-field-error-text">
                {formik.touched.first_name && formik.errors.first_name ? (
                  <div>{t(formik.errors.first_name)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_driver_general_details_last_name_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.last_name && formik.errors.last_name
                    ? 'form-field-error'
                    : formik.touched.last_name && !formik.errors.last_name
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="last_name"
                placeholder={t('page.add_driver_general_details_last_name_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
              />
              <div className="form-field-error-text">
                {formik.touched.last_name && formik.errors.last_name ? (
                  <div>{t(formik.errors.last_name)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_driver_general_details_email_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.email && formik.errors.email
                    ? 'form-field-error'
                    : formik.touched.email && !formik.errors.email
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="email"
                placeholder={t('page.add_driver_general_details_email_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <div className="form-field-error-text">
                {formik.touched.email && formik.errors.email ? (
                  <div>{t(formik.errors.email)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4}>
            <Form.Label className="field-label field-label-top">
              {t('page.add_driver_general_details_phone_label')}
            </Form.Label>
            <Row>
              <Col lg={6} xs={6}>
                <Select
                  name="country_id"
                  placeholder={t('page.add_driver_general_details_country_id_placeholder')}
                  value={defaultValue(countryCodeOptions, formik.values.country_id)}
                  defaultValue={formik.values.country_id}
                  onChange={(selectedOption) => {
                    if (selectedOption !== null) {
                      formik.setFieldValue('country_id', selectedOption.value);
                    } else {
                      formik.setFieldValue('country_id', '');
                    }
                  }}
                  className={
                    'contry-code ' +
                    (formik.touched.country_id && formik.errors.country_id
                      ? 'form-select-error'
                      : formik.touched.country_id && !formik.errors.country_id
                      ? 'form-select-success'
                      : '')
                  }
                  options={countryIsLoad ? countryCodeOptions : countryCodeOptions}
                />
                <div className="form-field-error-text">
                  {formik.touched.country_id && formik.errors.country_id ? (
                    <div>{t(formik.errors.country_id)}</div>
                  ) : null}
                </div>
              </Col>
              <Col lg={6} xs={6} className="px-0">
                <Form.Control
                  className={
                    'contact-number ' +
                    (formik.touched.phone && formik.errors.phone
                      ? 'form-field-error'
                      : formik.touched.phone && !formik.errors.phone
                      ? 'form-field-success'
                      : '')
                  }
                  type="text"
                  name="phone"
                  placeholder={t('page.add_driver_general_details_phone_placeholder')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
                <div className="form-field-error-text">
                  {formik.touched.phone && formik.errors.phone ? (
                    <div>{t(formik.errors.phone)}</div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_driver_general_details_date_of_birth_label')}
              </Form.Label>
              <DatePicker
                onKeyDown={dateFormatManualAdd}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column"
                placeholderText={dateFormatCommon().toUpperCase()}
                selected={formik.values.date_of_birth}
                dateFormat={dateFormatCommon()}
                maxDate={new Date()}
                onChange={(date) => {
                  formik.setFieldValue('date_of_birth', date);
                }}
                onBlur={(date) => {
                  var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                  if (!date1.isValid()) {
                    toast.error(t('page.invalid_date_format'));
                    formik.setFieldValue('date_of_birth', '');
                  }
                }}
              />
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.date_of_birth && formik.errors.date_of_birth
                    ? 'form-field-error'
                    : formik.touched.date_of_birth && !formik.errors.date_of_birth
                    ? 'form-field-success'
                    : '')
                }
                type="hidden"
                name="date_of_birth"
                max={moment().format('YYYY-MM-DD')}
                placeholder={t('page.add_driver_general_details_date_of_birth_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.date_of_birth || ''}
              />
              <div className="form-field-error-text">
                {formik.touched.date_of_birth && formik.errors.date_of_birth ? (
                  <div>{t(formik.errors.date_of_birth)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_driver_general_details_owner_ktp_label')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.owner_ktp && formik.errors.owner_ktp
                    ? 'form-field-error'
                    : formik.touched.owner_ktp && !formik.errors.owner_ktp
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="owner_ktp"
                onKeyUp={ktpformat}
                placeholder={t('page.add_driver_general_details_owner_ktp_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.owner_ktp}
              />
              <div className="form-field-error-text">
                {formik.touched.owner_ktp && formik.errors.owner_ktp ? (
                  <div>{t(formik.errors.owner_ktp)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={8}>
            {isLoaded && (
              <>
                <PlaceAutoComplete t={t} formik={formik} setAddress={setAddress} />
                <div className="mt-2">
                  {DriverCenter ? (
                    <>
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={DriverCenter}
                        zoom={DriverZoom}
                        onLoad={onLoad}
                        onPlaceSelected={(event) => {}}
                        onUnmount={onUnmount}>
                        <Marker
                          onLoad={onLoadmarker}
                          position={DriverCenter}
                          draggable={true}
                          onPositionChanged={(e) => {}}
                          onDragEnd={async (e) => {
                            formik.setFieldValue('latitude', e.latLng.lat());
                            formik.setFieldValue('longitude', e.latLng.lng());
                            setDriverCenter({
                              lat: e.latLng.lat(),
                              lng: e.latLng.lng()
                            });

                            const results = await getAddressFromLatLng(
                              e.latLng.lat(),
                              e.latLng.lng()
                            );
                            formik.setFieldValue('address', results);
                          }}
                        />
                      </GoogleMap>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.notes_label')}
                        </Form.Label>
                        <Form.Control
                          className={
                            formik.touched.address_notes && formik.errors.address_notes
                              ? 'form-field-error'
                              : formik.touched.address_notes && !formik.errors.address_notes
                              ? 'form-field-success'
                              : ''
                          }
                          as="textarea"
                          rows={4}
                          type="text"
                          name="address_notes"
                          placeholder={t('page.notes_placeholder')}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.address_notes}
                        />
                        <div className="form-field-error-text">
                          {formik.touched.address_notes && formik.errors.address_notes ? (
                            <div>{t(formik.errors.address_notes)}</div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ) : null}
                </div>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_driver_general_details_user_status_label')}
              </Form.Label>
              <Select
                isClearable={true}
                name="admin_status"
                options={StatusOption}
                placeholder={t('page.add_driver_general_details_user_status_placeholder')}
                onChange={(selected) => {
                  if (selected !== null) {
                    formik.setFieldValue('admin_status', selected.value);
                  } else {
                    formik.setFieldValue('admin_status', '');
                  }
                }}
                defaultValue={defaultValue(StatusOption, formik.values.admin_status)}
                value={defaultValue(StatusOption, formik.values.admin_status)}
                onBlur={formik.handleBlur}
                className={
                  formik.touched.admin_status && formik.errors.admin_status
                    ? 'form-select-error'
                    : formik.touched.admin_status && !formik.errors.admin_status
                    ? 'form-select-success'
                    : ''
                }
              />
              <div className="form-field-error-text">
                {formik.touched.admin_status && formik.errors.admin_status ? (
                  <div>{t(formik.errors.admin_status)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_driver_general_details_users_status_label')}
              </Form.Label>
              <Select
                isClearable={true}
                name="user_status"
                options={StatusOption}
                placeholder={t('page.add_driver_general_details_user_status_placeholder')}
                onChange={(selected) => {
                  if (selected !== null) {
                    formik.setFieldValue('user_status', selected.value);
                  } else {
                    formik.setFieldValue('user_status', '');
                  }
                }}
                defaultValue={formik.values.user_status}
                value={defaultValue(StatusOption, formik.values.user_status)}
                onBlur={formik.handleBlur}
                className={
                  formik.touched.user_status && formik.errors.user_status
                    ? 'form-select-error'
                    : formik.touched.user_status && !formik.errors.user_status
                    ? 'form-select-success'
                    : ''
                }
              />
              <div className="form-field-error-text">
                {formik.touched.user_status && formik.errors.user_status ? (
                  <div>{t(formik.errors.user_status)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4}>
            <Row>
              <Col lg={6} sm={12}>
                <Form.Label className="field-label field-label-top">
                  {t('page.add_driver_general_details_photo_label')}
                </Form.Label>
                <Form.Control
                  className={
                    'form-field ' +
                    (formik.touched.photo && formik.errors.photo
                      ? 'form-field-error'
                      : formik.touched.photo && !formik.errors.photo
                      ? 'form-field-success'
                      : '')
                  }
                  type="file"
                  hidden
                  name="photo"
                  accept="image/*"
                  placeholder={t('page.add_driver_general_details_photo_placeholder')}
                  onChange={(event) => {
                    formik.setFieldValue('photo', event.currentTarget.files[0]);
                  }}
                  ref={LogoRef}
                />
                <br />
                <Button
                  type="button"
                  onClick={() => {
                    LogoRef.current?.click();
                  }}
                  className={
                    'upload-button m-0' ||
                    (formik.touched.photo && formik.errors.photo
                      ? 'form-field-error'
                      : formik.touched.photo && !formik.errors.photo
                      ? 'form-field-success'
                      : '')
                  }>
                  {t('page.file_upload')}
                </Button>
              </Col>
              <Col lg={6} sm={12}>
                {formik.values.photo && (
                  <div className="preview-image">
                    <img src={imagePreviewFromik(formik.values.photo)} alt="profile_img" />
                    <FontAwesomeIcon
                      icon={faClose}
                      attchament_id={formik.values.photo_id}
                      field_name={'photo'}
                      onClick={(event) => {
                        handleDeleteClick(event);
                        LogoRef.current.value = null;
                      }}
                    />
                  </div>
                )}
              </Col>
              <div className="form-field-error-text">
                {formik.touched.photo && formik.errors.photo ? (
                  <div>{t(formik.errors.photo)}</div>
                ) : null}
              </div>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
EditGeneralDetails.propTypes = {
  t: PropTypes.func,
  countryCodeOptions: PropTypes.any,
  countryIsLoad: PropTypes.bool,
  formik: PropTypes.any
};
export { EditGeneralDetails };
