import client from '@libs/HttpClient';
class WarehouseService {
  static storeWarehouse(request) {
    return client.post('warehouse/store', request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }

  static listWarehouse(request) {
    const page_no = request.queryKey[1];
    const searchText = request.queryKey[2];
    const status = request.queryKey[3];
    const fromDate = request.queryKey[4];
    const toDate = request.queryKey[5];
    const selectedDateType = request.queryKey[6];
    const selectedOwnWarehouse = request.queryKey[7];
    const selectedFactory = request.queryKey[8];
    const sortBy = request.queryKey[9];
    const sortOrder = request.queryKey[10];

    return client.get(
      `warehouse/index?page=${page_no}&search_text=${searchText}&contract_status=${status}&from_date=${fromDate}&to_date=${toDate}&date_type=${selectedDateType}&own_warehouse=${selectedOwnWarehouse}&factory_id=${selectedFactory}&sort_by=${sortBy}&sort_order=${sortOrder}`,
      request
    );
  }

  static viewWarehouse(request) {
    return client.get(`/warehouse/show/${request}`);
  }

  static deleteWarehouseAttachment(request) {
    return client.put(
      `/warehouse/delete-attachment/${request.attachment_id}/${request.type}`,
      request
    );
  }

  static updateWarehouse(request) {
    const warehouse_id = request.get('warehouse_id');
    return client.post(`/warehouse/update/${warehouse_id}`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  static generateWarehouseReport(request) {
    return client.post(`/warehouse/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}
export { WarehouseService };
