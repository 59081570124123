import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { TNButton } from '@common/components';
import TNBreadCurm from '@common/components/TNBreadCurm';
import { useGenerateWarehouseReport, useListWarehouse, useViewFactoryList } from '@hooks';
import TNTable from '@common/components/TNTable';
import { dateFormatCommon, dateFormatManualAdd } from '@helpers/commonHelpers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Sort from '@common/components/Sorting';
import Checkbox from '../../common/components/Checkbox';
import { convertToPlainText } from '../../../helpers/commonHelpers';

const ListWarehousePage = ({ t }) => {
  const navigate = useNavigate();
  const [modalShowDownload, setModalShowDownload] = useState(false);
  /**
   * !This Block is for status drop down
   */
  const options = [
    { value: '', label: `${t('page.select_status')}` },
    { value: '1', label: t('page.contract_status_option_pending') },
    { value: '2', label: t('page.contract_status_option_inreview') },
    { value: '3', label: t('page.contract_status_option_active') },
    { value: '4', label: t('page.contract_status_option_hold') },
    { value: '5', label: t('page.contract_status_option_suspended') },
    { value: '6', label: t('page.contract_status_option_terminated') }
  ];

  /**
   * !This Block is for Date Type drop down
   */
  const dateTypes = [
    { value: '', label: `${t('page.select_date_type')}` },
    { value: '1', label: t('page.created_at_date_type') },
    { value: '2', label: t('page.contract_start_date_type') },
    { value: '3', label: t('page.contract_end_date_type') }
  ];

  /**
   * !This Block is for Date Type drop down
   */
  const ownWareHouseOptions = [
    { value: '', label: `${t('page.select_own_ware_house')}` },
    { value: '1', label: t('page.yes') },
    { value: '2', label: t('page.no') }
  ];

  const sortByExport = [
    { value: 'created_at', label: `${t('page.warehouse_created_at_and_id')}` },
    { value: 'warehouse_name', label: `${t('page.warehouse_name')}` },
    { value: 'territory_name', label: `${t('page.warehouse_terriotory_area_label')}` },
    { value: 'factory_name', label: `${t('page.warehouse_incineration_point')}` },
    { value: 'contract_start_date', label: `${t('page.warehouse_contract_start_date_label')}` },
    { value: 'contract_status', label: `${t('page.warehouse_contract_status_label')}` }
  ];

  const sortOrderExport = [
    { value: 'asc', label: `${t('page.asccending')}` },
    { value: 'desc', label: `${t('page.desccending')}` }
  ];

  const groupByExport = [
    { value: 'warehouse_name', label: `${t('page.users_user_status_name')}` },
    { value: 'factory_name', label: `${t('page.users_admin_status_name')}` },
    { value: 'warehouse_status', label: `${t('page.users_access_name')}` }
  ];

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    localStorage.adminWarehouseTable !== undefined && localStorage.adminWarehouseTable !== ''
      ? JSON.parse(localStorage.adminWarehouseTable).currentPage
      : 1
  );
  const [searchText, setSearchText] = useState(
    localStorage.adminWarehouseTable !== undefined && localStorage.adminWarehouseTable !== ''
      ? JSON.parse(localStorage.adminWarehouseTable).searchText
      : ''
  );

  const [searchTextFilter, setSearchTextFilter] = useState(
    localStorage.adminWarehouseTable !== undefined && localStorage.adminWarehouseTable !== ''
      ? JSON.parse(localStorage.adminWarehouseTable).searchTextFilter
      : ''
  );

  const [selectedStatus, setSelectedStatus] = useState(
    localStorage.adminWarehouseTable !== undefined && localStorage.adminWarehouseTable !== ''
      ? JSON.parse(localStorage.adminWarehouseTable).selectedStatus
      : ''
  );

  const [fromDate, setFromDate] = useState(
    localStorage.adminWarehouseTable !== undefined &&
      localStorage.adminWarehouseTable !== '' &&
      JSON.parse(localStorage.adminWarehouseTable).fromDate !== ''
      ? new Date(JSON.parse(localStorage.adminWarehouseTable).fromDate)
      : ''
  );
  const [toDate, setToDate] = useState(
    localStorage.adminWarehouseTable !== undefined &&
      localStorage.adminWarehouseTable !== '' &&
      JSON.parse(localStorage.adminWarehouseTable).toDate !== ''
      ? new Date(JSON.parse(localStorage.adminWarehouseTable).toDate)
      : ''
  );

  const [selectedDateType, setSelectedDateType] = useState(
    localStorage.adminWarehouseTable !== undefined && localStorage.adminWarehouseTable !== ''
      ? JSON.parse(localStorage.adminWarehouseTable).selectedDateType
      : ''
  );

  const [selectedOwnWarehouse, setSelectedOwnWarehouse] = useState(
    localStorage.adminWarehouseTable !== undefined && localStorage.adminWarehouseTable !== ''
      ? JSON.parse(localStorage.adminWarehouseTable).selectedOwnWarehouse
      : ''
  );

  const [selectedFactory, setSelectedFactory] = useState(
    localStorage.adminWarehouseTable !== undefined && localStorage.adminWarehouseTable !== ''
      ? JSON.parse(localStorage.adminWarehouseTable).selectedFactory
      : ''
  );

  const [sortBy, setSortBy] = useState(
    localStorage.adminWarehouseTable !== undefined && localStorage.adminWarehouseTable !== ''
      ? JSON.parse(localStorage.adminWarehouseTable).sortBy
      : 'created_at'
  );

  const [sortOrder, setSortOrder] = useState(
    localStorage.adminWarehouseTable !== undefined && localStorage.adminWarehouseTable !== ''
      ? JSON.parse(localStorage.adminWarehouseTable).sortOrder
      : 'desc'
  );

  const [groupBy, setGroupBy] = useState(
    localStorage.adminWarehouseTable !== undefined && localStorage.adminWarehouseTable !== ''
      ? JSON.parse(localStorage.adminWarehouseTable).groupBy
      : ''
  );

  const [paginationData, setPaginationData] = useState(null);

  /**
   * !This block is for set filter value in localstorage
   */
  useEffect(() => {
    let adminWarehouseTable = {
      searchText: searchText,
      searchTextFilter: searchTextFilter,
      currentPage: currentPage,
      selectedStatus: selectedStatus,
      fromDate: fromDate,
      toDate: toDate,
      selectedDateType: selectedDateType,
      selectedOwnWarehouse: selectedOwnWarehouse,
      selectedFactory: selectedFactory,
      sortBy: sortBy,
      sortOrder: sortOrder
    };
    localStorage.adminWarehouseTable = JSON.stringify(adminWarehouseTable);
  }, [
    currentPage,
    searchText,
    selectedStatus,
    fromDate,
    toDate,
    selectedDateType,
    selectedOwnWarehouse,
    selectedFactory,
    searchTextFilter,
    sortBy,
    sortOrder
  ]);
  const { mutate: doGenerateReport, isLoading: reportGenerateLoading } = useGenerateWarehouseReport(
    (response) => {
      toast.success(response.message);
      download(response.data.report_url);
      setModalShowDownload(false);
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );

  const download = (image) => {
    const fileName = image.split('/').pop();
    image = image.replace(window.origin, '');
    var link = document.createElement('a');
    link.href = image;
    link.target = '_blank';
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click'));
  };

  const downloadApiCall = (type) => {
    const values = {};
    values.report_type = type;
    values.search_text = searchText;
    values.by_status = selectedStatus;
    values.own_warehouse = selectedOwnWarehouse;
    values.factory = selectedFactory;
    values.from_date = fromDate !== '' ? moment(fromDate).format('YYYY-MM-DD') : '';
    values.to_date = toDate !== '' ? moment(toDate).format('YYYY-MM-DD') : '';
    values.date_type = selectedDateType;

    values.column_export = JSON.stringify(isCheck);

    doGenerateReport(values);
  };
  const handleSort = (name) => {
    setSortBy(name);
    sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc');
  };

  const breadcurmArray = [
    {
      label: t('page.warehouse_list_label'),
      link: '/warehouse/list',
      active: 'active'
    }
  ];
  /**
   * !This Function will call when user clicks on Edit Button
   * @param {*} tdata which is current element of button
   */
  const handleEditClick = (tdata) => {
    let warehouse_id = tdata.currentTarget.getAttribute('warehouse_id');
    navigate(`/warehouse/edit/${warehouse_id}`);
  };
  /**
   * !This Function will call when user clicks on View Button
   * @param {*} tdata which is current element of button
   */
  const handleViewClick = (tdata) => {
    let warehouse_id = tdata.currentTarget.getAttribute('warehouse_id');
    navigate(`/warehouse/view/${warehouse_id}`);
  };

  let factoryListOption = [];
  factoryListOption.push({
    value: '',
    label: t('page.select_factory')
  });
  /**
   * !This Api is for Factory drop down
   */
  const { data: factoryList, isSuccess: isFactorySuccess } = useViewFactoryList();
  if (isFactorySuccess !== false) {
    factoryList.data.map((item, i) => {
      return factoryListOption.push({ value: item.factory_id, label: item.name });
    });
  }

  const columnsjson = [
    {
      Header: (
        <div
          onClick={() => {
            handleSort('created_at');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.warehouse_created_at_and_id')
            }}></span>
          <Sort isActive={sortBy === 'created_at' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'created_at',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.created_at}
            <br />
            {row.original.warehouse_id}
          </div>
        );
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('name');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.warehouse_name')
            }}></span>
          <Sort isActive={sortBy === 'name' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'name',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return <div>{row.original.name}</div>;
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('territory_name');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.warehouse_terriotory_area_label')
            }}></span>
          <Sort isActive={sortBy === 'territory_name' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'type',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.territory_id}
            <br />
            {row.original.area_id}
          </div>
        );
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('factory_name');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.warehouse_incineration_point')
            }}></span>
          <Sort isActive={sortBy === 'factory_name' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'factory_id',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return <div>{row.original.factory_id}</div>;
      }
    },
    {
      Header: (
        <div className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.warehouse_own_warehouse_label')
            }}></span>
        </div>
      ),
      accessor: 'own_ware_house',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return <div>{row.original.own_ware_house}</div>;
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('contract_start_date');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.warehouse_contract_start_date_label')
            }}></span>
          <Sort isActive={sortBy === 'contract_start_date' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'contract_start_date',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <div>
            {row.original.contract_start_date} <br />
            {row.original.contract_end_date}
          </div>
        );
      }
    },
    {
      Header: (
        <div
          onClick={() => {
            handleSort('contract_status');
          }}
          className="sorting-main-div">
          <span
            className="me-2"
            dangerouslySetInnerHTML={{
              __html: t('page.warehouse_contract_status_label')
            }}></span>
          <Sort isActive={sortBy === 'contract_status' ? true : false} orderType={sortOrder} />
        </div>
      ),
      accessor: 'contract_status',
      disableSortBy: true,
      Cell: ({ value: initialValue, row }) => {
        return (
          <span
            className={
              row.original.contract_status_num === 3 ? 'status-active' : 'status-inactive'
            }>
            {t(initialValue)}
          </span>
        );
      }
    },
    {
      Header: `${t('page.action_column')}`,
      accessor: 'warehouse_id',
      Cell: ({ value: initialValue }) => {
        return (
          <div className="action_btn">
            <TNButton
              className="table-primary-button"
              warehouse_id={initialValue}
              onClick={handleViewClick.bind(this)}>
              {t('page.action_button_text_view')}
            </TNButton>
            <TNButton
              className="table-primary-button"
              warehouse_id={initialValue}
              onClick={handleEditClick.bind(this)}>
              {t('page.action_button_text_edit')}
            </TNButton>
          </div>
        );
      },
      disableSortBy: true
    }
  ];

  const [exportLabels, setExportLabels] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  /**
   * !This API will call while Page Load and set data. Once data load we are updating State
   */
  useListWarehouse(
    [
      currentPage,
      searchTextFilter,
      selectedStatus,
      fromDate !== '' ? moment(fromDate).format('YYYY-MM-DD') : '',
      toDate !== '' ? moment(toDate).format('YYYY-MM-DD') : '',
      selectedDateType,
      selectedOwnWarehouse,
      selectedFactory,
      sortBy,
      sortOrder
    ],
    (res) => {
      setExportLabels([
        'admin.warehouse_details_created_at',
        'page.warehouse_name',
        'admin.warehouse_npwp_number_csv',
        'admin.warehouse_contact_number_csv',
        'admin.warehouse_details_address_csv',
        'admin.warehouse_details_territory_name_csv',
        'admin.warehouse_details_truck_factory_csv',
        'admin.warehouse_details_own_warehouse_csv',
        'admin.warehouse_details_contarct_number_csv',
        'admin.warehouse_details_contarct_start_date_csv',
        'admin.warehouse_details_contarct_status_csv',
        'admin.warehouse_details_owner_details_csv',
        'admin.warehouse_details_owner_ktp_no_csv',
        'admin.warehouse_details_pic_details_csv',
        'admin.warehouse_details_accounting_details_csv'
      ]);

      setExportColumns([
        'warehouse_create_at',
        'warehouse_name',
        'warehouse_npwp_number',
        'warehouse_contact_number',
        'warehouse_address',
        'warehouse_territory_name',
        'warehouse_truck_factory',
        'warehouse_own_warehouse',
        'warehouse_contarct_number',
        'warehouse_contarct_start_date',
        'warehouse_contarct_status',
        'warehouse_owner_details',
        'warehouse_owner_ktp_no',
        'warehouse_pic_details',
        'warehouse_accounting_details'
      ]);

      setIsCheck(exportColumns);

      setData(res.data.warehouse_list);
      setPaginationData(res.data.pagination);
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => columnsjson, [sortBy, sortOrder]);
  if (paginationData === null) {
    return null;
  }

  const pageIndexHandle = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
  };

  /**
   * !This block will call on type in serachbox
   */
  const handleSearch = (search_text) => {
    setCurrentPage(1);
    setSearchText(search_text.target.value);
    if (search_text.target.value.length >= 3) {
      setSearchTextFilter(search_text.target.value);
    } else {
      setSearchTextFilter('');
    }
    // if (search_text.target.value.length >= 3) {
    //   setSearchText(search_text.target.value);
    // } else {
    //   setSearchText('');
    // }
  };

  const handleClearFilter = () => {
    setSearchText('');
    setSearchTextFilter('');
    setCurrentPage(1);
    setSelectedStatus('');
    setSelectedOwnWarehouse('');
    setSelectedFactory('');
    setSortBy('created_at');
    setSortOrder('desc');
    localStorage.removeItem('adminWarehouseTable');
  };
  /**
   * !This block will call on click clear date
   */
  const clearDateFilter = (date) => {
    setFromDate('');
    setToDate('');
    setSelectedDateType('');
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(exportColumns.map((li) => li));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleExportColumnChecked = (e) => {
    const { id, checked } = e.target;

    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />

      <Row>
        <Col lg={12}>
          <div className="filter">
            <div className="filter-field-space">
              <TNButton
                className="table-add-button"
                loading={false}
                type="button"
                onClick={() => navigate('/warehouse/add')}>
                {t('page.warehouse_add_link_text')}
              </TNButton>
              <TNButton
                className="table-add-button ml-0"
                loading={false}
                type="button"
                onClick={() => {
                  setModalShowDownload(true);
                }}>
                {t('page.payout_warehouse_download_button')}
              </TNButton>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group controlId="search" className="filter-field-space">
              <Form.Control
                placeholder={t('page.search_text')}
                className="filter-column form-field"
                onKeyUp={handleSearch}
                onChange={handleSearch}
                value={searchText}
              />
            </Form.Group>

            <Button
              type="button"
              onClick={handleClearFilter}
              className="secondary-remove-button filter-field-space ml-0">
              {t('page.cancel_search_button')}
            </Button>

            {/* <div className="table-add-button filter-field-space">
              <TNButton loading={false} type="button" onClick={addAgentHandle}>
                {t('page.add_producer_title')}
              </TNButton>
            </div> */}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={ownWareHouseOptions}
                value={ownWareHouseOptions.filter(
                  (option) => option.value === selectedOwnWarehouse
                )}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setSelectedOwnWarehouse(selectedOption.value);
                }}
              />
            </Form.Group>

            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={factoryListOption}
                value={factoryListOption.filter((option) => option.value === selectedFactory)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setSelectedFactory(selectedOption.value);
                }}
              />
            </Form.Group>
            <Form.Group controlId="search" className="filter-field-space">
              <Select
                className="filter-column"
                options={options}
                value={options.filter((option) => option.value === selectedStatus)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setSelectedStatus(selectedOption.value);
                }}
              />
            </Form.Group>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="filter">
            <Form.Group controlId="search">
              <Select
                className="filter-column"
                options={dateTypes}
                value={dateTypes.filter((option) => option.value === selectedDateType)}
                onChange={(selectedOption) => {
                  setCurrentPage(1);
                  setSelectedDateType(selectedOption.value);
                  setFromDate('');
                  setToDate('');
                }}
              />
            </Form.Group>

            <div
              className={selectedDateType !== '' ? '' : 'd-none'}
              style={{ display: 'contents' }}>
              <Form.Label className="field-label date-picker-label">
                {t('page.users_from_date_label')}
              </Form.Label>

              <Form.Group className="filter-field-space">
                <DatePicker
                  onKeyDown={dateFormatManualAdd}
                  dropdownMode="select"
                  showMonthDropdown
                  showYearDropdown
                  adjustDateOnChange
                  className="form-control filter-column"
                  placeholderText={dateFormatCommon().toUpperCase()}
                  selected={fromDate}
                  dateFormat={dateFormatCommon()}
                  onChange={(date) => setFromDate(date)}
                  maxDate={toDate !== '' && toDate !== undefined ? toDate : new Date()}
                  onBlur={(date) => {
                    var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                    if (date.currentTarget.value) {
                      if (!date1.isValid()) {
                        toast.error(t('page.invalid_date_format'));
                        setFromDate('');
                      } else {
                        var maxDate = toDate !== '' && toDate !== undefined ? toDate : new Date();
                        if (date1 > maxDate) {
                          toast.error(t('page.from_date_less_than_to_Date'));
                          setFromDate('');
                        }
                      }
                    }
                  }}
                />
              </Form.Group>
              <Form.Label className="field-label date-picker-label">
                {t('page.users_to_date_label')}
              </Form.Label>
              <Form.Group className="filter-field-space">
                <DatePicker
                  onKeyDown={dateFormatManualAdd}
                  dropdownMode="select"
                  showMonthDropdown
                  showYearDropdown
                  adjustDateOnChange
                  className="form-control filter-column"
                  placeholderText={dateFormatCommon().toUpperCase()}
                  minDate={fromDate}
                  selected={toDate}
                  dateFormat={dateFormatCommon()}
                  onChange={(date) => setToDate(date)}
                  onBlur={(date) => {
                    var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                    if (date.currentTarget.value) {
                      if (!date1.isValid()) {
                        toast.error(t('page.invalid_date_format'));
                        setToDate('');
                      } else {
                        var minDate = fromDate !== '' && fromDate !== undefined ? fromDate : date1;
                        if (date1 < minDate) {
                          toast.error(t('page.to_date_greater_than_from_Date'));
                          setToDate('');
                        }
                      }
                    }
                  }}
                />
              </Form.Group>
            </div>
            <TNButton
              className="secondary-add-button"
              loading={false}
              type="button"
              onClick={clearDateFilter}>
              {t('page.user_list_clear_date_filter_button')}
            </TNButton>
          </div>
        </Col>
      </Row>
      <h1> {t('page.warehouse_list_header_label')} </h1>
      <TNTable
        columns={columns}
        data={data}
        paginationData={paginationData}
        onSelectPage={pageIndexHandle}
        t={t}
        key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)}
        pageIndexGet={currentPage - 1}
      />
      <Modal
        show={modalShowDownload}
        size={'xl'}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header className="text-center">
          <h1>{t('page.warehouse_select_download_button')}</h1>
          <div className="close-popup">
            <FontAwesomeIcon
              icon={faClose}
              onClick={() => {
                setModalShowDownload(false);
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-5">
              <div className="select-all-bar">
                <Checkbox
                  type="checkbox"
                  id="selectAll"
                  name="SelectAll"
                  label={t('page.select_all')}
                  handleClick={handleSelectAll}
                  isChecked={isCheckAll}
                  style={{ textAlign: 'left' }}
                />
              </div>
              <div
                style={{ height: '200px', overflowY: 'scroll', border: '1px solid #ced4da' }}
                className="mb-5">
                {exportColumns.map((exportColumn, index) => {
                  return (
                    <Checkbox
                      key={index}
                      type="checkbox"
                      name={exportColumn}
                      label={t(exportLabels[index])}
                      id={exportColumn}
                      handleClick={handleExportColumnChecked}
                      isChecked={isCheck.includes(exportColumn)}
                      style={{ textAlign: 'left', paddingLeft: '5px' }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="col-4">
              <Form.Label className="field-label date-picker-label">
                {t('page.short_by_label')}
              </Form.Label>
              <div className="row">
                <div className="col-9">
                  <Form.Group>
                    <Select
                      className="filter-column"
                      options={sortByExport.map((option) => {
                        option.label = convertToPlainText(option.label);

                        return option;
                      })}
                      value={sortByExport.filter((option) => option.value === sortBy)}
                      onChange={(selectedOption) => {
                        setSortBy(selectedOption.value);
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="col-3">
                  <Form.Group>
                    <Select
                      className="filter-column"
                      options={sortOrderExport.map((option) => {
                        option.label = convertToPlainText(option.label);

                        return option;
                      })}
                      value={sortOrderExport.filter((option) => option.value === sortOrder)}
                      onChange={(selectedOption) => {
                        setSortOrder(selectedOption.value);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
              <Form.Label className="field-label date-picker-label mt-3">
                {t('page.group_by_label')}
              </Form.Label>
              <Form.Group>
                <Select
                  className="filter-column mb-3"
                  options={groupByExport}
                  value={groupByExport.filter((option) => option.value === groupBy)}
                  onChange={(selectedOption) => {
                    setGroupBy(selectedOption.value);
                  }}
                />
              </Form.Group>
              <div
                className={groupBy === 'warehouse_name' ? '' : 'd-none'}
                style={{ display: 'contents' }}>
                <Form.Group controlId="search">
                  <Select
                    className="filter-column"
                    options={ownWareHouseOptions}
                    value={ownWareHouseOptions.filter(
                      (option) => option.value === selectedOwnWarehouse
                    )}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setSelectedOwnWarehouse(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
              <div
                className={groupBy === 'factory_name' ? '' : 'd-none'}
                style={{ display: 'contents' }}>
                <Form.Group controlId="search">
                  <Select
                    className="filter-column"
                    options={factoryListOption}
                    value={factoryListOption.filter((option) => option.value === selectedFactory)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setSelectedFactory(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
              <div
                className={groupBy === 'warehouse_status' ? '' : 'd-none'}
                style={{ display: 'contents' }}>
                <Form.Group controlId="search">
                  <Select
                    className="filter-column"
                    options={options}
                    value={options.filter((option) => option.value === selectedStatus)}
                    onChange={(selectedOption) => {
                      setCurrentPage(1);
                      setSelectedStatus(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="col-3">
              <Form.Label className="field-label date-picker-label">
                {t('page.users_date_type_label')}
              </Form.Label>
              <Form.Group controlId="search" className="mb-3">
                <Select
                  className="filter-column"
                  options={dateTypes}
                  value={dateTypes.filter((option) => option.value === selectedDateType)}
                  onChange={(selectedOption) => {
                    setCurrentPage(1);
                    setSelectedDateType(selectedOption.value);
                    setFromDate('');
                    setToDate('');
                  }}
                />
              </Form.Group>

              <div
                className={selectedDateType !== '' ? '' : 'd-none'}
                style={{ display: 'contents' }}>
                <Form.Label className="field-label date-picker-label">
                  {t('page.users_from_date_label')}
                </Form.Label>

                <Form.Group>
                  <DatePicker
                    onKeyDown={dateFormatManualAdd}
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    adjustDateOnChange
                    className="form-control filter-column"
                    placeholderText={dateFormatCommon().toUpperCase()}
                    selected={fromDate}
                    dateFormat={dateFormatCommon()}
                    onChange={(date) => setFromDate(date)}
                    maxDate={toDate !== '' && toDate !== undefined ? toDate : new Date()}
                    onBlur={(date) => {
                      var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                      if (date.currentTarget.value) {
                        if (!date1.isValid()) {
                          toast.error(t('page.invalid_date_format'));
                          setFromDate('');
                        } else {
                          var maxDate = toDate !== '' && toDate !== undefined ? toDate : new Date();
                          if (date1 > maxDate) {
                            toast.error(t('page.from_date_less_than_to_Date'));
                            setFromDate('');
                          }
                        }
                      }
                    }}
                  />
                </Form.Group>
                <Form.Label className="field-label date-picker-label mt-3">
                  {t('page.users_to_date_label')}
                </Form.Label>
                <Form.Group>
                  <DatePicker
                    onKeyDown={dateFormatManualAdd}
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    adjustDateOnChange
                    className="form-control filter-column"
                    placeholderText={dateFormatCommon().toUpperCase()}
                    minDate={fromDate}
                    selected={toDate}
                    dateFormat={dateFormatCommon()}
                    onChange={(date) => setToDate(date)}
                    onBlur={(date) => {
                      var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                      if (date.currentTarget.value) {
                        if (!date1.isValid()) {
                          toast.error(t('page.invalid_date_format'));
                          setToDate('');
                        } else {
                          var minDate =
                            fromDate !== '' && fromDate !== undefined ? fromDate : date1;
                          if (date1 < minDate) {
                            toast.error(t('page.to_date_greater_than_from_Date'));
                            setToDate('');
                          }
                        }
                      }
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="primary-button">
            <TNButton
              type="button"
              loading={reportGenerateLoading}
              onClick={() => {
                downloadApiCall(1);
              }}>
              {t('page.excel_download_button_text')}
            </TNButton>
            <TNButton
              type="button"
              loading={reportGenerateLoading}
              onClick={() => {
                downloadApiCall(3);
              }}
              className="ms-2">
              {t('page.csv_download_button_text')}
            </TNButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
ListWarehousePage.propTypes = {
  t: PropTypes.func,
  columns: PropTypes.any,
  data: PropTypes.any,
  paginationData: PropTypes.any,
  row: PropTypes.any,
  value: PropTypes.any
};
export { ListWarehousePage };
