import { TNButton } from '@common/components/';
import AlertCommon from '@common/components/AlertCommon';
import TNBreadCurm from '@common/components/TNBreadCurm';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUpdateTruck } from '@hooks';
import client from '@libs/HttpClient';
import { Form, Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { validationSchema } from './AddTrucksValidation';
import { EditAttachmentFiles, GeneralDetails } from './components';
import { KirDetails } from './components/KirDetails';
import { StnkDetails } from './components/StnkDetails';

const EditTruckPage = ({ t }) => {
  const [accrdKey, setAccrdKey] = useState(0);
  let { truck_id } = useParams();
  const [transporter_id, setTransporter_id] = useState(0);
  const [truck_data, setTruck_data] = useState();
  const navigate = useNavigate();

  /**
   * !This API will call when page set. When response came we are setting up data into the form
   */

  async function fetchData(truck_id) {
    try {
      const data = await client.get('/transporter/trucks/view/' + truck_id);
      setTruck_data(data);
      setTransporter_id(data.data.transporter_id);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData(truck_id);
  }, [truck_id]);

  // let transporter_id = truck_data?.data?.transporter_id;

  const {
    mutate: doUpdateTruck,
    isLoading,
    error: addTRList,
    isError: addTRError
  } = useUpdateTruck(
    (res) => {
      toast.success(res.message);
      navigate(`/transporter/truck/list/${transporter_id}`);
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );

  if (!truck_data) {
    return 'loading...';
  }

  // From this api we are getting country code list.
  //const { isLoading: countryIsLoad } = useGetCountryCodeList();

  /**
   * This function is use when click on cancel button
   */

  const handleCancel = (dirty) => {
    if (dirty && dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message')
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(`/transporter/truck/list/${transporter_id}`);
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      navigate(`/transporter/truck/list/${transporter_id}`);
    }
  };
  /**
   * !This block will call on click submit button
   */

  /**
   * !This block is for initial form values
   */
  const initialValues = {
    truck_id: truck_id,
    transporter: truck_data.data.transporter_id,
    brand_name: truck_data.data.brand_name,
    model_name: truck_data.data.model_name,
    road_date: truck_data.data.road_date !== '' ? truck_data.data.road_date : '',
    plate_number: truck_data.data.plate_number,
    weight: truck_data.data.weight,
    max_weight_kg: truck_data.data.max_weight_kg,
    max_weight_ltr: truck_data.data.max_weight_ltr,
    is_active: truck_data.data.is_active,
    type: truck_data.data.type,
    conformity_certificate: truck_data.data.conformity_certificate,
    conformity_certificate_id: truck_data.data.conformity_certificate_id,
    truck_photos: truck_data.data.truck_photos,
    alliance_portal_id: truck_data.data.transporter_id,
    stnk: truck_data.data.stnk,
    stnk_expired_date:
      truck_data.data.stnk_expired_date !== '' ? truck_data.data.stnk_expired_date : '',
    registration_card: truck_data.data.registration_card,
    registration_card_id: truck_data.data.registration_card_id,
    kir: truck_data.data.kir,
    kir_expired_date:
      truck_data.data.kir_expired_date !== '' ? truck_data.data.kir_expired_date : '',
    kir_card: truck_data.data.kir_card,
    kir_card_id: truck_data.data.kir_card_id,
    gps_tracker: truck_data.data.gps_tracker
  };

  const breadcurmArray = [
    {
      label: t('page.transporter_list_title'),
      link: `/transporter/list`,
      active: ''
    },
    {
      label: t('page.transporter_view_details'),
      link: `/transporter/truck/list/${transporter_id}`,
      active: ''
    },
    {
      label: t('page.edit_truck_title'),
      link: `/transporter/truck/list/${transporter_id}`,
      active: 'active'
    }
  ];

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />

      <AlertCommon key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)} is_error={addTRError}>
        {addTRList}
      </AlertCommon>
      <h1>{t('page.edit_truck_title')} </h1>
      <Card>
        <Card.Body className="p-0">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const formData = new FormData();
              for (let key in values) {
                let value = values[key];

                if (key === 'truck_photos') {
                  for (let keycoll in value) {
                    let valuecolle = value[keycoll];
                    formData.append(`truck_photos[${keycoll}]`, valuecolle.image);
                  }
                } else {
                  formData.append(key, value);
                }
              }

              if (values.road_date !== '') {
                formData.append('road_date', moment(values.road_date).format('YYYY-MM-DD'));
              }

              if (values.stnk_expired_date !== '') {
                formData.append(
                  'stnk_expired_date',
                  moment(values.stnk_expired_date).format('YYYY-MM-DD')
                );
              }

              if (values.kir_expired_date !== '') {
                formData.append(
                  'kir_expired_date',
                  moment(values.kir_expired_date).format('YYYY-MM-DD')
                );
              }

              doUpdateTruck(formData);
            }}>
            {({ values, dirty }) => {
              if (values.road_date !== '') {
                if (values.road_date !== '0000-00-00') {
                  values.road_date = new Date(values.road_date);
                } else {
                  values.road_date = '';
                }
              }

              if (values.stnk_expired_date !== '') {
                if (values.stnk_expired_date !== '0000-00-00') {
                  values.stnk_expired_date = new Date(values.stnk_expired_date);
                } else {
                  values.stnk_expired_date = '';
                }
              }

              if (values.kir_expired_date !== '') {
                if (values.kir_expired_date !== '0000-00-00') {
                  values.kir_expired_date = new Date(values.kir_expired_date);
                } else {
                  values.kir_expired_date = new Date(values.road_date);
                }
              }

              return (
                <Form>
                  <Accordion
                    key={accrdKey}
                    alwaysOpen
                    defaultActiveKey={accrdKey && accrdKey !== 0 ? accrdKey : 1}>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 1}
                      className="my-1">
                      <Accordion.Header className="text-info fw-bold shadow-nond">
                        {t('page.add_truck_general_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <GeneralDetails t={t}></GeneralDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 2}
                      className="my-1">
                      <Accordion.Header className="text-info fw-bold shadow-nond">
                        {t('page.add_truck_stnk_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <StnkDetails t={t}></StnkDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 3}
                      className="my-1">
                      <Accordion.Header className="text-info fw-bold shadow-nond">
                        {t('page.add_truck_kir_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <KirDetails t={t}></KirDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 6}
                      className="border-bottom my-1">
                      <Accordion.Header>{t('page.add_trucks_attachment_title')} </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <EditAttachmentFiles t={t}></EditAttachmentFiles>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="primary-button">
                    <span className="link-center" onClick={() => handleCancel(dirty)}>
                      {t('page.add_transporter_cancel_button')}
                    </span>
                    <TNButton
                      loading={isLoading}
                      onClick={() => {
                        setAccrdKey(1);
                      }}
                      type="submit"
                      isdirtyform={dirty && dirty !== undefined ? 1 : 0}>
                      {t('page.add_transporter_submit_button')}
                    </TNButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
};
EditTruckPage.propTypes = {
  t: PropTypes.func
};
export { EditTruckPage };
