import client from '@libs/HttpClient';

class CompletedIncinerationService {
  static viewCompletedIncinerationService(params) {
    return client.get(`tour/incineration-complete/list`, { params });
  }

  static viewCompletedIncinerationServiceDetails(delivery_point_current_session_id) {
    return client.get(`tour/incineration-complete/details/${delivery_point_current_session_id}`);
  }

  static useCompletedIncinerationServiceAddNotes(request) {
    return client.post('tour/incineration-complete/add-notes', request);
  }

  static generateReport(request) {
    return client.post(`/tour/incineration-complete/generate-report`, request, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
}

export { CompletedIncinerationService };
