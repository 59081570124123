import { useMutation, useQuery } from 'react-query';

import { toast } from 'react-toastify';
import { UnloadTransportTruckService } from '@api/';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useUnloadTransportTruck = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['tour/transport-truck-loading/list', [params]],
    () => UnloadTransportTruckService.viewUnloadTransportTruck(params),
    {
      onSuccess,
      onError
    }
  );
};

const useViewUnloadTransportTruckDetails = (
  delivery_point_current_session_id,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    'tour/transport-truck-unload/details',
    () =>
      UnloadTransportTruckService.viewUnloadTransportTruckDetails(
        delivery_point_current_session_id
      ),
    {
      onSuccess,
      onError
    }
  );
};

const useUnloadTransportAddNotes = (onSuccess, onError = onDefaultError) => {
  return useMutation(UnloadTransportTruckService.useUnloadTransportAddNotes, {
    onSuccess,
    onError
  });
};

const useGenerateUnloadTransportTruckReport = (onSuccess, onError = onDefaultError) => {
  return useMutation(UnloadTransportTruckService.generateReport, {
    onSuccess,
    onError
  });
};
// export { useLoadTransportTruck, useViewLoadTransportTruckDetails, useTransportAddNotes };
export {
  useUnloadTransportTruck,
  useViewUnloadTransportTruckDetails,
  useUnloadTransportAddNotes,
  useGenerateUnloadTransportTruckReport
};
