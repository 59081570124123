import client from '@libs/HttpClient';

class DeliverySessionService {
  static viewDeliverySessionList(params) {
    return client.get(`delivery-session/list`, { params });
  }
  
  static viewIncinerationList(params) {
    return client.get(`delivery-session/incineration/list`, { params });
  }
}

export { DeliverySessionService };
