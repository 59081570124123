// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyBqwtFGSGVQ29HHSxKD4PX67NKtcCHQx0E',
  authDomain: 'cleanmedicweb.firebaseapp.com',
  projectId: 'cleanmedicweb',
  storageBucket: 'cleanmedicweb.appspot.com',
  messagingSenderId: '443766987284',
  appId: '1:443766987284:web:3a0fd38551e277d6136501',
  measurementId: 'G-5T4LLW3T1L'
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: `BKrMAgqSDeObZpzsBeTJHSBrMP9mJvKnjc6hfys6lLr6dWNSeq23Yk3yD_NsfeiPRGQuCc3gcx1-MjTUuPbnewc`
  })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem('fcmToken', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
      }
    })
    .catch((err) => {});
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
