import TNBreadCurm from '@common/components/TNBreadCurm';
import { TNButton } from '@common/components/TNButton';
import { useProducerList } from '@hooks';
import client from '@libs/HttpClient';
import { Formik } from 'formik';
import { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  producer_id: Yup.number().required(),
  price: Yup.number().min(1).required().typeError('price must be a number')
});

export function EditFactoryProducer({ t }) {
  let { factory_id, factory_producer_id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const { data, isLoading: loadingFetch } = useQuery('factory-producer' + factory_producer_id, () =>
    client.get(`/factory/${factory_id}/producer/${factory_producer_id}`)
  );

  const breadcurmArray = [
    {
      label: t('page.factory_list_title'),
      link: '/factory/list'
    },
    {
      label: t('page.view_factory_title'),
      link: `/factory/${factory_id}/producer`
    },
    {
      label: t('page.edit_factory_producer'),
      active: 'active'
    }
  ];
  const defaultValue = (option, value) => {
    return option ? option.find((option) => option.value === value) : '';
  };

  let producer_options = [];
  const { isSuccess: ProducerSuccess, data: producerList } = useProducerList();

  if (ProducerSuccess !== false) {
    producer_options = producerList.data.map((item, i) => {
      return { value: item.producer_id, label: item.name };
    });
  }

  const handleSubmit = async (values, errors) => {
    setLoading(true);
    try {
      const resp = await client.put(`/factory/${factory_id}/producer/${factory_producer_id}`, {
        producer_id: values.producer_id,
        price: values.price
      });
      toast.success(resp.message);
      navigate(`/factory/${factory_id}/producer`);
    } catch (error) {
      console.error(error);
      errors.setFieldError('producer_id', t('page.duplicate_producer'));
    }

    setLoading(false);
  };

  if (loadingFetch) {
    return <>loading</>;
  }

  return (
    <>
      <TNBreadCurm breadcurmArray={breadcurmArray} />

      <Card>
        <h1 className="page-heading-center">{t('page.edit_factory_producer')}</h1>
        <Formik
          initialValues={data.data}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}>
          {(props) => (
            <Form onSubmit={props.handleSubmit} className="edit-profile-form">
              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.producer')}
                </Form.Label>
                <Select
                  name="producer_id"
                  placeholder={t('Producer')}
                  options={producer_options}
                  onChange={(selected) => {
                    if (selected !== null) {
                      props.setFieldValue('producer_id', selected.value);
                    } else {
                      props.setFieldValue('producer_id', '');
                    }
                  }}
                  value={defaultValue(producer_options, props.values.producer_id)}
                  defaultValue={props.value?.producer_id}
                  onBlur={props.handleBlur}
                  className={
                    'form-field ' +
                    (props.touched.producer_id && props.errors.producer_id
                      ? 'form-field-error'
                      : props.touched.producer_id && !props.errors.producer_id
                      ? 'form-field-success'
                      : '')
                  }
                />
                <div className="form-field-error-text">
                  {props.touched.producer_id && props.errors.producer_id ? (
                    <div>{t(props.errors.producer_id)}</div>
                  ) : null}
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label className="field-label field-label-top">{t('page.price')}</Form.Label>
                <Form.Control
                  className={
                    'form-field ' +
                    (props.touched.price && props.errors.price
                      ? 'form-field-error'
                      : props.touched.price && !props.errors.price
                      ? 'form-field-success'
                      : '')
                  }
                  type="text"
                  name="price"
                  placeholder={t('page.price')}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.price}
                />
                <div className="form-field-error-text">
                  {props.touched.price && props.errors.price ? (
                    <div>{t(props.errors.price)}</div>
                  ) : null}
                </div>
              </Form.Group>

              <div className="primary-button">
                <span
                  className="link-center"
                  onClick={() => navigate(`/factory/${factory_id}/producer`)}>
                  {t('page.cancel_button_text')}
                </span>
                <TNButton
                  type="submit"
                  loading={isLoading}
                  isdirtyform={props.dirty && props.dirty !== undefined ? 1 : 0}>
                  {t('page.save_button_text')}
                </TNButton>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </>
  );
}
